import React from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";

const Prothesiste = () => {
  const navigate = useNavigate();
  return (
    <div className={"prothestsContainer"}>
      <h1>Médecin Dentiste ou Prothésiste?</h1>
      <p>
        Notre Plateforme Tunisienne est desinée pour Vous!{"  "}
        <span>créer Votre Compte ..</span>
      </p>
      <div className={"prothessticSmallContainer"}>
        <div className={"ProthesticCard card1"}>
          <h6>Médecin Dentiste</h6>
          <p>
            Vous pouvez bénéficier des avantages de la pateforme en vous
            inscrivant...
          </p>
          <button onClick={() => navigate("/register?role=dentiste")}>
            S’inscrire
          </button>
        </div>
        <div className={"ProthesticCard card2"}>
          <h6>Laboratoire de Prothèse Dentaire</h6>
          <p>
            En Tant Que Professionnel En Prothèse Dentaire. Bénéficier des
            avantages de la Platefomre en vous inscrivant.{" "}
          </p>
          <button onClick={() => navigate("/register?role=laboratoire")}>
            S’inscrire
          </button>
        </div>
      </div>
    </div>
  );
};

export default Prothesiste;
