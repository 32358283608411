import {
  Card,
  Form,
  Table,
  Col,
  Button,
  Dropdown,
  Modal,
  Badge,
  FormGroup,
  FormControl,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowAltCircleRight,
  faCheckCircle,
  faCircle,
  faCircleNotch,
  faEdit,
  faEllipsisV,
  faEye,
  faFlag,
  faMoneyBill,
  faPlusCircle,
  faSync,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { translate_fr, translate_column_value } from "../../../../lang/fr";
import Paginator from "../Paginator";
import EditAgent from "../Modals/EditAgent";
import CheckToggle from "../CheckToggle";
import CustomToast from "../CustomToast";
import moment from "moment";
import { axiosInstance } from "../../../../config/axios";
import { APIURL } from "../../../../configApi";
import { NotificationManager } from "react-notifications";
import LaboratoryService from "../../../services/LaboratoireService";
import PaginatorPayments from "../Paginator/PaginatorPayments";

export default function (props) {
  const [checked, setChecked] = useState(false);
  const [keys, setKeys] = useState([]);
  const [payment, setPayment] = useState({});
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDefault, setShowDefault] = useState(false);

  const [price, setPrice] = useState();
  const [dentist_id, setDentist_id] = useState();
  const [code_cheque, setCodeCheque] = useState();
  const [paymentMode, setPaymentMode] = useState("");
  const {
    pages,
    payments,
    headColumns,
    name,
    refreshPayments,
    dentists,
    setPayments,
  } = props;

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setShowDefault(false);
    setShowEditForm(false);
  };

  useEffect(() => {
    // console.log(payments);
    if (payments.length > 0) setKeys(Object.keys(payments[0]));
    renderThs();
  }, []);

  const renderThs = () => {
    let selectedKeys = [];
    //console.log("hello first payment ==> ",payments)
    if (headColumns.length > 0 && payments.length > 0)
      headColumns.map((key) => {
        // console.log("data in if ==[>", key, keys);
        if (Object.keys(payments[0]).includes(key)) selectedKeys.push(key);
      });
    // console.log(selectedKeys);
    setKeys(selectedKeys);

    // console.log("hello keys ...", keys);
  };
  const editPayment = (payment) => {};

  const renderLigne = () => {
    return payments.map((payment) => (
      <tr title={payment.description}>
        {renderColumn(payment)}
        <td>
          {payment.is_used === false ? (
            <Dropdown drop={faEllipsisV} className="me-2 mb-2">
              <Dropdown.Toggle split variant="default">
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  className="dropdown-arrow"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu
                className={"user-dropdown dropdown-menu-right mt-2"}
              >
                <Dropdown.Item
                  disabled={payment.is_totaly_used}
                  className={"text-success"}
                  onClick={() => {
                    setPayment(payment);
                    setDentist_id(payment.dentist_id);
                    setPaymentMode(payment.payment_type);
                    setCodeCheque(payment.description);
                    setPrice(payment.price);
                    setShowEditForm(true);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="dropdown-arrow me-2"
                  />{" "}
                  Modifier
                </Dropdown.Item>

                <Dropdown.Item
                  disabled={true}
                  onClick={() => {
                    setShowDefault(true);

                    setPayment(payment);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="dropdown-arrow me-2"
                  />{" "}
                  Supprimer
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            ""
          )}
        </td>
      </tr>
    ));
  };

  const renderColumn = (data) => {
    return headColumns.map((k, i) =>
      Object.keys(data).includes(k.toString()) ? (
        <td> {getObjectKeyValue(data, k)}</td>
      ) : (
        ""
      )
    );
  };

  const getObjectKeyValue = (obj, key) => {
    // console.log("get object value", obj, key);

    return Object.entries(obj).map((e) =>
      e[0] == key ? (
        ["created_at", "updated_at"].includes(e[0]) ? (
          moment.unix(e[1]).format("DD-MM-YYYY")
        ) : typeof e[1] == "string" ? (
          translate_column_value(e[1])
        ) : ["dentist", "laboratory"].includes(e[0]) ? (
          translate_column_value(e[1].last_name) +
          " " +
          translate_column_value(e[1].first_name)
        ) : ["is_total_used"].includes(e[0]) && e[1] == true ? (
          <span className="badge  bg-success p-2">
            <FontAwesomeIcon icon={faCheckCircle} />
            {` `}
            OUI
          </span>
        ) : ["is_total_used"].includes(e[0]) && e[1] == false ? (
          <span className="badge bg-danger p-2">
            <FontAwesomeIcon icon={faFlag} />
            {` `}
            NON{" "}
          </span>
        ) : (
          e[1] + " TND"
        )
      ) : (
        ""
      )
    );
  };

  const deletePayment = async () => {
    try {
      const response = await LaboratoryService.deletePayment(payment.id);
      if (response) {
        NotificationManager.success(
          "Paiement supprimé avec succès",
          "Notification"
        );
        setShowDefault(false);
        refreshPayments();
      }
    } catch (err) {
      NotificationManager.error(
        "Impossible de supprimé cette tranche de paiement",
        "Notification"
      );
    }
  };

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <div>
            <h5 className="mb-2">{name}</h5>

            {props.searched ? (
              <input
                type={"text"}
                placeholder={"chercher ..."}
                className={"form-control"}
              />
            ) : (
              ""
            )}
          </div>
        </Card.Header>
        {loading ? (
          <Spinner animation="border" className="m-4" />
        ) : (
          <>
            {" "}
            <Table
              responsive
              hover={true}
              className="align-items-center table-flush"
            >
              <thead className="thead-light">
                <tr>
                  {keys.map((key, i) => (
                    <th key={i}> {translate_fr(key, "payments")} </th>
                  ))}
                  <th> </th>
                </tr>
              </thead>
              <tbody>{renderLigne()}</tbody>
            </Table>
            <div className="d-flex justify-content-center mt-3">
              <PaginatorPayments
                totalPages={pages}
                updatePayments={(value) => setPayments(value)}
                loader={(value) => setLoading(value)}
                activatedItem={page}
                updatePageSelected={(value) => setPage(value)}
              />
            </div>
          </>
        )}
      </Card>

      <Modal
        as={Modal.Dialog}
        centered
        show={showEditForm}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faMoneyBill} /> Modifier une tranche de
            paiement
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <div className={"mb-2"}>
                  <label> Dentiste </label>
                  <select
                    className="form-control"
                    value={dentist_id}
                    onChange={(e) => setDentist_id(e.target.value)}
                  >
                    <option value=""> == Choisir le dentiste == </option>
                    {dentists.map((d) => (
                      <option value={d.id}>
                        {" "}
                        {d.last_name + " " + d.first_name}{" "}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col>
                <div className={"mb-2"}>
                  <label> Mode de Paiement </label>
                  <select
                    className="form-control"
                    value={paymentMode}
                    onChange={(e) => setPaymentMode(e.target.value)}
                  >
                    <option value="">
                      {" "}
                      == Choisir le mode de paiement =={" "}
                    </option>
                    <option value="cash"> Espèce </option>
                    <option value="cheque"> Chèque </option>
                    <option value="money_order">Virement </option>
                  </select>
                </div>
              </Col>
            </Row>

            <FormGroup className={"mb-2"}>
              <label>Montant</label>
              <FormControl
                type={"number"}
                min={0}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                placeholder={"Montant du paiement ..."}
              ></FormControl>
            </FormGroup>

            <FormGroup className={"mb-2"}>
              <label>Informations supplémentaires </label>
              <FormControl
                as={"textarea"}
                value={code_cheque}
                onChange={(e) => setCodeCheque(e.target.value)}
                placeholder={"Ajouter des notes ..."}
              ></FormControl>
            </FormGroup>

            <div className={"d-flex justify-content-end mb-2"}>
              <Button type={"submit"} variant={"main"}>
                <FontAwesomeIcon icon={faEdit} /> Modifier{" "}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h4 text-main">Alert Suppression</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <p className={"text-dark"}>
            {" "}
            Voulez-vous vraiment supprimee ce tranche de paiment ?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="main" className="ms-auto" onClick={handleClose}>
            Non
          </Button>
          <Button variant="danger" onClick={deletePayment}>
            Oui
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
