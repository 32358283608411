const {axiosInstance} = require("../config/axios");
const {APIURL} = require("../configApi");

const UserService = {}
UserService.updatePicture = (data)=> {
        return  axiosInstance.post(`${APIURL}/global/upload_files/change_profile_picture`,
                    data
            )
}

UserService.updatePassword = (data)=> {
        return  axiosInstance.put(`${APIURL}/global/auth/update-password`,
                    data
            )
}





export default UserService;