import React, { useEffect, useState } from "react";
import Preloader from "../../Shared/Preloader/Preloader";
import Sidebar from "../../Shared/Sidebar";
import Navbar from "../../Shared/Navbar/Navbar";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Row,
} from "@themesberg/react-bootstrap";
import { PagesRoutes } from "../../../pages_routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faHouseUser,
  faPlusCircle,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useNavigate } from "react-router-dom";
import { currentUser } from "../../../authentication/auth";
import { axiosInstance } from "../../../config/axios";
import { APIURL } from "../../../configApi";
import UsersTable from "../../Shared/components/Table/UsersTable";
import FirebaseNotif from "../../Shared/FirebaseNotif/FirebaseNotif";

const DentistLaboList = () => {
  const [email, setEmail] = useState();
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);

  const [dentists, setLaboratories] = useState([]);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [pages, setPages] = useState(0);

  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    //getAgents()
    getLaboratories();
  }, []);
  const getLaboratories = () => {
    axiosInstance
      .get(`${APIURL}/dentist/laboratories`)
      .then((res) => {
        setLaboratories(res.data.data.list);
        setPages(res.data.data.total);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const sendNewInvitation = (e) => {
    e.preventDefault();
    axiosInstance
      .post(`${APIURL}/global/request_invitations`, { email: email })
      .then((res) => {
        NotificationManager.success(
          "Invitation envoye avec suces",
          "Notification"
        );
        setShowDefault(false);
        setEmail("");

        //console.log(invitations)
      })
      .catch((err) => {
        NotificationManager.error(err.response.data.message, "Notification");
        setEmail("");
      });
  };

  return (
    <>
      <Preloader />
      <Sidebar user={user} />
      {/* <FirebaseNotif /> */}
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar
          user={user}
          pageName={"Liste des laboratoires"}
          icon={faHouseUser}
        />
        <hr />
        <div>
          <div className="d-flex justify-content-end mb-2">
            <Button variant={"main"} onClick={() => setShowDefault(true)}>
              <FontAwesomeIcon icon={faPlusCircle} /> Ajouter{" "}
            </Button>
          </div>
          <NotificationContainer />

          <div>
            {dentists && dentists.length > 0 ? (
              <UsersTable
                pages={pages}
                name={"Liste des laboratoires"}
                users={dentists}
                headColumns={[
                  "email",
                  "first_name",
                  "principal_phone",
                  "city",
                  "last_name",
                ]}
                type={"laboratoire"}
                refreshUsers={() => getLaboratories()}
              />
            ) : (
              "No data found .."
            )}
          </div>
        </div>
      </main>

      <Modal
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faEnvelope} /> Envoyer une nouvelle
            Invitation
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={sendNewInvitation}>
            <Row>
              <Col md={"10"}>
                <FormGroup>
                  <FormControl
                    type={"email"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={"Email de laboratoire ..."}
                  ></FormControl>
                </FormGroup>
              </Col>
              <Col md={"2"}>
                <Button type={"submit"} variant={"main"}>
                  <FontAwesomeIcon icon={faShare} /> Envoyer{" "}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DentistLaboList;
