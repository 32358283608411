import { useNavigate } from "react-router-dom";
import Preloader from "../../../Shared/Preloader/Preloader";
import Sidebar from "../../../Shared/Sidebar";
import Navbar from "../../../Shared/Navbar/Navbar";
import {
  Button,
  Card,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalFooter,
  Nav,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
import { PagesRoutes } from "../../../../pages_routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faCheckCircle,
  faDownload,
  faExclamationTriangle,
  faEye,
  faList,
  faSave,
  faTimesCircle,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import React, { useEffect, useRef, useState } from "react";
import { currentUser } from "../../../../authentication/auth";
import { axiosInstance } from "../../../../config/axios";
import { APIURL } from "../../../../configApi";

import CustomAutoSuggest from "../../../Shared/components/CustomAutoSuggest";
import Select from "react-select";
import FileService from "../../../services/FileService";

const WorksAdd = () => {
  const sgroupeRef = useRef();
  const groupeRef = useRef();
  const servicesRef = useRef();
  const navigate = useNavigate();
  const [showBlock, setShowBlock] = useState(false);
  const [image, setImage] = useState("");
  const [empreinte, setEmpreinte] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);

  const [attachments_files, setAttachments_files] = useState([]);
  const [files_extra_finger_print, setFiles_extra_finger_print] = useState([]);
  const [comment, setComment] = useState("");
  const [user, setUser] = useState(null);
  const [is_urgent, setIs_urgent] = useState(false);
  const [forseen_delivery_date, setForseenDeliveryDate] = useState();
  const [elements_numbers, setElements_numbers] = useState(1);
  const [dentist_id, setDentist_id] = useState(null);

  const [groupe, setGroupe] = useState();
  const [sub_groupe, setSub_groupe] = useState();

  const [errors, setErrors] = useState({});
  const [dentists, setDentists] = useState([]);
  const [services, setServices] = useState([]);
  const [groupes, setGroupes] = useState([]);

  const [groupeData, setGroupeData] = useState([]);
  const [sgroupeData, setSGroupeData] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [popupModal, setPopupModal] = useState(false);
  const [subgroupes, setSubgroupes] = useState([]);
  const [full_name_patient, setFull_name_patient] = useState("");
  const [age_patient, setAge_patient] = useState(null);
  const [sex_patient, setSex_patient] = useState("");
  const [service, setService] = useState(null);
  const [shade, setShade] = useState("");
  const [shades, setShades] = useState([]);
  const [file, setFile] = useState();

  const [localFiles, setLocalFiles] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingEmpreinte, setLoadingEmpreinte] = useState(false);
  const [disabledUploadImage, setDisabledUploadImage] = useState(true);
  const [disabledUploadEmpreint, setDisabledUploadEmpreint] = useState(true);
  const [real_reference, setRealReference] = useState("");
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(0);
  const [idFileDeleted, setIdFileDeleted] = useState();
  const [service_id, setServiceId] = useState("");
  const [groupe_id, setGroupeId] = useState(0);
  const [groupesService, setGroupesService] = useState([]);
  const updateServiceList = (value) => {
    if (value != "0") {
      setGroupesService([...groupes.filter((g) => g.id == value)]);
    } else {
      setGroupesService(groupes);
    }
  };

  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }

    getDentists();
    getGroupes();
    getShades();
  }, [update]);

  //useEffect(() => {}, [dentist_id]);

  const downloadImage = async (id) => {
    // console.log("download file", id);

    fetch(
      `https://api.easylab.tn/global/download_files/${id}/attachments_file`,
      {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    ).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "file.png";
        alink.click();
      });
    });
  };

  const chooseImageToShow = (file) => {
    setImage(file);
    setShowImageModal(true);
  };

  const chooseFile = async (e) => {
    e.preventDefault();
    if (e.target.files[0] == file) {
      NotificationManager.error(
        "Vous avez déjà téléchargé une photo avec le même nom. Verifiez SVP",
        "Notification"
      );
    } else {
      try {
        setFile(e.target.files[0]);
        setDisabledUploadImage(false);
      } catch (err) {}
    }
  };

  const Popupdelete = (id) => {
    setIdFileDeleted(id);
    setPopupModal(true);
  };
  const uploadFile = async () => {
    setLoadingImage(true);
    try {
      const data = new FormData();
      data.append("attachments_file", file);
      const response = await FileService.uploadImage(data, null);
      if (response.status === 200) {
        const lfiles = localFiles;
        const files = attachments_files;
        files.push(response.data.data?.id);
        lfiles.push(response.data.data);
        setLocalFiles(lfiles);
        setAttachments_files(files);
        NotificationManager.success(
          "Image téléchargée avec succès",
          "Notification"
        );
        // console.log(lfiles);
        setUpdate((prev) => prev + 1);
        setLoadingImage(false);
        setDisabledUploadImage(true);
      }
    } catch (err) {
      // console.log(err);
      NotificationManager.error(err.response?.data?.message, "Notification");
      setLoadingImage(false);
      setDisabledUploadImage(true);
    }
  };

  const getDentistRole = (id) => {
    setShowBlock(
      dentists.filter((d) => d.id == id)[0]?.role === "fake_dentist"
    );

    //setUpdate((prev) => prev + 1);
  };

  function formValidation() {
    let status = true;
    var localerrors = {};
    // console.log("inside form validation", dentist_id);
    if (dentist_id === null || dentist_id === "") {
      localerrors = {
        ...localerrors,
        ...{ dentist_id: "Veuillez choisir le dentiste ... " },
      };
    }

    if (service_id === null || service_id === "") {
      localerrors = {
        ...localerrors,
        ...{ service_id: "Veuillez choisir la nature du travail ... " },
      };
    }

    if (
      elements_numbers < 1 ||
      elements_numbers > 16 ||
      elements_numbers === ""
    ) {
      localerrors = {
        ...localerrors,
        ...{ elements_numbers: "Veuillez entrer le nombre d'éléments ... " },
      };
    }

    if (shade === "") {
      localerrors = {
        ...localerrors,
        ...{ shade: "Veuillez choisir la teinte principale ... " },
      };
    }

    //console.log("hello localerrors" , localerrors)
    setErrors(localerrors);
    //console.log("errors" , errors)

    if (Object.keys(localerrors).length > 0) status = false;
    //console.log("inside form validation" ,Object.keys(localerrors).length ,status)
    return status;
  }

  const chooseExtraFile = async (e) => {
    e.preventDefault();
    if (e.target.files[0] == empreinte) {
      NotificationManager.error(
        "Vous avez déjà téléchargé une empreinte avec le même nom. Verifiez SVP",
        "Notification"
      );
    } else {
      try {
        setEmpreinte(e.target.files[0]);
        setDisabledUploadEmpreint(false);
      } catch (err) {}
    }
  };

  const uploadExtraFile = async (e) => {
    // console.log(" extra file declenched ");

    setLoadingEmpreinte(true);
    try {
      const data = new FormData();
      data.append("file_extra_finger_print", empreinte);
      const response = await FileService.uploadExtraFile(data);
      if (response.status === 200) {
        //const lfiles = localFiles;
        const files = files_extra_finger_print;
        files.push(response.data.data?.id);
        //lfiles.push(response.data.data);
        //setLocalFiles(lfiles);
        setFiles_extra_finger_print(files);

        // console.log(" extra file declenched ", files, localFiles);

        NotificationManager.success(
          "Empreinte téléchargée avec succès",
          "Notification"
        );
        // console.log(files);
        setUpdate((prev) => prev + 1);
        setLoadingEmpreinte(false);
        setDisabledUploadEmpreint(true);
      }
    } catch (err) {
      // console.log(err);
      NotificationManager.error(err.response?.data?.message, "Notification");
      setLoadingImage(false);
      setDisabledUploadEmpreint(true);
    }
  };

  const getShades = () => {
    axiosInstance
      .get(`${APIURL}/global/settings/shades`)
      .then((res) => {
        setShades(res.data.data);
        // console.log(res.data);
        // console.log(dentists);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const getDentists = () => {
    axiosInstance
      .get(`${APIURL}/laboratory/dentists/all`)
      .then((res) => {
        setDentists(res.data.data);
        // console.log(res.data);
        // console.log(dentists);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const getGroupes = () => {
    //        if (user) {
    //console.log("user data" ,user)
    axiosInstance
      .get(`${APIURL}/laboratory/groups`)
      .then((res) => {
        // console.log(res.data.data.list);
        //setGroupeData(res.data.data.list);
        setGroupes(res.data.data.list);
        setGroupesService(res.data.data.list);
        // if (res.data.data.list) {
        //   let s_groupes = [];
        //   res.data.data.list.forEach((G) => {
        //     s_groupes.push({ label: G.name, value: G.id });
        //   });
        //   setGroupes(s_groupes);
        // }
      })
      .catch((err) => {
        // console.log(err);
      });

    //      }
  };

  const addWork = (e) => {
    e.preventDefault();
    setLoading(true);
    //         setLoading(true);
    setErrors({});
    if (!formValidation()) {
      // console.log("if condition");
      setLoading(false);
      return false;
    } else {
      setLoading(true);
      // console.log("attachment files ", attachments_files);
      const newWork = {
        attachments_files_ids: attachments_files,
        files_extra_finger_print_ids: files_extra_finger_print,
        dentist_id: dentist_id,
        service_id: service_id,
        full_name_patient: full_name_patient,
        comment: comment,
        sex_patient: sex_patient,
        age_patient: age_patient,
        elements_numbers: elements_numbers,
        forseen_delivery_date: forseen_delivery_date,
        is_urgent: is_urgent,
        shade: shade,
        accessible_id: null,
        real_reference: real_reference,
      };

      // console.log("all data in work", newWork);
      axiosInstance
        .post(`${APIURL}/global/works`, newWork)
        .then((res) => {
          navigate("/laboratory/works");
          // console.log(res);
          setLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          NotificationManager.error(err.response.data.message, "Notification");
          setLoading(false);
        });
    }
  };

  const chooseGroupe = (e) => {
    setGroupe(e);
    groupeData.forEach((g) => {
      if (g.id == e.value) {
        // console.log(g.sub_groups);
        let s_groupes = [];
        g.sub_groups.forEach((subG) => {
          s_groupes.push({ label: subG.name, value: subG.id });
        });
        setSGroupeData(g.sub_groups);
        setSubgroupes(s_groupes);
        setService({});
        setSub_groupe({});
      }
    });
    // console.log(subgroupes);
    if (sgroupeRef.current) {
      sgroupeRef.current.focus();
    }
  };

  const chooseSubGroupe = (e) => {
    //console.log(e)
    setSub_groupe(e);
    sgroupeData.forEach((sg) => {
      if (sg.id == e.value) {
        // console.log(sg.services);
        let services = [];
        sg.services.forEach((subG) => {
          services.push({ label: subG.name, value: subG.id });
        });
        setServicesData(sg.services);
        setServices(services);
        setService({});
      }
    });
    // console.log(services);
    if (servicesRef.current) {
      servicesRef.current.focus();
    }
    //console.log(e.value)
  };
  const chooseService = (e) => {
    setService(e);
    // console.log("choose service ", e);
    //setSub_groupe(e)
    //console.log(e.value)
  };

  const deleteFile = async (workId, FileId) => {
    // console.log("liste des fichiers ===>", localFiles, FileId);

    try {
      const response = await FileService.delete(workId, FileId);
      if (response.status === 200) {
        let newFiles = localFiles.filter(function (item) {
          return item.id !== FileId;
        });
        // console.log("liste des fichiers ===>", newFiles, FileId);
        setLocalFiles(newFiles);
        setAttachments_files(newFiles);
        NotificationManager.success(response.data.message, "Notification");

        setPopupModal(false);
        setUpdate((prev) => prev + 1);
      }
    } catch (err) {
      NotificationManager.error(err?.response?.data?.message, "Notification");
    }
  };
  const handleClose = () => {
    setShowImageModal(false);
    setPopupModal(false);
  };

  return (
    <>
      <Preloader />
      <Sidebar user={user} />

      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar
          user={user}
          pageName={"Ajouter un travail"}
          icon={faBriefcase}
        />
        <hr />
        <div>
          <div className="d-flex justify-content-end mb-2">
            <Button
              variant={"main"}
              onClick={() => navigate(PagesRoutes.LaboWorks.path)}
            >
              <FontAwesomeIcon icon={faList} /> voir liste
            </Button>
          </div>
          <NotificationContainer />
          <div>
            <Form onSubmit={addWork}>
              <Row>
                <Col md={5} lg={5}>
                  {" "}
                  <FormGroup className={"mb-3"}>
                    <FormLabel> Dentiste </FormLabel>
                    <select
                      value={dentist_id}
                      onChange={(e) => {
                        setDentist_id(e.target.value);
                        getDentistRole(e.target.value);
                      }}
                      className={"form-control"}
                    >
                      <option> == Choisir un dentiste == </option>
                      {dentists.length > 0 &&
                        dentists.map((d) => (
                          <option value={d.id}>
                            {" "}
                            {d.last_name + " " + d.first_name}
                          </option>
                        ))}
                    </select>

                    {errors?.dentist_id && (
                      <span
                        className="text-danger mt-3 fw-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {" "}
                        * {errors?.dentist_id}{" "}
                      </span>
                    )}
                  </FormGroup>{" "}
                </Col>
                <Col md={7} lg={7}>
                  {" "}
                  <Row className={"mb-3"}>
                    <Col sm={"2"}>
                      <FormGroup>
                        <FormLabel> N° Réel </FormLabel>
                        <FormControl
                          type={"text"}
                          value={real_reference}
                          placeholder={"Fiche N°"}
                          onChange={(e) => setRealReference(e.target.value)}
                        ></FormControl>
                        (Transitoire)
                      </FormGroup>
                    </Col>
                    <Col sm={"10"}>
                      {showBlock && (
                        <Row>
                          <Col sm={"5"}>
                            <FormGroup>
                              <FormLabel> Nom prénom patient </FormLabel>
                              <FormControl
                                type={"text"}
                                placeholder={"Nom prénom patient"}
                                value={full_name_patient}
                                onChange={(e) =>
                                  setFull_name_patient(e.target.value)
                                }
                              ></FormControl>
                            </FormGroup>
                          </Col>
                          <Col sm={"3"}>
                            <FormGroup>
                              <FormLabel> Age </FormLabel>
                              <FormControl
                                min={0}
                                max={120}
                                type={"number"}
                                value={age_patient}
                                placeholder={"Age"}
                                onChange={(e) => setAge_patient(e.target.value)}
                              ></FormControl>
                            </FormGroup>
                          </Col>
                          <Col sm={"4"}>
                            <FormGroup>
                              <FormLabel> </FormLabel>

                              {sex_patient === "women" ? (
                                <Row>
                                  <Col>
                                    <Nav
                                      fill
                                      variant="pills"
                                      className="d-flex"
                                    >
                                      <Nav.Item>
                                        <Nav.Link
                                          onClick={() =>
                                            setSex_patient("women")
                                          }
                                          className="mb-sm-3 mb-md-0 bg-main text-white"
                                        >
                                          <FontAwesomeIcon
                                            icon={faCheckCircle}
                                            className="text-white me-2"
                                          />
                                          <span>F</span>
                                        </Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item
                                        onClick={() => setSex_patient("man")}
                                      >
                                        <Nav.Link
                                          title={
                                            "Clique ici pour changer le sex"
                                          }
                                          className="border border-dark mb-sm-3 mb-md-0"
                                        >
                                          H
                                        </Nav.Link>
                                      </Nav.Item>
                                    </Nav>
                                  </Col>
                                </Row>
                              ) : sex_patient === "man" ? (
                                <Row>
                                  <Col>
                                    <Nav
                                      fill
                                      variant="pills"
                                      className="d-flex"
                                    >
                                      <Nav.Item>
                                        <Nav.Link
                                          title={
                                            "Clique ici pour changer le sex"
                                          }
                                          onClick={() =>
                                            setSex_patient("women")
                                          }
                                          className="border border-dark mb-sm-3 mb-md-0"
                                        >
                                          <span>F</span>
                                        </Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item
                                        onClick={() => setSex_patient("man")}
                                      >
                                        <Nav.Link className="mb-sm-3 mb-md-0  bg-main text-white">
                                          <FontAwesomeIcon
                                            icon={faCheckCircle}
                                            className="text-white me-2"
                                          />
                                          <span>H</span>
                                        </Nav.Link>
                                      </Nav.Item>
                                    </Nav>
                                  </Col>
                                </Row>
                              ) : (
                                <Row>
                                  <Col>
                                    <Nav
                                      fill
                                      variant="pills"
                                      className="d-flex"
                                    >
                                      <Nav.Item>
                                        <Nav.Link
                                          title={
                                            "Clique ici pour changer le sex"
                                          }
                                          onClick={() =>
                                            setSex_patient("women")
                                          }
                                          className="border border-dark mb-sm-3 mb-md-0"
                                        >
                                          <span>F</span>
                                        </Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item
                                        onClick={() => setSex_patient("man")}
                                      >
                                        <Nav.Link className="border border-dark mb-sm-3 mb-md-0">
                                          <span>H</span>
                                        </Nav.Link>
                                      </Nav.Item>
                                    </Nav>
                                  </Col>
                                </Row>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                      {/* <Card
                        className="p-2"
                        style={{ minHeight: "130px" }}
                      ></Card> */}
                    </Col>
                  </Row>
                </Col>
              </Row>

              {/* <Row className={"mb-3"}>
                <Col>
                  <FormGroup>
                    <FormLabel> Groupe </FormLabel>
                    <Select
                      value={groupe}
                      onChange={chooseGroupe}
                      options={groupes}
                    ></Select>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <FormLabel> Sous-Groupe </FormLabel>
                    <Select
                      openMenuOnFocus={true}
                      ref={sgroupeRef}
                      value={sub_groupe}
                      options={subgroupes}
                      onChange={chooseSubGroupe}
                    ></Select>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <FormLabel> Services </FormLabel>
                    <Select
                      openMenuOnFocus={true}
                      ref={servicesRef}
                      value={service}
                      onChange={chooseService}
                      options={services}
                    ></Select>

                    {errors?.service && (
                      <span
                        className="text-danger mt-3 fw-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {" "}
                        * {errors?.service}{" "}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row> */}

              <Row className={"mb-3"}>
                <Col>
                  <FormGroup>
                    <FormLabel>Liste des groupes : </FormLabel>
                    <select
                      className="form-control"
                      value={groupe_id}
                      onChange={(e) => {
                        setGroupeId(e.target.value);
                        updateServiceList(e.target.value);
                      }}
                    >
                      <option value={"0"}>
                        {" "}
                        <strong> == Tous les groupes == </strong>{" "}
                      </option>
                      {groupes && groupes.length > 0
                        ? groupes.map((g) => (
                            <option value={g.id}>{g.name}</option>
                          ))
                        : ""}
                    </select>
                  </FormGroup>
                </Col>
                <Col>
                  {" "}
                  <FormGroup>
                    <FormLabel>Liste des services : </FormLabel>
                    <select
                      className="form-control"
                      value={service_id}
                      onChange={(e) => {
                        setServiceId(e.target.value);
                      }}
                    >
                      <option> Choisir une service </option>
                      {groupesService && groupesService.length > 0
                        ? groupesService.map((g) =>
                            g.sub_groups.map((sg) => (
                              <optgroup label={sg.name + ` ( ` + g.name + ` )`}>
                                {" "}
                                {sg.services.map((s) => (
                                  <option value={s.id}>{s.name}</option>
                                ))}
                              </optgroup>
                            ))
                          )
                        : ""}
                    </select>
                  </FormGroup>
                  {errors && errors.service_id ? (
                    <p className="text-danger"> {errors.service_id} </p>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <Row className={"mb-3"}>
                <Col>
                  <FormGroup>
                    <FormLabel> Nombre d'élements </FormLabel>
                    <FormControl
                      value={elements_numbers}
                      type={"number"}
                      onChange={(e) => setElements_numbers(e.target.value)}
                      max={"16"}
                      min={"1"}
                    ></FormControl>
                    {errors?.elements_numbers && (
                      <span
                        className="text-danger mt-3 fw-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {" "}
                        * {errors?.elements_numbers}{" "}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <FormLabel> Teinte </FormLabel>
                    <CustomAutoSuggest
                      type={"text"}
                      value={shade}
                      onChange={(e) => setShade(e.target.value)}
                      changeValue={(newV) => {
                        setShade(newV);
                        // console.log("shade", shade);
                      }}
                      suggestions={shades}
                    />
                    {errors?.shade && (
                      <span
                        className="text-danger mt-3 fw-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {" "}
                        * {errors?.shade}{" "}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <FormLabel> Date de livraison prevu </FormLabel>
                    <FormControl
                      type={"date"}
                      value={forseen_delivery_date}
                      min={new Date().toISOString().split("T")[0]}
                      onChange={(e) => setForseenDeliveryDate(e.target.value)}
                    ></FormControl>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Form.Label> </Form.Label>
                    <div className={"d-flex"}>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={is_urgent}
                        onChange={() => setIs_urgent((prev) => !prev)}
                        // label="Changer status"
                      />
                      Urgent ?
                    </div>
                  </FormGroup>
                </Col>
              </Row>

              <Row className={"mb-3"}>
                <Col>
                  <FormGroup>
                    <FormLabel> Empreintes digitales </FormLabel>
                    <FormControl
                      className={"mb-2"}
                      type={"file"}
                      onChange={(e) => chooseExtraFile(e)}
                    ></FormControl>
                  </FormGroup>
                  <Button
                    disabled={disabledUploadEmpreint}
                    onClick={(e) => uploadExtraFile(e)}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faUpload} />{" "}
                    {loadingEmpreinte ? (
                      <Spinner animation="border" />
                    ) : (
                      `Télécharger`
                    )}
                  </Button>
                  <div className="pt-3">
                    {files_extra_finger_print &&
                    files_extra_finger_print.length > 0 ? (
                      <>
                        <h6>Liste des fichiers</h6>
                        <hr />
                        <div>
                          {files_extra_finger_print.map((file, index) =>
                            index < 3 ? (
                              <div className="border border-1 border-primary me-1 p-1">
                                <div className="d-flex justify-content-between align-items-center">
                                  <span> Empreinte {index + 1} </span>
                                </div>

                                <div></div>
                              </div>
                            ) : (
                              ""
                            )
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                <Col>
                  <FormGroup>
                    <FormLabel> Photos </FormLabel>{" "}
                    <span className={"text-muted"}></span>
                    <FormControl
                      className={"mb-2"}
                      type={"file"}
                      onChange={(e) => chooseFile(e)}
                    ></FormControl>
                    <Button
                      disabled={disabledUploadImage}
                      onClick={(e) => uploadFile()}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faUpload} />{" "}
                      {loadingImage ? (
                        <Spinner animation="border" />
                      ) : (
                        `Télécharger`
                      )}
                    </Button>
                  </FormGroup>
                  <div className="pt-3">
                    {localFiles && localFiles.length > 0 ? (
                      <>
                        <h6>Liste des fichiers</h6>
                        <hr />
                        <div className="d-flex">
                          {localFiles.map((file, index) =>
                            index < 3 ? (
                              <div className="border border-1 me-1 p-1">
                                <div className="d-flex justify-content-between">
                                  <span> Fichier {index + 1} </span>
                                  <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    onClick={() => Popupdelete(file.id)}
                                  />
                                </div>

                                <div>
                                  <img
                                    src={`${file.full_path}`}
                                    width={"100"}
                                    onClick={() => {
                                      chooseImageToShow(file);
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>

              <Row className={"mb-3"}>
                <FormGroup>
                  <FormLabel> Commentaires </FormLabel>
                  <FormControl
                    as={"textarea"}
                    placeholder={"votre commentaire ici ..."}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  ></FormControl>
                </FormGroup>
              </Row>

              {/* <Row  className={'mb-3'}>
                                <Col sm={'6'}>
                                    <FormGroup>
                                        <FormLabel> Nom prénom patient </FormLabel>
                                        <FormControl type={'text'} placeholder={'foulen ben foulen ...'} value={full_name_patient} onChange={(e)=>setFull_name_patient(e.target.value)} ></FormControl>
                                    </FormGroup>
                                </Col>
                                <Col sm={'4'}>
                                    <FormGroup>
                                        <FormLabel>  </FormLabel>


                                        {
                                            sex_patient === 'women' ?
                                                <Row>
                                                    <Col>
                                                        <Nav
                                                            fill

                                                            variant="pills"
                                                            className="d-flex"
                                                        >
                                                            <Nav.Item>
                                                                <Nav.Link

                                                                    onClick={() => setSex_patient('women')}
                                                                    className="mb-sm-3 mb-md-0 bg-main text-white"
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faCheckCircle}
                                                                        className="text-white me-2"
                                                                    />
                                                                    <span>F</span>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item onClick={() => setSex_patient('man')}>
                                                                <Nav.Link
                                                                    title={'Clique ici pour changer le sex'}
                                                                    className="border border-dark mb-sm-3 mb-md-0"
                                                                >
                                                                    H
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                    </Col>
                                                </Row>

                                                : sex_patient === 'man' ? <Row>
                                                        <Col>
                                                            <Nav
                                                                fill

                                                                variant="pills"
                                                                className="d-flex"
                                                            >
                                                                <Nav.Item>
                                                                    <Nav.Link
                                                                        title={'Clique ici pour changer le sex'}
                                                                        onClick={() => setSex_patient('women')}
                                                                        className="border border-dark mb-sm-3 mb-md-0"
                                                                    >

                                                                        <span>F</span>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item onClick={() => setSex_patient('man')}>
                                                                    <Nav.Link  className="mb-sm-3 mb-md-0  bg-main text-white">
                                                                        <FontAwesomeIcon
                                                                            icon={faCheckCircle}
                                                                            className="text-white me-2"
                                                                        />
                                                                        <span>H</span>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            </Nav>
                                                        </Col>
                                                    </Row> :
                                                <Row>
                                                    <Col>
                                                        <Nav
                                                            fill

                                                            variant="pills"
                                                            className="d-flex"
                                                        >
                                                            <Nav.Item>
                                                                <Nav.Link
                                                                    title={'Clique ici pour changer le sex'}
                                                                    onClick={() => setSex_patient('women')}
                                                                    className="border border-dark mb-sm-3 mb-md-0"
                                                                >

                                                                    <span>F</span>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item onClick={() => setSex_patient('man')}>
                                                                <Nav.Link  className="border border-dark mb-sm-3 mb-md-0">

                                                                    <span>H</span>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                    </Col>
                                                </Row>
                                        }



                                    </FormGroup>
                                </Col>
                                <Col sm={'2'}>
                                    <FormGroup>
                                        <FormLabel> Age du patient </FormLabel>
                                        <FormControl
                                            min={'0'}
                                            max={'120'}
                                            type={'number'}
                                            value={age_patient}
                                            onChange={(e)=>setAge_patient(e.target.value)}
                                        ></FormControl>
                                    </FormGroup>
                                </Col>
                            </Row> */}

              <div className={"d-flex justify-content-end my-3"}>
                {loading ? (
                  <Button variant={"main"}>
                    <Spinner animation={"border"} className={""} />
                  </Button>
                ) : (
                  <Button variant={"main"} type={"submit"}>
                    <FontAwesomeIcon icon={faSave} className={"me-2"} />
                    Sauvegarder{" "}
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </div>

        <Modal
          as={Modal.Dialog}
          centered
          show={showImageModal}
          onHide={handleClose}
          size={"lg"}
        >
          <Modal.Header>
            <Modal.Title className="h6">
              <FontAwesomeIcon icon={faEye} /> Afficher Image{" "}
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <img src={image.full_path} />
          </Modal.Body>
          <ModalFooter>
            <Button
              type="button"
              variant="success"
              onClick={() => downloadImage(image.id)}
            >
              <FontAwesomeIcon icon={faDownload} /> Télécharger{" "}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          as={Modal.Dialog}
          centered
          show={popupModal}
          onHide={handleClose}
          size={"lg"}
        >
          <Modal.Header>
            <Modal.Title className="h6">
              <FontAwesomeIcon icon={faEye} /> Alert de suppression du fichier{" "}
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <div className={"text-center"}>
              <h2>
                {" "}
                <FontAwesomeIcon icon={faExclamationTriangle} /> ATTENTION !{" "}
              </h2>
              Voulez-vous vraiment supprimer ce fichier !
            </div>
          </Modal.Body>
          <ModalFooter>
            <Button variant={"success"} onClick={handleClose}>
              Annuler
            </Button>

            <Button
              variant={"danger"}
              onClick={() => deleteFile(null, idFileDeleted)}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>
      </main>
    </>
  );
};

export default WorksAdd;
