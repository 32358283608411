import React from "react";
import "./style.css";
import CoverImage from "../../../assets/FirstImage.png";
const CoverPage = () => {
  return (
    <div className={"coverContainer"}>
      <div className={"coverSmallContainer"}>
        <div>
          <p className={"CoverPageTitle"}>
            Vous êtes un Professionnel en Art Dentaire, <span>EasyLab</span>{" "}
            c’est conçu pour Vous !
          </p>
          <p className={"CoverPageDescription"}>
            Collaborer, Informer et s’informer de l’état instantanné de vos
            travaux, Gérer vos Projets Prothétiques pour une ergonomie
            organisationnelle Maximale. Accomplissez toutes vos tâches grace à
            votre Compte
            <br />
            <span className={"span1"}>#EasyLab </span>
            <span className={"span2"}>Voir Plus..</span>
          </p>
          <button className={"Discover-Cover-button"}>Découvrez</button>
        </div>
        <img alt={"CoverImage"} src={CoverImage} className={"CoverPageImage"} />
      </div>
    </div>
  );
};

export default CoverPage;
