import { Button, Row } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import Invitations from "../../Shared/components/Table/Invitations";
import Navbar from "../../Shared/Navbar/Navbar";
import Preloader from "../../Shared/Preloader/Preloader";
import Sidebar from "../../Shared/Sidebar";
import { useNavigate } from "react-router-dom";
import { config, currentUser } from "../../../authentication/auth";
import { APIURL, getPages } from "../../../configApi";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faPlusCircle,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { PagesRoutes } from "../../../pages_routes";
import UsersTable from "../../Shared/components/Table/UsersTable";
import { NotificationContainer } from "react-notifications";
import AgentsTable from "../../Shared/components/Table/AgentsTable";
import LaboratoryService from "../../services/LaboratoireService";
import FirebaseNotif from "../../Shared/FirebaseNotif/FirebaseNotif";

export default function () {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [agents, setAgents] = useState([]);
  const [pages, setPages] = useState(0);
  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    getAgents(1);
  }, []);

  const getAgents = async (page) => {
    try {
      const response = await LaboratoryService.agentsList(page);
      if (response.status === 200) {
        setAgents(response.data.data.list);
        setPages(getPages(response.data.data.total));
      }
    } catch (err) {}
  };

  return (
    <>
      <Preloader />
      <Sidebar user={user} />
      {/* <FirebaseNotif /> */}
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar user={user} pageName={"Liste des agents"} icon={faUsers} />
        <hr />
        <div>
          <div className="d-flex justify-content-end mb-2">
            <Button
              variant={"main"}
              onClick={() => navigate(PagesRoutes.LaboAgentsAdd.path)}
            >
              <FontAwesomeIcon icon={faPlusCircle} /> Ajouter{" "}
            </Button>
          </div>
          <NotificationContainer />

          {agents && agents.length > 0 ? (
            <AgentsTable
              setUsers={(value) => setAgents(value)}
              pages={pages}
              name={"Liste des agents"}
              users={agents}
              headColumns={[
                "email",
                "first_name",
                "principal_phone",
                "is_active",
                "last_name",
              ]}
              refreshUsers={(value) => getAgents(value)}
              type={"agent"}
            />
          ) : (
            "Aucun agent dans la liste. Cliquez sur le button ajouter  .."
          )}
        </div>
      </main>
    </>
  );
}
