import { useNavigate } from "react-router-dom";
import Preloader from "../../Shared/Preloader/Preloader";
import Sidebar from "../../Shared/Sidebar";
import Navbar from "../../Shared/Navbar/Navbar";
import { Button, Spinner, Tab, Tabs } from "@themesberg/react-bootstrap";
import { PagesRoutes } from "../../../pages_routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import { NotificationContainer } from "react-notifications";
import React, { useEffect, useState } from "react";
import { currentUser } from "../../../authentication/auth";
import Works from "../../Shared/components/Table/Works";
import { axiosInstance } from "../../../config/axios";
import { APIURL, getPages } from "../../../configApi";
import FirebaseNotif from "../../Shared/FirebaseNotif/FirebaseNotif";

export default function () {
  let status = new URLSearchParams(window.location.search).get("status");
  const navigate = useNavigate();
  const [inital, setInitial] = useState(0);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [outgoing_dentist_rows, setOutgoing_dentist_rows] = useState(0);
  const [outgoing_laboratory_rows, setOutgoing_laboratory_rows] = useState(0);
  const [at_dentist_rows, setAt_dentist_rows] = useState(0);
  const [at_laboratory_rows, setAt_laboratory_rows] = useState(0);
  const [currentStatus, setCurrentStatus] = useState();
  const [works, setWorks] = useState([]);
  const [activeTab, setActiveTab] = useState("at_laboratory");
  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    resfreshAllData();

    if (status && status.length > 0) {
      setCurrentStatus(status);
      setActiveTab(status);
      getWorks(status);
    } else {
      setCurrentStatus("at_laboratory");
      setActiveTab("at_laboratory");
    }
  }, []);

  const resfreshAllData = () => {
    getWorks("at_laboratory");
    calculateRowsByStatus("outgoing_dentist");
    calculateRowsByStatus("outgoing_laboratory");
    calculateRowsByStatus("at_dentist");
  };

  const calculateRowsByStatus = (e) => {
    axiosInstance
      .get(`${APIURL}/global/works?page=1&status=${e}`)
      .then((res) => {
        if (e == "outgoing_dentist") {
          setOutgoing_dentist_rows(res.data.data.total);
        } else if (e == "at_laboratory") {
          setAt_laboratory_rows(res.data.data.total);
        } else if (e == "at_dentist") {
          setAt_dentist_rows(res.data.data.total);
        } else {
          setOutgoing_laboratory_rows(res.data.data.total);
        }
      })
      .catch((err) => {
        // console.log(err);
        // console.log(err);
        setWorks([]);
        setLoading(true);
      });
  };

  const getWorks = (e) => {
    setInitial((prev) => prev + 1);
    setLoading(false);
    axiosInstance
      .get(`${APIURL}/global/works?page=1&status=${e}`)
      .then((res) => {
        setWorks(res.data.data.list);
        //// console.log(res.data);
        setLoading(true);
        if (e == "outgoing_dentist") {
          setOutgoing_dentist_rows(res.data.data.total);
        } else if (e == "at_laboratory") {
          setAt_laboratory_rows(res.data.data.total);
        } else if (e == "at_dentist") {
          setAt_dentist_rows(res.data.data.total);
        } else {
          setOutgoing_laboratory_rows(res.data.data.total);
        }
      })
      .catch((err) => {
        // console.log(err);
        // console.log(err);
        setWorks([]);
        setLoading(true);
      });
  };

  return (
    <>
      <Preloader />
      <Sidebar user={user} />
      {/* <FirebaseNotif /> */}
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar user={user} pageName={"Liste des travaux"} icon={faBriefcase} />
        <hr />
        <div>
          <div className="d-flex justify-content-end mb-2">
            <Button
              variant={"main"}
              onClick={() => navigate(PagesRoutes.LaboWorksAdd.path)}
            >
              {" "}
              <FontAwesomeIcon icon={faPlusCircle} /> Ajouter{" "}
            </Button>
          </div>
          <NotificationContainer />

          <div>
            <Tabs
              defaultActiveKey={
                activeTab
                  ? activeTab
                  : new URLSearchParams(window.location.search).get("status")
              }
              id="uncontrolled-tab-example"
              className="mb-3"
              onSelect={(e) => {
                setLoading(false);
                // console.log("current status from on select", e);
                if (currentStatus != e) {
                  setActiveTab(e);
                  setCurrentStatus(e);
                  getWorks(e);
                }
                if (inital == 1) setLoading(true);
              }}
            >
              <Tab
                eventKey="outgoing_dentist"
                title={"Travaux Entrants ( " + outgoing_dentist_rows + " )"}
              >
                <div>
                  {!loading ? (
                    <div className="text-center">
                      <Spinner animation={"border"} className="my-4" />
                    </div>
                  ) : (
                    <Works
                      name={"Travaux Entrants"}
                      pages={getPages(outgoing_dentist_rows)}
                      refreshWorks={(status) => getWorks(status)}
                      currentStatus={"outgoing_dentist"}
                      works={works}
                      updateWorks={(tab) => setWorks(tab)}
                      updatedRows={() =>
                        setAt_laboratory_rows((prev) => prev + 1)
                      }
                    />
                  )}
                </div>
              </Tab>
              <Tab
                eventKey="at_laboratory"
                title={"Travaux Au Labo ( " + at_laboratory_rows + " )"}
              >
                <div>
                  {!loading ? (
                    <div className="text-center">
                      <Spinner animation={"border"} className="my-4" />
                    </div>
                  ) : (
                    <Works
                      updateWorks={(tab) => setWorks(tab)}
                      name={"Travaux Au Labo"}
                      pages={getPages(at_laboratory_rows)}
                      currentStatus={"at_laboratory"}
                      works={works}
                      refreshWorks={(status) => getWorks(status)}
                      updatedRows={() =>
                        setOutgoing_laboratory_rows((prev) => prev + 1)
                      }
                    />
                  )}
                </div>
              </Tab>
              <Tab
                eventKey="outgoing_laboratory"
                title={"Travaux Sortants ( " + outgoing_laboratory_rows + " )"}
              >
                <div>
                  {!loading ? (
                    <div className="text-center">
                      <Spinner animation={"border"} className="my-4" />
                    </div>
                  ) : (
                    <Works
                      updateWorks={(tab) => setWorks(tab)}
                      name={"Travaux Sortants"}
                      pages={getPages(outgoing_laboratory_rows)}
                      refreshWorks={(status) => getWorks(status)}
                      currentStatus={"outgoing_laboratory"}
                      works={works}
                      updatedRows={() => setAt_dentist_rows((prev) => prev + 1)}
                    />
                  )}
                </div>
              </Tab>
              <Tab
                eventKey="at_dentist"
                title={"Travaux Chez Dentiste ( " + at_dentist_rows + " )"}
              >
                <div>
                  {!loading ? (
                    <div className="text-center">
                      <Spinner animation={"border"} className="my-4" />
                    </div>
                  ) : (
                    <Works
                      updateWorks={(tab) => setWorks(tab)}
                      name={"Travaux Chez Dentiste"}
                      pages={getPages(at_dentist_rows)}
                      refreshWorks={(status) => getWorks(status)}
                      currentStatus={"at_dentist"}
                      works={works}
                      updatedRows={() => setAt_dentist_rows((prev) => prev + 1)}
                    />
                  )}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </main>
    </>
  );
}
