import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Dropdown,
  Form,
  Modal,
  OverlayTrigger,
  Spinner,
  Table,
  Tooltip,
} from "@themesberg/react-bootstrap";
import { translate_fr } from "../../../../lang/fr";
import Paginator from "../Paginator";
import {
  faArrowDown,
  faArrowUp,
  faBriefcaseMedical,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faEye,
  faFileAlt,
  faImage,
  faMoneyBill,
  faPaperclip,
  faPeopleCarry,
  faPlusCircle,
  faSortDown,
  faSortUp,
  faTrash,
  faTruckPickup,
} from "@fortawesome/free-solid-svg-icons";
import { config, currentUser } from "../../../../authentication/auth";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../../config/axios";
import { APIURL } from "../../../../configApi";
import CheckBox from "../CheckBox";
import moment from "moment/moment";
import "moment/locale/fr";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import LaboratoireService from "../../../../Services/LaboratoryService";
import LaboratoryService from "../../../services/LaboratoireService";
import PaginatorToBeInvoiced from "../Paginator/PaginatorToBeInvoiced";
import PaginatorNotInvoiced from "../Paginator/PaginatorNotInvoiced";
import WorkService from "../../../services/WorkService";
//import moment from "moment/moment";

moment.locale("fr");

const WorksNotInvoiced = (props) => {
  const navigate = useNavigate();

  const [agent_id, setAgent_id] = useState();
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  const [agents, setAgents] = useState([]);

  const [courier_id, setCourier_id] = useState();
  const [showDefault1, setShowDefault1] = useState(false);
  const handleClose1 = () => setShowDefault1(false);
  const [couriers, setCouriers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState(null);
  const [page, setPage] = useState(1);
  const {
    pages,
    currentStatus,
    works,
    refreshWorks,
    updatedRows,
    updateWorks,
    dentist_id,
  } = props;
  const [selectedWorks, setSelectedWorks] = useState([]);
  const [update, setUpdate] = useState(0);

  const globalAction = async (data) => {
    try {
      const response = await WorkService.changeStatusMultiple(data);
      if (response.status === 200) {
        NotificationManager.success(response.data.message, "Notification");
        updateWorks([]);
        refreshWorks(dentist_id);
        setUpdate((prev) => prev + 1);
        setSelectedWorks([]);

        //console.log(selectedWorks);
      }
    } catch (err) {
      NotificationManager.error(err?.response?.data?.message, "Notification");
    }
  };

  const facturer = async (workId) => {
    try {
      const response = await LaboratoryService.facturer(workId);
      if (response.status == 200) {
        NotificationManager.success(
          "Travail envoyee pour facturer ",
          "Notification"
        );
        refreshWorks(dentist_id);
      }
    } catch (err) {
      //alert(err);
      NotificationManager.error(err?.response?.data?.message, "Notification");
    }
  };
  const updateSelectedWorks = (workId, oper) => {
    let selectedworks = selectedWorks;
    if (oper === "+") {
      selectedWorks.push(workId);
    } else if (oper === "-") {
      selectedworks.splice(selectedworks.indexOf(workId), 1);
    }
    setSelectedWorks(selectedworks);
    setUpdate((prev) => prev + 1);

   // console.log("select check", selectedWorks);
  };
  useEffect(() => {
    //refreshWorks(currentStatus)
   
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }

    //getWorks()
  }, []);
  useEffect(() => {
    setSelectedWorks([]);
  }, [dentist_id]);
  const renderDropdown = () => {
    return (
      <>
        <Dropdown drop={faEllipsisV} className="me-2 mb-2">
          <Dropdown.Toggle split variant="default">
            <FontAwesomeIcon icon={faEllipsisV} className="dropdown-arrow" />
          </Dropdown.Toggle>
          <Dropdown.Menu className={"user-dropdown dropdown-menu-start mt-2"}>
            <Dropdown.Item
              className={"text-success"}
              onClick={() => {
                globalAction({
                  works_ids: selectedWorks,
                  action: "invoiced",
                });
              }}
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                className="dropdown-arrow me-2"
              />{" "}
              Facturer{" "}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };
  return (
    <>
      {loading ? (
        <div className="text-center">
          <Spinner animation={"border"} className="my-4" />
        </div>
      ) : (
        <Card border="light" className="shadow-sm">
          <Table
            responsive
            hover={true}
            className="align-items-center table-flush"
          >
            <thead className="thead-light">
              <tr>
                <th className="d-flex align-items-center justifity-content-between">
                  {selectedWorks && selectedWorks.length > 0
                    ? renderDropdown()
                    : ""}
                  Référence{" "}
                </th>

                <th> Travail </th>
                <th> Status </th>

                <th> Prix </th>

                <th>
                  <FontAwesomeIcon icon={faPaperclip} />{" "}
                </th>
                <th> Agent </th>

                <th> </th>
              </tr>
            </thead>
            <tbody>
              {works &&
                works
                  .filter((w) => w.status_invoiced === "not_invoiced")
                  .map((work, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor:
                          selectedWorks && selectedWorks.includes(work.id)
                            ? "#eaedf2"
                            : "transparent",
                      }}
                    >
                      <td>
                        <div className="d-flex gap-2 align-items-center">
                          <CheckBox
                            dentist_id={dentist_id}
                            disable={!work.is_accept_form_dentist}
                            workId={work.id}
                            updateSelectedWorks={(work, oper) =>
                              updateSelectedWorks(work, oper)
                            }
                          />

                          <OverlayTrigger
                            placement="right"
                            trigger={["hover", "focus"]}
                            overlay={
                              <Tooltip>
                                {`Date de creation : ` +
                                  moment.unix(work.created_at).format("DD-MMM")}
                                <br />
                                {work.comment}
                              </Tooltip>
                            }
                          >
                            <a
                              className={"text-primary"}
                              onclick={() =>
                                navigate(`/laboratory/works/show/${work.id}`)
                              }
                            >
                              {work.reference_work}{" "}
                            </a>
                          </OverlayTrigger>
                        </div>
                      </td>
                      {/*<td> {work.requested_delivery_date} </td>*/}
                      {/*<td> {work.service.name} </td>*/}

                      <td>
                        {" "}
                        {work.service.name} ( {work.elements_numbers} ){" "}
                      </td>
                      <td> {translate_fr(work.status, "invoices")} </td>
                      <td> {work.price} DT </td>
                      <td>
                        {" "}
                        {work.attachments_files.length > 0 && (
                          <div>
                            <FontAwesomeIcon
                              className={"fa-md"}
                              icon={faImage}
                            />{" "}
                            ( {work.attachments_files.length} )
                          </div>
                        )}
                        {work.files_extra_finger_print.length > 0 && (
                          <div>
                            <FontAwesomeIcon
                              className={"fa-md"}
                              icon={faFileAlt}
                            />{" "}
                            ( {work.files_extra_finger_print.length} )
                          </div>
                        )}
                        {work.attachments_files.length == 0 &&
                          work.files_extra_finger_print.length == 0 &&
                          "vide"}
                      </td>
                      <td>
                        {" "}
                        {work.accessible &&
                          work.accessible?.last_name +
                            " " +
                            work.accessible?.first_name}
                      </td>
                      <td>
                        {selectedWorks && !selectedWorks.includes(work.id) ? (
                          <Dropdown drop={faEllipsisV} className="me-2 mb-2">
                            <Dropdown.Toggle split variant="default">
                              <FontAwesomeIcon
                                icon={faEllipsisV}
                                className="dropdown-arrow"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              className={
                                "user-dropdown dropdown-menu-right mt-2"
                              }
                            >
                              <Dropdown.Item
                                className={"text-primary"}
                                onClick={() =>
                                  navigate("/laboratory/works/show/" + work.id)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faEye}
                                  className="dropdown-arrow me-2"
                                />{" "}
                                Afficher{" "}
                              </Dropdown.Item>

                              <Dropdown.Item
                                className={"text-success"}
                                onClick={() => facturer(work.id)}
                              >
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  className="dropdown-arrow me-2"
                                />{" "}
                                Facturer{" "}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : (
                          <div className="my-5"></div>
                        )}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </Table>
          <div className="d-flex justify-content-center mt-3">
            <PaginatorNotInvoiced
              loader={(value) => setLoading(value)}
              dentist_id={dentist_id}
              totalPages={pages}
              type={"works"}
              setWorks={updateWorks}
              status={currentStatus}
              activatedItem={page}
              updatePageSelected={(value) => setPage(value)}
            />
          </div>
        </Card>
      )}
    </>
  );
};

export default WorksNotInvoiced;
