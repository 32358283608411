import React from 'react';
import './style.css';
import img1 from '../../../assets/svgs/user.svg';
import img2 from '../../../assets/svgs/bycycle.svg';
import img3 from '../../../assets/svgs/clock.svg';
import img4 from '../../../assets/svgs/file.svg';
const Feature = () => {
    const  data = [
        {
            id: 1,
            text: 'éspace utilisateur\n' +
                'individuel',
            img: img1

        },
        {
            id: 2,
            text: 'Compte coursier\n' +
                'livreur associé',
            img: img2

        },{
            id: 3,
            text: 'état instantanné\n' +
                'des travaux',
            img: img3

        },{
            id: 4,
            text: 'Fiche de liaison\n' +
                'intuitive',
            img: img4

        },
    ]
    return (
        <div className={'featureContainer'} id="features">
            <h1>
                Fonctionalités
            </h1>
            <p>En se basant sur les besoins de Nos Utilisateurs Dentistes
                et Prothésistes Ainsi des études de marché nous avons élaboré les
                fonctionnalités de notre plateforme pour répondre parfaitement aux
                attentes de nos utilisateurs.
                <span>Voir Plus..</span>
            </p>
            <div className={'listDataFeature'}>
                {data.map((item, index)=>{
                    return(
                        <div className={'listDataItem'} key={index.toString()}>
                            <img src={item.img} />
                            <pre>{item.text}</pre>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default Feature;
