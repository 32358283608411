import React, { useState } from "react";
import "./style.css";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import impSlider from "../../../assets/EasyLabImageStep1.png";

const Avis = () => {
  const data = [
    {
      id: 1,
      img: impSlider,
      name: "DR Sami",
      Copyright: "CEO & Co-Founder",
      avis: "Mon espace dans la plateforme EasyLab me permet de gagner du temps et d'éviter les erreurs de communication en plus toutes les empreintes numériques et les photos sont bien regroupés. Fini la perte d’informations des mails et messenger",
      cabinet: "Laboratoire X",
    },
    {
      id: 2,
      img: impSlider,
      name: "DR Sami",
      Copyright: "CEO & Co-Founder Laboratoire X",
      avis: "Tous le tableau des différents travaux sont accessibles instantanément et les notifications automatiques me permettent de réagir rapidement, mon Espace EasyLab m’a permis d’avoir un œil sur tout à la fois",
      cabinet: "Laboratoire X",
    },
  ];
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    loop: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });
  return (
    <div className={"AvisGlobalContainer"}>
      <p>Les EasyLabeur ont dis sur Nous</p>
      <div className={"AvisContainer"}>
        <div className="AvisSlide ">
          <div ref={sliderRef} className="keen-slider">
            {data.map((item, index) => {
              return (
                <div
                  className="keen-slider__slide containerKeenSlider"
                  key={index.toString()}
                >
                  <div className={"smallAvisContainer"}>
                    <img src={item.img} />
                    <div className={"carouselBorder"}>
                      <p>{item.avis}</p>
                      <h1>{item.name}</h1>
                      <pre>
                        {item.Copyright} <span>{item.cabinet}</span>
                      </pre>
                    </div>
                  </div>
                </div>
              );
            })}
            {loaded && instanceRef.current && (
              <>
                <Arrow
                  left
                  onClick={(e) =>
                    e.stopPropagation() || instanceRef.current?.prev()
                  }
                  disabled={currentSlide === 0}
                />

                <Arrow
                  onClick={(e) =>
                    e.stopPropagation() || instanceRef.current?.next()
                  }
                  disabled={
                    currentSlide ===
                    instanceRef.current.track.details.slides.length - 1
                  }
                />
              </>
            )}
          </div>
        </div>

        {loaded && instanceRef.current && (
          <div className="dots">
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys(),
            ].map((idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    // eslint-disable-next-line no-unused-expressions
                    instanceRef.current?.moveToIdx(idx);
                  }}
                  className={"dot" + (currentSlide === idx ? " active" : "")}
                ></button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
function Arrow(props) {
  const disabeld = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabeld}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  );
}

export default Avis;
