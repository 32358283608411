import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { useLocation, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faTimes,
  faHome,
  faAddressBook,
  faFileInvoiceDollar,
  faMoneyCheck,
  faHospital,
  faBriefcase,
  faPlusCircle,
  faList,
  faPaperPlane,
  faHouseUser,
  faTruck,
  faArchive,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Badge,
  Image,
  Button,
  Dropdown,
  Accordion,
  Navbar,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { PagesRoutes } from "../../../pages_routes";
import ThemesbergLogo from "../../../assets/easylab.svg";
import NavItem from "../../Utils/NavItem";
import { axiosInstance } from "../../../config/axios";
import { APIURL } from "../../../configApi";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
const Sidebar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const user = props.user;

  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const logout = () => {
    axiosInstance
      .post(`${APIURL}/global/auth/logout`, {
        fcm_token: localStorage.getItem("token") || "",
      })
      .then((res) => {
        localStorage.clear();
        navigate("/login");
      })
      .catch((err) => {
        NotificationManager.error("Error lors de deconnexion", "Auth");
      });
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand className="me-lg-5" as={Link} to={`/`}>
          <Image src={ThemesbergLogo} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image
                    src={ThemesbergLogo}
                    className="card-img-top rounded-circle border-white"
                  />
                </div>
                <div className="d-block">
                  <h6>
                    Bonjour,{" "}
                    {props.user?.last_name + "" + props.user?.first_name}
                  </h6>
                  <Button
                    variant="secondary"
                    size="xs"
                    onClick={logout}
                    className="text-dark"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                    Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <div className="p-2 mb-4">
                <Image
                  src={ThemesbergLogo}
                  className="card-img-top border-white"
                />
              </div>

              {user?.role === "laboratory" ? (
                <>
                  <NavItem
                    className="nav-link"
                    title="Acceuil"
                    link={PagesRoutes.LaboDashboard.path}
                    icon={faHome}
                  />
                  <NavItem
                    title="Travaux"
                    link="/laboratory/works"
                    icon={faBriefcase}
                  />

                  <CollapsableNavItem
                    eventKey="dentistes"
                    title="Dentistes"
                    icon={faHouseUser}
                  >
                    <NavItem
                      title="Liste"
                      eventkey="dentistes"
                      link={PagesRoutes.LaboDentists.path}
                      icon={faList}
                    />
                    <NavItem
                      title="Invitations"
                      eventkey="invitations"
                      link={PagesRoutes.LaboInvitations.path}
                      icon={faPaperPlane}
                    />
                  </CollapsableNavItem>

                  <CollapsableNavItem
                    eventKey="agents"
                    title="Agents"
                    icon={faAddressBook}
                  >
                    <NavItem
                      title="Ajouter"
                      eventkey="agents/add"
                      link={PagesRoutes.LaboAgentsAdd.path}
                      icon={faPlusCircle}
                    />
                    <NavItem
                      title="Liste"
                      eventkey="agents"
                      link={PagesRoutes.LaboAgents.path}
                      icon={faList}
                    />
                  </CollapsableNavItem>

                  <CollapsableNavItem
                    eventKey="coursiers"
                    title="Coursiers"
                    icon={faTruck}
                  >
                    <NavItem
                      title="Ajouter"
                      eventkey="coursiers/add"
                      link={PagesRoutes.LaboCouriersAdd.path}
                      icon={faPlusCircle}
                    />
                    <NavItem
                      title="Liste"
                      eventkey="coursiers"
                      link={PagesRoutes.LaboCouriers.path}
                      icon={faList}
                    />
                  </CollapsableNavItem>
                  {/* <NavItem
                    title="Stock"
                    link="/laboratory/stock"
                    icon={faWarehouse}
                  /> */}
                  <NavItem
                    title="Paiements"
                    eventkey="paiements"
                    link={PagesRoutes.LaboPayments.path}
                    icon={faMoneyCheck}
                  />

                  <NavItem
                    title="Factures"
                    eventkey="invoices"
                    link={PagesRoutes.LaboInvoices.path}
                    icon={faFileInvoiceDollar}
                  />
                  <NavItem
                    title="Archives"
                    link={PagesRoutes.LaboArchives.path}
                    icon={faArchive}
                  />

                  {/*<NavItem title="Laboratoires" link="/laboratoires" icon={faHospital} />*/}
                </>
              ) : user?.role === "courier" ? (
                <>
                  <NavItem
                    title="Acceuil"
                    link={PagesRoutes.CourierDashboard.path}
                    icon={faHome}
                  />
                  <NavItem
                    title="Travaux"
                    link={PagesRoutes.CourierWorks.path}
                    icon={faBriefcase}
                  />
                  <NavItem
                    title="profile"
                    link={PagesRoutes.CourierProfile.path}
                    icon={faFileInvoiceDollar}
                  />
                </>
              ) : (
                <>
                  <NavItem
                    title="Acceuil"
                    link={PagesRoutes.DentistDashboard.path}
                    icon={faHome}
                  />
                  <NavItem
                    title="Travaux"
                    link={PagesRoutes.DentistWorks.path}
                    icon={faBriefcase}
                  />
                  <NavItem
                    title="Paiements"
                    link="/dentist/payments"
                    icon={faMoneyCheck}
                  />
                  <NavItem
                    title="Factures"
                    link={PagesRoutes.DentistInvoices.path}
                    icon={faFileInvoiceDollar}
                  />

                  <CollapsableNavItem
                    eventKey="laboratories"
                    title="Laboratoires"
                    icon={faHouseUser}
                  >
                    <NavItem
                      title="Liste"
                      link={PagesRoutes.DentistLaboratories.path}
                      icon={faList}
                    />
                    <NavItem
                      title="Invitations"
                      link={PagesRoutes.DentistInvitations.path}
                      icon={faPaperPlane}
                    />
                  </CollapsableNavItem>

                  <CollapsableNavItem
                    eventKey="assistants/"
                    title="Assistants"
                    icon={faAddressBook}
                  >
                    <NavItem
                      title="Ajouter"
                      link={PagesRoutes.DentistAgentsAdd.path}
                      icon={faPlusCircle}
                    />
                    <NavItem
                      title="Liste"
                      link={PagesRoutes.DentistAgents.path}
                      icon={faList}
                    />
                  </CollapsableNavItem>
                  <NavItem
                    title="Archives"
                    link={PagesRoutes.DentsitArchives.path}
                    icon={faArchive}
                  />
                </>
              )}

              <Dropdown.Divider className="my-3 border-indigo" />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};

export default Sidebar;
