import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  Row,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faTrash,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { axiosInstance } from "../../../../config/axios";
import { APIURL } from "../../../../configApi";
import { NotificationManager } from "react-notifications";
import couriers from "../index";

const ShowCourier = (props) => {
  const { showForm, courier, closeForm } = props;

  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [full_address, setFull_address] = useState("");
  const [principal_phone, setPrincipal_phone] = useState("");

  const [cin, setCin] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    // console.log("courier => ", courier)
    setFirst_name(courier.first_name);
    setLast_name(courier.last_name);
    setDescription(courier.description);
    setEmail(courier.email);
    setCin(courier.cin);
    setPrincipal_phone(courier.principal_phone);
    setFull_address(courier.full_address);
  }, [courier]);

  return (
    <>
      {courier && (
        <Modal
          as={Modal.Dialog}
          centered
          show={showForm}
          onHide={closeForm}
          size={"lg"}
        >
          <Modal.Header>
            <Modal.Title className="h4 text-main">
              {" "}
              <span className={"text-center"}>Détail de coursier </span>
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={closeForm} />
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6} sm={12}>
                <h5>
                  Nom : <span className={"text-success"}> {first_name} </span>
                </h5>
              </Col>
              <Col md={6} sm={12}>
                <h5>
                  Prénom : <span className={"text-success"}> {last_name} </span>
                </h5>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={6} sm={12}>
                <h5>
                  Email : <span className={"text-success"}> {email} </span>
                </h5>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={6} sm={12}>
                <h5>
                  CIN : <span className={"text-success"}> {cin} </span>
                </h5>
              </Col>
              <Col md={6} sm={12}>
                <h5>
                  Téléphone :{" "}
                  <span className={"text-success"}> {principal_phone} </span>
                </h5>
              </Col>
            </Row>
            <hr />

            <div>
              <h5>
                Adresse : <span className={"text-success"}>{full_address}</span>
              </h5>
            </div>
            <hr />
            <div>
              <h5>
                Déscription :{" "}
                <span className={"text-success"}>{description}</span>
              </h5>
            </div>

            {/*<div className="my-4 d-flex justify-content-end">*/}

            {/*    <Button variant={'main'} onClick={closeForm}> <FontAwesomeIcon icon={faWindowClose}/> Fermer*/}
            {/*    </Button>*/}
            {/*</div>*/}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ShowCourier;
