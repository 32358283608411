import React, { useEffect, useState } from "react";
import Sidebar from "../../../Shared/Sidebar";
import Preloader from "../../../Shared/Preloader/Preloader";
import Navbar from "../../../Shared/Navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  Button,
  Modal,
  Form,
  Col,
  Row,
  Table,
  Spinner,
} from "@themesberg/react-bootstrap";

import {
  faArrowAltCircleLeft,
  faPaperPlane,
  faPlus,
  faPlusCircle,
  faSave,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Category from "../components/Category";
import { currentUser } from "../../../../authentication/auth";
import StockService from "../../../services/StockService";
const LaboStockCategory = () => {
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [category, setCategory] = useState({});
  const [retourCategory, setRetourCategory] = useState("/laboratory/stock");
  const handleClose = () => {
    setShowAddProduct(false);
  };
  const [loading, setLoading] = useState(false);
  let { id } = useParams();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    getCategory();
    // console.log("url retour ===>", retourCategory);
  }, [id]);
  //const addProduct = () => {};
  const getCategory = async () => {
    try {
      const res = await StockService.getCategoryById(id);
      if (res.status === 200) {
        //NotificationManager.success(res.data.message, "Stock");
        setCategory(res.data.data);
        setLoading(true);
        if (res.data.data.category_id) {
          setRetourCategory(
            "/laboratory/stock/category/" + res.data.data.category_id
          );
        } else {
          setRetourCategory("/laboratory/stock");
        }
      }
    } catch (e) {
      NotificationManager.error("error", "Stock");
      setLoading(true);
    }
  };

  return (
    <>
      {" "}
      <Preloader />
      <Sidebar user={user} />
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar user={user} pageName={"Gestion du stock"} icon={faWarehouse} />
        <hr />
        <div>
          <div className="text-end mb-3"></div>
        </div>
        {loading ? (
          <div className="text-center mt-6">
            <Spinner animation="border" />
          </div>
        ) : (
          <div>
            <div className="d-flex justify-content-between">
              <Button
                variant={"main"}
                onClick={() => {
                  // console.log("retour link", retourCategory);
                  navigate(retourCategory);
                }}
              >
                <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Retour{" "}
              </Button>
              <Button variant={"main"} onClick={() => setShowAddProduct(true)}>
                <FontAwesomeIcon icon={faPlusCircle} /> Ajouter Categorie{" "}
              </Button>
            </div>
            {category && <div>hathi categorie {category?.name}</div>}

            <div className="mt-2">
              <Row>
                {category?.categories?.length > 0 &&
                  category?.categories.map((c) => (
                    <Col md={12} lg={3}>
                      <Category category={c} />
                    </Col>
                  ))}
              </Row>
            </div>
            {/*
  <div className="mt-2">
    <h5> Liste des articles</h5>

    <Table
      responsive
      hover={true}
      className="align-items-center table-flush table-bordered"
    >
      <thead className="thead-light">
        <tr>
          <td>Numero</td>
          <td>Nom</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>test</td>
          <td>test</td>
        </tr>
      </tbody>
    </Table>

    <div className="d-flex justify-content-end">
      <Button variant={"main"} onClick={() => setShowAddProduct(true)}>
        <FontAwesomeIcon icon={faPlusCircle} /> Ajouter article{" "}
      </Button>
    </div>
  </div> */}
          </div>
        )}

        <NotificationContainer />
      </main>
      <Modal
        as={Modal.Dialog}
        centered
        show={showAddProduct}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faPlus} /> Ajouter catégorie
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className={""}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label>Reference article</Form.Label>
                <Form.Control></Form.Control>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Nom article</Form.Label>
                <Form.Control></Form.Control>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Categorie article</Form.Label>
                <select className="form-control">
                  <option>Categorie 1</option>
                  <option>Categorie 1</option>
                </select>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Description article</Form.Label>
                <Form.Control as="textarea"></Form.Control>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Qunatite article</Form.Label>
                <Form.Control type="number"></Form.Control>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Unite article</Form.Label>
                <select className="form-control">
                  <option>Unite 1</option>
                </select>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Prix article</Form.Label>
                <Form.Control type="number"></Form.Control>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Commentaire article</Form.Label>
                <Form.Control></Form.Control>
              </Form.Group>

              <Form.Group className="d-flex justify-content-end">
                <Button type="submit" variant={"main"}>
                  <FontAwesomeIcon icon={faSave} /> Sauvegarder{" "}
                </Button>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LaboStockCategory;
