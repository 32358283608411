import React, { useEffect, useState } from "react";

const CheckBox = (props) => {
  const { workId, updateSelectedWorks, disable, dentist_id } = props;
  const [localCheck, setLocalCheck] = useState(false);

  const selectWork = () => {
    setLocalCheck((prev) => !prev);
    if (!localCheck) {
      updateSelectedWorks(workId, "+");
    } else {
      updateSelectedWorks(workId, "-");
    }
  };
  // useEffect(() => {
  //  // console.log("status insidde check  ", localCheck, "in work id", workId);
  // }, [workId, localCheck]);
  useEffect(() => {
    setLocalCheck(false);
  }, [dentist_id]);
  return (
    <>
      {!disable ? (
        <input
          type={"checkbox"}
          checked={localCheck}
          onChange={() => {
            selectWork();
          }}
        />
      ) : (
        <input disabled type={"checkbox"} checked={localCheck} />
      )}
    </>
  );
};

export default CheckBox;
