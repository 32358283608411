import { useNavigate } from "react-router-dom";
import Preloader from "../../Shared/Preloader/Preloader";
import Sidebar from "../../Shared/Sidebar";
import Navbar from "../../Shared/Navbar/Navbar";
import { Button, Spinner, Tab, Tabs } from "@themesberg/react-bootstrap";
import { PagesRoutes } from "../../../pages_routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import React, { useEffect, useState } from "react";
import { currentUser } from "../../../authentication/auth";
import { axiosInstance } from "../../../config/axios";
import { APIURL, getPages } from "../../../configApi";
import WorksDentist from "../../Shared/components/Table/WorksDentist";
import WorkService from "../../services/WorkService";
import FirebaseNotif from "../../Shared/FirebaseNotif/FirebaseNotif";

const DentistWorks = () => {
  const navigate = useNavigate();
  const [pages, setPages] = useState(0);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [outgoing_dentist_rows, setOutgoing_dentist_rows] = useState(0);
  const [outgoing_laboratory_rows, setOutgoing_laboratory_rows] = useState(0);
  const [at_dentist_rows, setAt_dentist_rows] = useState(0);
  const [at_laboratory_rows, setAt_laboratory_rows] = useState(0);
  const [currentStatus, setCurrentStatus] = useState("at_dentist");
  const [works, setWorks] = useState([]);
  const [activeTab, setActiveTab] = useState("at_dentist");
  let status = new URLSearchParams(window.location.search).get("status");
  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    //getAgents()
    resfreshAllData();
    if (status && status.length > 0) {
      setCurrentStatus(status);
      setActiveTab(status);
      getWorks(status);
    } else {
      setCurrentStatus("at_dentist");
      setActiveTab("at_dentist");
    }
  }, []);

  const resfreshAllData = () => {
    getWorks("at_dentist");
    calculateRowsByStatus("at_laboratory");
    calculateRowsByStatus("outgoing_laboratory");
    calculateRowsByStatus("outgoing_dentist");
  };

  const calculateRowsByStatus = async (e) => {
    try {
      const response = await WorkService.worksByStatus(e);
      if (response.status === 200) {
        if (e == "outgoing_dentist") {
          setOutgoing_dentist_rows(response.data.data.total);
        } else if (e == "at_laboratory") {
          setAt_laboratory_rows(response.data.data.total);
        } else if (e == "at_dentist") {
          setAt_dentist_rows(response.data.data.total);
        } else {
          setOutgoing_laboratory_rows(response.data.data.total);
        }
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const getWorks = async (e) => {
    try {
      const response = await WorkService.worksByStatus(e);
      if (response.status === 200) {
        setWorks(response.data.data.list);
        // console.log(response.data);
        setLoading(true);
        if (e == "outgoing_dentist") {
          setOutgoing_dentist_rows(response.data.data.total);
          setPages(getPages(response.data.data.total));
        } else if (e == "at_laboratory") {
          setAt_laboratory_rows(response.data.data.total);
          setPages(getPages(response.data.data.total));
        } else if (e == "at_dentist") {
          setAt_dentist_rows(response.data.data.total);
          setPages(getPages(response.data.data.total));
        } else {
          setOutgoing_laboratory_rows(response.data.data.total);
          setPages(getPages(response.data.data.total));
        }
      }
    } catch (err) {
      NotificationManager.error(err?.response?.data?.message, "Erreur");
    }
  };

  return (
    <>
      <Preloader />
      <Sidebar user={user} />
      {/* <FirebaseNotif /> */}
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar user={user} pageName={`Liste des travaux`} />
        <hr />
        <div>
          <div className="d-flex justify-content-end mb-2">
            <Button
              variant={"main"}
              onClick={() => navigate(PagesRoutes.DentistWorksAdd.path)}
            >
              {" "}
              <FontAwesomeIcon icon={faPlusCircle} /> Ajouter{" "}
            </Button>
          </div>
          <NotificationContainer />

          <div>
            <Tabs
              defaultActiveKey={activeTab}
              id="uncontrolled-tab-example"
              className="mb-3"
              onSelect={(e) => {
                setLoading(false);
                // console.log("current status from on select", e);
                // console.log(e, "test .....", currentStatus);
                if (currentStatus != e) {
                  // console.log(e, "test .....");
                  setActiveTab(e);
                  setCurrentStatus(e);
                  getWorks(e);
                }
              }}
            >
              <Tab
                eventKey="outgoing_dentist"
                title={"Travaux Sortants ( " + outgoing_dentist_rows + " )"}
              >
                <div>
                  {!loading ? (
                    <div className="text-center">
                      <Spinner animation={"border"} className="my-4" />
                    </div>
                  ) : (
                    <WorksDentist
                      name={"Travaux Sortants"}
                      pages={pages}
                      refreshWorks={(status) => getWorks(status)}
                      currentStatus={"outgoing_dentist"}
                      works={works}
                      updateWorks={(tab) => setWorks(tab)}
                      updatedRows={() =>
                        setAt_laboratory_rows((prev) => prev + 1)
                      }
                    />
                  )}
                </div>
              </Tab>
              <Tab
                eventKey="at_laboratory"
                title={"Travaux Au Labo ( " + at_laboratory_rows + " )"}
              >
                <div>
                  {!loading ? (
                    <div className="text-center">
                      <Spinner animation={"border"} className="my-4" />
                    </div>
                  ) : (
                    <WorksDentist
                      name={"Travaux Au Labo"}
                      pages={pages}
                      refreshWorks={(status) => getWorks(status)}
                      currentStatus={"at_laboratory"}
                      works={works}
                      updateWorks={(tab) => setWorks(tab)}
                      updatedRows={() =>
                        setOutgoing_laboratory_rows((prev) => prev + 1)
                      }
                    />
                  )}
                </div>
              </Tab>
              <Tab
                eventKey="outgoing_laboratory"
                title={"Travaux Entrants ( " + outgoing_laboratory_rows + " )"}
              >
                <div>
                  {!loading ? (
                    <div className="text-center">
                      <Spinner animation={"border"} className="my-4" />
                    </div>
                  ) : (
                    <WorksDentist
                      name={"Travaux Entrants"}
                      pages={pages}
                      refreshWorks={(status) => getWorks(status)}
                      currentStatus={"outgoing_laboratory"}
                      works={works}
                      updateWorks={(tab) => setWorks(tab)}
                      updatedRows={() =>
                        setAt_laboratory_rows((prev) => prev + 1)
                      }
                    />
                  )}
                </div>
              </Tab>
              <Tab
                eventKey="at_dentist"
                title={"Travaux Chez Dentiste ( " + at_dentist_rows + " )"}
              >
                <div>
                  {!loading ? (
                    <div className="text-center">
                      <Spinner animation={"border"} className="my-4" />
                    </div>
                  ) : (
                    <WorksDentist
                      name={"Travaux Chez Dentiste"}
                      pages={pages}
                      refreshWorks={(status) => getWorks(status)}
                      currentStatus={"at_dentist"}
                      works={works}
                    />
                  )}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </main>
    </>
  );
};

export default DentistWorks;
