import React, { useEffect, useState } from "react";
import Preloader from "../../Shared/Preloader/Preloader";
import Sidebar from "../../Shared/Sidebar";
import Navbar from "../../Shared/Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faSave,
  faUser,
  faCircleCheck,
  faCheckCircle,
  faTemperatureLow,
} from "@fortawesome/free-solid-svg-icons";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { currentUser } from "../../../authentication/auth";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "@themesberg/react-bootstrap";
import { axiosInstance } from "../../../config/axios";
import { APIURL, getPages } from "../../../configApi";
import moment from "moment";
import CoursierService from "../../services/CoursierService";
import CheckBox from "../../Shared/components/CheckBox";
import PaginatorCourierWork from "../../Shared/components/Paginator/PaginatorCourierWork";

const CourierWorks = () => {
  const navigate = useNavigate();
  const [work, setWork] = useState({});
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [user, setUser] = useState(null);
  const [works, setWorks] = useState([]);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const getWorks = () => {
    axiosInstance
      .get(`${APIURL}/courier/works?page=${page}`)
      .then((res) => {
        setWorks(res.data.data.list);
        setPages(getPages(res.data.data.total));
      })
      .catch((err) =>{})// console.log(err));
  };

  const recupererWork = async (work, status) => {
    let message = "";
    if (status == "recover_dentist" || status == "recover_laboratory") {
      message = "Travail bien récupéré";
    } else if (status == "deliver_laboratory" || status == "deliver_dentist") {
      message = "Travail bien livré";
    }
    try {
      const response = await CoursierService.changeStatus(work.id, status);
     // console.log("response ===> ", response);
      if (response.status === 200) {
        NotificationManager.success(message, "Coursier");
        getWorks();
      }
    } catch (err) {
     // console.log(err);
    }
  };

  const annulerWork = async (work, status) => {
    let message = "";
    if (status == null) {
      message = "Annulation de la récupération";
    } else if (status == "recover_dentist" || status == "recover_laboratory") {
      message = "Annulation de la livraison";
    }
    try {
      const response = await CoursierService.changeStatus(work.id, status);
     // console.log("response ===> ", response);
      if (response.status === 200) {
        NotificationManager.error(message, "Coursier");
        getWorks();
      }
    } catch (err) {
     // console.log(err);
    }
  };

  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }

    getWorks();
  }, []);

  return (
    <>
      <Preloader />
      <Sidebar user={user} />

      <main className="content pb-3" style={{ margin: "0px !important" }}>
        <Navbar user={user} />
        <hr />
        <div className="mb-3">
          <h2>
            <FontAwesomeIcon icon={faUser} className={"me-2"} />
            Liste des travaux{" "}
          </h2>

          <NotificationContainer />
          <hr />

          <div className="mb-3">
            {works && works.length > 0 ? (
              <>
                {loading ? (
                  <Spinner animation="border" className="m-4" />
                ) : (
                  <>
                    <Table
                      responsive
                      hover={true}
                      className="align-items-center table-flush"
                    >
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          <th> Date </th>
                          <th> Travail demandé </th>
                          <th>Depuis </th>
                          <th>Vers </th>
                        </tr>
                      </thead>
                      <tbody>
                        {works.map((work, index) =>
                          ["outgoing_dentist", "outgoing_laboratory"].includes(
                            work.status
                          ) ? (
                            <tr>
                              <td>
                                {" "}
                                {/* <CheckBox disabled={true} /> */}
                                {index + 1}{" "}
                              </td>
                              <td>
                                {" "}
                                {moment
                                  .unix(work.updated_at)
                                  .format("DD-MM-YYYY")}{" "}
                              </td>
                              <td> {work.service.name}</td>

                              {work.status == "outgoing_dentist" ? (
                                <>
                                  <td>
                                    <div
                                      className={"d-flex align-items-center"}
                                    >
                                      {work.sub_status_courier == null ? (
                                        <button
                                          className={
                                            "border border-dark rounded p-2 me-2"
                                          }
                                          onClick={() => {
                                            setWork(work);
                                            recupererWork(
                                              work,
                                              "recover_dentist"
                                            );
                                          }}
                                        >
                                          Récupéré
                                        </button>
                                      ) : work.sub_status_courier ==
                                        "deliver_laboratory" ? (
                                        <button
                                          disabled={true}
                                          className={"border rounded p-2 me-2"}
                                          style={{
                                            borderColor: "#0eaeba",
                                            backgroundColor: "#0eaeba",
                                            color: "white",
                                          }}
                                        >
                                          Récupéré
                                        </button>
                                      ) : (
                                        <button
                                          className={"border rounded p-2 me-2"}
                                          style={{
                                            borderColor: "#0eaeba",
                                            backgroundColor: "#0eaeba",
                                            color: "white",
                                          }}
                                          onClick={() => {
                                            setWork(work);
                                            //recupererWork(work, null);
                                            setShow(true);
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faCheckCircle}
                                          />{" "}
                                          Récupéré
                                        </button>
                                      )}

                                      {work.dentist.last_name +
                                        ` ` +
                                        work.dentist.first_name +
                                        ` (` +
                                        work.denist?.city?.name +
                                        `)`}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      className={"d-flex align-items-center"}
                                    >
                                      <b className={"text-main p-2"}>
                                        {work.laboratory.last_name +
                                          ` ` +
                                          work.laboratory.first_name +
                                          ` (` +
                                          work.laboratory.city?.name +
                                          `)`}
                                      </b>

                                      {work.sub_status_courier == null ? (
                                        <button
                                          disabled={true}
                                          className={"border rounded p-2 ms-2"}
                                          onClick={() => {
                                            setWork(work);
                                            recupererWork(
                                              work,
                                              "deliver_laboratory"
                                            );
                                          }}
                                        >
                                          Livré
                                        </button>
                                      ) : work.sub_status_courier ==
                                        "recover_dentist" ? (
                                        <button
                                          className={"border  rounded p-2 ms-2"}
                                          onClick={() => {
                                            setWork(work);
                                            recupererWork(
                                              work,
                                              "deliver_laboratory"
                                            );
                                          }}
                                        >
                                          Livré
                                        </button>
                                      ) : (
                                        <button
                                          className={"border rounded p-2 ms-2"}
                                          style={{
                                            borderColor: "#0eaeba",
                                            backgroundColor: "#0eaeba",
                                            color: "white",
                                          }}
                                          onClick={() => {
                                            setWork(work);
                                            setShow(true);
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faCheckCircle}
                                          />{" "}
                                          Livré
                                        </button>
                                      )}
                                    </div>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>
                                    <div
                                      className={"d-flex align-items-center"}
                                    >
                                      {work.sub_status_courier == null ? (
                                        <button
                                          className={
                                            "border border-dark rounded p-2 me-2"
                                          }
                                          onClick={() => {
                                            setWork(work);
                                            recupererWork(
                                              work,
                                              "recover_laboratory"
                                            );
                                          }}
                                        >
                                          Récupéré
                                        </button>
                                      ) : work.sub_status_courier ==
                                        "deliver_dentist" ? (
                                        <button
                                          disabled={true}
                                          className={"border rounded p-2 me-2"}
                                          style={{
                                            borderColor: "#0eaeba",
                                            backgroundColor: "#0eaeba",
                                            color: "white",
                                          }}
                                        >
                                          Récupéré
                                        </button>
                                      ) : (
                                        <button
                                          className={"border rounded p-2 me-2"}
                                          style={{
                                            borderColor: "#0eaeba",
                                            backgroundColor: "#0eaeba",
                                            color: "white",
                                          }}
                                          onClick={() => {
                                            setWork(work);
                                            //recupererWork(work, null);
                                            setShow(true);
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faCheckCircle}
                                          />{" "}
                                          Récupéré
                                        </button>
                                      )}
                                      <b className={"text-main p-2"}>
                                        {work?.laboratory?.last_name +
                                          ` ` +
                                          work?.laboratory?.first_name +
                                          ` (` +
                                          work?.laboratory?.city?.name +
                                          `)`}
                                      </b>
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      className={"d-flex align-items-center"}
                                    >
                                      {work.dentist.last_name +
                                        ` ` +
                                        work.dentist.first_name +
                                        ` (` +
                                        work.denist?.city?.name +
                                        `)`}

                                      {work.sub_status_courier == null ? (
                                        <button
                                          disabled={true}
                                          className={"border rounded p-2 ms-2"}
                                          onClick={() => {
                                            setWork(work);
                                            recupererWork(
                                              work,
                                              "deliver_laboratory"
                                            );
                                          }}
                                        >
                                          Livré
                                        </button>
                                      ) : work.sub_status_courier ==
                                        "recover_laboratory" ? (
                                        <button
                                          className={"border  rounded p-2 ms-2"}
                                          onClick={() => {
                                            setWork(work);
                                            recupererWork(
                                              work,
                                              "deliver_dentist"
                                            );
                                          }}
                                        >
                                          Livré
                                        </button>
                                      ) : (
                                        <button
                                          className={"border rounded p-2 ms-2"}
                                          style={{
                                            borderColor: "#0eaeba",
                                            backgroundColor: "#0eaeba",
                                            color: "white",
                                          }}
                                          onClick={() => {
                                            setWork(work);
                                            setShow(true);
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faCheckCircle}
                                          />{" "}
                                          Livré
                                        </button>
                                      )}
                                    </div>
                                  </td>
                                </>
                              )}
                            </tr>
                          ) : (
                            ""
                          )
                        )}
                      </tbody>
                    </Table>
                    <div className="d-flex justify-content-center mt-3">
                      <PaginatorCourierWork
                        loader={(value) => setLoading(value)}
                        totalPages={pages}
                        type={"works"}
                        setWorks={(value) => setWorks(value)}
                        updatePageSelected={(value) => setPage(value)}
                        activatedItem={page}
                      />
                    </div>
                  </>
                )}
              </>
            ) : (
              "Aucun travail n'est assignée pour vous"
            )}
          </div>
        </div>
      </main>

      <Modal
        as={Modal.Dialog}
        centered
        show={show}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faEdit} /> Confirmation
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {work.sub_status_courier == "deliver_laboratory" ||
          work.sub_status_courier == "deliver_dentist" ? (
            <div className={""}>
              <p>
                Vous êtes en train d'annuler la livraison du travail{" "}
                <span className={"text-main"}> {work?.service?.name} </span> du
                docteur{" "}
                <span className={"text-main"}>
                  {" "}
                  {`${work?.dentist?.last_name} ${work?.dentist?.first_name}`}{" "}
                </span>
              </p>
            </div>
          ) : (
            <div className={""}>
              <p>
                Vous êtes en train d'annuler la récupération du travail{" "}
                <span className={"text-main"}> {work?.service?.name} </span> du
                docteur{" "}
                <span className={"text-main"}>
                  {" "}
                  {`${work?.dentist?.last_name} ${work?.dentist?.first_name}`}{" "}
                </span>
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Non</Button>

          {work.sub_status_courier == "deliver_laboratory" ? (
            <Button
              variant={"main"}
              onClick={() => {
                annulerWork(work, "recover_dentist");
                handleClose();
              }}
            >
              OUI , Annuler{" "}
            </Button>
          ) : work.sub_status_courier == "deliver_dentist" ? (
            <Button
              variant={"main"}
              onClick={() => {
                annulerWork(work, "recover_laboratory");
                handleClose();
              }}
            >
              OUI , Annuler{" "}
            </Button>
          ) : (
            <Button
              variant={"main"}
              onClick={() => {
                annulerWork(work, null);
                handleClose();
              }}
            >
              OUI , Annuler{" "}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CourierWorks;
