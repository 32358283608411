export const PagesRoutes = {
  // pages
  ResetPasssword: { path: "/password/reset" },
  VerifyEmail: { path: "/email/verify" },
  Login: { path: "/login" },
  LaboStock: { path: "/laboratory/stock" },
  LaboStockCategory: { path: "/laboratory/stock/category/:id" },
  Register: { path: "/register" },
  LaboAgents: { path: "/laboratory/agents/" },
  LaboDashboard: { path: "/laboratory/dashboard/" },
  LaboAgentsEdit: { path: "/laboratory/agents/edit/:id" },
  LaboAgentsAdd: { path: "/laboratory/agents/add" },
  LaboInvitations: { path: "/laboratory/invitations" },
  LaboCouriers: { path: "/laboratory/coursiers/" },
  LaboCouriersAdd: { path: "/laboratory/coursiers/add" },
  LaboPayments: { path: "/laboratory/payments" },
  LaboWorks: { path: "/laboratory/works" },
  LaboWorksAdd: { path: "/laboratory/works/add" },
  LaboWorksShow: { path: "/laboratory/works/show/:id" },
  LaboSettings: { path: "/laboratory/settings" },
  LaboDentists: { path: "/laboratory/dentistes" },
  LaboProfile: { path: "/laboratory/profile" },
  Support: { path: "/support" },
  LaboInvoices: { path: "/laboratory/invoices" },
  LaboArchives: { path: "/laboratory/archives" },
  DentistDashboard: { path: "/dentist/dashboard/" },
  DentistStock: { path: "/dentist/stock" },
  DentistWorks: { path: "/dentist/works/" },
  DentistSettings: { path: "/dentist/settings/" },
  DentistLaboratories: { path: "/dentist/laboratories/" },
  DentistInvoices: { path: "/dentist/invoices/" },
  DentistWorkShow: { path: "/dentist/works/show/:id" },
  DentistWorksAdd: { path: "/dentist/works/add" },
  DentistAgents: { path: "/dentist/assistants/" },
  DentistAgentsAdd: { path: "/dentist/assistants/add" },
  DentistInvitations: { path: "/dentist/laboratories/invitations" },
  DentistPayments: { path: "/dentist/payments" },
  DentistProfile: { path: "/dentist/profile/" },
  DentsitArchives: { path: "/dentist/archives/" },
  CourierDashboard: { path: "/courier/dashboard" },
  CourierHome: { path: "/courier" },
  CourierWorks: { path: "/courier/works" },
  CourierProfile: { path: "/courier/profile" },
};
