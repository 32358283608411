import { axiosInstance } from "../../config/axios";
import { APIURL } from "../../configApi";

const StockService = {};
StockService.getCategories = () => {
  return axiosInstance.get(`${APIURL}/global/stock/categories`);
};

StockService.addCategory = (data) => {
  return axiosInstance.post(`${APIURL}/global/stock/categories`, data);
};

StockService.getCategoryById = (id) => {
  return axiosInstance.get(`${APIURL}/global/stock/categories/${id}`);
};

export default StockService;
