import {
  Card,
  Row,
  Table,
  Col,
  Button,
  Dropdown,
  Modal,
  Badge,
  Pagination,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import LaboratoryService from "../../../../services/LaboratoireService";
import { translate_fr } from "../../../../../lang/fr";

export default function (props) {
  const [activeItem, setActiveItem] = React.useState(1);
  const {
    loader,
    totalPages = 0,
    size = "md",
    withIcons = false,
    disablePrev = false,
    type,
    activatedItem,
    status,
    updatePageSelected,
    updateArchives,
  } = props;

  useEffect(() => {
    setActiveItem(activatedItem);

    // console.log("status here .....", status);
  }, []);

  const onPrevItem = () => {
    const prevActiveItem = activeItem === 1 ? activeItem : activeItem - 1;
    setActiveItem(prevActiveItem);
    updatePageSelected(prevActiveItem);
    refreshArchives(prevActiveItem);
  };

  const onNextItem = (totalPages) => {
    const nextActiveItem =
      activeItem === totalPages ? activeItem : activeItem + 1;
    setActiveItem(nextActiveItem);
    updatePageSelected(nextActiveItem);
    refreshArchives(nextActiveItem);
  };

  const items = [];
  for (let number = 1; number <= totalPages; number++) {
    const isItemActive = activeItem === number;

    const handlePaginationChange = () => {
      setActiveItem(number);
      refreshArchives(number);
      updatePageSelected(number);
    };

    items.push(
      <Pagination.Item
        active={isItemActive}
        key={number}
        onClick={handlePaginationChange}
      >
        {number}
      </Pagination.Item>
    );
  }

  // refresh Works with the new item activated

  const refreshArchives = async (number) => {
    loader(true);
    try {
      const response = await LaboratoryService.worksArchivee(number);
      if (response.status === 200) {
        updateArchives(response.data.data.list);
        loader(false);
      }
    } catch (err) {
      loader(false);
    }
  };

  return totalPages > 0 ? (
    <Pagination size={size} className="mt-3">
      <Pagination.Prev disabled={disablePrev} onClick={onPrevItem}>
        {withIcons ? (
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        ) : (
          translate_fr("previous")
        )}
      </Pagination.Prev>
      {items}
      <Pagination.Next onClick={() => onNextItem(totalPages)}>
        {withIcons ? (
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        ) : (
          translate_fr("Next")
        )}
      </Pagination.Next>
    </Pagination>
  ) : (
    ""
  );
}
