import {
  Card,
  Form,
  Table,
  Col,
  Button,
  Dropdown,
  Modal,
  Badge,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faCalendarTimes,
  faEdit,
  faEllipsisV,
  faEye,
  faMoneyBill,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { translate_fr } from "../../../../lang/fr";
import Paginator from "../Paginator";
import EditAgent from "../Modals/EditAgent";
import CheckToggle from "../CheckToggle";

import { axiosInstance } from "../../../../config/axios";
import { APIURL } from "../../../../configApi";
import { NotificationManager } from "react-notifications";
import ShowAgent from "../../../Laboratoire/Agents/Show";
import ShowCourier from "../../../Laboratoire/Couriers/Show";
import EditCourier from "../Modals/EditCourier";
import ShowLaboratoire from "../../../Dentist/Laboratoires/Show";
import ShowAssistant from "../../../Dentist/Agents/Show";
import ShowBalance from "../../../Dentist/Laboratoires/Balance";

export default function (props) {
  const [day, setDay] = useState(1);
  const [showDateFacturation, setShowDateFacturation] = useState(false);
  const [checked, setChecked] = useState(false);
  const [keys, setKeys] = useState([]);
  const [user, setUser] = useState({});
  const [showEditForm, setShowEditForm] = useState(false);
  const [showUserData, setShowUserData] = useState(false);
  const [showUserBalance, setShowUserBalance] = useState(false);
  const [showDefault, setShowDefault] = useState(false);
  const [searchKeywords, setSearchKeywords] = useState("");

  const { refreshUsers, users, setUsers, pages, type } = props;

  const handleClose = () => {
    setShowDefault(false);
    setShowDateFacturation(false);
  };

  useEffect(() => {
    if (users.length > 0) {
      setKeys(Object.keys(props.users[0]));
      renderThs();
    }
  }, []);

  const deleteAgent = () => {
    axiosInstance
      .delete(`${APIURL}/laboratory/agents/` + user.id)
      .then((res) => {
        NotificationManager.success(
          "Agent Supprimée avec succès",
          "Notification"
        );
        setShowDefault(false);
        refreshUsers();
        // console.log(res);
      })
      .catch((err) => {
        NotificationManager.error(err.response.data.message, "Notification");
        // console.log(err);
      });
  };

  const renderThs = async () => {
    let selectedKeys = [];
    // console.log(selectedKeys);
    // console.log(keys);
    if (keys)
      Object.keys(props.users[0]).map((key) => {
        if (props.headColumns.includes(key)) selectedKeys.push(key);
      });
    // console.log(selectedKeys);
    setKeys(selectedKeys);
  };
  const editAgent = (user) => {
    setUser(user);
    setShowEditForm(true);
    //console.log(showEditForm)
  };

  const renderLigne = () => {
    return props.users.map((selectedUser) => (
      <tr>
        {renderColumn(selectedUser)}
        <td>
          <Dropdown drop={faEllipsisV} className="me-2 mb-2">
            <Dropdown.Toggle split variant="default">
              <FontAwesomeIcon icon={faEllipsisV} className="dropdown-arrow" />
            </Dropdown.Toggle>
            {type && type == "agent" ? (
              <Dropdown.Menu
                className={"user-dropdown dropdown-menu-right mt-2"}
              >
                <Dropdown.Item
                  className={"text-primary"}
                  onClick={() => {
                    setShowUserData(true);
                    setUser(selectedUser);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEye}
                    className="dropdown-arrow me-2"
                  />{" "}
                  Afficher
                </Dropdown.Item>
                <Dropdown.Item
                  className={"text-success"}
                  onClick={() => editAgent(user)}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="dropdown-arrow me-2"
                  />{" "}
                  Modifier
                </Dropdown.Item>
                {/*<Dropdown.Item className={'text-danger'} onClick={() => {setShowDefault(true);setAgent(selectedUser)} }><FontAwesomeIcon icon={faTrash} className="dropdown-arrow me-2" /> Supprimer</Dropdown.Item>*/}
              </Dropdown.Menu>
            ) : type == "courier" ? (
              <Dropdown.Menu
                className={"user-dropdown dropdown-menu-right mt-2"}
              >
                <Dropdown.Item
                  className={"text-primary"}
                  onClick={() => {
                    setShowUserData(true);
                    setUser(selectedUser);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEye}
                    className="dropdown-arrow me-2"
                  />{" "}
                  Afficher
                </Dropdown.Item>
                <Dropdown.Item
                  className={"text-success"}
                  onClick={() => editAgent(selectedUser)}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="dropdown-arrow me-2"
                  />{" "}
                  Modifier
                </Dropdown.Item>
                {/*<Dropdown.Item className={'text-danger'} onClick={() => {setShowDefault(true);setAgent(selectedUser)} }><FontAwesomeIcon icon={faTrash} className="dropdown-arrow me-2" /> Supprimer</Dropdown.Item>*/}
              </Dropdown.Menu>
            ) : (
              <Dropdown.Menu
                className={"user-dropdown dropdown-menu-right mt-2"}
              >
                <Dropdown.Item
                  className={"text-primary"}
                  onClick={() => {
                    setShowUserData(true);
                    setUser(selectedUser);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEye}
                    className="dropdown-arrow me-2"
                  />{" "}
                  Afficher
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => {
                    setShowUserBalance(true);
                    setUser(selectedUser);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faMoneyBill}
                    className="dropdown-arrow me-2"
                  />{" "}
                  Balance{" "}
                </Dropdown.Item>

                {/*<Dropdown.Item className={'text-danger'} onClick={() => {setShowDefault(true);setAgent(selectedUser)} }><FontAwesomeIcon icon={faTrash} className="dropdown-arrow me-2" /> Supprimer</Dropdown.Item>*/}
              </Dropdown.Menu>
            )}
          </Dropdown>
        </td>
      </tr>
    ));
  };

  const renderColumn = (data) => {
    return Object.keys(data).map((k, i) =>
      props.headColumns.includes(k.toString()) ? (
        <td> {getObjectKeyValue(data, k)}</td>
      ) : (
        ""
      )
    );
  };

  const getObjectKeyValue = (obj, key) => {
    // console.log("get object value", obj.id, key);

    return Object.entries(obj).map((e) =>
      e[0] == key ? (
        ["city"].includes(e[0]) ? (
          e[1]?.name
        ) : [true, false].includes(e[1]) ? (
          <>
            {" "}
            <CheckToggle checked={e[1]} id={obj.id} />{" "}
          </>
        ) : (
          e[1]
        )
      ) : (
        ""
      )
    );
  };

  const searchUser = (value) => {
    if (searchKeywords) {
      axiosInstance
        .get(`${APIURL}/laboratory/agents/all?search=${value}`)
        .then((res) => setUsers(res.data.data))
        .catch((err) => {});
    }
  };

  const affectDateToDenrist = (e) => {
    e.preventDefault();
    axiosInstance
      .put(
        `${APIURL}/laboratory/dentists/update_invoice_day_by_dentist/${user.id}`,
        {
          day: day,
        }
      )
      .then((res) => {
        NotificationManager.success(
          "Actualisation de la date de facturation mensuelle effectuè avec succès",
          "Notification"
        );
        handleClose();
        setDay(1);
      })
      .catch((err) => {
        NotificationManager.error(
          "Erreur lors de l'actualisation de la date de facturation mensuelle",
          "Notification"
        );
        handleClose();
        setDay(1);
      });
  };
  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <div>
            <h5 className="mb-2">{props.name}</h5>

            {/* <input
              type={"text"}
              value={searchKeywords}
              placeholder={"chercher ..."}
              onChange={(e) => {
                setSearchKeywords(e.target.value);
                searchUser(e.target.value);
              }}
              className={"form-control"}
            /> */}
          </div>
        </Card.Header>
        <Table
          responsive
          hover={true}
          className="align-items-center table-flush"
        >
          <thead className="thead-light">
            <tr>
              {keys.map((key, i) => (
                <th key={i}> {translate_fr(key, "agents")} </th>
              ))}
              <th> </th>
            </tr>
          </thead>
          <tbody>{renderLigne()}</tbody>
        </Table>
        {/* <div className='d-flex justify-content-center mt-3'>
                    <Pagin totalPages={pages} type={type}  getSpecificPage={refreshUsers()} activatedItem={1} />
                </div> */}
      </Card>
      {type && type == "agent" ? (
        <>
          <ShowAgent
            showForm={showUserData}
            agent={user}
            closeForm={() => setShowUserData(false)}
          />
          <EditAgent
            showForm={showEditForm}
            agent={user}
            closeForm={() => setShowEditForm(false)}
          />

          <Modal
            as={Modal.Dialog}
            centered
            show={showDefault}
            onHide={handleClose}
          >
            <Modal.Header>
              <Modal.Title className="h4 text-main">
                Alert Suppression
              </Modal.Title>
              <Button
                variant="close"
                aria-label="Close"
                onClick={handleClose}
              />
            </Modal.Header>
            <Modal.Body>
              <p className={"text-dark"}>
                {" "}
                Voulez-vous vraiment supprimee ce agent ?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="main" className="ms-auto" onClick={handleClose}>
                Non
              </Button>
              <Button variant="danger" onClick={deleteAgent}>
                Oui
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : type && type == "courier" ? (
        <>
          <ShowCourier
            showForm={showUserData}
            courier={user}
            closeForm={() => setShowUserData(false)}
          />
          <EditCourier
            showForm={showEditForm}
            courier={user}
            closeForm={() => setShowEditForm(false)}
          />

          <Modal
            as={Modal.Dialog}
            centered
            show={showDefault}
            onHide={handleClose}
          >
            <Modal.Header>
              <Modal.Title className="h4 text-main">
                Alert Suppression
              </Modal.Title>
              <Button
                variant="close"
                aria-label="Close"
                onClick={handleClose}
              />
            </Modal.Header>
            <Modal.Body>
              <p className={"text-dark"}>
                {" "}
                Voulez-vous vraiment supprimer ce agent ?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="main" className="ms-auto" onClick={handleClose}>
                Non
              </Button>
              <Button variant="danger" onClick={deleteAgent}>
                Oui
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : type && type == "laboratoire" ? (
        <>
          <ShowLaboratoire
            showForm={showUserData}
            laboratoire={user}
            closeForm={() => setShowUserData(false)}
          />
          <ShowBalance
            showForm={showUserBalance}
            laboratoire={user}
            closeForm={() => setShowUserBalance(false)}
          />
        </>
      ) : (
        <>
          <ShowAssistant
            showForm={showUserData}
            assistant={user}
            closeForm={() => setShowUserData(false)}
          />
        </>
      )}

      <Modal
        as={Modal.Dialog}
        centered
        show={showDateFacturation}
        onHide={handleClose}
      >
        <Form onSubmit={affectDateToDenrist}>
          <Modal.Header>
            <Modal.Title className="h4 text-main">
              Choisir la date de facturation automatique pour Dr.{" "}
              {user.first_name}{" "}
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Nouvelle date de facturation</Form.Label>
              <Form.Control
                value={day}
                type={"number"}
                max={"25"}
                min={"1"}
                onChange={(e) => setDay(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" className="ms-auto" onClick={handleClose}>
              Annuler
            </Button>
            <Button variant="main" type={"submit"}>
              Enregistrer
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
