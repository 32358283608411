import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEnvelope,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";

import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
  Image,
  Spinner,
} from "@themesberg/react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ThemesbergLogo from "../../assets/easylab.svg";

import Preloader from "../Shared/Preloader/Preloader";

import { PagesRoutes } from "../../pages_routes";
import AuthService from "../services/AuthService";

import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import { generateToken } from "../notifications/firsebase";

function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState("");

  useEffect(() => {
    generateToken();
    checkAuth();
  }, []);
  const checkAuth = () => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("token") != undefined &&
      localStorage.getItem("token") != null &&
      localStorage.getItem("user")
    ) {
      if (JSON.parse(localStorage.getItem("user"))?.role === "courier") {
        navigate(`/${JSON.parse(localStorage.getItem("user"))?.role}/works`);
      } else {
        navigate(
          `/${JSON.parse(localStorage.getItem("user"))?.role}/dashboard`
        );
      }
    }
  };

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await AuthService.login({ email, password });
      if (response.status === 200) {
        // console.log("user data", response.data.data.user);
        if (response.data.data.user.is_email_verified) {
          localStorage.setItem("token", response.data.data.access_token);
          localStorage.setItem("user", JSON.stringify(response.data.data.user));

          if (response.data.data.user.role === "courier") {
            navigate("/" + response.data.data.user.role + "/works");
          } else {
            navigate("/" + response.data.data.user.role + "/dashboard");
          }
        } else {
          localStorage.setItem("temp_token", response.data.data.access_token);
          localStorage.setItem(
            "temp_user",
            JSON.stringify(response.data.data.user)
          );
          navigate("/email/verify");
        }
      }
    } catch (err) {
      setLoading(false);
      setError(err.response?.data?.message);
      setTimeout(() => setError(""), 5000);
    }
  };
  return (
    <main>
      <NotificationContainer />
      <Preloader />
      <section className="d-flex align-items-center my-4 mt-lg-3 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="p-2 mb-4 text-center">
                  <Image
                    src={ThemesbergLogo}
                    className="card-img-top w-50  border-white"
                  />
                </div>

                <div className="text-center text-md-center my-4 mt-md-0">
                  <h3 className="mb-0">Connectez chez EasyLab </h3>
                </div>
                {error && error != "" ? (
                  <div className="alert alert-danger"> {error}</div>
                ) : (
                  ""
                )}
                <Form className="mt-4" onSubmit={login}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Votre Email :</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        onChange={(e) => setEmail(e.target.value)}
                        autoFocus
                        required
                        value={email}
                        type="email"
                        placeholder="example@company.com"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Votre Mot de passe :</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          required
                          type="password"
                          placeholder="Password"
                        />
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label
                          htmlFor="defaultCheck5"
                          className="mb-0"
                        >
                          Garder ma session active
                        </FormCheck.Label>
                      </Form.Check>
                      <Card.Link
                        className="small text-end"
                        onClick={() =>
                          navigate(PagesRoutes.ResetPasssword.path)
                        }
                      >
                        Mot de passe oublie?
                      </Card.Link>
                    </div>
                  </Form.Group>
                  {loading ? (
                    <Button variant="main" disabled="true" className="w-100">
                      <Spinner animation="border" />
                    </Button>
                  ) : (
                    <Button variant="main" type="submit" className="w-100">
                      Connectez
                    </Button>
                  )}
                </Form>

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Vous n'avez pas un compte?
                    <Card.Link
                      as={Link}
                      to="/register"
                      className="ms-2 fw-bold"
                    >
                      {` Créer un compte `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
}

export default Login;
