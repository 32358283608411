import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Image,
  Button,
  Container,
} from "@themesberg/react-bootstrap";

import { Link, useNavigate } from "react-router-dom";

import { PagesRoutes } from "../../pages_routes";
import NotFoundImage from "../../assets/img/illustrations/404.svg";
import { currentUser } from "../../authentication/auth";

export default () => {
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
  }, []);
  return (
    <main>
      <section className="vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row>
            <Col
              xs={12}
              className="text-center d-flex align-items-center justify-content-center"
            >
              <div>
                <Card.Link>
                  <Image src={NotFoundImage} className="img-fluid w-75" />
                </Card.Link>
                <h1 className="text-primary mt-5">
                  Page non <span className="fw-bolder">trouvée</span>
                </h1>
                <p className="my-4">
                  Oups! Il sembre que vous ayez suivi un mauvais lien.
                  <br />
                  Si vous pensez que c'est une faute, merci de nous le faire
                  savoir,
                  {user?.role === "dentist" ? (
                    <span
                      onClick={() => navigate("/support")}
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      {" "}
                      reclamer .
                    </span>
                  ) : user?.role === "laboratory" ? (
                    <span
                      onClick={() => navigate("/support")}
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      {" "}
                      reclamer.
                    </span>
                  ) : (
                    <span
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={() => navigate("/support")}
                    >
                      {" "}
                      reclamer.
                    </span>
                  )}
                </p>

                {user?.role === "dentist" ? (
                  <Button
                    as={Link}
                    variant="primary"
                    className="animate-hover"
                    to="/dentist/dashboard"
                  >
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      className="animate-left-3 me-3 ms-2"
                    />
                    Revenir vers l'acceuil
                  </Button>
                ) : user?.role === "laboratory" ? (
                  <Button
                    as={Link}
                    variant="primary"
                    className="animate-hover"
                    to="/laboratory/dashboard"
                  >
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      className="animate-left-3 me-3 ms-2"
                    />
                    Revenir vers l'acceuil
                  </Button>
                ) : (
                  <Button
                    as={Link}
                    variant="primary"
                    className="animate-hover"
                    to="/"
                  >
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      className="animate-left-3 me-3 ms-2"
                    />
                    Revenir vers l'acceuil
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
