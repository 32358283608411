import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  Row,
  InputGroup,
  Spinner,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faAddressCard,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { axiosInstance } from "../../../../config/axios";
import { APIURL } from "../../../../configApi";
import { NotificationManager } from "react-notifications";
import AutoSuggest from "../InputAutoSuggest/AutoSuggest";
import CheckToggle from "../CheckToggle";
import { useNavigate } from "react-router-dom";
import RegisterService from "../../../services/RegisterService";
import LaboratoryService from "../../../services/LaboratoireService";

export default function (props) {
  const { showForm, agent, closeForm, refreshUsers } = props;
  // console.log("test agent==>", agent);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const [country_id, setCountry_id] = useState(1);
  const [state_id, setState_id] = useState();
  const [city_id, setCity_id] = useState();

  const [errors, setErrors] = useState({});

  const [first_name, setFirst_name] = useState(agent.first_name);
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [full_address, setFull_address] = useState("");
  const [principal_phone, setPrincipal_phone] = useState("");

  const [cin, setCin] = useState("");
  const [description, setDescription] = useState("");

  const getCountries = async () => {
    try {
      const response = await RegisterService.getCountries();
      setCountries(response.data.data);
      // console.log("countries", response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };
  const getStates = async (idCountry) => {
    try {
      const response = await RegisterService.getStates(idCountry);
      setStates(response.data.data);
      // console.log("states", response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };
  const getCities = async (idState) => {
    try {
      const response = await RegisterService.getCities(idState);
      setCities(response.data.data);
      // console.log("cities", response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };

  const resetForm = () => {
    setFirst_name("");
    setLast_name("");
    setDescription("");
    setFull_address("");
    setPassword("");
    setEmail("");
    setCin("");
    setPrincipal_phone("");
    setCity_id("");
    setState_id("");
    setErrors({});
  };

  const formValidation = () => {
    var localerrors = {};
    if (first_name === "") {
      localerrors = {
        ...localerrors,
        ...{ first_name: "Le Nom ne peut pas être  vide ... " },
      };
    }

    if (last_name === "") {
      localerrors = {
        ...localerrors,
        ...{ last_name: "Le Prénom ne peut pas être vide ... " },
      };
    }

    if (email === "") {
      localerrors = {
        ...localerrors,
        ...{ email: "L'Email ne peut pas être vide ... " },
      };
    }

    if (password === "") {
      localerrors = {
        ...localerrors,
        ...{ password: "Le Mot de passe ne peut pas être vide ... " },
      };
    }

    if (full_address === "") {
      localerrors = {
        ...localerrors,
        ...{ full_address: "L'Adresse  ne peut pas être vide ... " },
      };
    }

    if (cin === "") {
      localerrors = {
        ...localerrors,
        ...{ cin: "CIN ne peut pas être vide ... " },
      };
    } else if (cin.length != 8) {
      localerrors = {
        ...localerrors,
        ...{ cin: "CIN doit contient 8 chiffres" },
      };
    }

    if (principal_phone === "") {
      localerrors = {
        ...localerrors,
        ...{ principal_phone: "Le Téléphone ne peut pas être vide ... " },
      };
    } else if (principal_phone?.length != 8 && principal_phone?.length != 12) {
      localerrors = {
        ...localerrors,
        ...{
          principal_phone: "Tapper un numéro du téléphone valide ",
        },
      };
    }
    setErrors(localerrors);
    if (Object.keys(localerrors) > 0) return false;
    else return true;
  };
  const edit = async (e) => {
    //e.preventDefault();
    e.preventDefault();
    setErrors({});
    if (formValidation()) {
      try {
        let newAgent = {
          first_name,
          last_name,
          full_address,
          principal_phone,
          email,
          cin,
          description,
          country_id,
          state_id,
          city_id,
        };
        const response = await LaboratoryService.editAgent(agent.id, newAgent);
        if (response.status === 200) {
          NotificationManager.success(response.data.message, "Notification");
          refreshUsers();
          resetForm();
          closeForm();
          //navigate(PagesRoutes.LaboAgents.path);
        }
      } catch (err) {
        // console.log(err);
        NotificationManager.error(err?.response?.data?.message, "Notification");
      }
    }
  };

  useEffect(() => {
    // console.log("agent => ", agent);
    setFirst_name(agent.first_name);
    setLast_name(agent.last_name);
    setDescription(agent.description);
    setEmail(agent.email);
    setCin(agent.cin);
    setPrincipal_phone(agent.principal_phone);
    setFull_address(agent.full_address);
    setCity_id(agent.city_id);
    setState_id(agent.state_id);
    getCountries();
    getStates(1);
    getCities(agent.state_id);
  }, [agent]);

  return (
    <>
      {agent ? (
        <Modal
          as={Modal.Dialog}
          centered
          show={showForm}
          onHide={closeForm}
          size={"lg"}
        >
          <Modal.Header>
            <Modal.Title className="h4 text-main">
              Modifier les informations de l'agent
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={closeForm} />
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={edit}>
              <Row>
                <Col md={9} sm={12}>
                  <FormGroup className="mb-2">
                    <h4>
                      Email : <span> {email} </span>
                    </h4>
                    {/*<FormControl value={email} onChange={(e) => setEmail(e.target.value)} type={'email'}*/}
                    {/*             placeholder="..."></FormControl>*/}
                  </FormGroup>
                </Col>

                <Col md={3} sm={12}>
                  <div className="d-flex justify-content-evenly mb-2">
                    <FormLabel>Activer</FormLabel>
                    <CheckToggle checked={agent.is_active} id={agent.id} />
                  </div>
                </Col>
              </Row>

              <hr />

              <Row>
                <Col md={6} sm={12}>
                  <FormGroup className="mb-2">
                    <FormLabel>Nom</FormLabel>
                    <FormControl
                      value={first_name}
                      onChange={(e) => setFirst_name(e.target.value)}
                      type={"text"}
                      placeholder="..."
                    ></FormControl>
                  </FormGroup>
                </Col>
                <Col md={6} sm={12}>
                  <FormGroup className="mb-2">
                    <FormLabel>Prénom</FormLabel>
                    <FormControl
                      value={last_name}
                      onChange={(e) => setLast_name(e.target.value)}
                      type={"text"}
                      placeholder="..."
                    ></FormControl>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6} sm={12}>
                  <FormGroup className="mb-2">
                    <FormLabel>CIN</FormLabel>
                    <FormControl
                      value={cin}
                      onChange={(e) => setCin(e.target.value)}
                      type={"number"}
                      placeholder="..."
                    ></FormControl>
                  </FormGroup>
                </Col>
                <Col md={6} sm={12}>
                  <FormGroup className="mb-2">
                    <FormLabel>Téléphone</FormLabel>
                    <FormControl
                      value={principal_phone}
                      onChange={(e) => setPrincipal_phone(e.target.value)}
                      type={"tel"}
                      placeholder="..."
                    ></FormControl>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Pays :</Form.Label>
                    <Form.Select
                      disabled={true}
                      value={country_id}
                      onChange={(e) => {
                        setCountry_id(e.target.value);
                        getStates(e.target.value);
                      }}
                    >
                      <option defaultValue>Selectionner une pays </option>

                      {countries.map((country) => (
                        <option value={country.id}> {country.name}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Gouvernorat :</Form.Label>
                    <Form.Select
                      value={state_id}
                      onChange={(e) => {
                        setState_id(e.target.value);
                        getCities(e.target.value);
                      }}
                    >
                      <option defaultValue>
                        Selectionner une Gouvernorat{" "}
                      </option>

                      {states.map((state) => (
                        <option value={state.id}> {state.name}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Ville :</Form.Label>
                    <Form.Select
                      value={city_id}
                      onChange={(e) => setCity_id(e.target.value)}
                    >
                      <option defaultValue>Selectionner une ville </option>

                      {cities.map((city) => (
                        <option value={city.id}> {city?.name}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mb-2">
                    <FormLabel>Adresse de l'agent</FormLabel>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faAddressCard} />
                      </InputGroup.Text>
                      <FormControl
                        value={full_address}
                        onChange={(e) => setFull_address(e.target.value)}
                        type={"text"}
                        placeholder="..."
                      ></FormControl>
                    </InputGroup>
                    {errors?.full_address && (
                      <span
                        className="text-danger mt-3 fw-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {" "}
                        * {errors?.full_address}{" "}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup className="mb-2">
                <FormLabel>Déscription de l'agent</FormLabel>
                <FormControl
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  as={"textarea"}
                  placeholder="..."
                ></FormControl>
                {errors?.description && (
                  <span
                    className="text-danger mt-3 fw-bold"
                    style={{ fontSize: "14px" }}
                  >
                    {" "}
                    * {errors?.description}{" "}
                  </span>
                )}
              </FormGroup>

              <div className="my-4 d-flex justify-content-end">
                <Button variant={"danger"} onClick={closeForm} className="me-2">
                  {" "}
                  <FontAwesomeIcon icon={faWindowClose} /> Annuler{" "}
                </Button>
                <Button variant={"main"} type={"submit"}>
                  {" "}
                  <FontAwesomeIcon icon={faSave} /> Sauvegarder
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      ) : (
        <Spinner animation="border" className="m-4" />
      )}
    </>
  );
}
