import React, { useEffect, useState } from "react";
import Preloader from "../../Shared/Preloader/Preloader";
import Sidebar from "../../Shared/Sidebar";
import Navbar from "../../Shared/Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare, faUser } from "@fortawesome/free-solid-svg-icons";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { currentUser } from "../../../authentication/auth";
import { useNavigate } from "react-router-dom";
import { Form, Button, Row, Col, Spinner } from "@themesberg/react-bootstrap";
import { axiosInstance } from "../../../config/axios";
import { APIURL } from "../../../configApi";
import SupportService from "../../../Services/SupportService";
import FirebaseNotif from "../../Shared/FirebaseNotif/FirebaseNotif";

const Labosupport = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(null);
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [phone, setPhone] = useState();
  const [subject, setSubject] = useState();
  const [client_name, setClientName] = useState();
  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
      setEmail(currentUser()?.email);
      setPhone(currentUser()?.principal_phone);
      setClientName(currentUser()?.last_name + " " + currentUser()?.first_name);
    }
    //console.log(user)
  }, []);

  const sendMessageToSupport = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      client_name: client_name,
      email: email,
      phone: phone,
      subject: subject,
      description: message,
    };

    try {
      const response = await SupportService.sendMessage(data);
      NotificationManager.success(
        "L'equipe EasyLab vous remercie. Votre message a été bien envoyé ",
        "Notification"
      );
      setLoading(false);
      setClientName(user?.last_name + " " + user?.first_name);
      setPhone(user?.principal_phone);
      setSubject("");
      setMessage("");
    } catch (err) {
      NotificationManager.error(
        "Error lors de l'envoie de votre message. réessayer",
        "Notification"
      );
    }

    setLoading(false);
  };

  return (
    <>
      <Preloader />
      <Sidebar user={user} />
      {/* <FirebaseNotif /> */}
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar user={user} pageName={"Support"} icon={faUser} />

        <div>
          <NotificationContainer />
          <hr />

          <div>
            <h4> Mes Coordonnées </h4>
            <hr />

            <Form onSubmit={sendMessageToSupport}>
              <Form.Group className={"mb-2"}>
                <Form.Label> Email : {email} </Form.Label>
              </Form.Group>

              <Row>
                <Col>
                  <Form.Group className={"mb-2"}>
                    <Form.Label> Nom et prénom : </Form.Label>
                    <Form.Control
                      placeholder={"votre nom ..."}
                      value={client_name}
                      onChange={(e) => setClientName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className={"mb-2"}>
                    <Form.Label> Téléphone : </Form.Label>

                    <Form.Control
                      type={"phone"}
                      placeholder={"votre numéro de téléphone ..."}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <hr />

              <Form.Group className={"mb-2"}>
                <Form.Label> Sujet : </Form.Label>

                <Form.Control
                  placeholder={"entrer le sujet ..."}
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group className={"mb-2"}>
                <Form.Label> Votre message : </Form.Label>

                <Form.Control
                  as={"textarea"}
                  placeholder={"entrer votre message ..."}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group className={"mb-2 d-flex justify-content-center"}>
                {!loading ? (
                  <Button
                    variant={"success"}
                    type={"submit"}
                    className={"mt-2"}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faShare} /> Envoyer{" "}
                  </Button>
                ) : (
                  <Button
                    variant={"success"}
                    type={"submit"}
                    className={"mt-2"}
                    disabled
                  >
                    <Spinner animation={"border"} className={""} />
                  </Button>
                )}
              </Form.Group>
            </Form>
          </div>
        </div>
      </main>
    </>
  );
};

export default Labosupport;
