import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Image,
  Modal,
  Row,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faTrash,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { axiosInstance } from "../../../../config/axios";
import { APIURL } from "../../../../configApi";
import { NotificationManager } from "react-notifications";
import couriers from "../index";
import UserUnknownAvatar from "../../../../assets/img/user-unknown.jpg";
import { Navigate, useNavigate } from "react-router-dom";

const ShowBalance = (props) => {
  const navigate = useNavigate();
  const { showForm, laboratoire, closeForm } = props;

  const [total, setTotal] = useState(0);

  const getPaidValue = async (id) => {
    try {
      const response = await axiosInstance.get(
        `${APIURL}/global/statistics/paid_values/${laboratoire?.id}`
      );
      if (response.status === 200) {
        // console.log(response.data);
        setTotal(response.data.data.total_paid);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    getPaidValue(laboratoire.id);
  }, [laboratoire]);

  return (
    <>
      {laboratoire && (
        <Modal
          as={Modal.Dialog}
          centered
          show={showForm}
          onHide={closeForm}
          size={"lg"}
        >
          <Modal.Header>
            <Modal.Title className="h4 text-main text-center">
              {" "}
              Total restant à payer du laboratoire{" "}
              <span className={"text-center"}>
                " {laboratoire?.first_name + " " + laboratoire?.last_name} "
              </span>
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={closeForm} />
          </Modal.Header>
          <Modal.Body>
            <h5 className="text-center my-3">
              Total à payer : <span> {total} DT </span>{" "}
            </h5>

            <div className="d-flex justify-content-evenly">
              <Button onClick={() => navigate("/dentist/payments")}>
                {" "}
                Historique des paiements
              </Button>
              <Button
                onClick={() =>
                  navigate(`/dentist/invoices?laboId=${laboratoire.id}`)
                }
              >
                {" "}
                Historique des factures
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ShowBalance;
