import {
  faCheckCircle,
  faEdit,
  faExclamationTriangle,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  Button,
  Modal,
  Spinner,
  Form,
} from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { axiosInstance } from "../../../config/axios";
import { APIURL } from "../../../configApi";
import { NotificationManager } from "react-notifications";
import LaboratoryService from "../../services/LaboratoireService";
const GearBlock = (props) => {
  const {
    setDentistId,
    facturation_day,
    dentist_id,
    current_total_invoice,
    travaux,
    doctor,
    refreshUsers,
  } = props;
  const [showDateFacturation, setShowDateFacturation] = useState(false);
  const [day, setDay] = useState(facturation_day);
  const [popupModal, setPopupModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setPopupModal(false);
    setShowDateFacturation(false);
  };
  const facturer = () => {
    setLoading(true);
    axiosInstance
      .post(`${APIURL}/laboratory/invoices/${dentist_id}/create`)
      .then((data) => {
        NotificationManager.success("Facture créée avec succès", "Facuration");
        setLoading(false);

        setDentistId(dentist_id);
        handleClose();
        //window.location.reload();
      })
      .catch((err) => {
        NotificationManager.error("Erreur lors de la création", "Facuration");
        setLoading(false);
      });
  };

  const affectDateToDentist = async (e) => {
    e.preventDefault();

    try {
      const response = await LaboratoryService.updateFacturationDateDentist(
        dentist_id,
        { day: day }
      );
      if (response.status === 200) {
        NotificationManager.success(
          "Actualisation de la date de facturation mensuelle effectuè avec succès",
          "Notification"
        );
        refreshUsers();
        handleClose();
        setDay(day);
      }
    } catch (err) {
      NotificationManager.error(
        "Erreur lors de l'actualisation de la date de facturation mensuelle",
        "Notification"
      );
      handleClose();
      setDay(facturation_day);
    }
  };

  return (
    <>
      {" "}
      <Card className="my-2">
        <Card.Header>
          <h5 className="mb-2">{"Paramètres de facturation"}</h5>
        </Card.Header>
        <Card.Body>
          <div className="text-center mb-2">
            {/* <p>
              Cette facture sera facturé automatiquement le prochain{" "}
              <span className="badge bg-success p-2"> {facturation_day} </span>{" "}
              du mois .
            </p>
            <Button
              variant="primary"
              className="me-2 mb-2 btn-sm"
              onClick={() => setShowDateFacturation(true)}
            >
              <FontAwesomeIcon icon={faEdit} /> Modifier le jour
            </Button> */}
            {current_total_invoice > 0 ? (
              <Button
                variant="success"
                className="btn-sm"
                onClick={() => setPopupModal(true)}
              >
                <FontAwesomeIcon icon={faCheckCircle} /> Facturer manuellement
                aujourd'hui
              </Button>
            ) : (
              ""
            )}
          </div>
        </Card.Body>
      </Card>
      <Modal
        as={Modal.Dialog}
        centered
        show={popupModal}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faFile} /> Création manuelle de facture{" "}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className={"text-center"}>
            <h2>
              {" "}
              <FontAwesomeIcon icon={faExclamationTriangle} /> ATTENTION !{" "}
            </h2>
            Voulez-vous vraiment créer la facture du{" "}
            <strong className="text-success"> Docteur {doctor} </strong> avec
            les {travaux} travaux et avec un montant total de{" "}
            <strong className="text-success">
              {" "}
              {current_total_invoice} DT{" "}
            </strong>{" "}
            . <br />
            <br />
            Cette action est irréversible. Vous ne pouvez pas supprimer un
            travail d'une facture déja facturé !
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={"success"} onClick={handleClose}>
            Annuler
          </Button>
          {loading ? (
            <Button variant={"danger"}>
              <Spinner animation="border"></Spinner>
            </Button>
          ) : (
            <Button variant={"danger"} onClick={() => facturer()}>
              OK
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        as={Modal.Dialog}
        centered
        show={showDateFacturation}
        onHide={handleClose}
      >
        <Form onSubmit={affectDateToDentist}>
          <Modal.Header>
            <Modal.Title className="h4 text-main">
              Choisir la date de facturation automatique pour Dr. {doctor}
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Nouvelle date de facturation</Form.Label>
              <Form.Control
                value={day}
                type={"number"}
                max={"28"}
                min={"1"}
                onChange={(e) => setDay(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" className="ms-auto" onClick={handleClose}>
              Annuler
            </Button>
            <Button variant="main" type={"submit"}>
              Enregistrer
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default GearBlock;
