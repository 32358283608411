import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
} from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { axiosInstance } from "../../../../config/axios";
import { APIURL } from "../../../../configApi";
import { NotificationManager } from "react-notifications";

const AddSousGroupe = (props) => {
  const { refreshGroupeList, groupe_id } = props;
  const [sous_groupe_name, setSous_Groupe_Name] = useState("");
  const storeGroupre = (e) => {
    e.preventDefault();
    axiosInstance
      .post(`${APIURL}/laboratory/sub_groups`, {
        name: sous_groupe_name,
        group_id: groupe_id,
      })
      .then((res) => {
        // console.log(res.data);
        NotificationManager.success(
          "Sous Groupe ajouté avec succès",
          "Notification"
        );
        refreshGroupeList();
        setSous_Groupe_Name("");
      })
      .catch((err) => {
        // console.log(err);
        NotificationManager.error(
          "Erreur lors de l'ajout d'un Sous-Groupe",
          "Notification"
        );
      });
  };

  return (
    <Form className={"d-flex align-items-end"} onSubmit={storeGroupre}>
      <FormGroup className={"w-75 me-4"}>
        <FormLabel>Nom du sous-groupe</FormLabel>
        <FormControl
          type={"text"}
          placeholder={"Nom du sous-groupe ..."}
          value={sous_groupe_name}
          onChange={(e) => setSous_Groupe_Name(e.target.value)}
          maxLength={50}
        ></FormControl>
      </FormGroup>
      <FormGroup>
        <Button variant={"btn btn-primary"} type={"submit"}>
          {" "}
          Sauvegarder{" "}
        </Button>
      </FormGroup>
    </Form>
  );
};

export default AddSousGroupe;
