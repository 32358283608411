import React from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
const LandingFooter = () => {
  return (
    <div className={"footerLandingContainer"}>
      <p>
        <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp : +216 99 838 200 <br />
        ©EasyLAB 2022. All right reserved
      </p>
      <div className={"leftSideContainerFooter"}>
        <p>Suivez-nous sur</p>
        <div className={"iconsContainer"}>
          {/* <FontAwesomeIcon icon={faInstagram} className={'iconsFooter'}  /> */}
          <a href="https://www.facebook.com/easylab.tn" target="_blank">
            <FontAwesomeIcon icon={faFacebook} className={"iconsFooter"} />
          </a>
          {/* <FontAwesomeIcon icon={faTwitter} className={'iconsFooter'} />
                    <FontAwesomeIcon icon={faLinkedin} className={'iconsFooter'} /> */}
          <a
            href="https://www.youtube.com/channel/UCRG6E4y07ivYkWM50pTRnlw"
            target="_blank"
          >
            <FontAwesomeIcon icon={faYoutube} className={"iconsFooter"} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default LandingFooter;
