import { faWifi } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

const NetworkStatus = () => {
  const [status, setStatus] = useState(true);

  useEffect(() => {
    // console.log("test testt");
    function changeStatus() {
      setStatus(navigator.onLine); // true , false
    }
    window.addEventListener("online", changeStatus); // true
    window.addEventListener("offline", changeStatus); // false
    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };
  }, [status]);

  return status ? (
    <div className={status ? "mx-2 text-main" : "mx-2 text-main"}>
      {" "}
      <FontAwesomeIcon icon={faWifi} />{" "}
    </div>
  ) : (
    <div className={status ? "mx-2 text-main" : "mx-2 text-danger"}>
      {" "}
      <FontAwesomeIcon icon={faWifi} />{" "}
    </div>
  );
};

export default NetworkStatus;
