import React, { useState } from "react";
import "./style.css";
import secondaryAccountImage from "../../../assets/secondaryAccountImage.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "@themesberg/react-bootstrap";

const SecondaryAccount = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className={"SecondaryAccountContainer"}>
      <div className={"SecondarySmallContainer"}>
        <div className={"SecondaryDescription"}>
          <h1>Créer Gratuitement</h1>
          <h1>des comptes sécondaires</h1>

          <p>Votre équipe aussi !</p>
          <ul className={"unorderedListAccount"}>
            <li>
              Le compte EasyLab prothésiste vous permet de créer des comptes
              secondaires pour les agents et coursiers/livreurs.
            </li>
            <li>
              Le compte EasyLab dentiste vous permet de créer des comptes
              secondaires pour les assistant(e)s pour vous.
            </li>
            <p>
              Vous pouvez a tout moment attribuer et visualisez les tâches de
              chacun en un simple click !!
            </p>
          </ul>
          <div onClick={handleShow} className={"videoSecondaryCOntainer"}>
            <FontAwesomeIcon icon={faPlayCircle} color={"#006DEE"} size="2x" />
            <span>Regardez la vidéo</span>
          </div>
        </div>
        <img src={secondaryAccountImage} alt={"img"} />
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
      >
        <Modal.Body className={"row"}>
          <iframe
            width="300"
            height="300"
            src="https://www.youtube.com/embed/GvP2BJiSG3g"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SecondaryAccount;
