import {
  faEnvelope,
  faMoneyBill,
  faPlusCircle,
  faShare,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, Form } from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import WorkService from "../../services/WorkService";

import { NotificationManager } from "react-notifications";
import LaboratoryService from "../../services/LaboratoireService";
import { faWindows } from "@fortawesome/free-brands-svg-icons";
import { axiosInstance } from "../../../config/axios";
import { APIURL } from "../../../configApi";
import DentistService from "../../services/DentistService";

const WorkActionList = ({ work, couriers, agents }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  const [courier_id, setCourier_id] = useState();
  const [agent_id, setAgent_id] = useState();
  const navigate = useNavigate();

  const receivedLaboratory = async () => {
    try {
      const response = await WorkService.recieveLaboratory(work.id);
      // console.log("received_laboratory", response);
      NotificationManager.success(
        "Travail bien reçu au laboratoire",
        "Notification"
      );
      navigate("/laboratory/works");
    } catch (err) {
      // console.log("erreur", err);
      NotificationManager.error("Une erreur s'est produite", "Notification");
    }
  };

  const assigneToCourier = async (e) => {
    e.preventDefault();
    if (work.status == "at_laboratory") {
      try {
        const response = await WorkService.changeStatus(work.id, {
          step: "send_laboratory",
          courier_id: courier_id,
        });

        if (response.status === 200) {
          // console.log("received_laboratory", response);
          // NotificationManager.success(
          //   "Travail envoyée vers le dentiste",
          //   "Notification"
          // );
          navigate("/laboratory/works");
        }
      } catch (err) {
        NotificationManager.error("Une erreur s'est produite", "Notification");
      }
    } else {
      try {
        const response = await WorkService.changeStatus(work.id, {
          courier_id: courier_id,
        });

        if (response === 200) {
          // NotificationManager.success(
          //   "Travail affecté au coursier",
          //   "Notification"
          // );
          navigate("/laboratory/works");
        }
      } catch (err) {
        NotificationManager.error("Une erreur s'est produite", "Notification");
      }
      setShowDefault(false);
    }
  };

  const payeMe = async () => {
    try {
      const response = await LaboratoryService.paySelectedWork(work.id);
      // console.log("Done ...", response);
      if (response.status === 200) {
        // console.log("Done ...");
        NotificationManager.success(
          "Travail facturée avce succès",
          "Notification"
        );
        window.location.reload(false);
      }
    } catch (err) {
      NotificationManager.error(err?.response?.data?.message, "Notification");
    }
  };

  const recueAuLocalDentist = async (workId) => {
    // axiosInstance
    //   .put(`${APIURL}/global/works/${workId}/change_status`, {
    //     step: "received_dentist",
    //   })
    //   .then((res) => {
    //     NotificationManager.success("Tes", "Notification");
    //     //refreshWorks(currentStatus);
    //   })
    //   .catch((err) => {
    //    // console.log("erreur", err);
    //     NotificationManager.error(err?.response?.data?.message, "Notification");
    //   });

    try {
      const response = await DentistService.recievedDentist(workId, {
        step: "received_dentist",
      });

      if (response.status === 200) {
        NotificationManager.success(
          "Travail bien reçu au laboratoire",
          "Notification"
        );
      }
    } catch (err) {
      // console.log("erreur", err);
      NotificationManager.error(err?.response?.data?.message, "Notification");
    }
  };

  const sendToLabo = (workId) => {
    axiosInstance
      .put(`${APIURL}/global/works/${workId}/change_status`, {
        step: "send_dentist",
      })
      .then((res) => {
        // console.log("outgoing_dentist", res);
        NotificationManager.success(
          "Travail a recevoir au laboratoire",
          "Notification"
        );
        //refreshWorks(currentStatus);
      })
      .catch((err) => {
        // console.log("erreur", err);
        NotificationManager.error("Une erreur s'est produite", "Notification");
      });
  };
  return (
    <>
      {work.status === "outgoing_laboratory" &&
      work.dentist_type == "fake_dentist" ? (
        <Button
          variant="main"
          onClick={() => {
            // console.log("you click bien recue");
            recueAuLocalDentist(work.id);
            navigate("/laboratory/works");
          }}
        >
          <FontAwesomeIcon icon={faEnvelope} /> Bien reçu{" "}
        </Button>
      ) : (
        ""
      )}

      {work.status === "at_laboratory" ? (
        <div>
          <Button
            onClick={() => {
              setShowDefault(true);
            }}
          >
            <FontAwesomeIcon icon={faEnvelope} /> Pret pour l'envoi{" "}
          </Button>
        </div>
      ) : work.status === "outgoing_dentist" ? (
        <div>
          <Button onClick={() => receivedLaboratory()}>
            <FontAwesomeIcon icon={faEnvelope} /> Bien reçu au labo{" "}
          </Button>
        </div>
      ) : work.status === "at_dentist" ? (
        <div className="d-flex justify-content-evenly">
          {work.dentist_type == "fake_dentist" && (
            <Button
              variant="main"
              className="me-4"
              onClick={() => {
                sendToLabo(work.id);
                navigate("/laboratory/works");
              }}
            >
              <FontAwesomeIcon icon={faShare} /> Recevoir{" "}
            </Button>
          )}
          <Button onClick={() => payeMe()}>
            <FontAwesomeIcon icon={faEnvelope} /> Facturer{" "}
          </Button>
        </div>
      ) : work.status === "outgoing_laboratory" &&
        !["to_be_invoiced", "invoiced"].includes(work.status_invoiced) ? (
        <Button onClick={() => payeMe()}>
          <FontAwesomeIcon icon={faEnvelope} /> Facturer{" "}
        </Button>
      ) : (
        ""
      )}

      <Modal
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faTruck} /> Associer ce travail au coursier{" "}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {couriers && couriers.length > 0 ? (
            <Form onSubmit={assigneToCourier}>
              <div className={"mb-2"}>
                <label> Courier </label>
                <select
                  className="form-control"
                  value={courier_id}
                  onChange={(e) => setCourier_id(e.target.value)}
                >
                  <option value=""> == Choisir un Courier == </option>
                  {couriers.map((c) => (
                    <option value={c.id}>
                      {" "}
                      {c.last_name + " " + c.first_name}{" "}
                    </option>
                  ))}
                </select>
              </div>

              <div className={"d-flex justify-content-end mb-2"}>
                <Button type={"submit"} variant={"main"}>
                  <FontAwesomeIcon icon={faPlusCircle} /> Associer{" "}
                </Button>
              </div>
            </Form>
          ) : (
            <div>
              <p>
                {" "}
                Vous n'avez aucun coursier inscrit dans votre liste. Ajouter un
                nouveau{" "}
              </p>
              <div className={"d-flex justify-content-center"}>
                <Button
                  variant={"main"}
                  onClick={() => navigate("/laboratory/coursiers/add")}
                >
                  Ajouter un coursier
                </Button>
                <Form onSubmit={assigneToCourier}>
                  <Button type={"submit"} variant={"main"}>
                    <FontAwesomeIcon icon={faPlusCircle} /> Travail sortant{" "}
                  </Button>
                </Form>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WorkActionList;
