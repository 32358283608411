import React, { useEffect, useState } from "react";
import Preloader from "../../Shared/Preloader/Preloader";
import Sidebar from "../../Shared/Sidebar";
import Navbar from "../../Shared/Navbar/Navbar";
import { Button, Spinner } from "@themesberg/react-bootstrap";
import { PagesRoutes } from "../../../pages_routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCarCrash,
  faCashRegister,
  faFileInvoiceDollar,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { currentUser } from "../../../authentication/auth";
import { useNavigate } from "react-router-dom";
import Payments from "../../Shared/components/Table/Payments";
import { axiosInstance } from "../../../config/axios";
import { APIURL, getPages } from "../../../configApi";
import FirebaseNotif from "../../Shared/FirebaseNotif/FirebaseNotif";

const DentistPayments = () => {
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [dentists, setDentists] = useState([]);
  const [user, setUser] = useState();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  const navigate = useNavigate();
  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    getMyPayments();
  }, []);

  const getDentists = () => {};
  const getMyPayments = () => {
    setLoading(true);
    axiosInstance
      .get(`${APIURL}/dentist/payments?page=${page}`)
      .then((res) => {
        setPayments(res.data.data.list);
        setPages(res.data.data.total);
        setLoading(false);
        // console.log(payments);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <Preloader />
      <Sidebar user={user} />
      {/* <FirebaseNotif /> */}
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar
          user={user}
          pageName={`Liste des paiements`}
          icon={faFileInvoiceDollar}
        />
        <hr />
        <div>
          <div>
            {payments && payments.length > 0 ? (
              loading ? (
                <Spinner animation="border" margin="m-4" />
              ) : (
                <Payments
                  pages={getPages(pages)}
                  setPayments={(value) => setPayments(value)}
                  refreshPayments={() => getMyPayments()}
                  dentists={dentists}
                  payments={payments}
                  headColumns={[
                    "laboratory",
                    "price",
                    "remaining_price",
                    "created_at",
                    "is_total_used",
                  ]}
                  name={"Paiements"}
                  searched={false}
                />
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default DentistPayments;
