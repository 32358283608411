import {
  Row,
  Col,
  Button,
  Card,
  ButtonGroup,
  Modal,
  Image,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import Preloader from "../../Shared/Preloader/Preloader";
import Sidebar from "../../Shared/Sidebar";
import Navbar from "../../Shared/Navbar/Navbar";
import { currentUser } from "../../../authentication/auth";
import { useNavigate } from "react-router-dom";
import {
  faCashRegister,
  faCheckCircle,
  faCogs,
  faEdit,
  faEnvelope,
  faFileWord,
  faHome,
  faMoneyBillWave,
  faPlusCircle,
  faSmile,
  faTeeth,
  faTooth,
  faTruck,
  faTruckPickup,
  faUserFriends,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import DentistService from "../../services/DentistService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LaboratoryService from "../../services/LaboratoireService";
import WorkService from "../../services/WorkService";
import event from "../../../assets/GabesJanvier.jpg";
import { trafficShares } from "../../../data/charts";
import { CircleChartWidget } from "../../../components/Widgets";
import { translate_fr } from "../../../lang/fr";
import UserUnknownAvatar from "../../../assets/img/user-unknown.jpg";
import FirebaseNotif from "../../Shared/FirebaseNotif/FirebaseNotif";
import { axiosInstance } from "../../../config/axios";
import { APIURL } from "../../../configApi";
const DashLaboratory = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState();

  const [showInvit, setShowInvit] = useState(null);
  const handleClose = () => {
    setShowInvit(false);
  };
  const [user, setUser] = useState(null);
  const [agents, setAgents] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [works, setWorks] = useState([]);
  const [outgoing_works, setOutWorks] = useState([]);
  const [ingoing_works, setInWorks] = useState([]);
  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    getInvitations();
    //getWorks();
    calculateRowsByStatus("outgoing_dentist");
    calculateRowsByStatus("outgoing_laboratory");
    getAgents();
    //getWorksByStatus("outgoing_dentist");
  }, []);

  const [outgoing_dentist, setOutgoing_dentist] = useState(0);
  const [outgoing_labo, setOutgoing_labo] = useState(0);

  const calculateRowsByStatus = (e) => {
    axiosInstance
      .get(`${APIURL}/global/works?page=1&status=${e}`)
      .then((res) => {
        if (e == "outgoing_dentist") {
          setOutgoing_dentist(res.data.data.total);
        } else {
          setOutgoing_labo(res.data.data.total);
        }
      })
      .catch((err) => {
        // console.log(err);
        //console.log(err);
        //setWorks([]);
        //setLoading(true);
      });
  };

  const getInvitations = async () => {
    try {
      const response = await DentistService.Invitations();
      if (response.status === 200) {
        // NotificationManager.success(response.data.message, "Notification");
        setInvitations(
          [...response.data.data.list].filter(
            (invi) =>
              invi.source_invitation === "dentist" && invi.status != "accepted"
          )
        );
      }
    } catch (err) {
      // console.log(err);
      NotificationManager.error(err?.response?.data?.message);
    }
  };

  const getAgents = async () => {
    try {
      const response = await LaboratoryService.getAgents();
      if (response.status === 200) {
        //   NotificationManager.success(response.data.message, "Notification");
        setAgents(response.data.data);
      }
    } catch (err) {
      // console.log(err);
      NotificationManager.error(err?.response?.data?.message);
    }
  };

  const getWorks = async () => {
    try {
      const response = await WorkService.Works();
      if (response.status === 200) {
        //   NotificationManager.success(response.data.message, "Notification");
        setWorks(response.data.data.list);
      }
    } catch (err) {
      // console.log(err);
      NotificationManager.error(err?.response?.data?.message);
    }
  };

  const accepted_request = async (idreques) => {
    try {
      const response = await LaboratoryService.acceptInvitation(idreques);
      if (response.status === 200) {
        NotificationManager.success(response.data.message);
        getInvitations();
      }
    } catch (err) {
      NotificationManager.err(err?.response?.data?.message);
    }
  };

  const agentWorkCount = (idAgent) => {
    return [...works].filter((work) => work.accessible_id === idAgent).length;
  };

  const countWorkInProcess = () => {
    return [...works].filter(
      (work) =>
        work.status === "outgoing_dentist" && work.status === "at_laboratory"
    ).length;
  };

  return (
    <>
      <Preloader />
      <Sidebar user={user} />
      {/* <FirebaseNotif /> */}
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar user={user} pageName={"Menu principal"} icon={faHome} />
        <hr />
        <div>
          <NotificationContainer />
          <Row>
            <Col lg={"12"}>
              {invitations && invitations.length > 0 ? (
                <Col className="mb-4">
                  <Card className="p-3">
                    <h5 className="mt-2 text-center">
                      Bienvenu dans votre espace <strong>Easylab</strong> , ici
                      vous recevez les nouvelles invitations de vos dentistes{" "}
                      {/* <span className="badge bg-success p-2">
                        {" "}
                        {translate_fr(user?.role)}{" "}
                      </span>{" "} */}
                    </h5>
                    <hr />
                    <h5>
                      {" "}
                      <FontAwesomeIcon icon={faEnvelope} /> Nouvelles
                      Invitations{" "}
                    </h5>
                    {invitations.map((invi, index) => (
                      <div key={index} className="mt-2 ps-3">
                        {" "}
                        <>
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setUserData(invi.dentist);
                              setShowInvit(true);
                            }}
                          >
                            {" "}
                            {invi.dentist?.last_name +
                              " " +
                              invi.dentist?.first_name}
                          </span>
                          {"     "}

                          <Button
                            className="ms-5"
                            variant="success"
                            size="sm"
                            onClick={() => accepted_request(invi.id)}
                          >
                            {" "}
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                            /> Accepter{" "}
                          </Button>
                        </>
                      </div>
                    ))}
                  </Card>
                </Col>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Card className="mb-4  text-center p-3">
            <h5 className="mt-2">
              Bienvenu dans votre espace <strong>Easylab</strong> , compte{" "}
              <span className="badge bg-success p-2">
                {" "}
                {translate_fr(user?.role)}{" "}
              </span>{" "}
            </h5>
            <hr />

            <p>
              Pour bien démarrer régler tout d’abord les informations de votre
              profil et ajouter une image en cliquant sur la petite flèche en
              haut à droite, choisissez par la suite le menu{" "}
              <strong>‘MON PROFIL’</strong>
            </p>

            {/* <h4>Bienvenue dans votre espace EasyLab , compte </h4>
            <h4>Suivez les etapes pour bien demarer :</h4>
            <div className="mt-2">
              <Button
                onClick={() => navigate("/laboratory/profile")}
                className="btn-sm me-2 mb-2"
                variant="outline-success"
                type="button"
              >
                <FontAwesomeIcon icon={faEdit} />
                Modifier mes informations
              </Button>
              <Button
                onClick={() => navigate("/laboratory/settings")}
                className="btn-sm me-2 mb-2"
                variant="outline-success"
              >
                {" "}
                <FontAwesomeIcon icon={faCogs} /> Regler ma liste des prix
              </Button>
              <Button
                onClick={() => navigate("/laboratory/invitations")}
                className="btn-sm me-2 mb-2"
                variant="outline-success"
              >
                {" "}
                <FontAwesomeIcon icon={faPlusCircle} />
                Ajouter et inviter des dentistes
              </Button>
              <Button
                onClick={() => navigate("/laboratory/agents")}
                className="btn-sm me-2 mb-2"
                variant="outline-success"
              >
                {" "}
                <FontAwesomeIcon icon={faCogs} /> Regler mon equipe agents
              </Button>
              <Button
                onClick={() => navigate("/laboratory/dentistes")}
                className="btn-sm me-2 mb-2"
                variant="outline-success"
              >
                {" "}
                <FontAwesomeIcon icon={faCogs} /> Regler mon equipe coursies
              </Button>
            </div> */}
          </Card>

          <Row className=" ">
            <Col lg={"6"} md={"12"} className="mb-4">
              <Card className="p-4" style={{ minHeight: "100%" }}>
                <Card.Header>
                  <h5 className="text-main">
                    <FontAwesomeIcon icon={faMoneyBillWave} className="me-2" />
                    Personaliser votre liste des prix{" "}
                  </h5>
                </Card.Header>
                <Card.Body>
                  Nous savons que Votre Laboratoire est UNIQUE, pour cela nous
                  vous invitons à régler la liste des services que vous procurer
                  avec descriptions et prix. Les services sont organisées en
                  Grand Groupes, Sous groupes et Services. Vous pouvez régler
                  ceci facilement dans le menu <strong> ‘Paramètres’ </strong>{" "}
                  en cliquant sur la petite flèche en haut à droite
                </Card.Body>
              </Card>
            </Col>
            <Col lg={"6"} md={"12"} className="mb-4">
              <Card className="p-4" style={{ minHeight: "100%" }}>
                <Card.Header>
                  <h5 className="text-main">
                    <FontAwesomeIcon icon={faTooth} className="me-2" />
                    Editer la liste des dentistes{" "}
                  </h5>
                </Card.Header>
                <Card.Body>
                  Votre espace vous procure de collaborer avec les comptes
                  Dentistes pour cela invitez les à vous rejoindre dans votre
                  espace en cliquant sur le menu à gauche l’onglet{" "}
                  <strong> ‘Dentistes’ </strong>
                  <strong> ‘Liste’ </strong> . Dans la page{" "}
                  <strong> ‘Dentistes’ </strong> cliquer maintenant sur le
                  boutton en haut à droite <strong> ‘AJOUTER’ </strong> Ainsi
                  vous pouvez introduire l’adresse mail du Dentiste avec qui
                  vous collaborer et l'inviter à votre espace OU créer tout
                  simplement un profil Dentiste que vous allez gérez dans votre
                  espace.
                </Card.Body>
              </Card>
            </Col>
            <Col lg={"6"} md={"12"} className="mb-4">
              <Card className="p-4" style={{ minHeight: "100%" }}>
                <Card.Header>
                  <h5 className="text-main">
                    <FontAwesomeIcon icon={faUserFriends} className="me-2" />
                    Gerer votre equipe d'agents collaborateurs{" "}
                    <FontAwesomeIcon icon={faTruck} />
                  </h5>
                </Card.Header>
                <Card.Body>
                  Votre équipe aussi, Alors créer maintenant vos collaborateurs
                  <strong> ‘AGENT’ </strong> et / ou{" "}
                  <strong> ‘COURSIER’ </strong> dans le menu correspondant à
                  gauche , introduisez alors l'adresse mail correspondante pour
                  leurs donner un accès limité à votre espace où vous pouvez
                  activer/désactiver le compte à tout moment par un simple Clic
                </Card.Body>
              </Card>
            </Col>
            <Col lg={"6"} md={"12"} className="mb-4">
              <Card className="p-4" style={{ minHeight: "100%" }}>
                <Card.Header>
                  <h5 className="text-main">
                    <FontAwesomeIcon icon={faSmile} className="me-2" />
                    READY{" "}
                  </h5>
                </Card.Header>
                <Card.Body>
                  Votre espace est prêt maintenant alors commencer par recevoir
                  les travaux dans le menu <strong> ‘TRAVAUX’.</strong> Vous
                  pouvez aussi créer les travaux en cliquant sur le boutton
                  <strong> ‘AJOUTER’ </strong>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={"12"} md={"12"}>
              <Card className="p-4" style={{ minHeight: "100%" }}>
                <Card.Header>
                  <h5 className="text-main">
                    <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                    CONTACTEZ NOUS{" "}
                  </h5>
                </Card.Header>
                <Card.Body>
                  Vous avez une remarque, un commentaire à partager avec nous,
                  vous voulez une démonstration ou des informations
                  supplémentaires, n’hesitez pas à nous contacter dans le menu{" "}
                  <strong>' CONTACTEZ NOUS'</strong> en haut droite.
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <div className="alert alert-success text-center p-3  my-4">
            Easylab, Réveillez l’Artiste caché en vous, votre espace Easylab
            s’occupe du reste !
          </div>
        </div>
        <Row>
          <Col lg={"6"} className="mb-4">
            <Col className="mb-4">
              <CircleChartWidget title="Mon Equipe" data={trafficShares} />
            </Col>
          </Col>
          <Col lg={"6"} className="mb-4">
            <Card className="p-3">
              <h3> Les travaux arrivants </h3>
              <hr />
              <div className="d-flex align-items-center mb-3">
                <h5>
                  <FontAwesomeIcon icon={faFileWord} /> Travaux entrants :{" "}
                  {outgoing_dentist}
                </h5>
                <Button
                  onClick={() =>
                    navigate("/laboratory/works?status=outgoing_dentist")
                  }
                  variant="success"
                  size="sm"
                  className="ms-3 mb-2"
                >
                  {" "}
                  Voir{" "}
                </Button>
              </div>

              <div className="d-flex align-items-center">
                <h5>
                  <FontAwesomeIcon icon={faFileWord} /> Travaux sortants :{" "}
                  {outgoing_labo}
                </h5>
                <Button
                  onClick={() =>
                    navigate("/laboratory/works?status=outgoing_laboratory")
                  }
                  variant="success"
                  size="sm"
                  className="ms-3 mb-2"
                >
                  {" "}
                  Voir{" "}
                </Button>
              </div>
            </Card>
          </Col>
          <Col lg={"12"}>
            <h4>Actualités </h4>
            <hr />

            <div className="w-100 text-center">
              <img src={event} className="w-50 mx-auto my-0" />
            </div>
          </Col>
        </Row>
      </main>

      <Modal
        as={Modal.Dialog}
        centered
        show={showInvit}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faEnvelope} /> Détails de l'invitation
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            {userData?.photo_profile ? (
              <Image
                src={userData?.photo_profile}
                className="rounded-circle mx-auto my-2"
                style={{ width: "8rem", height: "8rem" }}
              />
            ) : (
              <Image
                src={UserUnknownAvatar}
                className="rounded-circle mx-auto my-2"
                style={{ width: "8rem", height: "8rem" }}
              />
            )}
          </div>
          <hr />
          <Row>
            <Col md={6} sm={12}>
              <h5>
                Nom :{" "}
                <span className={"text-success"}> {userData?.first_name} </span>
              </h5>
            </Col>
            <Col md={6} sm={12}>
              <h5>
                Prénom :{" "}
                <span className={"text-success"}> {userData?.last_name} </span>
              </h5>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={6} sm={12}>
              <h5>
                Email :{" "}
                <span className={"text-success"}> {userData?.email} </span>
              </h5>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={6} sm={12}>
              <h5>
                Ville :{" "}
                <span className={"text-success"}>
                  {" "}
                  {userData?.city?.name} ( {userData?.state?.name} ){" "}
                </span>
              </h5>
            </Col>
            <Col md={6} sm={12}>
              <h5>
                Téléphone :{" "}
                <span className={"text-success"}>
                  {" "}
                  {userData?.principal_phone}{" "}
                </span>
              </h5>
            </Col>
          </Row>
          <hr />

          <div>
            <h5>
              Adresse :{" "}
              <span className={"text-success"}>{userData?.full_address}</span>
            </h5>
          </div>
          <hr />
          <div>
            <h5>
              Déscription :{" "}
              <span className={"text-success"}>{userData?.description}</span>
            </h5>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DashLaboratory;
