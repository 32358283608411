import React from 'react';
import './style.css';
import Img1 from '../../../assets/svgs/assesibility.svg';
import Img2 from '../../../assets/svgs/exchange.svg';
import Img3 from '../../../assets/svgs/free.svg';
import Img4 from '../../../assets/svgs/upgrade.svg';

const DispoServiceLanding = () => {
    const data = [
        {
            id: 1,
            text: 'Accessible\n' +
                'partout',
            image:  Img1
        },
        {
            id: 2,
            text: 'Canal d’échange \n' +
                ' unifié ',
            image:  Img2
        },
        {
            id: 3,
            text: 'Sans frais de\n' +
                'Maintenance',
            image:  Img3
        },
        {
            id: 4,
            text: 'Amélioraion\n' +
                'continue',
            image:  Img4
        },
    ]
    return (
        <div className={'dispoServiceContainer'} >
            {data.map((item, index)=>{
                return(
                    <div className={'itemContainer'} key={index.toString()}>
                        <img src={item.image}/>
                        <pre>{item.text}</pre>
                    </div>
                )
            })}
        </div>
    );
};

export default DispoServiceLanding;
