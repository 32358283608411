import React from "react";
import "./style.css";
import OurServiceImage from "../../../assets/ourService.png";
const OurService = () => {
  return (
    <div className={"ourServiceContainer"} id="services">
      <img src={OurServiceImage} />
      <div className={"ourServiceDescription"}>
        <h1>Nos Services</h1>
        <p>
          La Plateforme <span>Easylab.tn</span> vous procure tout ce dont vous
          avez besoin pour organiser votre Laboratoire.. Commencez votre
          experience dès Maintenant!
        </p>
        <div className={"buttonServiceContainer"}>
          <button className={"seeMoreServiceButton"}>Voir Plus</button>
          <button className={"devisServiceButton"}>Devis Gratuit</button>
        </div>
      </div>
    </div>
  );
};

export default OurService;
