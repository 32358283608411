import React, {useState} from 'react';
import './style.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";

const QuetionsLanding = () => {
    const [question1, setQuestion1] = useState(false);
    const [question2, setQuestion2] = useState(false);

    return (
        <div  className={'questionLandingContainer'}>
            <p>Des Questions?</p>
            <div  className='quetionContainer'>
                {
                    !question1? (
                    <button onClick={()=>{setQuestion1(true)}} className={'questionDetails'}>
                    <p>
                    Ici Partie pour des Questions courantes...?
                    </p>
                    <FontAwesomeIcon icon={faPlusCircle} className={'iconQuetion'} />
                    </button>
                    ) : (
                        <button
                            onClick={()=>{setQuestion1(false)}}
                            className={'questionDetailsFocused'}>
                            <div className={'quetionFocusedCountent'}>
                        <span>
                        Ici Partie pour des Questions courantes...?
                    </span>
                                <FontAwesomeIcon icon={faPlusCircle} className={'iconQuetion'} />
                            </div>
                            <p>
                                Le lorem ipsum est, en imprimerie, une suite de mots
                                sans signification utilisée à titre provisoire pour
                                calibrer une mise en page, le texte définitif venant
                                remplacer le faux-texte dès qu'il est prêt ou que la mise en
                                page est achevée.
                            </p>

                        </button>
                    )
                }
                {
                    !question2? (
                    <button onClick={()=>{setQuestion2(true)}} className={'questionDetails'}>
                    <p>
                    Ici Partie pour des Questions courantes...?
                    </p>
                    <FontAwesomeIcon icon={faPlusCircle} className={'iconQuetion'} />
                    </button>
                    ) : (
                        <button
                            onClick={()=>{setQuestion2(false)}}
                            className={'questionDetailsFocused'}>
                            <div className={'quetionFocusedCountent'}>
                        <span>
                        Ici Partie pour des Questions courantes...?
                    </span>
                                <FontAwesomeIcon icon={faPlusCircle} className={'iconQuetion'} />
                            </div>
                            <p>
                                Le lorem ipsum est, en imprimerie, une suite de mots
                                sans signification utilisée à titre provisoire pour
                                calibrer une mise en page, le texte définitif venant
                                remplacer le faux-texte dès qu'il est prêt ou que la mise en
                                page est achevée.
                            </p>

                        </button>
                    )
                }



            </div >
        </div>
    );
};

export default QuetionsLanding;
