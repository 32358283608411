import { axiosInstance } from "../../config/axios"
import { APIURL } from "../../configApi";


const RegisterService = {}
RegisterService.getCountries = async  (  ) => {
     
                return await axiosInstance.get(`${APIURL}/global/settings/countries`)
        
         
   
}

RegisterService.getStates = async  ( idCountry ) => {
     
    return await axiosInstance.get(`${APIURL}/global/settings/countries/${idCountry}/states`)

}

RegisterService.getCities = async  ( idState ) => {
     
    return await axiosInstance.get(`${APIURL}/global/settings/states/${idState}/cities`)

}


export default RegisterService;