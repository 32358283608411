import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyDyGXrbCAaG4Y9J_eCXSlffr7ehmKs0FmM",
  authDomain: "easylab-10bd8.firebaseapp.com",
  projectId: "easylab-10bd8",
  storageBucket: "easylab-10bd8.appspot.com",
  messagingSenderId: "586619004809",
  appId: "1:586619004809:web:b57d035e3a809dff086358",
  measurementId: "G-6X8J11K343",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);
export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  // console.log(permission);
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapiKey:
        "BI3uNfQDy0agTRd0HlDznaooGgkrly4PL1Yslb_EoEZ2krDY5Rb9Pa0I7h3jayD7OfX0TrOelK32WWR7mQCM3gQ",
    });
    // console.log(token);
    localStorage.setItem("fcm_token", token);
  }
};
