import React, { useEffect, useRef, useState } from "react";
import Preloader from "../../Shared/Preloader/Preloader";
import Sidebar from "../../Shared/Sidebar";
import Navbar from "../../Shared/Navbar/Navbar";
import {
  Button,
  Col,
  Form,
  Image,
  Modal,
  Row,
  Spinner,
  InputGroup,
  FormControl,
  FormGroup,
  FormLabel,
} from "@themesberg/react-bootstrap";
import { PagesRoutes } from "../../../pages_routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faExclamationTriangle,
  faPlusCircle,
  faSave,
  faTrash,
  faUser,
  faUserEdit,
  faWindowClose,
  faAddressCard,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import { useNavigate } from "react-router-dom";
import { currentUser } from "../../../authentication/auth";
import Profile3 from "../../../assets/img/team/profile-picture-3.jpg";
import moment from "moment";
import { translate_fr } from "../../../lang/fr";
import UserService from "../../../Services/UserService";
import RegisterService from "../../services/RegisterService";
import LaboratoryService from "../../services/LaboratoireService";
import FirebaseNotif from "../../Shared/FirebaseNotif/FirebaseNotif";

const Laboprofile = () => {
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const hiddenFileInput = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [btnloading, setBtnLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [agents, setAgents] = useState([]);
  const [pages, setPages] = useState(0);
  const [show, setShow] = useState(false);
  const [showEditPass, setShowEditPass] = useState(false);

  const handleClose = () => {
    setShow(false);
    setShowEditPass(false);
  };
  const [preview, setPreview] = useState();
  const [city_id, setCity_id] = useState(user?.city_id);
  const [photo_profile, setPhoto_Profile] = useState("");
  const [email, setEmail] = useState(user?.email);
  const [first_name, setFirst_name] = useState(user?.first_name);
  const [last_name, setLast_name] = useState(user?.last_name);
  const [description, setDescription] = useState(user?.description);
  const [principal_phone, setPrincipal_phone] = useState(user?.principal_phone);
  const [full_address, setFull_Address] = useState(user?.full_address);
  const [country_id, setCountry_id] = useState(1);
  const [state_id, setState_id] = useState(user?.state_id);
  const [errors, setErrors] = useState({});

  // edit password { old_password , confirm_password , password }
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const updatePassword = async (e) => {
    e.preventDefault();
    if (oldPassword.length === 0) {
      setErrors({
        old_password: "Ancien mot de passe vide",
      });
      setBtnLoading(false);
      return false;
    }
    if (newPassword.length === 0) {
      setErrors({
        new_password: "Nouveau mot de passe vide",
      });
      setBtnLoading(false);
      return false;
    }
    if (newPassword !== confirmPassword) {
      // console.log("error password");
      setErrors({
        confirm_password: "Les deux mot de passe ne sont pas identiques",
      });
      setBtnLoading(false);
      return false;
    }
    try {
      const response = await UserService.updatePassword({
        old_password: oldPassword,
        password: newPassword,
        confirm_password: confirmPassword,
      });

      handleClose();
      if (response.status === 200) {
        NotificationManager.success(
          "Mot de passe mis a jour avec success",
          "Notification"
        );
      } else {
        NotificationManager.warning(response.data, "Notification");
      }
    } catch (err) {
      NotificationManager.error(err?.response?.data?.message, "Notification");
    }
    setBtnLoading(false);
    setErrors({});
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    //console.log(user)
    getProfileData();
    getCountries();
    getStates(1);
  }, []);

  const getCountries = async () => {
    try {
      const response = await RegisterService.getCountries();
      setCountries(response.data.data);
      // console.log("countries", response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };
  const getStates = async (idCountry) => {
    try {
      const response = await RegisterService.getStates(idCountry);
      setStates(response.data.data);
      // console.log("states", response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };
  const getCities = async (idState) => {
    try {
      const response = await RegisterService.getCities(idState);
      setCities(response.data.data);
      // console.log("cities", response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };
  const getProfileData = () => {
    if (user) {
      //setPhoto_Profile(user.photo_profile)
      setDescription(user.description);
      setEmail(user.email);
      setFirst_name(user.first_name);
      setLast_name(user.last_name);
      setPrincipal_phone(user.principal_phone);
      setFull_Address(user.full_address);
      setCity_id(user.city_id);
      setState_id(user.state_id);
      getCities(user?.state_id);
    }
  };
  const chooseFile = async (image) => {
    setPhoto_Profile(image);
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);
  };
  const updateProfilePicture = async () => {
    setLoading(true);
    const data = new FormData();
    data.append("picture", photo_profile);
    try {
      const response = await UserService.updatePicture(data);
      // console.log(response);
      setUser(response.data.data);
      localStorage.setItem("user", JSON.stringify(response.data.data));
      //setPhoto_Profile(response.data.data.photo_profile)
      NotificationManager.success(
        "Photo de profil mise à jour",
        "Notification"
      );
      setLoading(false);
      setPreview("");
    } catch (err) {
      NotificationManager.error(
        "Error lors de mise à jour de photo profile",
        "Notification"
      );
    }

    setLoading(false);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const editInfo = async (e) => {
    e.preventDefault();

    try {
      const response = await LaboratoryService.editProfile({
        first_name,
        last_name,
        principal_phone,
        description,
        city_id,
        state_id,
        country_id,
        full_address,
      });
      if (response.status === 200) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
        NotificationManager.success(response.data.message, "Notification");
        setUser(response.data.data);
        handleClose();
      }
    } catch (err) {
      NotificationManager.error(err?.response?.data?.message, "Notification");
    }
  };

  return (
    <>
      <Preloader />
      <Sidebar user={user} />
      {/* <FirebaseNotif /> */}
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar
          user={user}
          pageName={"Informations du profil"}
          icon={faUserEdit}
        />

        <div>
          <NotificationContainer />
          <hr />
          {user && (
            <div>
              <Row>
                <Col lg={"4"} md={"12"}>
                  <div className={"card text-center mb-2 px-5 pt-5"}>
                    <h3> {user?.last_name + " " + user?.first_name} </h3>

                    {preview ? (
                      <Image
                        src={preview}
                        className="rounded-circle mx-auto my-2"
                        style={{ width: "8rem", height: "8rem" }}
                      />
                    ) : (
                      <Image
                        src={user?.photo_profile}
                        className="rounded-circle mx-auto my-2"
                        style={{ width: "8rem", height: "8rem" }}
                      />
                    )}

                    <div className="p-3">
                      {!loading ? (
                        <>
                          <Button onClick={handleClick} variant={"success"}>
                            <FontAwesomeIcon icon={faEdit} /> Changer la photo{" "}
                          </Button>

                          {preview ? (
                            <Button
                              onClick={updateProfilePicture}
                              className={"ms-2"}
                            >
                              <FontAwesomeIcon icon={faSave} /> Valider
                            </Button>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <Spinner animation={"border"} className={"ms-2"} />
                      )}
                    </div>

                    <h4>
                      <address>
                        {user?.full_address},{user?.city?.name}
                        <br />
                      </address>
                    </h4>

                    {user?.offer_expiration_date ? (
                      <Button variant="outline-success" disabled>
                        {" "}
                        {user?.offer_expiration_date}{" "}
                      </Button>
                    ) : (
                      ""
                    )}

                    <input
                      type="file"
                      className="form-control invisible"
                      ref={hiddenFileInput}
                      onChange={(e) => chooseFile(e.target.files[0])}
                    />
                  </div>
                </Col>
                <Col lg={"8"} md={"12"}>
                  <div className={"card p-5 d-flex"}>
                    <h3 className={"mb-4 d-flex justify-content-between"}>
                      <div>
                        Email :{" "}
                        <span className={"text-muted text-main"}>
                          {" "}
                          {user?.email}{" "}
                        </span>
                      </div>
                      <span className={"badge bg-main p-2"}>
                        {" "}
                        {translate_fr(user?.role, "")}{" "}
                      </span>{" "}
                    </h3>
                    <h3 className={"mb-4"}>
                      {" "}
                      Téléphone :{" "}
                      <span className={"text-muted text-main"}>
                        {" "}
                        {user?.principal_phone}{" "}
                      </span>{" "}
                    </h3>

                    {user.ordinal_number_dentist ? (
                      <h3 className={"mb-4"}>
                        Numéro Ordinal :{" "}
                        <span className={"text-muted text-main"}>
                          {" "}
                          {user?.ordinal_number_dentist}{" "}
                        </span>
                      </h3>
                    ) : (
                      <h3 className={"mb-4"}>
                        Matricule Fiscale N :{" "}
                        <span className={"text-muted text-main"}>
                          {" "}
                          {user?.fiscal_number_laboratory}{" "}
                        </span>
                      </h3>
                    )}

                    <h3 className={"mb-4"}>
                      {" "}
                      Etat du compte :{" "}
                      <span className={"badge bg-main p-2"}>
                        {" "}
                        {user?.is_active ? "Active" : "Non Active"}{" "}
                      </span>{" "}
                    </h3>

                    <h3 className={"mb-4"}> Déscription : </h3>
                    <p>{user?.description}</p>

                    <div className={"d-flex justify-content-center"}>
                      <Button
                        variant={"success"}
                        onClick={() => {
                          setShow(true);
                          getProfileData();
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} /> Editer{" "}
                      </Button>
                      <Button
                        className="ms-4"
                        variant={"primary"}
                        onClick={() => {
                          setShowEditPass(true);
                          setOldPassword("");
                          setNewPassword("");
                          setConfirmPassword("");
                        }}
                      >
                        <FontAwesomeIcon icon={faKey} /> Mot de passe{" "}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>

        <Modal
          as={Modal.Dialog}
          centered
          show={show}
          onHide={handleClose}
          size={"lg"}
        >
          <Modal.Header>
            <Modal.Title className="h6">
              <FontAwesomeIcon icon={faEdit} /> Editer mes coordonnées
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <div className={""}>
              <Form onSubmit={editInfo}>
                <Row>
                  <Col>
                    <Form.Group className={"mb-2"}>
                      <Form.Label>Nom</Form.Label>
                      <Form.Control
                        value={first_name}
                        onChange={(e) => setFirst_name(e.target.value)}
                        placeholder={"tapper votre nom ..."}
                      ></Form.Control>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className={"mb-2"}>
                      <Form.Label>Prénom</Form.Label>
                      <Form.Control
                        value={last_name}
                        onChange={(e) => setLast_name(e.target.value)}
                        placeholder={"tapper votre prénom ..."}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className={"mb-2"}>
                  <Form.Label>Téléphone</Form.Label>
                  <Form.Control
                    value={principal_phone}
                    onChange={(e) => setPrincipal_phone(e.target.value)}
                    placeholder={"tapper votre téléphone ..."}
                  ></Form.Control>
                </Form.Group>

                <Row>
                  <Col>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Pays </Form.Label>
                      <Form.Select
                        disabled={true}
                        value={country_id}
                        onChange={(e) => {
                          setCountry_id(e.target.value);
                          getStates(e.target.value);
                        }}
                      >
                        <option defaultValue>Selectionner une pays </option>

                        {countries.map((country) => (
                          <option value={country.id}> {country.name}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Gouvernorat </Form.Label>
                      <Form.Select
                        value={state_id}
                        onChange={(e) => {
                          setState_id(e.target.value);
                          getCities(e.target.value);
                        }}
                      >
                        <option defaultValue>
                          Selectionner une Gouvernorat{" "}
                        </option>

                        {states.map((state) => (
                          <option value={state.id}> {state.name}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Ville </Form.Label>
                      <Form.Select
                        value={city_id}
                        onChange={(e) => setCity_id(e.target.value)}
                      >
                        <option defaultValue>Selectionner une ville </option>

                        {cities.map((city) => (
                          <option value={city.id}> {city?.name}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className="mb-2">
                      <FormLabel>Adresse professionnelle</FormLabel>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faAddressCard} />
                        </InputGroup.Text>
                        <FormControl
                          value={full_address}
                          onChange={(e) => setFull_Address(e.target.value)}
                          type={"text"}
                          placeholder="..."
                        ></FormControl>
                        {errors?.full_address && (
                          <span
                            className="text-danger mt-3 fw-bold"
                            style={{ fontSize: "14px" }}
                          >
                            {" "}
                            * {errors?.full_address}{" "}
                          </span>
                        )}
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <Form.Group className={"mb-2"}>
                  <Form.Label>Déscription</Form.Label>
                  <Form.Control
                    as={"textarea"}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder={"A propos de vous ..."}
                  ></Form.Control>
                </Form.Group>

                <Form.Group className={"d-flex justify-content-end"}>
                  <Button
                    type={"reset"}
                    onClick={handleClose}
                    variant={"danger"}
                    className={"me-2"}
                  >
                    <FontAwesomeIcon icon={faWindowClose} />
                    {" Annuler "}{" "}
                  </Button>
                  <Button type={"submit"} variant={"success"}>
                    <FontAwesomeIcon icon={faSave} />
                    {" Sauvegarder"}{" "}
                  </Button>
                </Form.Group>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          as={Modal.Dialog}
          centered
          show={showEditPass}
          onHide={handleClose}
          size={"md"}
        >
          <Modal.Header>
            <Modal.Title className="h6">
              <FontAwesomeIcon icon={faEdit} /> Modifier mot de passe
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <div className={""}>
              <Form onSubmit={updatePassword}>
                <Col>
                  <Form.Group className={"mb-2"}>
                    <Form.Label>Ancien mot de passe</Form.Label>
                    <Form.Control
                      type="password"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  {errors?.old_password && errors?.old_password.length > 0 ? (
                    <p className="text-danger"> {errors.old_password} </p>
                  ) : (
                    ""
                  )}
                </Col>

                <Col>
                  <Form.Group className={"mb-2"}>
                    <Form.Label>Nouveau mot de passe</Form.Label>
                    <Form.Control
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  {errors?.new_password && errors?.new_password.length > 0 ? (
                    <p className="text-danger"> {errors.new_password} </p>
                  ) : (
                    ""
                  )}
                  {errors?.confirm_password &&
                  errors?.confirm_password.length > 0 ? (
                    <p className="text-danger"> {errors.confirm_password} </p>
                  ) : (
                    ""
                  )}
                </Col>

                <Col>
                  <Form.Group className={"mb-2"}>
                    <Form.Label>Confirmation nouveau mot de passe</Form.Label>
                    <Form.Control
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder="Confimer "
                    ></Form.Control>
                  </Form.Group>
                </Col>

                <Form.Group className={"d-flex justify-content-end"}>
                  <Button
                    type={"reset"}
                    onClick={handleClose}
                    variant={"danger"}
                    className={"me-2"}
                  >
                    <FontAwesomeIcon icon={faWindowClose} />
                    {" Annuler "}{" "}
                  </Button>
                  {btnloading ? (
                    <Button type={"submit"} variant={"success"}>
                      <Spinner animation="border" />
                    </Button>
                  ) : (
                    <Button
                      type={"submit"}
                      onClick={() => setBtnLoading(true)}
                      variant={"success"}
                    >
                      <FontAwesomeIcon icon={faSave} />
                      {" Sauvegarder"}{" "}
                    </Button>
                  )}
                </Form.Group>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    </>
  );
};

export default Laboprofile;
