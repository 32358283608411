import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
  InputGroup,
  Spinner,
  Modal,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import axios from "axios";
import { config, currentUser } from "../../../../authentication/auth";
import { APIURL } from "../../../../configApi";
import Preloader from "../../../Shared/Preloader/Preloader";
import Sidebar from "../../../Shared/Sidebar";
import Navbar from "../../../Shared/Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import {
  faArrowLeft,
  faList,
  faPlusCircle,
  faSave,
  faTrash,
  faWindowClose,
  faAddressCard,
} from "@fortawesome/free-solid-svg-icons";
import { PagesRoutes } from "../../../../pages_routes";
import RegisterService from "../../../services/RegisterService";
import LaboratoryService from "../../../services/LaboratoireService";
import AddAgent from "../../Agents/Add";

const AddCourier = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const [warning, setWarning] = useState(0);
  const handleClose = () => {
    setShowWarning(false);
  };
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const [country_id, setCountry_id] = useState(1);
  const [state_id, setState_id] = useState();
  const [city_id, setCity_id] = useState();

  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [full_address, setFull_address] = useState("");
  const [principal_phone, setPrincipal_phone] = useState("");

  const [cin, setCin] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});

  const getCountries = async () => {
    try {
      const response = await RegisterService.getCountries();
      setCountries(response.data.data);
      // console.log("countries", response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };
  const getStates = async (idCountry) => {
    try {
      const response = await RegisterService.getStates(idCountry);
      setStates(response.data.data);
      // console.log("states", response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };
  const getCities = async (idState) => {
    try {
      const response = await RegisterService.getCities(idState);
      setCities(response.data.data);
      // console.log("cities", response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    getCountries();
    getStates(1);
  }, []);

  const resetForm = () => {
    setFirst_name("");
    setLast_name("");
    setDescription("");
    setFull_address("");
    setPassword("");
    setEmail("");
    setCin("");
    setPrincipal_phone("");
    setErrors({});
  };

  const formValidation = () => {
    var localerrors = {};
    if (first_name === "") {
      localerrors = {
        ...localerrors,
        ...{ first_name: "Le Nom ne peut pas être  vide ... " },
      };
    }

    if (last_name === "") {
      localerrors = {
        ...localerrors,
        ...{ last_name: "Le Prénom ne peut pas être vide ... " },
      };
    }

    if (email === "") {
      localerrors = {
        ...localerrors,
        ...{ email: "L'Email ne peut pas être vide ... " },
      };
    }

    if (password === "") {
      localerrors = {
        ...localerrors,
        ...{ password: "Le Mot de passe ne peut pas être vide ... " },
      };
    }
    let localwarning = 0;
    if (full_address === "") {
      localwarning++;
      //setWarning((prev) => prev + 1);
      localerrors = {
        ...localerrors,
        ...{ full_address: "L'Adresse  ne peut pas être vide ... " },
      };
    }

    if (cin === "") {
      localwarning++;
      //setWarning((prev) => prev + 1);
      localerrors = {
        ...localerrors,
        ...{ cin: "CIN ne peut pas être vide ... " },
      };
    } else if (cin.length != 8) {
      localwarning++;
      //setWarning((prev) => prev + 1);
      localerrors = {
        ...localerrors,
        ...{ cin: "CIN doit contient 8 chiffres" },
      };
    }

    if (principal_phone === "") {
      localerrors = {
        ...localerrors,
        ...{ principal_phone: "Le Téléphone ne peut pas être vide ... " },
      };
    } else if (principal_phone.length != 8 && principal_phone.length != 12) {
      localerrors = {
        ...localerrors,
        ...{
          principal_phone: "Tapper un numéro du téléphone valide ",
        },
      };
    }

    setErrors(localerrors);
    setWarning(localwarning);

    if (Object.keys(localerrors).length > 0 && localwarning == 0) return 0;
    else if (Object.keys(localerrors).length > localwarning && localwarning > 0)
      return 0;
    else if (
      Object.keys(localerrors).length == localwarning &&
      localwarning > 0
    )
      return 1;
    else return 2;
  };
  const verifyBeforeAdd = (e) => {
    e.preventDefault();
    setErrors({});
    setWarning(0);
    // console.log("form validation ==> ", formValidation());
    switch (formValidation()) {
      case 0:
        // console.log("validation error");
        break;
      case 1:
        // console.log("show  warning", city_id.length, state_id.length, user);
        cin.length < 8 && setCin("10000000");
        full_address.length == 0 && setFull_address(".");
        city_id == "Selectionner une ville" && setCity_id(user?.city_id);
        state_id == "Selectionner une Gouvernorat" &&
          setState_id(user?.state_id);
        setShowWarning(true);
        //return false;
        break;
      default:
        // console.log("Add agent");
        addCourier();
    }

    //return false;
  };

  const addCourier = async () => {
    setLoading(true);
    let newCourier = {
      first_name,
      last_name,
      full_address,
      principal_phone,
      email,
      password,
      cin,
      description,
      country_id,
      state_id,
      city_id,
    };

    try {
      const response = await LaboratoryService.addCourier(newCourier);
      if (response.status === 200) {
        setLoading(false);
        setShowWarning(false);
        NotificationManager.success("Coursier créé avec succès", "Coursier");
        navigate(PagesRoutes.LaboCouriers.path);
      }
    } catch (err) {
      setLoading(false);
      // console.log(err);
      NotificationManager.error(err?.response?.data?.message);
      setShowWarning(false);
    }
  };

  return (
    <>
      <Preloader />
      <Sidebar user={user} />
      <NotificationContainer />
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar user={user} pageName={"Ajouter Coursier"} icon={faPlusCircle} />
        <hr />
        <div>
          <div className="d-flex justify-content-end mb-2">
            <Button
              variant={"main"}
              onClick={() => navigate(PagesRoutes.LaboCouriers.path)}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Voir liste{" "}
            </Button>
          </div>

          <hr />
          <Form onSubmit={verifyBeforeAdd}>
            <Row>
              <Col md={6} sm={12}>
                <FormGroup className="mb-2">
                  <FormLabel>Nom</FormLabel>
                  <FormControl
                    value={first_name}
                    onChange={(e) => setFirst_name(e.target.value)}
                    type={"text"}
                    placeholder="..."
                  ></FormControl>
                  {errors?.first_name && (
                    <span
                      className="text-danger mt-3 fw-bold"
                      style={{ fontSize: "14px" }}
                    >
                      {" "}
                      * {errors?.first_name}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md={6} sm={12}>
                <FormGroup className="mb-2">
                  <FormLabel>Prénom</FormLabel>
                  <FormControl
                    value={last_name}
                    onChange={(e) => setLast_name(e.target.value)}
                    type={"text"}
                    placeholder="..."
                  ></FormControl>
                  {errors?.last_name && (
                    <span
                      className="text-danger mt-3 fw-bold"
                      style={{ fontSize: "14px" }}
                    >
                      {" "}
                      * {errors?.last_name}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={6} sm={12}>
                <FormGroup className="mb-2">
                  <FormLabel>Email</FormLabel>
                  <FormControl
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type={"email"}
                    placeholder="..."
                  ></FormControl>
                  {errors?.email && (
                    <span
                      className="text-danger mt-3 fw-bold"
                      style={{ fontSize: "14px" }}
                    >
                      {" "}
                      * {errors?.email}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md={6} sm={12}>
                <FormGroup className="mb-2">
                  <FormLabel>Mot de passe</FormLabel>
                  <FormControl
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={"password"}
                    placeholder="..."
                  ></FormControl>
                  {errors?.password && (
                    <span
                      className="text-danger mt-3 fw-bold"
                      style={{ fontSize: "14px" }}
                    >
                      {" "}
                      * {errors?.password}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={6} sm={12}>
                <FormGroup className="mb-2">
                  <FormLabel>CIN</FormLabel>
                  <FormControl
                    value={cin}
                    onChange={(e) => setCin(e.target.value)}
                    type={"number"}
                    placeholder="..."
                  ></FormControl>
                  {errors?.cin && (
                    <span
                      className="text-danger mt-3 fw-bold"
                      style={{ fontSize: "14px" }}
                    >
                      {" "}
                      {errors?.cin}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md={6} sm={12}>
                <FormGroup className="mb-2">
                  <FormLabel>Téléphone</FormLabel>
                  <FormControl
                    value={principal_phone}
                    onChange={(e) => setPrincipal_phone(e.target.value)}
                    type={"tel"}
                    placeholder="..."
                  ></FormControl>
                  {errors?.principal_phone && (
                    <span
                      className="text-danger mt-3 fw-bold"
                      style={{ fontSize: "14px" }}
                    >
                      {" "}
                      * {errors?.principal_phone}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group id="password" className="mb-4">
                  <Form.Label>Pays </Form.Label>
                  <Form.Select
                    disabled={true}
                    value={country_id}
                    onChange={(e) => {
                      setCountry_id(e.target.value);
                      getStates(e.target.value);
                    }}
                  >
                    <option defaultValue>Selectionner une pays </option>

                    {countries.map((country) => (
                      <option value={country.id}> {country.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Gouvernorat </Form.Label>
                  <Form.Select
                    value={state_id}
                    onChange={(e) => {
                      setState_id(e.target.value);
                      getCities(e.target.value);
                    }}
                  >
                    <option defaultValue>Selectionner une Gouvernorat </option>

                    {states.map((state) => (
                      <option value={state.id}> {state.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Ville </Form.Label>
                  <Form.Select
                    value={city_id}
                    onChange={(e) => setCity_id(e.target.value)}
                  >
                    <option defaultValue>Selectionner une ville </option>

                    {cities.map((city) => (
                      <option value={city.id}> {city?.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className="mb-2">
                  <FormLabel>Adresse du Coursier</FormLabel>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faAddressCard} />
                    </InputGroup.Text>
                    <FormControl
                      value={full_address}
                      onChange={(e) => setFull_address(e.target.value)}
                      type={"text"}
                      placeholder="..."
                    ></FormControl>
                  </InputGroup>
                  {errors?.full_address && (
                    <span
                      className="text-danger mt-3 fw-bold"
                      style={{ fontSize: "14px" }}
                    >
                      {" "}
                      {errors?.full_address}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <FormGroup className="mb-2">
              <FormLabel>Déscription</FormLabel>
              <FormControl
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                as={"textarea"}
                placeholder="..."
              ></FormControl>
              {errors?.description && (
                <span
                  className="text-danger mt-3 fw-bold"
                  style={{ fontSize: "14px" }}
                >
                  {" "}
                  * {errors?.description}{" "}
                </span>
              )}
            </FormGroup>

            <div className="my-4 d-flex justify-content-end">
              <Button variant={"danger"} className="me-2" onClick={resetForm}>
                <FontAwesomeIcon icon={faTrash} /> Effacer{" "}
              </Button>
              <Button variant={"primary"} type={"submit"}>
                {" "}
                <FontAwesomeIcon icon={faSave} /> Sauvegarder{" "}
              </Button>
            </div>
          </Form>
        </div>
      </main>
      <Modal as={Modal.Dialog} centered show={showWarning} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h4 text-main">Passer cette étape</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <p className={"text-dark"}>
            {" "}
            Vous avez passé quelques champs obligatoires, vraiment Voulez-vous
            vraiment, continuer ?
          </p>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <Button variant="danger">
              <Spinner animation="border" />
            </Button>
          ) : (
            <Button variant="danger" onClick={() => addCourier()}>
              Continuez et Sauvegarder
            </Button>
          )}

          <Button variant="main" className="ms-auto" onClick={handleClose}>
            Revenir
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddCourier;
