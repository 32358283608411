import React, { useEffect, useRef } from "react";
import "./style.css";
import Logo from "../../../assets/HeaderLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faTimes,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
const LandingHeader = () => {
  const navRef = useRef();
  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  const navigate = useNavigate();
  return (
    <header className={"landing-Header-container"}>
      <img src={Logo} alt={"logo"} className={"logoHeader"} />
      <div className={"sectionHeader"} ref={navRef}>
        <Link to="#apropos" onClick={showNavbar}>
          à propos
        </Link>

        <Link to="#services" onClick={showNavbar}>
          Services
        </Link>

        <Link to="#features" onClick={showNavbar}>
          Fonctionalités
        </Link>

        <Link to="#contacts" onClick={showNavbar}>
          Contacts
        </Link>
        <button
          className={"inscription-header-button"}
          onClick={() => navigate("/register")}
        >
          inscription
        </button>
        <button
          className={"LogIn-header-button"}
          onClick={() => navigate("/login")}
        >
          connexion
        </button>
        <button className={"responsive-button"} onClick={showNavbar}>
          <FontAwesomeIcon icon={faTimes} size={"2x"} />
        </button>
      </div>
      <button className={"responsive-button"} onClick={showNavbar}>
        <FontAwesomeIcon size={"2x"} icon={faBars} />
      </button>
    </header>
  );
};

export default LandingHeader;
