import { faArrowAltCircleUp, faCode } from "@fortawesome/free-solid-svg-icons";
import BgImage from "../../assets/img/illustrations/signin.svg";
import ThemesbergLogo from "../../assets/easylab.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Spinner,
  Row,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Preloader from "../Shared/Preloader/Preloader";
import { axiosInstance } from "../../config/axios";
import { APIURL } from "../../configApi";
import { axiosInstanceTemp } from "../../config/axios-temp";

const VerifyEmail = () => {
  const [email, setEmail] = useState(
    JSON.parse(localStorage.getItem("temp_user"))?.email
  );
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [step, setStep] = useState(1);
  useEffect(() => {}, []);

  const checkAuth = () => {
    if (
      localStorage.getItem("temp_token") &&
      localStorage.getItem("temp_token") != undefined &&
      localStorage.getItem("temp_token") != null &&
      localStorage.getItem("temp_user")
    ) {
      navigate(
        `/${JSON.parse(localStorage.getItem("temp_user"))?.role}/dashboard`
      );
    }
  };

  const resendCode = async (e) => {
    e.preventDefault();
    try {
      setSuccess("");
      setLoading(true);
      const response = await axiosInstanceTemp.put(
        `${APIURL}/global/auth/resend_code_email_verified`,
        {}
      );
      if (response.status === 200) {
        setError("");
        setSuccess(`Code renvoyé avec succès vers ${email}`);
        setLoading(false);
      }
    } catch (e) {
      setError(e?.response?.data?.message);
      setLoading(false);
    }
  };
  const checkCode = async (e) => {
    e.preventDefault();
    try {
      setSuccess("");
      setLoading(true);
      const response = await axiosInstanceTemp.post(
        `${APIURL}/global/auth/check_code_email_verified`,
        {
          code: code,
        }
      );
      if (response.status === 200) {
        setError("");
        localStorage.setItem("token", localStorage.getItem("temp_token"));
        localStorage.setItem("user", localStorage.getItem("temp_user"));
        navigate(
          `/${JSON.parse(localStorage.getItem("temp_user"))?.role}/dashboard`
        );
        setLoading(false);
      }
    } catch (e) {
      setError(e?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <>
      <main>
        <Preloader />
        <section className="d-flex align-items-center my-4 mt-lg-3 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image" style={{}}>
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="p-2 mb-4 text-center">
                    <Image
                      src={ThemesbergLogo}
                      className="card-img-top w-50  border-white"
                    />
                  </div>

                  <div className="text-center text-md-center my-4 mt-md-0">
                    <h3 className="mb-0">Vérifiez votre email </h3>
                  </div>
                  {success && success != "" ? (
                    <div className="alert alert-success"> {success}</div>
                  ) : (
                    ""
                  )}
                  {error && error != "" ? (
                    <div className="alert alert-danger"> {error}</div>
                  ) : (
                    ""
                  )}
                  <Form className="mt-4" onSubmit={checkCode}>
                    <Form.Group id="email" className="mb-4">
                      <Alert variant={"success"}>
                        Nous avons envoyé un email à <b>{email}</b> contenant un
                        code de vérification.
                        <br />
                        Veuillez entrer le code reçu.
                      </Alert>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCode} />
                        </InputGroup.Text>
                        <Form.Control
                          onChange={(e) => setCode(e.target.value)}
                          autoFocus
                          required
                          value={code}
                          type="text"
                          placeholder="Code"
                        />
                      </InputGroup>
                    </Form.Group>
                    <div className={"d-flex justify-content-end my-3"}>
                      <a onClick={resendCode}>
                        {" "}
                        <FontAwesomeIcon icon={faArrowAltCircleUp} /> renvoyer
                        le code{" "}
                      </a>
                    </div>
                    {loading ? (
                      <Button
                        variant="main"
                        disabled={"true"}
                        className="w-100"
                      >
                        <Spinner animation="border" />
                      </Button>
                    ) : (
                      <Button variant="main" type="submit" className="w-100">
                        Activez votre compte
                      </Button>
                    )}
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
};

export default VerifyEmail;
