import { Button, Row } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import Invitations from "../../Shared/components/Table/Invitations";
import Navbar from "../../Shared/Navbar/Navbar";
import Preloader from "../../Shared/Preloader/Preloader";
import Sidebar from "../../Shared/Sidebar";
import { useNavigate } from "react-router-dom";
import { config, currentUser } from "../../../authentication/auth";
import { APIURL, getPages } from "../../../configApi";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faPlus,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { PagesRoutes } from "../../../pages_routes";
import UsersTable from "../../Shared/components/Table/UsersTable";
import { NotificationContainer } from "react-notifications";
import CouriersTable from "../../Shared/components/Table/CouriersTable";
import LaboratoryService from "../../services/LaboratoireService";
import FirebaseNotif from "../../Shared/FirebaseNotif/FirebaseNotif";

export default function () {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [couriers, setCouriers] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    getCouriers(1);
  }, []);

  const getCouriers = async (page) => {
    try {
      const response = await LaboratoryService.couriersList(page);
      if (response.status === 200) {
        setCouriers(response.data.data.list);
        setPages(getPages(response.data.data.total));
      }
    } catch (err) {}
  };

  return (
    <>
      <Preloader />
      <Sidebar user={user} />
      {/* <FirebaseNotif /> */}
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar user={user} pageName={"Liste des coursiers"} icon={faList} />
        <hr />
        <div>
          <div className="d-flex justify-content-end mb-2">
            <Button
              variant={"main"}
              onClick={() => navigate(PagesRoutes.LaboCouriersAdd.path)}
            >
              {" "}
              <FontAwesomeIcon icon={faPlusCircle} /> Ajouter{" "}
            </Button>
          </div>

          <NotificationContainer />

          {couriers && couriers.length > 0 ? (
            <CouriersTable
              setCouriers={(value) => setCouriers(value)}
              refreshUsers={(value) => getCouriers(value)}
              name={"Liste des coursiers"}
              users={couriers}
              type={"courier"}
              headColumns={[
                "email",
                "first_name",
                "principal_phone",
                "last_name",
                "is_active",
              ]}
              pages={pages}
            />
          ) : (
            "Aucun coursier dans la liste. Cliquez sur le button ajouter  .."
          )}
        </div>
      </main>
    </>
  );
}
