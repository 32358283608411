import {
  faCheckCircle,
  faEnvelope,
  faMoneyBill,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, Form } from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import WorkService from "../../services/WorkService";

import { NotificationManager } from "react-notifications";
import DentistService from "../../services/DentistService";

const WorkActionList = ({ work }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  const [courier_id, setCourier_id] = useState();
  const [agent_id, setAgent_id] = useState();
  const navigate = useNavigate();

  const validateWork = async (id) => {
    try {
      const response = await DentistService.acceptWork(id);
      if (response.status === 200) {
        // NotificationManager.success(
        //   "Travail accepté avec succès",
        //   "Notification"
        // );
        window.location.reload(false);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const recueAuDentist = async (workId) => {
    try {
      const response = await DentistService.recievedDentist(workId, {
        step: "received_dentist",
      });

      if (response.status === 200) {
        // NotificationManager.success(
        //   "Travail bien reçu au dentist",
        //   "Notification"
        // );
        navigate("/dentist/works");
      }
    } catch (err) {
      // console.log("erreur", err);
      NotificationManager.error(err?.response?.data?.message, "Notification");
    }
  };

  const sendToLabo = async (workId) => {
    try {
      const response = await DentistService.sentToLaboratory(workId, {
        step: "send_dentist",
      });

      if (response.status === 200) {
        // NotificationManager.success(
        //   "Notification envoyée au laboratoire",
        //   "Notification"
        // );
        navigate("/dentist/works");
      }
    } catch (err) {
      // console.log("erreur", err);
      NotificationManager.error("Une erreur s'est produite", "Notification");
    }
  };

  const archiverWork = async (workId) => {
    try {
      const response = await DentistService.archiver(workId);

      if (response.status === 200) {
        // NotificationManager.success(response.data.status, "Notification");
        navigate("/dentist/works");
      }
    } catch (err) {
      // console.log("erreur", err);
      NotificationManager.error(err?.response?.data?.message, "Notification");
    }
  };

  return (
    <>
      <div className={"text-center"}>
        {work.status === "at_laboratory" ? (
          <div>
            {!work.is_accept_form_dentist && (
              <Button variant="danger" onClick={() => validateWork(work.id)}>
                <FontAwesomeIcon icon={faCheckCircle} /> Valider{" "}
              </Button>
            )}
          </div>
        ) : work.status === "outgoing_dentist" ? (
          <div>
            {!work.is_accept_form_dentist && (
              <Button variant="danger" onClick={() => validateWork(work.id)}>
                <FontAwesomeIcon icon={faCheckCircle} /> Valider{" "}
              </Button>
            )}
          </div>
        ) : work.status === "at_dentist" ? (
          <div>
            <Button onClick={() => sendToLabo(work.id)}>
              <FontAwesomeIcon icon={faEnvelope} /> Envoyer au labo{" "}
            </Button>
            <Button className="ms-2" onClick={() => archiverWork(work.id)}>
              <FontAwesomeIcon icon={faEnvelope} /> Archiver{" "}
            </Button>
          </div>
        ) : work.status === "outgoing_laboratory" ? (
          !work.is_accept_form_dentist ? (
            <Button variant="danger" onClick={() => validateWork(work.id)}>
              <FontAwesomeIcon icon={faCheckCircle} /> Valider{" "}
            </Button>
          ) : (
            <Button onClick={() => recueAuDentist(work.id)}>
              <FontAwesomeIcon icon={faEnvelope} /> Bien reçu{" "}
            </Button>
          )
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default WorkActionList;
