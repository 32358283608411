import React, { useEffect, useState } from "react";
import Preloader from "../../Shared/Preloader/Preloader";
import Sidebar from "../../Shared/Sidebar";
import Navbar from "../../Shared/Navbar/Navbar";
import { Alert, Button, Spinner, Tab, Tabs } from "@themesberg/react-bootstrap";

import {
  faCreditCard,
  faFileInvoiceDollar,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { NotificationContainer } from "react-notifications";

import InvoicesTable from "../../Shared/components/Table/Invoices";

import { currentUser } from "../../../authentication/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import InvoicesService from "../../services/InvoicesService";
import LaboratoryService from "../../services/LaboratoireService";
import Works from "../../Shared/components/Table/Works";
import WorksToBeInvoiced from "../../Shared/components/Table/WorkToBeInvoiced";
import WorksNotInvoiced from "../../Shared/components/Table/WorksNotInvoiced";
import DentistService from "../../services/DentistService";
import { getPages } from "../../../configApi";
import FirebaseNotif from "../../Shared/FirebaseNotif/FirebaseNotif";
const InvoicesDentist = () => {
  const [invoices, setInvoices] = useState([]);
  const [currentInvoices, setCurrentInvoices] = useState([]);
  const [laboratories, setLaboratories] = useState([]);

  const [worksToBeInvoiced, setWorksToBeInvoiced] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("");
  const [user, setUser] = useState();
  const [update, setUpdate] = useState(0);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const laboId = searchParams.get("laboId");
  const [laboratory_id, setLaboratoryId] = useState(laboId);
  const [pagesToBeInvoiced, setPagesToBeInvoiced] = useState(0);

  // const [update, setUpdate] = useState(1);
  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    // console.log("Labo ID", laboId);

    getInvoices();
  }, []);

  const getInvoices = () => {
    getAllInvoices();
    getAllLaboratory();
    getWorksToBeInvoiced();
  };

  const updateLaboratory = (id) => {
    // console.log("update dentist", id);
    setLoading(true);
    setLaboratoryId(id);
    getWorksToBeInvoiced(id);
    getAllInvoices(id);
  };

  const refreshData = () => {
    setLoading(true);
    getAllInvoices(laboratory_id);
    getWorksToBeInvoiced(laboratory_id);
  };

  const getAllInvoices = async (laboratory_id) => {
    try {
      const invoices = await InvoicesService.List();
      if (invoices.status == 200) {
        setInvoices(
          invoices.data.data.list.filter(
            (inv) => inv.laboratory_id == laboratory_id
          )
        );
      }
    } catch (err) {}
  };

  const getAllLaboratory = async () => {
    try {
      const laboratories = await DentistService.Laboratories();
      if (laboratories.status == 200) {
        // console.log("liste des laboratories==>", laboratories.data.data);

        setLaboratories(laboratories.data.data);
      }
    } catch (err) {}
  };

  const getWorksToBeInvoiced = async (id) => {
    // console.log("id laboratoire ====>", id);
    try {
      const works = await DentistService.worksToBeInvoicedByLaboratory(id, 1);
      if (works.status == 200) {
        // console.log("liste des works==>", works.data.data.list);

        // console.log(
        //"liste des works after filter 111 ==> ",
          //  works.data.data.list
          //);
          setPagesToBeInvoiced(works.data.data.total);
        setWorksToBeInvoiced(works.data.data.list);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <Preloader />
      <Sidebar user={user} />
      {/* <FirebaseNotif /> */}
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar
          user={user}
          pageName={"Liste des factures"}
          icon={faFileInvoiceDollar}
        />
        <hr />
        <div>
          <NotificationContainer />
          <div className="mb-3 col-4 offset-4">
            <label> Choisir un laboratoire </label>
            {/* <Select options={laboratories}></Select> */}

            <select
              className="form-control"
              onChange={(e) =>
                updateLaboratory(e.target.value, "to_be_invoiced")
              }
            >
              <option> Choisir un laboratoire </option>

              {laboratories && laboratories.length === 1 ? (
                <option value={laboratories[0].id} selected>
                  {" "}
                  {laboratories[0].last_name +
                    " " +
                    laboratories[0].first_name}{" "}
                </option>
              ) : (
                laboratories.map((d) => (
                  <option value={d.id}>
                    {" "}
                    {d.last_name + " " + d.first_name}{" "}
                  </option>
                ))
              )}
              {/* {laboratories.map((d) => (
                <option value={d.id}>
                  {" "}
                  {d.last_name + " " + d.first_name}{" "}
                </option>
              ))} */}
            </select>
          </div>
          <hr />
          {laboratory_id && laboratory_id != " " ? (
            <Tabs
              defaultActiveKey="to_be_invoiced"
              className="mb-3"
              onSelect={() => refreshData()}
            >
              <Tab eventKey="to_be_invoiced" title={`Facture en cours`}>
                {loading ? (
                  <div className="text-center">
                    <Spinner animation={"border"} className={"mt-4"} />
                  </div>
                ) : !loading && worksToBeInvoiced ? (
                  <WorksToBeInvoiced
                    dentist_id={laboratory_id}
                    works={worksToBeInvoiced}
                    updateWorks={() => getWorksToBeInvoiced(laboratory_id)}
                    dentists={laboratories}
                    currentStatus={"to_be_invoiced"}
                    pages={getPages(pagesToBeInvoiced)}
                    refreshWorks={(id) => getWorksToBeInvoiced(id)}
                    refreshUsers={() => getAllLaboratory()}
                  />
                ) : (
                  ""
                )}
              </Tab>

              <Tab eventKey="invoices" title={`Anciennes factures`}>
                {invoices && invoices.length > 0 ? (
                  <InvoicesTable
                    invoices={invoices}
                    dentist_id={laboratory_id}
                    name={"Anciennes factures"}
                    headColumns={[
                      "laboratory",
                      "created_at",
                      "total_price",
                      "remaining_price",
                      "path",
                      "status_paid",
                    ]}
                  />
                ) : (
                  "Aucune facture dans la liste."
                )}
              </Tab>
            </Tabs>
          ) : (
            <Alert variant="warning">
              {" "}
              Choisir un laboratoire tout d'abord pour voir la liste des
              factures ...{" "}
            </Alert>
          )}
        </div>
      </main>
    </>
  );
};

export default InvoicesDentist;
