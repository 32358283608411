import React, { useEffect, useState } from "react";
import Preloader from "../../Shared/Preloader/Preloader";
import Sidebar from "../../Shared/Sidebar";
import Navbar from "../../Shared/Navbar/Navbar";
import {
  Accordion,
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  Row,
  Spinner,
  useAccordionButton,
} from "@themesberg/react-bootstrap";
import { PagesRoutes } from "../../../pages_routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faExclamationTriangle,
  faFileMedical,
  faFolder,
  faList,
  faListOl,
  faMoneyBill,
  faPlusCircle,
  faSave,
  faTeeth,
  faTools,
  faTooth,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { currentUser } from "../../../authentication/auth";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../config/axios";
import { APIURL } from "../../../configApi";
import AddGroupe from "./Groupe";
import AddSousGroupe from "./SousGroupe";
import AddService from "./Service";
import EditService from "./Service/edit";
import EditGroupe from "./Groupe/edit";
import EditSousGroupe from "./SousGroupe/edit";
import moment from "moment";
// import "react-modern-calendar-datepicker/lib/DatePicker.css";
// import { Calendar } from "react-modern-calendar-datepicker";
import { fr } from "date-fns/locale";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import LaboratoryService from "../../services/LaboratoireService";
import FirebaseNotif from "../../Shared/FirebaseNotif/FirebaseNotif";
import Select from "react-select";

const SettingsLabo = () => {
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();

  const [groupesService, setGroupesService] = useState([]);
  const updateServiceList = (value) => {
    if (value != "0") {
      setGroupesService([...groupes.filter((g) => g.id == value)]);
    } else {
      setGroupesService(groupes);
    }
  };

  const [launch_date, setLaunchDate] = useState();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [groupes, setGroupes] = useState();

  const [editGroupe, setEditGroupe] = useState(false);
  const [showDeleteGroupe, setShowDeleteGroupe] = useState(false);
  const [showRemise, setShowRemise] = useState(false);

  const [dentists, setDentists] = useState([]);

  const [selectedGroupe, setSelectedGroupe] = useState({});
  const [deletedType, setDeletedType] = useState("Groupe");
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [price, setPrice] = useState();
  const [showPlanifier, setShowPlanifier] = useState(false);
  const [errors, setErrors] = useState({
    dentists_ids: "",
    discount: "",
    service_id: "",
  });
  const [showChangement, setShowChangement] = useState(false);
  const [GPrice, setGPrice] = useState("");
  const [service_id, setServiceId] = useState("");

  const [groupe_id, setGroupeId] = useState(0);
  const [discount, setDiscount] = useState("");
  const [dentists_ids, setDentistsIds] = useState([]);
  const userIdHandler = (value) => {
    let ids = [];
    value.map((sv) => {
      ids.push(sv.value);
    });
    setDentistsIds(ids);
  };

  const getServiceGPrice = (serviceId) => {
    groupes.map((g) => {
      g.sub_groups.map((sb) => {
        sb.services.map((s) => {
          if (s.id == serviceId) {
            setGPrice(s.price);
          }
        });
      });
    });
  };

  const getAllDentists = async () => {
    try {
      const res = await LaboratoryService.Dentists();
      if (res.status === 200) {
        // console.log("remise", res.data);
        setDentists(res.data.data);
        let loptions = [];
        res.data.data.map((d) => {
          loptions.push({
            value: d.id,
            label: d.last_name + " " + d.first_name,
          });
        });
        setOptions(loptions);
        // console.log("hello", loptions);
      }
    } catch (e) {
      // console.log(e);
    }
  };
  const handleClose = () => {
    setShowRemise(false);
    setShowPlanifier(false);
    setShowDeleteGroupe(false);
    setShowChangement(false);
  };
  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
      getAllDentists();
    }
    getGroupes();
    setGroupesService(groupes);
  }, []);

  const deleteGroupe = () => {
    axiosInstance
      .delete(`${APIURL}/laboratory/groups/${selectedGroupe.id}`)
      .then((res) => {
        // console.log(res.data);
        NotificationManager.success(
          "Groupe supprimé avec succès",
          "Notification"
        );
        getGroupes();
      })
      .catch((err) => {
        // console.log(err);
        NotificationManager.error(
          "Erreur lors de la suppression du groupe",
          "Notification"
        );
      });
    handleClose();
  };

  const deleteSousGroupe = (idSousGroupe) => {
    axiosInstance
      .delete(`${APIURL}/laboratory/sub_groups/${idSousGroupe}`)
      .then((res) => {
        // console.log(res.data);
        NotificationManager.success(
          "Sous-Groupe supprimé avec succès",
          "Notification"
        );
        getGroupes();
      })
      .catch((err) => {
        // console.log(err);
        NotificationManager.error(
          "Erreur lors de la suppression du Sous-groupe",
          "Notification"
        );
      });
  };

  const selectService = (service) => {
    setName(service.name);
    setDescription(service.description);
    setPrice(service.price);
  };
  const getGroupes = () => {
    axiosInstance
      .get(`${APIURL}/laboratory/groups`)
      .then((res) => {
        // console.log(res.data.data.list);
        setGroupes(res.data.data.list);
        setGroupesService(res.data.data.list);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const planifier = () => {
    // console.log("date ====>", typeof launch_date);

    // console.log("date ====>", format(launch_date, "PP"));
    //e.preventDefault();
    setShowPlanifier(false);
    setShowChangement(true);
  };

  let footer = <p></p>;
  if (launch_date) {
    footer = <p>A partir du : {format(launch_date, "dd-MM-yyyy")}.</p>;
  }

  const addRemise = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});
    if (dentists_ids.length === 0) {
      setErrors({ dentists_ids: "Choisir au moins un dentiste" });
      setLoading(false);
      return false;
    }
    if (service_id.length === 0) {
      setErrors({ service_id: "Choisir un service" });
      setLoading(false);
      return false;
    }
    if (discount.length === 0) {
      setErrors({ discount: "Choisir un nouveau prix" });
      setLoading(false);
      return false;
    }
    // console.log(parseInt(GPrice) - parseInt(discount));
    if (parseInt(GPrice) - parseInt(discount) <= 0) {
      setErrors({ discount: "Valeur erronée" });
      setLoading(false);
      return false;
    }
    setDiscount(parseInt(GPrice) - parseInt(discount));

    // console.log(discount, dentists_ids, service_id);
    try {
      const res = await LaboratoryService.addRemise({
        ...{
          dentists_ids,
          service_id,
        },
        ...{ discount: parseInt(GPrice) - parseInt(discount) },
      });
      if (res.status === 200) {
        NotificationManager.success(
          "Prix  ajouté avec succès",
          "Prix Spécifique"
        );
        setDentistsIds([]);
        setDiscount("");
        setServiceId("");
        getGroupes();

        handleClose();
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      NotificationManager.error(
        "Problème lors de l'ajout de prix",
        "Prix Spécifique"
      );
    }
  };
  const [discounts, setDiscounts] = useState([]);
  const discountsListByDentist = async (serviceId) => {
    //console.log(discount, dentists_ids, service_id);
    try {
      const res = await LaboratoryService.DiscountsListByDentist(serviceId);
      if (res.status === 200) {
        setDiscounts(res.data.data);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <>
      <Preloader />
      <Sidebar user={user} />
      {/* <FirebaseNotif /> */}
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar user={user} />
        <hr />
        <div>
          <NotificationContainer />
          <div className={"flex text-start text-md-end mb-3"}>
            <Button
              variant={"primary"}
              className={"me-2"}
              onClick={() => setShowRemise(true)}
            >
              {" "}
              Ajouter Prix spécifique{" "}
            </Button>
            <Button
              className={"btn btn-main"}
              onClick={() => setShowPlanifier(true)}
            >
              {" "}
              Planifier la mise à jour{" "}
            </Button>
          </div>

          <Accordion defaultActiveKey={["0"]} alwaysOpen>
            {groupes && groupes.length > 0
              ? groupes.map((g, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>
                      <span className={"fw-bold"}>
                        <FontAwesomeIcon icon={faFolder} className={"me-2"} />
                        {g?.name?.toUpperCase()}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Accordion defaultActiveKey={["0"]} alwaysOpen>
                        <Accordion.Item eventKey={["12"]}>
                          <Accordion.Header>
                            <FontAwesomeIcon icon={faEdit} className={"me-2"} />{" "}
                            Modifier le nom du groupe
                          </Accordion.Header>
                          <Accordion.Body>
                            <EditGroupe
                              refreshGroupeList={getGroupes}
                              setEditGroupe={setEditGroupe}
                              groupe={g}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        {g.sub_groups.length > 0 &&
                          g.sub_groups.map((sg, indexSG) => (
                            <Accordion.Item eventKey={indexSG}>
                              <Accordion.Header
                                onClick={() => setEditGroupe(false)}
                              >
                                <span className={"fw-bold"}>
                                  <FontAwesomeIcon
                                    icon={faListOl}
                                    className={"me-2"}
                                  />
                                  {sg?.name?.toUpperCase()}{" "}
                                </span>{" "}
                              </Accordion.Header>
                              <Accordion.Body>
                                <Accordion defaultActiveKey={["0"]} alwaysOpen>
                                  <Accordion.Item eventKey={["1"]}>
                                    <Accordion.Header>
                                      <FontAwesomeIcon
                                        icon={faEdit}
                                        className={"me-2"}
                                      />
                                      Modifier le nom du sous-groupe
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <EditSousGroupe
                                        refreshGroupeList={getGroupes}
                                        sousgroupe={sg}
                                      />
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  {sg.services.length > 0 &&
                                    sg.services.map((service, indexS) => (
                                      <Accordion.Item eventKey={indexS}>
                                        <Accordion.Header
                                          onClick={() => selectService(service)}
                                        >
                                          <span className={"fw-bold"}>
                                            <FontAwesomeIcon
                                              icon={faFileMedical}
                                              className={"me-2"}
                                            />
                                            {service?.name?.toUpperCase()}
                                            <i className="ms-4">
                                              {` ( ` + service?.price + ` dt )`}
                                            </i>{" "}
                                          </span>{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <EditService
                                            refreshGroupeList={getGroupes}
                                            service={service}
                                          />
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    ))}
                                  <Accordion.Item eventKey={["102"]}>
                                    <Accordion.Header>
                                      <FontAwesomeIcon
                                        icon={faPlusCircle}
                                        className={"me-2"}
                                      />{" "}
                                      <span className={"fw-bold"}>
                                        Ajouter un service
                                      </span>{" "}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <AddService
                                        refreshGroupeList={getGroupes}
                                        sub_group_id={sg.id}
                                      />
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                                <div
                                  className={"my-3 d-flex justify-content-end"}
                                >
                                  <Button
                                    variant={"danger"}
                                    onClick={() => {
                                      setDeletedType("SGroupe");
                                      setShowDeleteGroupe(true);
                                      setSelectedGroupe(sg);
                                    }}
                                  >
                                    {" "}
                                    Supprimer le sous groupe{" "}
                                  </Button>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          ))}

                        <Accordion.Item eventKey={["101"]}>
                          <Accordion.Header>
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              className={"me-2"}
                            />{" "}
                            <span className={"fw-bold"}>
                              Ajouter un Sous-groupe
                            </span>{" "}
                          </Accordion.Header>
                          <Accordion.Body>
                            <AddSousGroupe
                              refreshGroupeList={getGroupes}
                              groupe_id={g.id}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div className={"my-3 d-flex justify-content-end"}>
                        <Button
                          variant={"danger"}
                          onClick={() => {
                            setShowDeleteGroupe(true);
                            setDeletedType("Groupe");
                            setSelectedGroupe(g);
                          }}
                        >
                          {" "}
                          Supprimer le groupe{" "}
                        </Button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))
              : ""}
            <Accordion.Item eventKey={["100"]}>
              <Accordion.Header>
                <FontAwesomeIcon icon={faPlusCircle} className={"me-2"} />{" "}
                <span className={"fw-bold"}>Ajouter un groupe</span>{" "}
              </Accordion.Header>
              <Accordion.Body>
                <AddGroupe refreshGroupeList={getGroupes} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </main>
      <NotificationContainer />

      <Modal
        as={Modal.Dialog}
        centered
        show={showDeleteGroupe}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faTrash} />
            Supprimer le {deletedType != "Groupe" ? "Sous-" : " "}groupe{" "}
            <span className={"text-danger"}>{selectedGroupe.name}</span>
          </Modal.Title>

          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {deletedType != "Groupe" ? (
            <div className={"text-center"}>
              <h2>
                {" "}
                <FontAwesomeIcon icon={faExclamationTriangle} /> ATTENTION !{" "}
              </h2>
              Voulez-vous vraiment supprimer le sous groupe{" "}
              <span className={"text-danger"}>{selectedGroupe.name}</span> et
              tous les sous élémets !
            </div>
          ) : (
            <div className={"text-center"}>
              <h2>
                {" "}
                <FontAwesomeIcon icon={faExclamationTriangle} /> ATTENTION !{" "}
              </h2>
              Voulez-vous vraiment supprimer le groupe{" "}
              <span className={"text-danger"}>{selectedGroupe.name}</span> et
              tous les sous élémets !
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant={"success"} onClick={handleClose}>
            Annuler
          </Button>
          {deletedType == "Groupe" ? (
            <Button variant={"danger"} onClick={deleteGroupe}>
              OK
            </Button>
          ) : (
            <Button variant={"danger"} onClick={deleteSousGroupe}>
              OK
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        as={Modal.Dialog}
        centered
        show={showRemise}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">Ajouter un prix spécifique</Modal.Title>

          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormGroup>
              <FormLabel>Dentistes : </FormLabel>

              {options && options.length > 0 ? (
                <Select
                  name="dentist"
                  onChange={userIdHandler}
                  options={options}
                  isMulti
                />
              ) : (
                ""
              )}
              {errors && errors.dentists_ids ? (
                <p className="text-danger"> {errors.dentists_ids} </p>
              ) : (
                ""
              )}
            </FormGroup>
            <Row>
              <Col>
                <FormGroup>
                  <FormLabel>Liste des groupes : </FormLabel>
                  <select
                    className="form-control"
                    value={groupe_id}
                    onChange={(e) => {
                      setGroupeId(e.target.value);
                      updateServiceList(e.target.value);
                    }}
                  >
                    <option value={"0"}>
                      {" "}
                      <strong> == Tous les groupes == </strong>{" "}
                    </option>
                    {groupes && groupes.length > 0
                      ? groupes.map((g) => (
                          <option value={g.id}>{g.name}</option>
                        ))
                      : ""}
                  </select>
                </FormGroup>
              </Col>
              <Col>
                {" "}
                <FormGroup>
                  <FormLabel>Liste des services : </FormLabel>
                  <select
                    className="form-control"
                    value={service_id}
                    onChange={(e) => {
                      setServiceId(e.target.value);
                      getServiceGPrice(e.target.value);
                    }}
                  >
                    <option> Choisir une service </option>
                    {groupesService && groupesService.length > 0
                      ? groupesService.map((g) =>
                          g.sub_groups.map((sg) => (
                            <optgroup label={sg.name + ` ( ` + g.name + ` )`}>
                              {" "}
                              {sg.services.map((s) => (
                                <option value={s.id}>{s.name}</option>
                              ))}
                            </optgroup>
                          ))
                        )
                      : ""}
                  </select>
                </FormGroup>
                {errors && errors.service_id ? (
                  <p className="text-danger"> {errors.service_id} </p>
                ) : (
                  ""
                )}
              </Col>
            </Row>

            <Row>
              <Col>
                {" "}
                <FormGroup>
                  <FormLabel>Prix spécifique : </FormLabel>
                  <FormControl
                    min={0}
                    max={GPrice - 1}
                    type="number"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </FormGroup>
                {errors && errors.discount ? (
                  <p className="text-danger"> {errors.discount} </p>
                ) : (
                  ""
                )}
              </Col>
              <Col>
                <h6 className="mt-2">Prix général :</h6>
                <h6 className="mt-2">{GPrice} DT</h6>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={"danger"} onClick={handleClose}>
            Annuler
          </Button>
          {loading ? (
            <Button variant={"success"}>
              <Spinner animation="border" />
            </Button>
          ) : (
            <Button variant={"success"} onClick={addRemise}>
              Sauvegarder
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        as={Modal.Dialog}
        centered
        show={showPlanifier}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h5">
            Choisir la date à partir de la quelle cette nouvelle liste va
            prendre effet.
          </Modal.Title>

          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>

        <Modal.Body>
          <div className={"d-flex justify-content-center"}>
            <DayPicker
              mode="single"
              selected={launch_date}
              onSelect={setLaunchDate}
              footer={footer}
              locale={fr}
              fromDate={new Date(moment().add(1, "days"))}
            />
          </div>

          <div className="d-flex justify-content-center">
            {launch_date ? (
              <Button variant={"success"} onClick={() => planifier()}>
                <FontAwesomeIcon icon={faSave} className={"ms-2"} /> Valider
              </Button>
            ) : (
              <Button variant={"success"} disabled>
                <FontAwesomeIcon icon={faSave} className={"ms-2"} /> Valider
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        as={Modal.Dialog}
        centered
        show={showChangement}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h5">
            Effectuer les changements à partir du{" "}
            {moment(new Date(launch_date)).format("LL")}
          </Modal.Title>

          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>

        <Modal.Body>
          {/* <Accordion defaultActiveKey={["0"]} alwaysOpen>
            {groupes && groupes.length > 0
              ? groupes.map((g, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>
                      <span className={"fw-bold"}>
                        <FontAwesomeIcon icon={faFolder} className={"me-2"} />
                        {g?.name?.toUpperCase()}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Accordion defaultActiveKey={["0"]} alwaysOpen>
                        <Accordion.Item eventKey={["12"]}>
                          <Accordion.Header>
                            <FontAwesomeIcon icon={faEdit} className={"me-2"} />{" "}
                            Modifier le nom du groupe
                          </Accordion.Header>
                          <Accordion.Body>
                            <EditGroupe
                              refreshGroupeList={getGroupes}
                              setEditGroupe={setEditGroupe}
                              groupe={g}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        {g.sub_groups.length > 0 &&
                          g.sub_groups.map((sg, indexSG) => (
                            <Accordion.Item eventKey={indexSG}>
                              <Accordion.Header
                                onClick={() => setEditGroupe(false)}
                              >
                                <span className={"fw-bold"}>
                                  <FontAwesomeIcon
                                    icon={faListOl}
                                    className={"me-2"}
                                  />
                                  {sg?.name?.toUpperCase()}{" "}
                                </span>{" "}
                              </Accordion.Header>
                              <Accordion.Body>
                                <Accordion defaultActiveKey={["0"]} alwaysOpen>
                                  <Accordion.Item eventKey={["1"]}>
                                    <Accordion.Header>
                                      <FontAwesomeIcon
                                        icon={faEdit}
                                        className={"me-2"}
                                      />
                                      Modifier le nom du groupe
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <EditSousGroupe
                                        refreshGroupeList={getGroupes}
                                        sousgroupe={sg}
                                      />
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  {sg.services.length > 0 &&
                                    sg.services.map((service, indexS) => (
                                      <Accordion.Item eventKey={indexS}>
                                        <Accordion.Header
                                          onClick={() => selectService(service)}
                                        >
                                          <span className={"fw-bold"}>
                                            <FontAwesomeIcon
                                              icon={faFileMedical}
                                              className={"me-2"}
                                            />
                                            {service?.name?.toUpperCase()}
                                            <i className="ms-4">
                                              {` ( ` + service?.price + ` dt )`}
                                            </i>{" "}
                                          </span>{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <EditService
                                            refreshGroupeList={getGroupes}
                                            service={service}
                                          />
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    ))}
                                  <Accordion.Item eventKey={["102"]}>
                                    <Accordion.Header>
                                      <FontAwesomeIcon
                                        icon={faPlusCircle}
                                        className={"me-2"}
                                      />{" "}
                                      <span className={"fw-bold"}>
                                        Ajouter un service
                                      </span>{" "}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <AddService
                                        refreshGroupeList={getGroupes}
                                        sub_group_id={sg.id}
                                      />
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                                <div
                                  className={"my-3 d-flex justify-content-end"}
                                >
                                  <Button
                                    variant={"danger"}
                                    onClick={() => {
                                      setDeletedType("SGroupe");
                                      setShowDeleteGroupe(true);
                                      setSelectedGroupe(sg);
                                    }}
                                  >
                                    {" "}
                                    Supprimer le sous groupe{" "}
                                  </Button>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          ))}

                        <Accordion.Item eventKey={["101"]}>
                          <Accordion.Header>
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              className={"me-2"}
                            />{" "}
                            <span className={"fw-bold"}>
                              Ajouter un Sous-groupe
                            </span>{" "}
                          </Accordion.Header>
                          <Accordion.Body>
                            <AddSousGroupe
                              refreshGroupeList={getGroupes}
                              groupe_id={g.id}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div className={"my-3 d-flex justify-content-end"}>
                        <Button
                          variant={"danger"}
                          onClick={() => {
                            setShowDeleteGroupe(true);
                            setDeletedType("Groupe");
                            setSelectedGroupe(g);
                          }}
                        >
                          {" "}
                          Supprimer le groupe{" "}
                        </Button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))
              : ""}
            <Accordion.Item eventKey={["100"]}>
              <Accordion.Header>
                <FontAwesomeIcon icon={faPlusCircle} className={"me-2"} />{" "}
                <span className={"fw-bold"}>Ajouter un groupe</span>{" "}
              </Accordion.Header>
              <Accordion.Body>
                <AddGroupe refreshGroupeList={getGroupes} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion> */}

          <p className="text-success text-center">
            {" "}
            <FontAwesomeIcon icon={faTools} />{" "}
            <FontAwesomeIcon icon={faTooth} /> Under constrcution comming soon
            ...{" "}
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center text-center">
          Vous pouvez changer directement dans la liste actuelle.
          <br /> le changement sera immédiat.
          {/* <div className="d-flex justify-content-center">
            <Button variant={"danger"} className={"me-2"}>
              <FontAwesomeIcon icon={faTrash} /> Reinitialiser
            </Button>
            <Button variant={"success"}>
              <FontAwesomeIcon icon={faSave} /> Confirmer
            </Button>
          </div> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SettingsLabo;
