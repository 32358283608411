import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { currentUser } from "../../../authentication/auth";
import { axiosInstance } from "../../../config/axios";
import { APIURL, getPages } from "../../../configApi";
import Preloader from "../../Shared/Preloader/Preloader";
import Sidebar from "../../Shared/Sidebar";
import Navbar from "../../Shared/Navbar/Navbar";
import Payments from "../../Shared/components/Table/Payments";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faEnvelope,
  faMoneyBill,
  faPlusCircle,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import PaymentService from "../../../Services/PaymentService";
import LaboratoryService from "../../services/LaboratoireService";
import GearPaymentBlock from "./GearBlock";
import FirebaseNotif from "../../Shared/FirebaseNotif/FirebaseNotif";

const LaboPayments = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [price, setPrice] = useState("");
  const [dentist_id, setDentist_id] = useState("");
  const [showDefault, setShowDefault] = useState(false);
  const [errors, setErrors] = useState({});
  const handleClose = () => {
    setShowDefault(false);
  };
  const [dentists, setDentists] = useState([]);
  const [paymentsListe, setPaymentsListe] = useState([]);
  const [user, setUser] = useState();
  const [pages, setPages] = useState(1);
  const [code_cheque, setCodeCheque] = useState("");
  const [paymentMode, setPaymentMode] = useState("");

  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    getMyPayments();
    getAllDentists();
  }, []);

  const formValidation = () => {
    var localerrors = {};
    if (dentist_id === "") {
      localerrors = {
        ...localerrors,
        ...{ dentist_id: "Choissisez un dentiste ... " },
      };
    }

    if (paymentMode === "") {
      localerrors = {
        ...localerrors,
        ...{ paymentMode: "Choissisez le mode paiement ... " },
      };
    }

    if (price === "") {
      localerrors = {
        ...localerrors,
        ...{ price: "Le montant ne peut pas être vide ... " },
      };
    }
    setErrors(localerrors);
    if (Object.keys(localerrors).length > 0) return false;
    else return true;
  };

  const getMyPayments = async () => {
    try {
      const response = await PaymentService.liste();
      if (response.status === 200) {
        setPages(response.data.data.total);
        setPaymentsListe(response.data.data.list);
      }
    } catch (err) {
     // console.log(err);
    }
  };

  const getAllDentists = () => {
    axiosInstance
      .get(`${APIURL}/laboratory/dentists/all`)
      .then((res) => {
        setDentists(res.data.data);
       // console.log(dentists);
      })
      .catch((err) => {
       // console.log(err);
      });
  };

  const addNewPayment = async (e) => {
    e.preventDefault();
    setLoading(true);
 
    if (formValidation()) {
      try {
        const response = await LaboratoryService.addPayment({
          payment_type: paymentMode,
          description: code_cheque,
          dentist_id: dentist_id,
          price: price,
        });
        if (response.status === 200) {
          setLoading(false);
          setShowDefault(false);
          setPrice("");
          setDentist_id("");
          setPaymentMode("");
          setCodeCheque("");
          getMyPayments();
          NotificationManager.success(
            "Paiement ajouté avec succès",
            "Notification"
          );
        }
      } catch (err) {
        setLoading(false);
        NotificationManager.error(err.response.data.message, "Notification");
      }
    } else {
      setLoading(false);
    }
  };
  return (
    <>
      <Preloader />
      <Sidebar user={user} />
      {/* <FirebaseNotif /> */}
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar
          user={user}
          pageName={"Liste des paiements"}
          icon={faCreditCard}
        />
        <hr />
        <div className={"d-flex align-items-center justify-content-end"}>
          <Button variant={"main"} onClick={() => setShowDefault(true)}>
            {" "}
            <FontAwesomeIcon icon={faPlusCircle} /> Ajouter paiement{" "}
          </Button>
        </div>

        <div>
          <div className={"mt-2"}>
            {paymentsListe && paymentsListe.length > 0 ? (
              <>
                <Payments
                  pages={getPages(pages)}
                  setPayments={(value) => setPaymentsListe(value)}
                  refreshPayments={() => getMyPayments()}
                  dentists={dentists}
                  payments={paymentsListe}
                  headColumns={[
                    "dentist",
                    "price",
                    "remaining_price",
                    "created_at",
                    "is_total_used",
                  ]}
                  name={"Paiements"}
                  searched={false}
                />
                <GearPaymentBlock />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </main>

      <Modal
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faMoneyBill} /> Ajouter une nouvelle tranche
            de paiement
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={addNewPayment}>
            <Row>
              <Col>
                <div className={"mb-2"}>
                  <label> Dentiste </label>
                  <select
                    className="form-control"
                    value={dentist_id}
                    onChange={(e) => setDentist_id(e.target.value)}
                  >
                    <option value=""> == Choisir le dentiste == </option>
                    {dentists.map((d) => (
                      <option value={d.id}>
                        {" "}
                        {d.last_name + " " + d.first_name}{" "}
                      </option>
                    ))}
                  </select>

                  {errors?.dentist_id && (
                    <span
                      className="text-danger mt-3 fw-bold"
                      style={{ fontSize: "14px" }}
                    >
                      {" "}
                      * {errors?.dentist_id}{" "}
                    </span>
                  )}
                </div>
              </Col>
              <Col>
                <div className={"mb-2"}>
                  <label> Mode de Paiement </label>
                  <select
                    className="form-control"
                    value={paymentMode}
                    onChange={(e) => setPaymentMode(e.target.value)}
                  >
                    <option> == Choisir le mode de paiement == </option>
                    <option value="cash"> Espèce </option>
                    <option value="cheque"> Chèque </option>
                    <option value="money_order"> Virement </option>
                  </select>

                  {errors?.paymentMode && (
                    <span
                      className="text-danger mt-3 fw-bold"
                      style={{ fontSize: "14px" }}
                    >
                      {" "}
                      * {errors?.paymentMode}{" "}
                    </span>
                  )}
                </div>
              </Col>
            </Row>

            <FormGroup className={"mb-2"}>
              <label>Montant</label>
              <FormControl
                type={"number"}
                min={0}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                placeholder={"Montant du paiement ..."}
              ></FormControl>
              {errors?.price && (
                <span
                  className="text-danger mt-3 fw-bold"
                  style={{ fontSize: "14px" }}
                >
                  {" "}
                  * {errors?.price}{" "}
                </span>
              )}
            </FormGroup>

            <FormGroup className={"mb-2"}>
              <label>Informations supplémentaires </label>
              <FormControl
                as={"textarea"}
                value={code_cheque}
                onChange={(e) => setCodeCheque(e.target.value)}
                placeholder={"Ajouter des notes ..."}
              ></FormControl>
            </FormGroup>

            <div className={"d-flex justify-content-end mb-2"}>
              {loading ? (
                <Button>
                  <Spinner animation="border" />
                </Button>
              ) : (
                <Button type={"submit"} variant={"main"}>
                  <FontAwesomeIcon icon={faPlusCircle} /> Ajouter{" "}
                </Button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <NotificationContainer />
    </>
  );
};

export default LaboPayments;
