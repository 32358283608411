import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Dropdown,
  Form,
  Modal,
  OverlayTrigger,
  Spinner,
  Table,
  Tooltip,
} from "@themesberg/react-bootstrap";

import Paginator from "../Paginator";
import {
  faArchive,
  faBriefcaseMedical,
  faCheckCircle,
  faEllipsisV,
  faEnvelope,
  faEye,
  faFileAlt,
  faImage,
  faPaperclip,
  faPeopleCarry,
  faShare,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { currentUser } from "../../../../authentication/auth";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../../config/axios";
import { APIURL } from "../../../../configApi";
import CheckBox from "../CheckBox";
import { NotificationManager } from "react-notifications";
import DentistService from "../../../services/DentistService";
import moment from "moment";
import WorkService from "../../../services/WorkService";
moment.locale("fr");
const WorksDentist = (props) => {
  const navigate = useNavigate();

  const [agent_id, setAgent_id] = useState();
  const [showDefault, setShowDefault] = useState(false);
  const [showDefaultMultiple, setShowDefaultMultiple] = useState(false);
  const [showDefault1Multiple, setShowDefault1Multiple] = useState(false);
  const handleClose = () => setShowDefault(false);
  const [agents, setAgents] = useState([]);
  const [page, setPage] = useState(1);
  const [courier_id, setCourier_id] = useState();
  const [showDefault1, setShowDefault1] = useState(false);
  const handleClose1 = () => setShowDefault1(false);
  const [couriers, setCouriers] = useState([]);

  const [selectedWork, setSelectedWork] = useState();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    pages,
    currentStatus,
    works,
    refreshWorks,
    updatedRows,
    updateWorks,
  } = props;
  const [selectedWorks, setSelectedWorks] = useState([]);
  const globalAction = async (data) => {
    // console.log("global action data", data);
    try {
      const res = await WorkService.changeStatusMultiple(data);
      if (res.status === 200) {
        NotificationManager.success("Action mutiple", "Travaux");
        refreshWorks(currentStatus);
        setSelectedWorks([]);
        updatedRows();
        //setUpdate((prev) => prev + 1);
      }
      // console.log("global action", res);
    } catch (e) {
      // console.log(e);
    }
  };
  const [update, setUpdate] = useState(0);
  useEffect(() => {
    // console.log("status ====>", currentStatus);
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    // console.log("");
  }, []);
  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
  }, [update]);

  const recueAuDentist = (workId) => {
    axiosInstance
      .put(`${APIURL}/global/works/${workId}/change_status`, {
        step: "received_dentist",
      })
      .then((res) => {
        // console.log("received_dentist", res);
        NotificationManager.success(
          "Travail bien reçu au dentist",
          "Notification"
        );
        refreshWorks(currentStatus);
      })
      .catch((err) => {
        // console.log("erreur", err);
        NotificationManager.error("Une erreur s'est produite", "Notification");
      });
  };

  const sendToLabo = (workId) => {
    axiosInstance
      .put(`${APIURL}/global/works/${workId}/change_status`, {
        step: "send_dentist",
      })
      .then((res) => {
        // console.log("outgoing_dentist", res);
        NotificationManager.success(
          "Notification envoyée au laboratoire",
          "Notification"
        );
        refreshWorks(currentStatus);
      })
      .catch((err) => {
        // console.log("erreur", err);
        NotificationManager.error("Une erreur s'est produite", "Notification");
      });
  };

  const updateSelectedWorks = (workId, oper) => {
    let selectedworks = selectedWorks;
    if (oper === "+") {
      selectedWorks.push(workId);
    } else if (oper === "-") {
      selectedworks.splice(selectedworks.indexOf(workId), 1);
    }
    setSelectedWorks(selectedworks);
    setUpdate((prev) => prev + 1);
  };

  const checkWork = (e) => {
    // console.log(e.target.value);
  };

  const ArchiverWork = async (id) => {
    try {
      const response = await DentistService.archiver(id);
      if (response.status === 200) {
        NotificationManager.success(response?.data?.message, "Notification");
        refreshWorks(currentStatus);
      }
    } catch (err) {
      NotificationManager.error(err?.response?.data?.message, "Notification");
      refreshWorks(currentStatus);
    }
  };

  const validateWork = async (id) => {
    try {
      const response = await DentistService.acceptWork(id);
      if (response.status === 200) {
        NotificationManager.success(
          "Travail accepté avec succès",
          "Notification"
        );
        refreshWorks(currentStatus);
      }
    } catch (err) {
      // console.log(err);
    }
  };
  const renderDropdown = () => {
    switch (currentStatus) {
      case "at_dentist":
        return (
          <>
            <Dropdown
              drop={faEllipsisV}
              className="me-2 mb-2"
              style={{ textTransform: "capitalize" }}
            >
              <Dropdown.Toggle split variant="default">
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  className="dropdown-arrow"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu
                className={"user-dropdown dropdown-menu-right mt-2"}
              >
                <Dropdown.Item
                  className="text-muted text-success"
                  onClick={() =>
                    globalAction({
                      works_ids: selectedWorks,
                      action: "send_dentist",
                    })
                  }
                >
                  <FontAwesomeIcon
                    icon={faShare}
                    className="dropdown-arrow me-2"
                  />{" "}
                  Envoyer au labo
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-muted"
                  onClick={() =>
                    globalAction({
                      works_ids: selectedWorks,
                      action: "archieved",
                    })
                  }
                >
                  <small>
                    <FontAwesomeIcon
                      icon={faArchive}
                      className="dropdown-arrow me-2"
                    />{" "}
                    Archiver{" "}
                  </small>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      case "outgoing_laboratory":
        return (
          <>
            <Dropdown
              drop={faEllipsisV}
              className="me-2 mb-2"
              style={{ textTransform: "capitalize" }}
            >
              <Dropdown.Toggle split variant="default">
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  className="dropdown-arrow"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu
                className={"user-dropdown dropdown-menu-right mt-2"}
              >
                <Dropdown.Item
                  className={"text-primary"}
                  onClick={() =>
                    globalAction({
                      works_ids: selectedWorks,
                      action: "received_dentist",
                    })
                  }
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="dropdown-arrow me-2"
                  />{" "}
                  Bien reçu{" "}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );

      default:
        return "";
    }
  };
  return (
    <>
      {loading ? (
        <div className="text-center">
          <Spinner animation={"border"} className="my-4" />
        </div>
      ) : (
        <Card border="light" className="shadow-sm">
          <Table
            responsive
            hover={true}
            className="align-items-center table-flush"
          >
            <thead className="thead-light">
              <tr>
                <th className="d-flex align-items-center justifity-content-between">
                  {selectedWorks && selectedWorks.length > 0
                    ? renderDropdown()
                    : ""}
                  Référence{" "}
                </th>
                <th>
                  <div className={"d-flex align-items-center"}>
                    Laboratoire{" "}
                    <div
                      className={"ms-2 d-flex flex-column align-items-center "}
                    >
                      <FontAwesomeIcon
                        className={"fa-md"}
                        style={{ marginBottom: "-10px" }}
                        icon={faSortUp}
                      />
                      <FontAwesomeIcon className={"fa-md"} icon={faSortDown} />
                    </div>
                  </div>
                </th>

                <th> Travail </th>

                <th>
                  {" "}
                  <FontAwesomeIcon icon={faPaperclip} />{" "}
                </th>
                <th> Commentaire </th>

                <th> </th>
              </tr>
            </thead>
            <tbody>
              {works.map((work, index) => {
                return (
                  <tr
                    title={`${
                      work?.full_name_patient && work?.full_name_patient
                    } ( ${
                      work.sex_patient === "women"
                        ? "F"
                        : work.sex_patient === "man"
                        ? "H"
                        : ""
                    } )`}
                    key={index}
                    style={{
                      backgroundColor:
                        selectedWorks && selectedWorks.includes(work.id)
                          ? "#eaedf2"
                          : work.is_urgent
                          ? "#f6a40921"
                          : work.is_delivery_date_requested
                          ? "rgba(14, 174, 186 , .19)"
                          : "transparent",
                    }}
                  >
                    <td>
                      <div className="d-flex gap-2 align-items-center">
                        <OverlayTrigger
                          placement="right"
                          trigger={["hover", "focus"]}
                          overlay={
                            <Tooltip>
                              {`Date de creation : ` +
                                moment.unix(work.created_at).format("DD-MMM")}
                              <br />
                              {work.comment}
                            </Tooltip>
                          }
                        >
                          {work.is_accept_form_dentist ? (
                            <>
                              {["at_dentist", "outgoing_laboratory"].includes(
                                work.status
                              ) && (
                                <CheckBox
                                  workId={work.id}
                                  updateSelectedWorks={(work, oper) =>
                                    updateSelectedWorks(work, oper)
                                  }
                                />
                              )}

                              <a
                                onClick={() =>
                                  navigate("/dentist/works/show/" + work.id)
                                }
                              >
                                {" "}
                                {work.reference_work}{" "}
                              </a>
                            </>
                          ) : (
                            <Button
                              variant="orangered"
                              onClick={() => validateWork(work.id)}
                            >
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                className={"me-2"}
                              />
                              Valider{" "}
                            </Button>
                          )}
                        </OverlayTrigger>
                      </div>
                    </td>

                    {/*<td> {work.requested_delivery_date} </td>*/}
                    {/*<td> {work.service.name} </td>*/}
                    <td>
                      {" "}
                      {work.laboratory.first_name +
                        " " +
                        work.laboratory.last_name}{" "}
                      ( {work.laboratory.city?.name} ){" "}
                    </td>

                    <td>
                      {" "}
                      {work.service.name} ( {work.elements_numbers} ){" "}
                    </td>

                    <td>
                      {" "}
                      {work.attachments_files.length > 0 && (
                        <div>
                          <FontAwesomeIcon className={"fa-md"} icon={faImage} />{" "}
                          ( {work.attachments_files.length} )
                        </div>
                      )}
                      {work.files_extra_finger_print.length > 0 && (
                        <div>
                          <FontAwesomeIcon
                            className={"fa-md"}
                            icon={faFileAlt}
                          />{" "}
                          ( {work.files_extra_finger_print.length} )
                        </div>
                      )}
                      {work.attachments_files.length == 0 &&
                        work.files_extra_finger_print.length == 0 &&
                        "vide"}
                    </td>
                    <td> {work.forseen_delivery_date} </td>
                    <td>
                      <Dropdown drop={faEllipsisV} className="me-2 mb-2">
                        <Dropdown.Toggle split variant="default">
                          <FontAwesomeIcon
                            icon={faEllipsisV}
                            className="dropdown-arrow"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className={"user-dropdown dropdown-menu-right mt-2"}
                        >
                          <Dropdown.Item
                            className={"text-primary"}
                            onClick={() =>
                              navigate("/dentist/works/show/" + work.id)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faEye}
                              className="dropdown-arrow me-2"
                            />{" "}
                            Afficher{" "}
                          </Dropdown.Item>

                          {props.currentStatus === "outgoing_dentist" ? (
                            !work.is_accept_form_dentist ? (
                              <>
                                <Dropdown.Item
                                  className={"text-success"}
                                  onClick={() => validateWork(work?.id)}
                                >
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className="dropdown-arrow me-2"
                                  />{" "}
                                  Valider{" "}
                                </Dropdown.Item>
                              </>
                            ) : (
                              ""
                            )
                          ) : props.currentStatus === "at_laboratory" ? (
                            <></>
                          ) : props.currentStatus === "outgoing_laboratory" ? (
                            !work.is_accept_form_dentist ? (
                              <>
                                <Dropdown.Item
                                  className={"text-success"}
                                  onClick={() => validateWork(work?.id)}
                                >
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className="dropdown-arrow me-2"
                                  />{" "}
                                  Valider{" "}
                                </Dropdown.Item>
                              </>
                            ) : (
                              <>
                                <Dropdown.Item
                                  className={"text-success"}
                                  onClick={() => recueAuDentist(work.id)}
                                >
                                  <FontAwesomeIcon
                                    icon={faEnvelope}
                                    className="dropdown-arrow me-2"
                                  />{" "}
                                  Bien reçu{" "}
                                </Dropdown.Item>
                              </>
                            )
                          ) : !work.is_accept_form_dentist ? (
                            <>
                              <Dropdown.Item
                                className={"text-success"}
                                onClick={() => validateWork(work?.id)}
                              >
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  className="dropdown-arrow me-2"
                                />{" "}
                                Valider{" "}
                              </Dropdown.Item>
                            </>
                          ) : (
                            <>
                              <Dropdown.Item
                                className={"text-success"}
                                onClick={() => sendToLabo(work.id)}
                              >
                                <FontAwesomeIcon
                                  icon={faShare}
                                  className="dropdown-arrow me-2"
                                />{" "}
                                Envoyer au labo{" "}
                              </Dropdown.Item>
                              <Dropdown.Item
                                style={{ fontSize: "14px" }}
                                onClick={() => ArchiverWork(work.id)}
                              >
                                <FontAwesomeIcon
                                  icon={faArchive}
                                  className="dropdown-arrow me-2"
                                />{" "}
                                Archiver
                              </Dropdown.Item>
                            </>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="d-flex justify-content-center mt-3">
            <Paginator
              loader={(value) => setLoading(value)}
              totalPages={pages}
              type={"works"}
              setWorks={updateWorks}
              status={currentStatus}
              updatePageSelected={(value) => setPage(value)}
              activatedItem={page}
            />
          </div>
        </Card>
      )}
    </>
  );
};

export default WorksDentist;
