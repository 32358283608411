import React, { useEffect, useState } from "react";
import Preloader from "../../Shared/Preloader/Preloader";
import Sidebar from "../../Shared/Sidebar";
import Navbar from "../../Shared/Navbar/Navbar";
import {
  Button,
  Card,
  Col,
  Image,
  Modal,
  Row,
} from "@themesberg/react-bootstrap";
import { PagesRoutes } from "../../../pages_routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faEnvelope,
  faFileWord,
  faHome,
  faMoneyBillWave,
  faSmile,
  faTooth,
  faTruck,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useNavigate } from "react-router-dom";
import { currentUser } from "../../../authentication/auth";
import { axiosInstance } from "../../../config/axios";
import { APIURL, getPages } from "../../../configApi";
import UsersTable from "../../Shared/components/Table/UsersTable";
import DentistsTable from "../../Shared/components/Table/DentistsTable";
import RegisterService from "../../services/RegisterService";
import axios from "axios";
import PaginatorDentistList from "../../Shared/components/Paginator/PaginatorDentistList";
import DentistService from "../../services/DentistService";
import { translate_fr } from "../../../lang/fr";
import { trafficShares } from "../../../data/charts";
import { CircleChartWidget } from "../../../components/Widgets";
import WorkService from "../../services/WorkService";
import LaboratoryService from "../../services/LaboratoireService";
import event from "../../../assets/GabesJanvier.jpg";
import UserUnknownAvatar from "../../../assets/img/user-unknown.jpg";
import FirebaseNotif from "../../Shared/FirebaseNotif/FirebaseNotif";
const DashDentist = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const [email, setEmail] = useState(null);
  const [addedType, setAddedType] = useState(0);
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => {
    setShowDefault(false);
    setAddedType(0);
    setShowInvit(false);
  };

  const [dentists, setDentists] = useState([]);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [pages, setPages] = useState(0);
  const [userData, setUserData] = useState();

  const [showInvit, setShowInvit] = useState(null);

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [description, setDescription] = useState("");
  const [country_id, setCountryId] = useState(1);
  const [state_id, setStateId] = useState();
  const [city_id, setCityId] = useState();
  const [principal_phone, setPrincipalPhone] = useState();
  const [ordinal_number_dentist, setOrdinalNumberDentist] = useState();
  const [full_address, setFullAdress] = useState();
  const [invitations, setInvitations] = useState([]);
  const getCountries = async () => {
    try {
      const response = await RegisterService.getCountries();
      setCountries(response.data.data);
      // console.log("countries", response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };
  const getStates = async (idCountry) => {
    try {
      const response = await RegisterService.getStates(idCountry);
      setStates(response.data.data);
      // console.log("states", response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };
  const getCities = async (idState) => {
    try {
      const response = await RegisterService.getCities(idState);
      setCities(response.data.data);
      // console.log("cities", response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };
  const [update, setUpdate] = useState(0);

  const [ids, setIds] = useState([]);
  const getAllNotifications = async () => {
    try {
      const res = await axiosInstance.get(`${APIURL}/global/user_fcm_token`);
      // console.log("data of notifications", res.data);
      if (res.status === 200) {
      }
    } catch (e) {
      // console.log(e);
    }
  };
  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    //getAgents()
    getDentists();
    getCountries();
    getStates(1);
    getInvitations();
    getWorks();
    getAllNotifications();
  }, []);
  const getDentists = () => {
    axiosInstance
      .get(`${APIURL}/laboratory/dentists`)
      .then((res) => {
        setDentists(res.data.data.list);
        setPages(getPages(res.data.data.total));
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const sendNewInvitation = (e) => {
    e.preventDefault();
    axiosInstance
      .post(`${APIURL}/global/request_invitations`, { email: email })
      .then((res) => {
        NotificationManager.success(
          "Invitation envoye avec succès",
          "Notification"
        );
        setShowDefault(false);
        setEmail("");

        //console.log(invitations)
      })
      .catch((err) => {
        NotificationManager.error(err.response.data.message, "Notification");
        setEmail("");
      });
  };

  const changeAddedType = (e) => {
    // console.log("type is changing");
    setAddedType(e);
  };

  const registerUser = (e) => {
    e.preventDefault();
    setLoading(true);
    let user = {};
    let endpoint = "";

    user = {
      first_name,
      last_name,
      full_address,
      ordinal_number_dentist,
      principal_phone,
      city_id,
      country_id,
      state_id,
      description,
      email,
    };
    endpoint = "/laboratory/dentists/temporary";

    // console.log("user", user);

    axiosInstance
      .post(`${APIURL + endpoint}`, user)
      .then((res) => {
        // console.log(res);
        setError("");
        setSuccess("Compte crée avec succès");
        setLoading(false);
        handleClose();
        getDentists();
        NotificationManager.success(
          "Compte local ajoutee avec succès",
          "Notification"
        );
      })
      .catch((err) => {
        // console.log(err);
        setError(err.response.data.message);
        setLoading(false);
        NotificationManager.error(err.response.data.message, "Notification");
      });
  };

  const renderWorksNotValidated = () => {
    return (
      <>
        {works &&
          [...works]
            .filter((work) => !work.is_accept_form_dentist)
            .map((w) => (
              <div className="my-2">
                <a onClick={() => navigate(`/dentist/works/show/${w?.id}`)}>
                  {" "}
                  {`${w?.laboratory?.last_name} ${w?.laboratory?.first_name} : ${w?.service?.name} ( ${w?.elements_numbers} )`}{" "}
                </a>
                {ids.includes(w?.id) ? (
                  ""
                ) : (
                  <Button
                    onClick={() => validateWork(w?.id)}
                    variant="warning"
                    size="sm"
                    className="text-white ms-3"
                  >
                    <FontAwesomeIcon icon={faCheckCircle} /> Valider
                  </Button>
                )}
              </div>
            ))}
      </>
    );
  };

  const validateWork = async (id) => {
    try {
      const response = await DentistService.acceptWork(id);
      if (response.status === 200) {
        let t = ids;
        t.push(id);
        setIds(t);
        setUpdate((prev) => prev + 1);
        //btnElement.current.style.visibility = "hidden";
        NotificationManager.success(
          "Travail accepté avec succès",
          "Notification"
        );
        //getWorks();
        // console.log("liste des ids ", ids);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  // Added by hamdaoui wassim 08022024
  const [works, setWorks] = useState([]);
  const getInvitations = async () => {
    try {
      const response = await DentistService.Invitations();
      if (response.status === 200) {
        // NotificationManager.success(response.data.message, "Notification");
        setInvitations(
          [...response.data.data.list].filter(
            (invi) =>
              invi.source_invitation === "dentist" && invi.status != "accepted"
          )
        );
      }
    } catch (err) {
      // console.log(err);
      NotificationManager.error(err?.response?.data?.message);
    }
  };

  const accepted_request = async (idreques) => {
    try {
      const response = await LaboratoryService.acceptInvitation(idreques);
      if (response.status === 200) {
        NotificationManager.success(response.data.message);
        getInvitations();
      }
    } catch (err) {
      NotificationManager.err(err?.response?.data?.message);
    }
  };

  const getWorks = async () => {
    try {
      const response = await WorkService.Works();
      if (response.status === 200) {
        //   NotificationManager.success(response.data.message, "Notification");
        setWorks(response.data.data.list);
      }
    } catch (err) {
      // console.log(err);
      NotificationManager.error(err?.response?.data?.message);
    }
  };
  return (
    <>
      <Preloader />
      <Sidebar user={user} />
      {/* <FirebaseNotif /> */}
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar user={user} pageName={"Menu principal"} icon={faHome} />
        <hr />
        <div>
          <NotificationContainer />
          <Row>
            <Col lg={"12"}>
              {invitations && invitations.length > 0 ? (
                <Col className="mb-4">
                  <Card className="p-3">
                    <h5 className="mt-2 text-center">
                      Bienvenu dans votre espace <strong>Easylab</strong> , ici
                      vous recevez les nouvelles invitations de vos laboratoires{" "}
                      {/* <span className="badge bg-success p-2">
                      {" "}
                      {translate_fr(user?.role)}{" "}
                    </span>{" "} */}
                    </h5>
                    <hr />
                    <h5>
                      {" "}
                      <FontAwesomeIcon icon={faEnvelope} /> Nouvelles
                      Invitations{" "}
                    </h5>
                    {invitations.map((invi, index) => (
                      <div key={index} className="mt-2 ps-3">
                        {" "}
                        <>
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setUserData(invi.laboratory);
                              setShowInvit(true);
                            }}
                          >
                            {" "}
                            {invi.laboratory?.last_name +
                              " " +
                              invi.laboratory?.first_name}
                          </span>
                          {"     "}

                          <Button
                            className="ms-5"
                            variant="success"
                            size="sm"
                            onClick={() => accepted_request(invi.id)}
                          >
                            {" "}
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                            /> Accepter{" "}
                          </Button>
                        </>
                      </div>
                    ))}
                  </Card>
                </Col>
              ) : (
                ""
              )}
            </Col>
            <Col>
              {works &&
              [...works].filter((work) => !work.is_accept_form_dentist).length >
                0 ? (
                <Col xs={12} className="mb-4">
                  <Card className="p-4 mt-2">
                    <h5>
                      {" "}
                      <FontAwesomeIcon icon={faEnvelope} /> Nouveaux Travaux à
                      valider{" "}
                    </h5>

                    {renderWorksNotValidated()}
                  </Card>
                </Col>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Card className="mb-4  text-center p-3">
            <h5 className="mt-2">
              Bienvenu dans votre espace <strong>Easylab</strong> , compte{" "}
              <span className="badge bg-success p-2">
                {" "}
                {translate_fr(user?.role)}{" "}
              </span>{" "}
            </h5>
            <hr />

            <p>
              Pour bien démarrer régler tout d’abord les informations de votre
              profil et ajouter une image en cliquant sur la petite flèche en
              haut à droite, choisissez par la suite le menu{" "}
              <strong>‘MON PROFIL’</strong>
            </p>
          </Card>

          <Row className=" ">
            {/* <Col lg={"6"} md={"12"} className="mb-4">
              <Card className="p-4" style={{ minHeight: "100%" }}>
                <Card.Header>
                  <h5 className="text-main">
                    <FontAwesomeIcon icon={faMoneyBillWave} className="me-2" />
                    Personaliser votre liste des prix{" "}
                  </h5>
                </Card.Header>
                <Card.Body>
                  Nous savons que Votre Laboratoire est UNIQUE, pour cela nous
                  vous invitons à régler la liste des services que vous procurer
                  avec descriptions et prix. Les services sont organisées en
                  Grand Groupes, Sous groupes et Services. Vous pouvez régler
                  ceci facilement dans le menu <strong> ‘Settings’ </strong> en
                  cliquant sur la petite flèche en haut à droite
                </Card.Body>
              </Card>
            </Col> */}
            <Col lg={"6"} md={"12"} className="mb-4">
              <Card className="p-4" style={{ minHeight: "100%" }}>
                <Card.Header>
                  <h5 className="text-main">
                    <FontAwesomeIcon icon={faTooth} className="me-2" />
                    Invitez vos laboratoires{" "}
                  </h5>
                </Card.Header>
                <Card.Body>
                  Votre espace vous procure de collaborer avec les comptes
                  Laboratoires pour cela une invitation doit etre acceptée, pour
                  ainsi partager les fiches travaux.
                  <br />
                  vous rejoindre dans votre espace en cliquant sur le menu à
                  gauche l’onglet <strong> Laboratoires </strong>
                  <strong> ‘Liste’ </strong> . Dans la page{" "}
                  <strong> ‘Laboratoires’ </strong> cliquer maintenant sur le
                  boutton en haut à droite <strong> ‘AJOUTER’ </strong> Ainsi
                  vous pouvez introduire l’adresse mail du Laboratoire avec qui
                  vous collaborer et l'inviter à votre espace. Vous pouvez
                  consulter la liste des services du laboratoire en cliquant à
                  haut à droite <strong> ‘Paramètres’ </strong>.
                </Card.Body>
              </Card>
            </Col>
            {/* <Col lg={"6"} md={"12"} className="mb-4">
              <Card className="p-4" style={{ minHeight: "100%" }}>
                <Card.Header>
                  <h5 className="text-main">
                    <FontAwesomeIcon icon={faUserFriends} className="me-2" />
                    Gerer votre equipe d'agents collaborateurs{" "}
                    <FontAwesomeIcon icon={faTruck} />
                  </h5>
                </Card.Header>
                <Card.Body>
                  Votre équipe aussi, Alors créer maintenant vos collaborateurs
                  <strong> ‘AGENT’ </strong> et / ou{" "}
                  <strong> ‘COURSIER’ </strong> dans le menu correspondant à
                  gauche , introduisez alors l'adresse mail correspondante pour
                  leurs donner un accès limité à votre espace où vous pouvez
                  activer/désactiver le compte à tout moment par un simple Clic
                </Card.Body>
              </Card>
            </Col> */}
            <Col lg={"6"} md={"12"} className="mb-4">
              <Card className="p-4" style={{ minHeight: "100%" }}>
                <Card.Header>
                  <h5 className="text-main">
                    <FontAwesomeIcon icon={faSmile} className="me-2" />
                    READY{" "}
                  </h5>
                </Card.Header>
                <Card.Body>
                  Votre espace est prêt maintenant alors commencer par recevoir
                  les travaux dans le menu <strong> ‘TRAVAUX’.</strong> Vous
                  pouvez aussi créer les travaux en cliquant sur le boutton
                  <strong> ‘AJOUTER’ </strong>
                  <p className="mt-2">
                    {" "}
                    Les fiches créées par le laboratoire pour vous doivent être
                    validée par vos soins en cliquant sur le bouton{" "}
                    <strong> valider </strong>.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={"12"} md={"12"}>
              <Card className="p-4" style={{ minHeight: "100%" }}>
                <Card.Header>
                  <h5 className="text-main">
                    <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                    CONTACTEZ NOUS{" "}
                  </h5>
                </Card.Header>
                <Card.Body>
                  Vous avez une remarque, un commentaire à partager avec nous,
                  vous voulez une démonstration ou des informations
                  supplémentaires, n’hesitez pas à nous contacter dans le menu{" "}
                  <strong>' CONTACTEZ NOUS'</strong> en haut droite.
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <div className="alert alert-success text-center p-3  my-4">
            Easylab, Réveillez l’Artiste caché en vous, votre espace Easylab
            s’occupe du reste !
          </div>
        </div>
        <Row>
          <Col lg={"12"} className="mb-4">
            <Card className="p-3">
              <h3> Les travaux arrivants </h3>
              <hr />
              <div className="d-flex align-items-center mb-3">
                <h5>
                  <FontAwesomeIcon icon={faFileWord} /> Travaux entrants :{" "}
                  {
                    [...works].filter(
                      (work) => work.status === "outgoing_dentist"
                    ).length
                  }{" "}
                </h5>
                <Button
                  onClick={() =>
                    navigate("/laboratory/works?status=outgoing_dentist")
                  }
                  variant="success"
                  size="sm"
                  className="ms-3 mb-2"
                >
                  {" "}
                  Voir{" "}
                </Button>
              </div>

              <div className="d-flex align-items-center">
                <h5>
                  <FontAwesomeIcon icon={faFileWord} /> Travaux sortants :{" "}
                  {
                    [...works].filter(
                      (work) => work.status === "outgoing_laboratory"
                    ).length
                  }{" "}
                </h5>
                <Button
                  onClick={() =>
                    navigate("/laboratory/works?status=outgoing_laboratory")
                  }
                  variant="success"
                  size="sm"
                  className="ms-3 mb-2"
                >
                  {" "}
                  Voir{" "}
                </Button>
              </div>
            </Card>
          </Col>
          <Col lg={"12"}>
            <h4>Actualités </h4>
            <hr />

            <img src={event} className="w-100" />
          </Col>
        </Row>
      </main>
      <Modal
        as={Modal.Dialog}
        centered
        show={showInvit}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faEnvelope} /> Détails de l'invitation
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            {userData?.photo_profile ? (
              <Image
                src={userData?.photo_profile}
                className="rounded-circle mx-auto my-2"
                style={{ width: "8rem", height: "8rem" }}
              />
            ) : (
              <Image
                src={UserUnknownAvatar}
                className="rounded-circle mx-auto my-2"
                style={{ width: "8rem", height: "8rem" }}
              />
            )}
          </div>
          <hr />
          <Row>
            <Col md={6} sm={12}>
              <h5>
                Nom :{" "}
                <span className={"text-success"}> {userData?.first_name} </span>
              </h5>
            </Col>
            <Col md={6} sm={12}>
              <h5>
                Prénom :{" "}
                <span className={"text-success"}> {userData?.last_name} </span>
              </h5>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={6} sm={12}>
              <h5>
                Email :{" "}
                <span className={"text-success"}> {userData?.email} </span>
              </h5>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={6} sm={12}>
              <h5>
                Ville :{" "}
                <span className={"text-success"}>
                  {" "}
                  {userData?.city?.name} ( {userData?.state?.name} ){" "}
                </span>
              </h5>
            </Col>
            <Col md={6} sm={12}>
              <h5>
                Téléphone :{" "}
                <span className={"text-success"}>
                  {" "}
                  {userData?.principal_phone}{" "}
                </span>
              </h5>
            </Col>
          </Row>
          <hr />

          <div>
            <h5>
              Adresse :{" "}
              <span className={"text-success"}>{userData?.full_address}</span>
            </h5>
          </div>
          <hr />
          <div>
            <h5>
              Déscription :{" "}
              <span className={"text-success"}>{userData?.description}</span>
            </h5>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DashDentist;
