import { axiosInstance } from "../../config/axios";
import { APIURL } from "../../configApi";

const LaboratoryService = {};
LaboratoryService.ListDentist = (page) => {
  return axiosInstance.get(`${APIURL}/laboratory/dentists?page=${page}`);
};

LaboratoryService.Additional_fees = (iddentist, data) => {
  return axiosInstance.put(
    `${APIURL}/laboratory/dentists/update_additional_fees_by_dentist/${iddentist}`,
    data
  );
};

LaboratoryService.updatePatient = (idWork, data) => {
  return axiosInstance.put(
    `${APIURL}/global/works/${idWork}/update_patient`,
    data
  );
};

LaboratoryService.updateRealReference = (idWork, data) => {
  return axiosInstance.put(
    `${APIURL}/laboratory/works/${idWork}/real-reference`,
    data
  );
};

LaboratoryService.Dentists = () => {
  return axiosInstance.get(`${APIURL}/laboratory/dentists/all`);
};

LaboratoryService.worksArchivee = (page) => {
  return axiosInstance.get(
    `${APIURL}/global/works?page=${page}&status=archived`
  );
};

LaboratoryService.worksToBeInvoiced = () => {
  return axiosInstance.get(`${APIURL}/global/works`);
};

LaboratoryService.worksNotInvoiced = () => {
  return axiosInstance.get(`${APIURL}/laboratory/works/pre_invoice`);
};

LaboratoryService.worksToBeInvoicedByDentist = (idDentist, page) => {
  return axiosInstance.get(
    `${APIURL}/laboratory/works/pre_invoice?status_invoiced=to_be_invoiced&dentist_id=${idDentist}&page=${page}`
  );
};

LaboratoryService.worksNotInvoicedByDentist = (idDentist, page) => {
  return axiosInstance.get(
    `${APIURL}/laboratory/works/pre_invoice?status_invoiced=not_invoiced&dentist_id=${idDentist}&page=${page}`
  );
};

LaboratoryService.facturer = (idWork) => {
  return axiosInstance.put(
    `${APIURL}/laboratory/works/${idWork}/to_be_invoiced`
  );
};

LaboratoryService.editWorkPrice = (idWork, newPrice) => {
  return axiosInstance.put(`${APIURL}/global/works/${idWork}/edit_price`, {
    price: newPrice,
  });
};

LaboratoryService.addPayment = (data) => {
  return axiosInstance.post(`${APIURL}/laboratory/payments`, data);
};

LaboratoryService.paySelectedWork = (workId) => {
  return axiosInstance.put(
    `${APIURL}/laboratory/works/${workId}/to_be_invoiced`
  );
};

LaboratoryService.deletePayment = (paymentId) => {
  return axiosInstance.delete(`${APIURL}/laboratory/payments/` + paymentId);
};

LaboratoryService.addAgent = (data) => {
  return axiosInstance.post(`${APIURL}/laboratory/agents`, data);
};

LaboratoryService.addCourier = (data) => {
  return axiosInstance.post(`${APIURL}/laboratory/couriers`, data);
};

LaboratoryService.paymentsList = (page) => {
  return axiosInstance.get(`${APIURL}/laboratory/payments?page=${page}`);
};

LaboratoryService.agentsList = (page) => {
  return axiosInstance.get(`${APIURL}/laboratory/agents?page=${page}`);
};

LaboratoryService.agentsAll = () => {
  return axiosInstance.get(`${APIURL}/laboratory/agents/all`);
};

LaboratoryService.couriersList = (page) => {
  return axiosInstance.get(`${APIURL}/laboratory/couriers?page=${page}`);
};

LaboratoryService.couriersAll = () => {
  return axiosInstance.get(`${APIURL}/laboratory/couriers/all`);
};

LaboratoryService.editAgent = (agent_id, newAgent) => {
  return axiosInstance.put(`${APIURL}/laboratory/agents/` + agent_id, newAgent);
};

LaboratoryService.editCourier = (courier_id, newCourier) => {
  return axiosInstance.put(
    `${APIURL}/laboratory/couriers/` + courier_id,
    newCourier
  );
};

LaboratoryService.getAgent = (agent_id) => {
  return axiosInstance.get(`${APIURL}/laboratory/agents/` + agent_id);
};

LaboratoryService.editProfile = (data) => {
  return axiosInstance.put(`${APIURL}/global/auth/update`, data);
};

LaboratoryService.updateFacturationDateDentist = (dentist_id, data) => {
  return axiosInstance.put(
    `${APIURL}/laboratory/dentists/update_invoice_day_by_dentist/${dentist_id}`,
    data
  );
};

LaboratoryService.acceptInvitation = (id) => {
  return axiosInstance.put(
    `${APIURL}/global/request_invitations/${id}/accepted`,
    {}
  );
};

LaboratoryService.getAgents = () => {
  return axiosInstance.get(`${APIURL}/laboratory/agents`);
};

LaboratoryService.updateTemporaryDentist = (id, dentist) => {
  return axiosInstance.put(
    `${APIURL}/laboratory/dentists/temporary/${id}`,
    dentist
  );
};

LaboratoryService.addRemise = (data) => {
  return axiosInstance.post(
    `${APIURL}/laboratory/services/discounts/add_or_update`,
    data
  );
};

LaboratoryService.deleteRemise = (data) => {
  return axiosInstance.delete(
    `${APIURL}/laboratory/services/discounts/clear?discounts_ids=[${data}]`
  );
};

LaboratoryService.remiseListByDentist = (dentistId) => {
  return axiosInstance.get(
    `${APIURL}/laboratory/services/discounts/${dentistId}/list_of_discounts`
  );
};

export default LaboratoryService;
