import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Image,
  InputGroup,
  Modal,
  Row,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faAddressCard,
  faEdit,
  faEnvelope,
  faPhoneAlt,
  faSave,
  faTimes,
  faTrash,
  faUser,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { axiosInstance } from "../../../../config/axios";
import { APIURL } from "../../../../configApi";
import { NotificationManager } from "react-notifications";
import dentists from "../index";
import UserUnknownAvatar from "../../../../assets/img/user-unknown.jpg";
import RegisterService from "../../../services/RegisterService";
import LaboratoryService from "../../../services/LaboratoireService";

const ShowDentist = (props) => {
  const { showForm, dentist, closeForm, refreshList, page, updateDentist } =
    props;

  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [full_address, setFull_address] = useState("");
  const [principal_phone, setPrincipal_phone] = useState("");

  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [description, setDescription] = useState("");

  const [country_id, setCountryId] = useState(1);
  const [state_id, setStateId] = useState();
  const [city_id, setCityId] = useState();

  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [id, setId] = useState("");
  const [edit, setEdit] = useState(false);

  const getCountries = async () => {
    try {
      const response = await RegisterService.getCountries();
      setCountries(response.data.data);
      // console.log("countries", response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };
  const getStates = async (idCountry) => {
    try {
      const response = await RegisterService.getStates(idCountry);
      setStates(response.data.data);
      // console.log("states", response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };
  const getCities = async (idState) => {
    try {
      const response = await RegisterService.getCities(idState);
      setCities(response.data.data);
      // console.log("cities", response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };

  // const refreshDentistList = async () => {
  //   const data = await LaboratoryService.ListDentist(page);
  //  // console.log("data in refresh funcions", data.data.data);
  //   refreshList(data.data.data.list);
  // };

  useEffect(() => {
    getCountries();
    getStates(1);
    getCities(dentist?.state?.id);
    // console.log("dentist => ", dentist);
    setId(dentist.id);
    setFirst_name(dentist.first_name);
    setLast_name(dentist.last_name);
    setDescription(dentist.description);
    setEmail(dentist.email);
    setCity(dentist?.city);
    setState(dentist.state);
    setCityId(dentist?.city?.id);
    setStateId(dentist.state_id);
    setPrincipal_phone(dentist.principal_phone);
    setFull_address(dentist.full_address);
    setEdit(false);
  }, [dentist]);

  const updateTemporaryDentist = async (e) => {
    e.preventDefault();
    let user = {
      first_name,
      last_name,
      full_address,
      principal_phone,
      city_id,
      country_id,
      state_id,
      description,
      email,
    };
    try {
      const updated = await LaboratoryService.updateTemporaryDentist(id, user);

      NotificationManager.success(
        "Info dentiste mis à jour avec succès",
        "Notification"
      );
      //refreshDentistList();
      closeForm();
      updateDentist();
    } catch (err) {
      NotificationManager.error(err?.response?.data?.message, "Notification");
    }
  };

  return (
    <>
      {dentist && (
        <Modal
          as={Modal.Dialog}
          centered
          show={showForm}
          onHide={closeForm}
          size={"lg"}
        >
          <Modal.Header>
            <Modal.Title className="h4 text-main">
              {" "}
              {!edit ? (
                <span className={"text-center"}>
                  {" "}
                  <FontAwesomeIcon icon={faAddressBook} /> Détail du dentiste{" "}
                </span>
              ) : (
                <span className={"text-center"}>
                  {" "}
                  <FontAwesomeIcon icon={faEdit} /> Modifier informations
                  dentiste{" "}
                </span>
              )}
            </Modal.Title>
            <Button
              variant="close"
              aria-label="Close"
              onClick={() => {
                setEdit(false);
                closeForm();
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center">
              {dentist?.photo_profile ? (
                <Image
                  src={dentist?.photo_profile}
                  className="rounded-circle mx-auto my-2"
                  style={{ width: "8rem", height: "8rem" }}
                />
              ) : (
                <Image
                  src={UserUnknownAvatar}
                  className="rounded-circle mx-auto my-2"
                  style={{ width: "8rem", height: "8rem" }}
                />
              )}
            </div>
            {dentist?.email?.indexOf("temporary") != -1 && !edit ? (
              <div>
                <Button variant={"primary"} onClick={() => setEdit(true)}>
                  {" "}
                  <FontAwesomeIcon icon={faEdit} /> Modifier
                </Button>{" "}
              </div>
            ) : (
              ""
            )}

            <hr />

            {!edit && (
              <div>
                <Row>
                  <Col md={6} sm={12}>
                    <h5>
                      Nom :{" "}
                      <span className={"text-success"}> {first_name} </span>
                    </h5>
                  </Col>
                  <Col md={6} sm={12}>
                    <h5>
                      Prénom :{" "}
                      <span className={"text-success"}> {last_name} </span>
                    </h5>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md={12} sm={12}>
                    <h5>
                      Email :{" "}
                      <span className={"text-success"}>
                        {email && email.indexOf("temporary") != -1
                          ? "Compte local"
                          : email}
                      </span>
                    </h5>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md={6} sm={12}>
                    <h5>
                      Ville :{" "}
                      <span className={"text-success"}>
                        {" "}
                        {city?.name} ( {state?.name} ){" "}
                      </span>
                    </h5>
                  </Col>
                  <Col md={6} sm={12}>
                    <h5>
                      Téléphone :{" "}
                      <span className={"text-success"}>
                        {" "}
                        {principal_phone}{" "}
                      </span>
                    </h5>
                  </Col>
                </Row>
                <hr />

                <div>
                  <h5>
                    Adresse :{" "}
                    <span className={"text-success"}>{full_address}</span>
                  </h5>
                </div>
                <hr />
                <div>
                  <h5>
                    Déscription :{" "}
                    <span className={"text-success"}>{description}</span>
                  </h5>
                </div>
              </div>
            )}
            {edit && (
              <div>
                <Form onSubmit={updateTemporaryDentist} className="mt-4">
                  {" "}
                  <Row>
                    <Col>
                      <Form.Group id="lastname" className="mb-4">
                        <Form.Label>Nom </Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUser} />
                          </InputGroup.Text>
                          <Form.Control
                            value={first_name}
                            onChange={(e) => setFirst_name(e.target.value)}
                            autoFocus
                            required
                            type="text"
                            placeholder="Nom du dentiste..."
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group id="firstname" className="mb-4">
                        <Form.Label>Prénom </Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUser} />
                          </InputGroup.Text>
                          <Form.Control
                            value={last_name}
                            onChange={(e) => setLast_name(e.target.value)}
                            required
                            type="text"
                            placeholder="prénom du dentiste ..."
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group id="password" className="mb-4">
                        <Form.Label>Pays :</Form.Label>
                        <Form.Select
                          disabled={true}
                          value={country_id}
                          onChange={(e) => {
                            setCountryId(e.target.value);
                            getStates(e.target.value);
                          }}
                        >
                          <option defaultValue>Selectionner une pays </option>

                          {countries.map((country) => (
                            <option value={country.id}> {country.name}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Gouvernorat :</Form.Label>
                        <Form.Select
                          value={state_id}
                          onChange={(e) => {
                            setStateId(e.target.value);
                            getCities(e.target.value);
                          }}
                        >
                          <option defaultValue>
                            Selectionner une Gouvernorat{" "}
                          </option>

                          {states.map((state) => (
                            <option value={state.id}> {state.name}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Ville :</Form.Label>
                        <Form.Select
                          value={city_id}
                          onChange={(e) => setCityId(e.target.value)}
                        >
                          <option defaultValue>Selectionner une ville </option>

                          {cities.map((city) => (
                            <option value={city.id}> {city?.name}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group id="password" className="mb-4">
                        <Form.Label>Adresse du dentiste : </Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faAddressCard} />
                          </InputGroup.Text>
                          <Form.Control
                            value={full_address}
                            onChange={(e) => setFull_address(e.target.value)}
                            required
                            type="text"
                            placeholder="Adresse ..."
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-4">
                        <Form.Label>Téléphone : </Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faPhoneAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            value={principal_phone}
                            onChange={(e) => setPrincipal_phone(e.target.value)}
                            required
                            type="tel"
                            placeholder="Numero téléphone"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Col>
                    <FormGroup className="mb-3">
                      <Form.Label>Description : </Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faAddressCard} />
                        </InputGroup.Text>
                        <FormControl
                          as="textarea"
                          rows={1}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          placeholder={"Commentaire ..."}
                        ></FormControl>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <div className="my-4 d-flex justify-content-end">
                    {/* <Button variant={"main"} onClick={handleClose}>
                      {" "}
                      <FontAwesomeIcon icon={faTimes} /> Annuler
                    </Button> */}
                    <Button variant={"main"} type="submit">
                      {" "}
                      <FontAwesomeIcon icon={faSave} /> Sauvegarder
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ShowDentist;
