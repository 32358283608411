import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

// pages

import NotFoundPage from "./examples/NotFound";

// components

import Home from "./Home";
import LaboStock from "./Laboratoire/Stock";
import LaboStockCategory from "./Laboratoire/Stock/StockCategory";
import Register from "./Register";
import Login from "./Login";
import Invitations from "./Laboratoire/Invitations";
import Agents from "./Laboratoire/Agents";
import AddAgent from "./Laboratoire/Agents/Add";
import { PagesRoutes } from "../pages_routes";
import Couriers from "./Laboratoire/Couriers";
import AddCourier from "./Laboratoire/Couriers/Add";
import DentistInvitations from "./Dentist/Invitations";
import DentistAgents from "./Dentist/Agents";
import DentistAddAgent from "./Dentist/Agents/Add";
import DentistPayments from "./Dentist/Payments";
import LaboPayments from "./Laboratoire/Payments";
import Works from "./Laboratoire/Works";
import WorksAdd from "./Laboratoire/Works/Add";
import SettingsLabo from "./Laboratoire/Settings";
import DentistWorks from "./Dentist/Works";
import DentistWorksAdd from "./Dentist/Works/Add";
import DentistWorkShow from "./Dentist/Works/Show";
import LaboWorkShow from "./Laboratoire/Works/Show";
import LaboDentistList from "./Laboratoire/Dentists";
import Profile from "./Laboratoire/Profile";
import Laboprofile from "./Laboratoire/Profile";
import Labosupport from "./Laboratoire/Support";
import InvoicesLabo from "./Laboratoire/invoices";
import CourierProfile from "./Coursier/Profile";
import CourierWorks from "./Coursier/Works";
import ResetPassword from "./ResetPassword";
import Dentistprofile from "./Dentist/Profile";
import Archives from "./Laboratoire/Archives";
import EditAgent from "./Laboratoire/Agents/Edit";
import DentistLaboList from "./Dentist/Laboratoires";
import InvoicesDentist from "./Dentist/Invoices";
import SettingsDentist from "./Dentist/Settings";
import DashDentist from "./Dentist/Dashboard";
import Landing from "./Landing";
import Politics from "./Politics";
import DashLaboratory from "./Laboratoire/Dashboard";
import DashCourier from "./Coursier/Dashboard";
import ArchivesDentist from "./Dentist/Archives";
import { DentistRoute } from "./Middlewares/DentistRoute";
import { LaboRoute } from "./Middlewares/LaboRoute";
import { CourierRoute } from "./Middlewares/CourierRoute";
import VerifyEmail from "./VerifyEmail";


export default () => (
  <Routes>
    <Route exact path="/" element={<Landing />} />
    <Route exact path="/politics" element={<Politics />} />
    <Route exact path={PagesRoutes.Login.path} element={<Login />} />
    <Route
      exact
      path={PagesRoutes.VerifyEmail.path}
      element={<VerifyEmail />}
    />
    <Route
      exact
      path={PagesRoutes.ResetPasssword.path}
      element={<ResetPassword />}
    />
    <Route exact path={PagesRoutes.Register.path} element={<Register />} />

    {/* LABORATORY PATHS */}
    <Route
      exact
      path={PagesRoutes.LaboDashboard.path}
      element={
        <LaboRoute>
          <DashLaboratory />
        </LaboRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.LaboInvitations.path}
      element={
        <LaboRoute>
          <Invitations />
        </LaboRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.LaboStockCategory.path}
      element={
        <LaboRoute>
          <LaboStockCategory />
        </LaboRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.LaboStock.path}
      element={
        <LaboRoute>
          <LaboStock />
        </LaboRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.LaboDentists.path}
      element={
        <LaboRoute>
          <LaboDentistList />
        </LaboRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.LaboAgents.path}
      element={
        <LaboRoute>
          <Agents />
        </LaboRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.LaboAgentsAdd.path}
      element={
        <LaboRoute>
          <AddAgent />
        </LaboRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.LaboAgentsEdit.path}
      element={
        <LaboRoute>
          <EditAgent />
        </LaboRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.LaboCouriers.path}
      element={
        <LaboRoute>
          <Couriers />
        </LaboRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.LaboCouriersAdd.path}
      element={
        <LaboRoute>
          <AddCourier />
        </LaboRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.LaboPayments.path}
      element={
        <LaboRoute>
          <LaboPayments />
        </LaboRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.LaboWorks.path}
      element={
        <LaboRoute>
          <Works />
        </LaboRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.LaboWorksAdd.path}
      element={
        <LaboRoute>
          <WorksAdd />
        </LaboRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.LaboWorksShow.path}
      element={
        <LaboRoute>
          <LaboWorkShow />
        </LaboRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.LaboSettings.path}
      element={
        <LaboRoute>
          <SettingsLabo />
        </LaboRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.LaboProfile.path}
      element={
        <LaboRoute>
          <Laboprofile />
        </LaboRoute>
      }
    />
    <Route exact path={PagesRoutes.Support.path} element={<Labosupport />} />
    <Route
      exact
      path={PagesRoutes.LaboInvoices.path}
      element={
        <LaboRoute>
          <InvoicesLabo />
        </LaboRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.LaboArchives.path}
      element={
        <LaboRoute>
          <Archives />
        </LaboRoute>
      }
    />
    {/* DENTIST PATHS */}

    <Route
      exact
      path={PagesRoutes.DentistDashboard.path}
      element={
        <DentistRoute>
          <DashDentist />
        </DentistRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.DentistLaboratories.path}
      element={
        <DentistRoute>
          <DentistLaboList />
        </DentistRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.DentistSettings.path}
      element={<SettingsDentist />}
    />
    <Route
      exact
      path={PagesRoutes.DentistInvoices.path}
      element={
        <DentistRoute>
          <InvoicesDentist />
        </DentistRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.DentistInvitations.path}
      element={
        <DentistRoute>
          <DentistInvitations />
        </DentistRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.DentistAgents.path}
      element={
        <DentistRoute>
          <DentistAgents />
        </DentistRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.DentistAgentsAdd.path}
      element={
        <DentistRoute>
          <DentistAddAgent />
        </DentistRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.DentistWorks.path}
      element={
        <DentistRoute>
          <DentistWorks />
        </DentistRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.DentistWorksAdd.path}
      element={
        <DentistRoute>
          <DentistWorksAdd />
        </DentistRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.DentistWorkShow.path}
      element={
        <DentistRoute>
          <DentistWorkShow />
        </DentistRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.DentistPayments.path}
      element={
        <DentistRoute>
          <DentistPayments />
        </DentistRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.DentistProfile.path}
      element={
        <DentistRoute>
          <Dentistprofile />
        </DentistRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.DentsitArchives.path}
      element={
        <DentistRoute>
          <ArchivesDentist />
        </DentistRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.CourierProfile.path}
      element={
        <CourierRoute>
          <CourierProfile />
        </CourierRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.CourierWorks.path}
      element={
        <CourierRoute>
          <CourierWorks />
        </CourierRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.CourierHome.path}
      element={
        <CourierRoute>
          <CourierWorks />
        </CourierRoute>
      }
    />
    <Route
      exact
      path={PagesRoutes.CourierDashboard.path}
      element={
        <CourierRoute>
          <DashCourier />
        </CourierRoute>
      }
    />

    <Route exact path="/404" element={<NotFoundPage />} />

    <Route path="*" element={<Navigate to="/404" replace />} />
  </Routes>
);
