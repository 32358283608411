import { useNavigate } from "react-router-dom";
import Preloader from "../../../Shared/Preloader/Preloader";
import Sidebar from "../../../Shared/Sidebar";
import Navbar from "../../../Shared/Navbar/Navbar";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalFooter,
  Nav,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
import { PagesRoutes } from "../../../../pages_routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faDownload,
  faExclamationTriangle,
  faEye,
  faList,
  faMoneyBill,
  faPlusCircle,
  faSave,
  faTimesCircle,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import React, { useEffect, useRef, useState } from "react";
import { currentUser } from "../../../../authentication/auth";
import { axiosInstance } from "../../../../config/axios";
import { APIURL } from "../../../../configApi";

import CustomAutoSuggest from "../../../Shared/components/CustomAutoSuggest";
import Select from "react-select";
import FileService from "../../../services/FileService";
import WorkService from "../../../services/WorkService";
import FileSaver from "file-saver";
const DentistWorksAdd = () => {
  const [image, setImage] = useState("");
  const [empreinte, setEmpreinte] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const sgroupeRef = useRef();
  const groupeRef = useRef();
  const servicesRef = useRef();
  const navigate = useNavigate();
  const [attachments_files, setAttachments_files] = useState([]);
  const [files_extra_finger_print, setFiles_extra_finger_print] = useState([]);
  const [comment, setComment] = useState("");
  const [user, setUser] = useState(null);
  const [is_urgent, setIs_urgent] = useState(false);
  const [requested_delivery_date, setRequested_delivery_date] = useState();
  const [elements_numbers, setElements_numbers] = useState(1);
  const [laboratory_id, setLaboratory_id] = useState("");
  const [errors, setErrors] = useState({});

  const [groupe, setGroupe] = useState();
  const [sub_groupe, setSub_groupe] = useState();

  const [laboratories, setLaboratories] = useState([]);
  const [services, setServices] = useState([]);
  const [groupes, setGroupes] = useState([]);

  const [groupeData, setGroupeData] = useState([]);
  const [sgroupeData, setSGroupeData] = useState([]);
  const [servicesData, setServicesData] = useState([]);

  const [subgroupes, setSubgroupes] = useState([]);
  const [full_name_patient, setFull_name_patient] = useState("");
  const [age_patient, setAge_patient] = useState(0);
  const [sex_patient, setSex_patient] = useState("");
  const [service, setService] = useState("");
  const [shade, setShade] = useState("");
  const [shades, setShades] = useState([]);
  const [file, setFile] = useState();

  const [localFiles, setLocalFiles] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingEmpreinte, setLoadingEmpreinte] = useState(false);
  const [disabledUploadImage, setDisabledUploadImage] = useState(true);
  const [disabledUploadEmpreint, setDisabledUploadEmpreint] = useState(true);

  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(0);
  const handleClose = () => {
    setShowImageModal(false);
    setPopupModal(false);
  };

  const [idFileDeleted, setIdFileDeleted] = useState();
  const [popupModal, setPopupModal] = useState(false);
  function formValidation() {
    let status = true;
    var localerrors = {};

    if (laboratory_id === null || laboratory_id === "") {
      localerrors = {
        ...localerrors,
        ...{ laboratory_id: "Veuillez choisir le laboratoire ... " },
      };
    }

    if (service === null || service === "") {
      localerrors = {
        ...localerrors,
        ...{ service: "Veuillez choisir la nature du travail ... " },
      };
    }

    if (
      elements_numbers < 1 ||
      elements_numbers > 16 ||
      elements_numbers === ""
    ) {
      localerrors = {
        ...localerrors,
        ...{ elements_numbers: "Veuillez entrer le nombre d'éléments ... " },
      };
    }

    if (shade === "") {
      localerrors = {
        ...localerrors,
        ...{ shade: "Veuillez choisir la teinte principale ... " },
      };
    }

    //console.log("hello localerrors" , localerrors)
    setErrors(localerrors);
    //console.log("errors" , errors)

    if (Object.keys(localerrors).length > 0) status = false;
    //console.log("inside form validation" ,Object.keys(localerrors).length ,status)
    return status;
  }
  const chooseImageToShow = (file) => {
    setImage(file);
    setShowImageModal(true);
  };
  const downloadImage = async (id) => {
    // console.log("download file", id);

    fetch(
      `https://api.easylab.tn/global/download_files/${id}/attachments_file`,
      {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    ).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "file.png";
        alink.click();
      });
    });
  };

  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    getLaboratories();
    getGroupes();
    getShades();
  }, [update]);

  const deleteFile = async (workId, FileId) => {
    // console.log("liste des fichiers ===>", localFiles, FileId);

    try {
      const response = await FileService.delete(workId, FileId);
      if (response.status === 200) {
        let newFiles = localFiles.filter(function (item) {
          return item.id !== FileId;
        });
        // console.log("liste des fichiers ===>", newFiles, FileId);
        setLocalFiles(newFiles);
        setAttachments_files(newFiles);
        NotificationManager.success(response.data.message, "Notification");

        setPopupModal(false);
        setUpdate((prev) => prev + 1);
      }
    } catch (err) {
      NotificationManager.error(err?.response?.data?.message, "Notification");
    }
  };

  const chooseFile = async (e) => {
    e.preventDefault();
    if (e.target.files[0] == file) {
      NotificationManager.error(
        "Vous avez déjà téléchargé une photo avec le même nom. Verifiez SVP",
        "Notification"
      );
    } else {
      try {
        setFile(e.target.files[0]);
        setDisabledUploadImage(false);
      } catch (err) {}
    }
  };

  const uploadFile = async () => {
    setLoadingImage(true);
    try {
      const data = new FormData();
      data.append("attachments_file", file);
      const response = await FileService.uploadImage(data);
      if (response.status === 200) {
        const lfiles = localFiles;
        const files = attachments_files;
        files.push(response.data.data?.id);
        lfiles.push(response.data.data);
        setLocalFiles(lfiles);
        setAttachments_files(files);
        NotificationManager.success(
          "Image téléchargée avec succès",
          "Notification"
        );
        // console.log(lfiles);
        setUpdate((prev) => prev + 1);
        setLoadingImage(false);
        setDisabledUploadImage(true);
      }
    } catch (err) {
      // console.log(err);
      NotificationManager.error(err.response?.data?.message, "Notification");
      setLoadingImage(false);
      setDisabledUploadImage(true);
    }
  };

  const chooseExtraFile = async (e) => {
    e.preventDefault();
    if (e.target.files[0] == empreinte) {
      NotificationManager.error(
        "Vous avez déjà téléchargé une empreinte avec le même nom. Verifiez SVP",
        "Notification"
      );
    } else {
      try {
        setEmpreinte(e.target.files[0]);
        setDisabledUploadEmpreint(false);
      } catch (err) {}
    }
  };

  const uploadExtraFile = async (e) => {
    setLoadingEmpreinte(true);
    try {
      const data = new FormData();
      data.append("file_extra_finger_print", empreinte);
      const response = await FileService.uploadExtraFile(data);
      if (response.status === 200) {
        //const lfiles = localFiles;
        const files = files_extra_finger_print;
        files.push(response.data.data?.id);
        //lfiles.push(response.data.data);
        //setLocalFiles(lfiles);
        setFiles_extra_finger_print(files);
        NotificationManager.success(
          "Empreinte téléchargée avec succès",
          "Notification"
        );
        setEmpreinte("");
        //console.log(lfiles);
        setUpdate((prev) => prev + 1);
        setLoadingEmpreinte(false);
        setDisabledUploadEmpreint(true);
      }
    } catch (err) {
      // console.log(err);
      NotificationManager.error(err.response?.data?.message, "Notification");
      setLoadingImage(false);
      setDisabledUploadEmpreint(true);
    }
  };

  const getShades = () => {
    axiosInstance
      .get(`${APIURL}/global/settings/shades`)
      .then((res) => {
        setShades(res.data.data);
        // console.log(res.data);
        //console.log(dentists)
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const getLaboratories = () => {
    axiosInstance
      .get(`${APIURL}/dentist/laboratories/all`)
      .then((res) => {
        setLaboratories(res.data.data);
        // console.log(res.data);
        //console.log(dentists)
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const getGroupes = (id) => {
    // console.log(id);
    axiosInstance
      .get(`${APIURL}/global/settings/services/${id}`)
      .then((res) => {
        // console.log(res.data.data);
        setGroupeData(res.data.data);
        // setGroupes(res.data.data.list)
        if (res.data.data) {
          let s_groupes = [];
          res.data.data.forEach((G) => {
            s_groupes.push({ label: G.name, value: G.id });
          });
          setGroupes(s_groupes);
        }
      })
      .catch((err) => {
        // console.log(err);
      });

    //      }
  };

  // const addWork = (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   //         setLoading(true);
  //   setErrors({});
  //   if (!formValidation()) {
  //    // console.log("if condition");
  //     setLoading(false);
  //     return false;
  //   } else {
  //     setLoading(true);
  //    // console.log("attachment files ", attachments_files);
  //     const newWork = {
  //       attachments_files_ids: attachments_files,
  //       files_extra_finger_print_ids: files_extra_finger_print,
  //       dentist_id: dentist_id,
  //       service_id: service_id,
  //       full_name_patient: full_name_patient,
  //       comment: comment,
  //       sex_patient: sex_patient,
  //       age_patient: age_patient,
  //       elements_numbers: elements_numbers,
  //       forseen_delivery_date: forseen_delivery_date,
  //       is_urgent: is_urgent,
  //       shade: shade,
  //       accessible_id: null,
  //       real_reference: real_reference,
  //     };

  //    // console.log("all data in work", newWork);
  //     axiosInstance
  //       .post(`${APIURL}/global/works`, newWork)
  //       .then((res) => {
  //         // NotificationManager.success(
  //         //   "Travail ajouté avec succès",
  //         //   "Notification"
  //         // );
  //         navigate("/laboratory/works");
  //        // console.log(res);
  //         setLoading(false);
  //       })
  //       .catch((err) => {
  //        // console.log(err);
  //         NotificationManager.error(err.response.data.message, "Notification");
  //         setLoading(false);
  //       });
  //   }
  // };
  const addWork = async (e) => {
    e.preventDefault();
    setLoading(true);
    //setLoading(true);
    setErrors({});
    if (!formValidation()) {
      setLoading(false);
      return false;
    } else {
      setLoading(true);

      const newWork = {
        attachments_files_ids: attachments_files,
        files_extra_finger_print_ids: files_extra_finger_print,
        laboratory_id: laboratory_id,
        service_id: service?.value,
        full_name_patient: full_name_patient,
        comment: comment,
        sex_patient: sex_patient,
        age_patient: parseInt(age_patient),
        elements_numbers: elements_numbers,
        requested_delivery_date: requested_delivery_date,
        is_urgent: is_urgent,
        shade: shade,
        accessible_id: null,
      };
      try {
        const response = await WorkService.add(newWork);
        if (response.status === 200) {
          //NotificationManager.success(response.data.message, "Notification");
          navigate("/dentist/works");
          setLoading(false);
        }
      } catch (err) {
        // console.log(err);
        NotificationManager.error(err.response.data.message, "Notification");
        setLoading(false);
      }
    }
  };

  const chooseGroupe = (e) => {
    setGroupe(e);
    groupeData.forEach((g) => {
      if (g.id == e.value) {
        // console.log(g.sub_groups);
        let s_groupes = [];
        g.sub_groups.forEach((subG) => {
          s_groupes.push({ label: subG.name, value: subG.id });
        });
        setSGroupeData(g.sub_groups);
        setSubgroupes(s_groupes);
      }
    });
    // console.log(subgroupes);
    if (sgroupeRef.current) {
      sgroupeRef.current.focus();
    }
  };

  const chooseSubGroupe = (e) => {
    //console.log(e)
    setSub_groupe(e);
    sgroupeData.forEach((sg) => {
      if (sg.id == e.value) {
        // console.log(sg.services);
        let services = [];
        sg.services.forEach((subG) => {
          services.push({ label: subG.name, value: subG.id });
        });
        setServicesData(sg.services);
        setServices(services);
      }
    });
    // console.log(services);
    if (servicesRef.current) {
      servicesRef.current.focus();
    }
    //console.log(e.value)
  };
  const chooseService = (e) => {
    setService(e);
    // console.log("choose service ", e);
    //setSub_groupe(e)
    //console.log(e.value)
  };

  const Popupdelete = (id) => {
    setIdFileDeleted(id);
    setPopupModal(true);
  };
  return (
    <>
      <Preloader />
      <Sidebar user={user} />

      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar
          user={user}
          pageName={"Ajouter un travail"}
          icon={faPlusCircle}
        />
        <hr />
        <div>
          <div className="d-flex justify-content-end mb-2">
            <Button
              variant={"main"}
              onClick={() => navigate(PagesRoutes.DentistWorks.path)}
            >
              <FontAwesomeIcon icon={faList} /> voir la liste
            </Button>
          </div>
          <NotificationContainer />
          <div>
            <Form onSubmit={addWork}>
              <FormGroup className={"mb-3"}>
                <FormLabel> Laboratoire </FormLabel>
                <select
                  value={laboratory_id}
                  onChange={(e) => {
                    setLaboratory_id(e.target.value);
                    getGroupes(e.target.value);
                  }}
                  className={"form-control"}
                >
                  <option> == Choisir votre laboratoire == </option>
                  {laboratories.length > 0 &&
                    laboratories.map((l) => (
                      <option value={l.id}>
                        {" "}
                        {l.last_name + " " + l.first_name}
                      </option>
                    ))}
                </select>
              </FormGroup>
              {errors?.laboratory_id && (
                <span
                  className="text-danger mt-3 fw-bold"
                  style={{ fontSize: "14px" }}
                >
                  {" "}
                  * {errors?.laboratory_id}{" "}
                </span>
              )}
              <Row className={"mb-3"}>
                <Col>
                  <FormGroup>
                    <FormLabel> Groupe </FormLabel>
                    <Select
                      value={groupe}
                      onChange={chooseGroupe}
                      options={groupes}
                    ></Select>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <FormLabel> Sous Groupe </FormLabel>
                    <Select
                      openMenuOnFocus={true}
                      ref={sgroupeRef}
                      value={sub_groupe}
                      options={subgroupes}
                      onChange={chooseSubGroupe}
                    ></Select>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <FormLabel> Services </FormLabel>
                    <Select
                      openMenuOnFocus={true}
                      ref={servicesRef}
                      value={service}
                      onChange={chooseService}
                      options={services}
                    ></Select>
                    {errors?.service && (
                      <span
                        className="text-danger mt-3 fw-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {" "}
                        * {errors?.service}{" "}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row className={"mb-3"}>
                <Col>
                  <FormGroup>
                    <FormLabel> Nombre d'élements </FormLabel>
                    <FormControl
                      value={elements_numbers}
                      type={"number"}
                      min={"1"}
                      max={"16"}
                      onChange={(e) => setElements_numbers(e.target.value)}
                    ></FormControl>
                    {errors?.elements_numbers && (
                      <span
                        className="text-danger mt-3 fw-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {" "}
                        * {errors?.elements_numbers}{" "}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <FormLabel> Teinte </FormLabel>
                    <CustomAutoSuggest
                      type={"text"}
                      value={shade}
                      onChange={(e) => setShade(e.target.value)}
                      changeValue={(newV) => {
                        setShade(newV);
                        // console.log("shade", shade);
                      }}
                      suggestions={shades}
                    />
                    {errors?.shade && (
                      <span
                        className="text-danger mt-3 fw-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {" "}
                        * {errors?.shade}{" "}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <FormLabel> Date de livraison sohaitée </FormLabel>
                    <FormControl
                      type={"date"}
                      value={requested_delivery_date}
                      onChange={(e) =>
                        setRequested_delivery_date(e.target.value)
                      }
                    ></FormControl>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Form.Label> Urgence </Form.Label>
                    <div className={"d-flex"}>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={is_urgent}
                        onChange={() => setIs_urgent((prev) => !prev)}
                        // label="Changer status"
                      />
                      Urgent ?
                    </div>
                  </FormGroup>
                </Col>
              </Row>

              <Row className={"mb-3"}>
                <Col>
                  <FormGroup>
                    <FormLabel> Empreintes digitales </FormLabel>
                    <FormControl
                      className={"mb-2"}
                      type={"file"}
                      onChange={(e) => chooseExtraFile(e)}
                    ></FormControl>
                  </FormGroup>
                  <Button
                    disabled={disabledUploadEmpreint}
                    onClick={(e) => uploadExtraFile(e)}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faUpload} />{" "}
                    {loadingEmpreinte ? (
                      <Spinner animation="border" />
                    ) : (
                      `Télécharger`
                    )}
                  </Button>
                  <div className="pt-3">
                    {files_extra_finger_print &&
                    files_extra_finger_print.length > 0 ? (
                      <>
                        <h6>Liste des fichiers</h6>
                        <hr />
                        <div className="d-flex">
                          {files_extra_finger_print.map((file, index) =>
                            index < 3 ? (
                              <div className="w-50 border border-1 border-success me-1 p-1">
                                <div className="d-flex justify-content-between">
                                  <span> Empreinte {index + 1} </span>
                                </div>
                              </div>
                            ) : (
                              ""
                            )
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                <Col>
                  <FormGroup>
                    <FormLabel> Photos </FormLabel>{" "}
                    <span className={"text-muted"}></span>
                    <FormControl
                      className={"mb-2"}
                      type={"file"}
                      onChange={(e) => chooseFile(e)}
                    ></FormControl>
                    <Button
                      disabled={disabledUploadImage}
                      onClick={(e) => uploadFile()}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faUpload} />{" "}
                      {loadingImage ? (
                        <Spinner animation="border" />
                      ) : (
                        `Télécharger`
                      )}
                    </Button>
                  </FormGroup>
                  <div className="pt-3">
                    {localFiles && localFiles.length > 0 ? (
                      <>
                        <h6>Liste des fichiers</h6>
                        <hr />
                        <div className="d-flex">
                          {localFiles.map((file, index) =>
                            index < 3 ? (
                              <div className="border border-1 me-1 p-1">
                                <div className="d-flex justify-content-between">
                                  <span> Fichier {index + 1} </span>
                                  <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    onClick={() => Popupdelete(file.id)}
                                  />
                                </div>

                                <div>
                                  <img
                                    src={`${file.full_path}`}
                                    width={"100"}
                                    onClick={() => {
                                      chooseImageToShow(file);
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>

              <Row className={"mb-3"}>
                <FormGroup>
                  <FormLabel> Comment </FormLabel>
                  <FormControl
                    as={"textarea"}
                    placeholder={"votre commentaire ici ..."}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  ></FormControl>
                </FormGroup>
              </Row>

              <Row className={"mb-3"}>
                <Col sm={"6"}>
                  <FormGroup>
                    <FormLabel> Nom prénom patient </FormLabel>
                    <FormControl
                      type={"text"}
                      placeholder={"foulen ben foulen ..."}
                      value={full_name_patient}
                      onChange={(e) => setFull_name_patient(e.target.value)}
                    ></FormControl>
                  </FormGroup>
                </Col>
                <Col sm={"4"}>
                  <FormGroup>
                    <FormLabel> </FormLabel>

                    {sex_patient === "women" ? (
                      <Row>
                        <Col>
                          <Nav fill variant="pills" className="d-flex">
                            <Nav.Item>
                              <Nav.Link
                                onClick={() => setSex_patient("women")}
                                className="mb-sm-3 mb-md-0 bg-main text-white"
                              >
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  className="text-white me-2"
                                />
                                <span>Femme</span>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item onClick={() => setSex_patient("man")}>
                              <Nav.Link
                                title={"Clique ici pour changer le sex"}
                                className="border border-dark mb-sm-3 mb-md-0"
                              >
                                Homme
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                      </Row>
                    ) : sex_patient === "man" ? (
                      <Row>
                        <Col>
                          <Nav fill variant="pills" className="d-flex">
                            <Nav.Item>
                              <Nav.Link
                                title={"Clique ici pour changer le sex"}
                                onClick={() => setSex_patient("women")}
                                className="border border-dark mb-sm-3 mb-md-0"
                              >
                                <span>Femme</span>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item onClick={() => setSex_patient("man")}>
                              <Nav.Link className="mb-sm-3 mb-md-0  bg-main text-white">
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  className="text-white me-2"
                                />
                                <span>Homme</span>
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col>
                          <Nav fill variant="pills" className="d-flex">
                            <Nav.Item>
                              <Nav.Link
                                title={"Clique ici pour changer le sex"}
                                onClick={() => setSex_patient("women")}
                                className="border border-dark mb-sm-3 mb-md-0"
                              >
                                <span>Femme</span>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item onClick={() => setSex_patient("man")}>
                              <Nav.Link className="border border-dark mb-sm-3 mb-md-0">
                                <span>Homme</span>
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                      </Row>
                    )}
                  </FormGroup>
                </Col>
                <Col sm={"2"}>
                  <FormGroup>
                    <FormLabel> Age du patient </FormLabel>
                    <FormControl
                      min={0}
                      max={120}
                      type={"number"}
                      value={age_patient}
                      onChange={(e) => setAge_patient(e.target.value)}
                    ></FormControl>
                  </FormGroup>
                </Col>
              </Row>

              <div className={"d-flex justify-content-end my-3"}>
                <Button variant={"main"} type={"submit"}>
                  <FontAwesomeIcon icon={faSave} className={"me-2"} />
                  Sauvegarder{" "}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </main>

      {image && image.id ? (
        <Modal
          as={Modal.Dialog}
          centered
          show={showImageModal}
          onHide={handleClose}
          size={"lg"}
        >
          <Modal.Header>
            <Modal.Title className="h6">
              <FontAwesomeIcon icon={faEye} /> Afficher Image{" "}
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <img src={image.full_path} />
            </div>
          </Modal.Body>
          <ModalFooter>
            <Button
              type="button"
              variant="success"
              onClick={() => downloadImage(image.id)}
            >
              <FontAwesomeIcon icon={faDownload} /> Telecharger{" "}
            </Button>
          </ModalFooter>
        </Modal>
      ) : (
        ""
      )}

      <Modal
        as={Modal.Dialog}
        centered
        show={popupModal}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faEye} /> Alert de suppression du fichier{" "}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className={"text-center"}>
            <h2>
              {" "}
              <FontAwesomeIcon icon={faExclamationTriangle} /> ATTENTION !{" "}
            </h2>
            Voulez-vous vraiment supprimer ce fichier !
          </div>
        </Modal.Body>
        <ModalFooter>
          <Button variant={"success"} onClick={handleClose}>
            Annuler
          </Button>

          <Button
            variant={"danger"}
            onClick={() => deleteFile(null, idFileDeleted)}
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DentistWorksAdd;
