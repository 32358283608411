import { axiosInstance } from "../../config/axios";
import { APIURL } from "../../configApi";

const FileService = {};
FileService.uploadImage = (data, work_id) => {
  let uri = "";
  if (work_id) {
    uri = `${APIURL}/global/upload_files/pictures?work_id=${work_id}`;
  } else {
    uri = `${APIURL}/global/upload_files/pictures`;
  }
  return axiosInstance.post(uri, data);
};

FileService.uploadExtraFile = (data) => {
  return axiosInstance.post(`${APIURL}/global/upload_files/extra_files`, data);
};

FileService.delete = (work_id, fileId, type) => {
  let uri = "";

  if (work_id) {
    uri = `${APIURL}/global/works/files/attachments_files/${fileId}?work_id=${work_id}`;
  } else {
    uri = `${APIURL}/global/works/files/attachments_files/${fileId}`;
  }

  return axiosInstance.delete(uri);
};

export default FileService;
