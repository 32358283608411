import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Dropdown,
  Form,
  Modal,
  OverlayTrigger,
  Spinner,
  Table,
  Tooltip,
} from "@themesberg/react-bootstrap";

import Paginator from "../Paginator";
import {
  faArchive,
  faBriefcaseMedical,
  faEllipsisV,
  faEnvelope,
  faEye,
  faFileAlt,
  faImage,
  faMoneyBill,
  faPaperclip,
  faPeopleCarry,
  faPlusCircle,
  faShare,
  faSortDown,
  faSortUp,
  faTrash,
  faTruck,
  faTruckPickup,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { config, currentUser } from "../../../../authentication/auth";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../../config/axios";
import { APIURL } from "../../../../configApi";
import CheckBox from "../CheckBox";
import moment from "moment/moment";
import "moment/locale/fr";

import { NotificationManager } from "react-notifications";
import LaboratoryService from "../../../services/LaboratoireService";
import WorkService from "../../../services/WorkService";
import DentistService from "../../../services/DentistService";
//import moment from "moment/moment";

moment.locale("fr");

const Works = (props) => {
  const navigate = useNavigate();

  const [agent_id, setAgent_id] = useState();
  const [showDefault, setShowDefault] = useState(false);
  const [showDefaultMultiple, setShowDefaultMultiple] = useState(false);
  const [showDefault1Multiple, setShowDefault1Multiple] = useState(false);

  const handleClose = () => setShowDefault(false);
  const [agents, setAgents] = useState([]);

  const [courier_id, setCourier_id] = useState();
  const [showDefault1, setShowDefault1] = useState(false);
  const handleClose1 = () => {
    setShowDefault1(false);
    setShowDefaultMultiple(false);
    setShowDefault1Multiple(false);
  };
  const [couriers, setCouriers] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedWork, setSelectedWork] = useState();
  const [user, setUser] = useState(null);

  const [loading, setLoading] = useState(false);

  const {
    pages,
    currentStatus,
    works,
    refreshWorks,
    updatedRows,
    updateWorks,
  } = props;
  const [selectedWorks, setSelectedWorks] = useState([]);
  const [update, setUpdate] = useState(0);
  const globalAction = async (data) => {
    // console.log("global action data", data);
    try {
      const res = await WorkService.changeStatusMultiple(data);
      if (res.status === 200) {
        NotificationManager.success("Action mutiple", "Travaux");
        refreshWorks(currentStatus);
        setSelectedWorks([]);
        updatedRows();
      }
      // console.log("global action", res);
    } catch (e) {
      // console.log(e);
    }
  };
  useEffect(() => {
    //refreshWorks(currentStatus)
    //console.log("status , pages ====>", currentStatus, pages);
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    //getWorks()
    getAgents();
    getCouries();
  }, []);
  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
  }, [update]);
  const archiver = async (WorkId) => {
    try {
      const res = await WorkService.archiver(WorkId);
      if (res.status === 200) {
        NotificationManager.success("Travail archiver", "Travaux");
      }
    } catch (e) {
      // console.log(e);
    }
  };
  const getAgents = async () => {
    setLoading(true);

    try {
      const response = await LaboratoryService.agentsAll();
      if (response.status === 200) {
        setLoading(false);
        setAgents(response.data.data);
      }
    } catch (err) {
      setLoading(false);
      // console.log(err);
    }
  };
  const payeThisWork = async (id) => {
    try {
      const response = await LaboratoryService.facturer(id);
      if (response.status === 200) {
        NotificationManager.success(response.data.message, "Notification");
        refreshWorks(currentStatus);
      }
    } catch (err) {
      NotificationManager.error(err?.response?.data?.message, "Notification");
    }
  };
  const getCouries = async () => {
    setLoading(true);

    try {
      const response = await LaboratoryService.couriersAll();
      if (response.status === 200) {
        setLoading(false);
        setCouriers(response.data.data);
      }
    } catch (err) {
      setLoading(false);
      // console.log(err);
    }
  };

  const assigneToAgent = async (e) => {
    e.preventDefault();

    try {
      const response = await WorkService.assignToAgent(selectedWork, {
        accessible_id: agent_id,
      });
      if (response.status === 200) {
        NotificationManager.success(
          "Travail affecte a un agent",
          "Notification"
        );
        refreshWorks(currentStatus);
      }
    } catch (err) {
      NotificationManager.error(err?.response?.data?.message, "Notification");
    }

    setShowDefault(false);
  };

  const assigneToCourier = async (e) => {
    e.preventDefault();
    if (currentStatus == "at_laboratory") {
      try {
        const response = await WorkService.changeStatus(selectedWork, {
          step: "send_laboratory",
          courier_id: courier_id,
        });
        if (response.status === 200) {
          NotificationManager.success(
            "Travail envoyée vers le dentiste",
            "Notification"
          );
          refreshWorks(currentStatus);
          updatedRows();
        }
      } catch (err) {
        NotificationManager.error(err?.response?.data?.message, "Notification");
      }
      setShowDefault1(false);
      //console.log(currentStatus)
    } else {
      try {
        const response = await WorkService.assignToCourier(selectedWork, {
          courier_id: courier_id,
        });
        if (response.status === 200) {
          NotificationManager.success(
            "Travail affecté au coursier",
            "Notification"
          );
          refreshWorks(currentStatus);
        }
      } catch (err) {
        NotificationManager.error(err?.response?.data?.message, "Notification");
      }

      setShowDefault1(false);
      //console.log(currentStatus)
    }
  };

  const recueAuLabo = async (workId) => {
    try {
      const response = await WorkService.recieveLaboratory(workId);
      if (response.status === 200) {
        NotificationManager.success(
          "Travail bien reçu au laboratoire",
          "Notification"
        );
        refreshWorks(currentStatus);
        updatedRows();
      }
    } catch (err) {
      NotificationManager.error(err?.response?.data?.message, "Notification");
    }
  };

  const updateSelectedWorks = (workId, oper) => {
    let selectedworks = selectedWorks;
    if (oper === "+") {
      selectedWorks.push(workId);
    } else if (oper === "-") {
      selectedworks.splice(selectedworks.indexOf(workId), 1);
    }
    setSelectedWorks(selectedworks);
    setUpdate((prev) => prev + 1);

    // console.log("select check", selectedWorks);
  };

  const checkWork = (e) => {
    // console.log(e.target.value);
  };

  const sendToLabo = (workId) => {
    axiosInstance
      .put(`${APIURL}/global/works/${workId}/change_status`, {
        step: "send_dentist",
      })
      .then((res) => {
        // console.log("outgoing_dentist", res);
        NotificationManager.success(
          "Notification envoyée au laboratoire",
          "Notification"
        );
        refreshWorks(currentStatus);
      })
      .catch((err) => {
        // console.log("erreur", err);
        NotificationManager.error("Une erreur s'est produite", "Notification");
      });
  };

  const recueAuDentist = async (workId) => {
    try {
      const response = await DentistService.recievedDentist(workId, {
        step: "received_dentist",
      });

      if (response.status === 200) {
        NotificationManager.success(
          "Travail bien reçu au deniste",
          "Notification"
        );
        refreshWorks(currentStatus);
        updatedRows();
      }
    } catch (err) {
      // console.log("erreur", err);
      NotificationManager.error(err?.response?.data?.message, "Notification");
    }
  };

  const renderDropdown = () => {
    switch (currentStatus) {
      case "at_laboratory":
        return (
          <>
            <Dropdown drop={faEllipsisV} className="me-2 mb-2">
              <Dropdown.Toggle split variant="default">
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  className="dropdown-arrow"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu
                className={"user-dropdown dropdown-menu-start mt-2"}
              >
                <Dropdown.Item
                  className={"text-primary"}
                  onClick={() => setShowDefaultMultiple(true)}
                >
                  <FontAwesomeIcon
                    icon={faBriefcaseMedical}
                    className="dropdown-arrow me-2 text-lowercase"
                  />{" "}
                  Assigner à un Agent{" "}
                </Dropdown.Item>
                <Dropdown.Item
                  className={"text-success"}
                  onClick={
                    () => setShowDefault1Multiple(true)
                    // globalAction({
                    //   works_ids: selectedWorks,
                    //   action: "send_laboratory",
                    // })
                  }
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="dropdown-arrow me-2"
                  />{" "}
                  Prêt pour envoi{" "}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      case "at_dentist":
        return (
          <>
            <Dropdown
              drop={faEllipsisV}
              className="me-2 mb-2"
              style={{ textTransform: "capitalize" }}
            >
              <Dropdown.Toggle split variant="default">
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  className="dropdown-arrow"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu
                className={"user-dropdown dropdown-menu-right mt-2"}
              >
                <Dropdown.Item
                  className="text-muted"
                  onClick={() =>
                    globalAction({
                      works_ids: selectedWorks,
                      action: "send_dentist",
                    })
                  }
                >
                  <FontAwesomeIcon
                    icon={faShare}
                    className="dropdown-arrow me-2"
                  />{" "}
                  A recevoir depuis dentiste
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      case "outgoing_laboratory":
        return (
          <>
            <Dropdown
              drop={faEllipsisV}
              className="me-2 mb-2"
              style={{ textTransform: "capitalize" }}
            >
              <Dropdown.Toggle split variant="default">
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  className="dropdown-arrow"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu
                className={"user-dropdown dropdown-menu-right mt-2"}
              >
                <Dropdown.Item
                  className={"text-primary"}
                  onClick={() => setShowDefault1Multiple(true)}
                >
                  <FontAwesomeIcon
                    icon={faPeopleCarry}
                    className="dropdown-arrow me-2"
                  />{" "}
                  Assigner à un Coursier{" "}
                </Dropdown.Item>
                <Dropdown.Item
                  className={"text-success"}
                  onClick={() =>
                    globalAction({
                      works_ids: selectedWorks,
                      action: "invoiced",
                    })
                  }
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="dropdown-arrow me-2"
                  />{" "}
                  Facturer{" "}
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-muted"
                  onClick={() =>
                    globalAction({
                      works_ids: selectedWorks,
                      action: "received_dentist",
                    })
                  }
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="dropdown-arrow me-2"
                  />{" "}
                  Bien reçu chez dentiste
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      case "outgoing_dentist":
        return (
          <>
            <Dropdown
              drop={faEllipsisV}
              className="me-2 mb-2"
              style={{ textTransform: "capitalize" }}
            >
              <Dropdown.Toggle split variant="default">
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  className="dropdown-arrow"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu
                className={"user-dropdown dropdown-menu-right mt-2"}
              >
                <Dropdown.Item
                  className={"text-primary"}
                  onClick={() => setShowDefault1Multiple(true)}
                >
                  <FontAwesomeIcon
                    icon={faPeopleCarry}
                    className="dropdown-arrow me-2"
                  />{" "}
                  Assigner à un Coursier{" "}
                </Dropdown.Item>
                <Dropdown.Item
                  className={"text-primary"}
                  onClick={() => setShowDefaultMultiple(true)}
                >
                  <FontAwesomeIcon
                    icon={faBriefcaseMedical}
                    className="dropdown-arrow me-2"
                  />{" "}
                  Assigner à un Agent{" "}
                </Dropdown.Item>
                <Dropdown.Item
                  className={"text-success"}
                  onClick={() =>
                    globalAction({
                      works_ids: selectedWorks,
                      action: "received_laboratory",
                    })
                  }
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="dropdown-arrow me-2"
                  />{" "}
                  Bien reçu au labo
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );

      default:
        return <h1>No project match</h1>;
    }
  };
  return (
    <>
      <Card border="light" className="shadow-sm">
        {loading ? (
          <div className="text-center">
            <Spinner animation={"border"} className="my-4" />
          </div>
        ) : (
          <>
            <Table
              responsive
              hover={true}
              className="align-items-center table-flush"
            >
              <thead className="thead-light">
                <tr>
                  <th className="d-flex align-items-center justifity-content-between">
                    {selectedWorks && selectedWorks.length > 0
                      ? renderDropdown()
                      : ""}
                    Référence{" "}
                  </th>
                  <th>
                    <div className={"d-flex align-items-center"}>
                      Dentiste{" "}
                      <div
                        className={
                          "ms-2 d-flex flex-column align-items-center "
                        }
                      >
                        <FontAwesomeIcon
                          className={"fa-md"}
                          style={{ marginBottom: "-10px" }}
                          icon={faSortUp}
                        />
                        <FontAwesomeIcon
                          className={"fa-md"}
                          icon={faSortDown}
                        />
                      </div>
                    </div>
                  </th>

                  <th> Travail </th>

                  <th>
                    {" "}
                    <FontAwesomeIcon icon={faPaperclip} />{" "}
                  </th>
                  <th>
                    {" "}
                    {["outgoing_laboratory", "outgoing_dentist"].includes(
                      currentStatus
                    )
                      ? "Coursier"
                      : "Agent"}{" "}
                  </th>

                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {works.map((work, index) => {
                  return (
                    <tr
                      key={index}
                      style={{
                        backgroundColor:
                          selectedWorks && selectedWorks.includes(work.id)
                            ? "#eaedf2"
                            : work.is_urgent
                            ? "#f6a40921"
                            : work.is_delivery_date_requested
                            ? "rgba(14, 174, 186 , .19)"
                            : "transparent",
                      }}
                    >
                      <td>
                        <div className="d-flex gap-2 align-items-center">
                          <CheckBox
                            workId={work.id}
                            updateSelectedWorks={updateSelectedWorks}
                          />

                          <OverlayTrigger
                            placement="right"
                            trigger={["hover", "focus"]}
                            overlay={
                              <Tooltip>
                                {`Date de creation : ` +
                                  moment.unix(work.created_at).format("DD-MMM")}
                                <br />
                                {work.comment}
                              </Tooltip>
                            }
                          >
                            <a
                              className={"text-primary"}
                              onClick={() =>
                                navigate(`/laboratory/works/show/${work.id}`)
                              }
                            >
                              {work.reference_work}{" "}
                            </a>
                          </OverlayTrigger>
                        </div>
                      </td>
                      {/*<td> {work.requested_delivery_date} </td>*/}
                      {/*<td> {work.service.name} </td>*/}
                      <td>
                        {work.dentist.role === "fake_dentist" ? (
                          <strong>
                            {" "}
                            {work.dentist.first_name +
                              " " +
                              work.dentist.last_name +
                              "(" +
                              work.denist?.city?.name +
                              ")"}
                          </strong>
                        ) : (
                          <i>
                            <FontAwesomeIcon icon={faUser} />{" "}
                            {work.dentist.first_name +
                              " " +
                              work.dentist.last_name +
                              "(" +
                              work.denist?.city?.name +
                              ")"}
                          </i>
                        )}
                      </td>
                      <td>
                        {" "}
                        {work.service.name} ( {work.elements_numbers} )
                        {` ` + work.shade &&
                          work.shade != "SansTeinte" &&
                          work.shade}
                      </td>

                      <td>
                        {" "}
                        {work.attachments_files.length > 0 && (
                          <div>
                            <FontAwesomeIcon
                              className={"fa-md"}
                              icon={faImage}
                            />{" "}
                            ( {work.attachments_files.length} )
                          </div>
                        )}
                        {work.files_extra_finger_print.length > 0 && (
                          <div>
                            <FontAwesomeIcon
                              className={"fa-md"}
                              icon={faFileAlt}
                            />{" "}
                            ( {work.files_extra_finger_print.length} )
                          </div>
                        )}
                        {work.attachments_files.length == 0 &&
                          work.files_extra_finger_print.length == 0 &&
                          "vide"}
                      </td>
                      <td>
                        {" "}
                        {["outgoing_laboratory", "outgoing_dentist"].includes(
                          currentStatus
                        )
                          ? work.courier &&
                            work.courier?.last_name +
                              " " +
                              work.courier?.first_name
                          : work.accessible &&
                            work.accessible?.last_name +
                              " " +
                              work.accessible?.first_name}
                      </td>
                      <td>
                        {selectedWorks && !selectedWorks.includes(work.id) ? (
                          <Dropdown drop={faEllipsisV} className="me-2 mb-2">
                            <Dropdown.Toggle split variant="default">
                              <FontAwesomeIcon
                                icon={faEllipsisV}
                                className="dropdown-arrow"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              className={
                                "user-dropdown dropdown-menu-right mt-2"
                              }
                            >
                              <Dropdown.Item
                                className={"text-primary"}
                                onClick={() =>
                                  navigate("/laboratory/works/show/" + work.id)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faEye}
                                  className="dropdown-arrow me-2"
                                />{" "}
                                Afficher{" "}
                              </Dropdown.Item>

                              {props.currentStatus === "outgoing_dentist" ? (
                                <>
                                  <Dropdown.Item
                                    className={"text-primary"}
                                    onClick={() => {
                                      setSelectedWork(work.id);
                                      setShowDefault1(true);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faPeopleCarry}
                                      className="dropdown-arrow me-2"
                                    />
                                    Assigner à un Coursier{" "}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className={"text-primary"}
                                    onClick={() => {
                                      setSelectedWork(work.id);
                                      setShowDefault(true);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faBriefcaseMedical}
                                      className="dropdown-arrow me-2"
                                    />
                                    Assigner à un Agent{" "}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className={"text-success"}
                                    onClick={() => recueAuLabo(work.id)}
                                  >
                                    <FontAwesomeIcon
                                      icon={faEnvelope}
                                      className="dropdown-arrow me-2"
                                    />{" "}
                                    Bien reçu au labo
                                  </Dropdown.Item>
                                </>
                              ) : props.currentStatus === "at_laboratory" ? (
                                <>
                                  <Dropdown.Item
                                    className={"text-primary"}
                                    onClick={() => {
                                      setSelectedWork(work.id);
                                      setShowDefault(true);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faBriefcaseMedical}
                                      className="dropdown-arrow me-2"
                                    />{" "}
                                    Assigner à un Agent{" "}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className={"text-success"}
                                    onClick={() => {
                                      setSelectedWork(work.id);
                                      setShowDefault1(true);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faEnvelope}
                                      className="dropdown-arrow me-2"
                                    />{" "}
                                    Prêt pour envoi{" "}
                                  </Dropdown.Item>
                                </>
                              ) : props.currentStatus ===
                                "outgoing_laboratory" ? (
                                <>
                                  <Dropdown.Item
                                    className={"text-primary"}
                                    onClick={() => {
                                      setSelectedWork(work.id);
                                      setShowDefault1(true);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faBriefcaseMedical}
                                      className="dropdown-arrow me-2"
                                    />
                                    Assigner à un coursier{" "}
                                  </Dropdown.Item>

                                  {work.dentist_type == "fake_dentist" && (
                                    <Dropdown.Item
                                      onClick={() => recueAuDentist(work.id)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faEnvelope}
                                        className="dropdown-arrow me-2"
                                      />{" "}
                                      Bien reçu chez dentiste{" "}
                                    </Dropdown.Item>
                                  )}

                                  {["to_be_invoiced", "invoiced"].includes(
                                    work.status_invoiced
                                  ) ? (
                                    ""
                                  ) : (
                                    <Dropdown.Item
                                      className={"text-success"}
                                      onClick={() => payeThisWork(work.id)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faEnvelope}
                                        className="dropdown-arrow me-2"
                                      />
                                      Facturer{" "}
                                    </Dropdown.Item>
                                  )}
                                </>
                              ) : (
                                work.dentist_type == "fake_dentist" && (
                                  <>
                                    <Dropdown.Item
                                      className="text-main"
                                      onClick={() => sendToLabo(work.id)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faShare}
                                        className="dropdown-arrow me-2"
                                      />{" "}
                                      Recevoir du dentiste{" "}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => archiver(work.id)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faArchive}
                                        className="dropdown-arrow me-2"
                                      />{" "}
                                      Archiver{" "}
                                    </Dropdown.Item>
                                  </>
                                )
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : (
                          <div className="my-5"></div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <div className="d-flex justify-content-center mt-5 pt-2">
              <Paginator
                loader={(value) => setLoading(value)}
                totalPages={pages}
                type={"works"}
                setWorks={updateWorks}
                status={currentStatus}
                updatePageSelected={(value) => setPage(value)}
                activatedItem={page}
              />
            </div>
          </>
        )}
      </Card>

      <Modal
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faMoneyBill} /> Associer ce travail à l'agent{" "}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {agents && agents.length > 0 ? (
            <Form onSubmit={assigneToAgent}>
              <div className={"mb-2"}>
                <label> Agent </label>
                <select
                  className="form-control"
                  value={agent_id}
                  onChange={(e) => setAgent_id(e.target.value)}
                >
                  <option value=""> == Choisir un agent == </option>
                  {agents.map((a) =>
                    a.is_active ? (
                      <option value={a.id}>
                        {a.last_name + " " + a.first_name}{" "}
                      </option>
                    ) : (
                      ""
                    )
                  )}
                </select>
              </div>

              <div className={"d-flex justify-content-end mb-2"}>
                <Button type={"submit"} variant={"main"}>
                  <FontAwesomeIcon icon={faPlusCircle} /> Assigner{" "}
                </Button>
              </div>
            </Form>
          ) : (
            <div>
              <p>
                {" "}
                Vous n'avez aucun agent actif dans votre liste. Ajouter un
                nouveau{" "}
              </p>
              <div className={"d-flex justify-content-center"}>
                <Button
                  variant={"main"}
                  onClick={() => navigate("/laboratory/agents/add")}
                >
                  Ajouter un agent
                </Button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        as={Modal.Dialog}
        centered
        show={showDefault1}
        onHide={handleClose1}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faTruck} /> Associer ce travail au coursier{" "}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose1} />
        </Modal.Header>
        <Modal.Body>
          {couriers && couriers.length > 0 ? (
            <Form onSubmit={assigneToCourier}>
              <div className={"mb-2"}>
                <label> Coursier </label>
                <select
                  className="form-control"
                  value={courier_id}
                  onChange={(e) => setCourier_id(e.target.value)}
                >
                  <option value=""> == Choisir un Coursier == </option>
                  {couriers.map((c) =>
                    c.is_active ? (
                      <option value={c.id}>
                        {" "}
                        {c.last_name + " " + c.first_name}{" "}
                      </option>
                    ) : (
                      ""
                    )
                  )}
                </select>
              </div>

              <div className={"d-flex justify-content-end mb-2"}>
                <Button type={"submit"} variant={"main"}>
                  <FontAwesomeIcon icon={faPlusCircle} /> Associer{" "}
                </Button>
              </div>
            </Form>
          ) : (
            <div>
              <p>
                {" "}
                Vous n'avez aucun coursier actif dans votre liste. Ajouter un
                nouveau{" "}
              </p>
              <div className={""}>
                {currentStatus === "at_laboratory" ? (
                  <Form onSubmit={assigneToCourier}>
                    <div className={"d-flex justify-content-between mb-2"}>
                      <Button
                        variant={"main-outline"}
                        onClick={() => navigate("/laboratory/coursiers/add")}
                      >
                        Ajouter un coursier
                      </Button>
                      <Button className="ms-2" type={"submit"} variant={"main"}>
                        <FontAwesomeIcon icon={faPlusCircle} /> Envoyer sans
                        coursier{" "}
                      </Button>
                    </div>
                  </Form>
                ) : (
                  <Button
                    variant={"main"}
                    onClick={() => navigate("/laboratory/coursiers/add")}
                  >
                    Ajouter un coursier
                  </Button>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* modal assigned to agent muliple choice */}

      <Modal
        as={Modal.Dialog}
        centered
        show={showDefaultMultiple}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faMoneyBill} /> Associer ces travaux à
            l'agent{" "}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {agents && agents.length > 0 ? (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                // console.log("agent selectionne ", agent_id);
                globalAction({
                  works_ids: selectedWorks,
                  agent_id: agent_id,
                  action: "assigned_to_agent",
                });
              }}
            >
              <div className={"mb-2"}>
                <label> Agent </label>
                <select
                  className="form-control"
                  value={agent_id}
                  onChange={(e) => setAgent_id(e.target.value)}
                >
                  <option value=""> == Choisir un agent == </option>
                  {agents.map((a) =>
                    a.is_active ? (
                      <option value={a.id}>
                        {a.last_name + " " + a.first_name}{" "}
                      </option>
                    ) : (
                      ""
                    )
                  )}
                </select>
              </div>

              <div className={"d-flex justify-content-end mb-2"}>
                <Button type={"submit"} variant={"main"}>
                  <FontAwesomeIcon icon={faPlusCircle} /> Assigner{" "}
                </Button>
              </div>
            </Form>
          ) : (
            <div>
              <p>
                {" "}
                Vous n'avez aucun agent actif dans votre liste. Ajouter un
                nouveau{" "}
              </p>
              <div className={"d-flex justify-content-center"}>
                <Button
                  variant={"main"}
                  onClick={() => navigate("/laboratory/agents/add")}
                >
                  Ajouter un agent
                </Button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/** modal assigned courier multiple choice   */}
      <Modal
        as={Modal.Dialog}
        centered
        show={showDefault1Multiple}
        onHide={handleClose1}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faTruck} /> Associer ces travaux au coursier{" "}
            {currentStatus === "at_laboratory" ? "et les envoyer" : ""}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose1} />
        </Modal.Header>
        <Modal.Body>
          {/* {couriers && couriers.length > 0 ? (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                globalAction({
                  works_ids: selectedWorks,
                  courier_id: courier_id,

                  action: "assigned_to_courier",
                });
              }}
            >
              <div className={"mb-2"}>
                <label> Coursier </label>
                <select
                  className="form-control"
                  value={courier_id}
                  onChange={(e) => setCourier_id(e.target.value)}
                >
                  <option value=""> == Choisir un Coursier == </option>
                  {couriers.map((c) =>
                    c.is_active ? (
                      <option value={c.id}>
                        {" "}
                        {c.last_name + " " + c.first_name}{" "}
                      </option>
                    ) : (
                      ""
                    )
                  )}
                </select>
              </div>

              <div className={"d-flex justify-content-end mb-2"}>
                <Button type={"submit"} variant={"main"}>
                  <FontAwesomeIcon icon={faPlusCircle} /> Associer{" "}
                </Button>
              </div>
            </Form>
          ) : (
            <div>
              <p>
                {" "}
                Vous n'avez aucun coursier actif dans votre liste. Ajouter un
                nouveau{" "}
              </p>
              <div className={"d-flex justify-content-center"}>
                <Button
                  variant={"main"}
                  onClick={() => navigate("/laboratory/coursiers/add")}
                >
                  Ajouter un coursier
                </Button>
              </div>
            </div>
          )} */}
          {couriers && couriers.length > 0 ? (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                globalAction({
                  works_ids: selectedWorks,
                  courier_id: courier_id,

                  action: "assigned_to_courier",
                });
              }}
            >
              <div className={"mb-2"}>
                <label> Coursier </label>
                <select
                  className="form-control"
                  value={courier_id}
                  onChange={(e) => setCourier_id(e.target.value)}
                >
                  <option value=""> == Choisir un Coursier == </option>
                  {couriers.map((c) =>
                    c.is_active ? (
                      <option value={c.id}>
                        {" "}
                        {c.last_name + " " + c.first_name}{" "}
                      </option>
                    ) : (
                      ""
                    )
                  )}
                </select>
              </div>

              <div className={"d-flex justify-content-end mb-2"}>
                <Button type={"submit"} variant={"main"}>
                  <FontAwesomeIcon icon={faPlusCircle} /> Associer{" "}
                </Button>
              </div>
            </Form>
          ) : (
            <div>
              <p>
                {" "}
                Vous n'avez aucun coursier actif dans votre liste. Ajouter un
                nouveau{" "}
              </p>
              <div className={""}>
                {currentStatus === "at_laboratory" ? (
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      globalAction({
                        works_ids: selectedWorks,
                        courier_id: null,

                        action: "assigned_to_courier",
                      });
                    }}
                  >
                    <div className={"d-flex justify-content-between mb-2"}>
                      <Button
                        variant={"main-outline"}
                        onClick={() => navigate("/laboratory/coursiers/add")}
                      >
                        Ajouter un coursier
                      </Button>
                      <Button className="ms-2" type={"submit"} variant={"main"}>
                        <FontAwesomeIcon icon={faPlusCircle} /> Envoyer sans
                        coursier{" "}
                      </Button>
                    </div>
                  </Form>
                ) : (
                  <div className="d-flex justify-content-center">
                    <Button
                      variant={"main"}
                      onClick={() => navigate("/laboratory/coursiers/add")}
                    >
                      Ajouter un coursier
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Works;
