import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
} from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { axiosInstance } from "../../../../config/axios";
import { APIURL } from "../../../../configApi";
import { NotificationManager } from "react-notifications";

const AddService = (props) => {
  const { refreshGroupeList, sub_group_id } = props;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    description: "",
    price: "",
  });

  const verifForm = () => {
    if (name.length < 1) {
      setErrors({ name: "Nom de service est obligatoire" });
      return false;
    }
    // if (description.length < 1) {
    //   setErrors({ description: "Description de service est obligatoire" });
    //   return false;
    // }
    if (price.length < 1) {
      setErrors({ price: "Prix de service est obligatoire" });
      return false;
    }
    return true;
  };
  const storeService = (e) => {
    e.preventDefault();
    if (verifForm()) {
      axiosInstance
        .post(`${APIURL}/laboratory/services`, {
          name: name,
          description: description,
          price: price,
          sub_group_id: sub_group_id,
        })
        .then((res) => {
          // console.log(res.data);
          NotificationManager.success(
            "Service ajouté avec succès",
            "Notification"
          );
          refreshGroupeList();
          setName("");
          setDescription("");
          setPrice("");
          setErrors({});
        })
        .catch((err) => {
          //console.log(err);
          setErrors({});
          NotificationManager.error(
            "Erreur lors de l'ajout d'un Service",
            "Notification"
          );
        });
    }
  };
  return (
    <Form onSubmit={storeService}>
      <FormGroup>
        <FormLabel> Nom du service </FormLabel>
        <FormControl
          type={"text"}
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={"Nom du service"}
          maxLength={50}
        ></FormControl>
        {errors && errors.name ? (
          <p className="text-danger">{errors?.name}</p>
        ) : (
          " "
        )}
      </FormGroup>
      <FormGroup>
        <FormLabel> Déscription du service </FormLabel>
        <FormControl
          as={"textarea"}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder={"Description du service"}
        ></FormControl>
        {errors && errors.description ? (
          <p className="text-danger">{errors?.description}</p>
        ) : (
          " "
        )}
      </FormGroup>
      <FormGroup>
        <FormLabel> Prix du service </FormLabel>
        <FormControl
          type={"number"}
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder={"Prix du service"}
        ></FormControl>
        {errors && errors.price ? (
          <p className="text-danger">{errors?.price}</p>
        ) : (
          " "
        )}
      </FormGroup>
      <FormGroup className={"d-flex justify-content-center"}>
        <Button variant={"success"} className={"mt-3"} type={"submit"}>
          {" "}
          Ajouter{" "}
        </Button>
      </FormGroup>
    </Form>
  );
};

export default AddService;
