import { Row, Col, Button, Card } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import Preloader from "../../Shared/Preloader/Preloader";
import Sidebar from "../../Shared/Sidebar";
import Navbar from "../../Shared/Navbar/Navbar";
import { currentUser } from "../../../authentication/auth";
import { useNavigate } from "react-router-dom";
import {
  faCheckCircle,
  faEnvelope,
  faFileWord,
  faHome,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import DentistService from "../../services/DentistService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WorkService from "../../services/WorkService";
import { PagesRoutes } from "../../../pages_routes";

const DashCourier = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [agents, setAgents] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [works, setWorks] = useState([]);
  const [outgoing_works, setOutWorks] = useState([]);
  const [ingoing_works, setInWorks] = useState([]);
  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    getInvitations();
    getWorks();
    getAgents();
    //getWorksByStatus("outgoing_dentist");
  }, []);

  const getInvitations = async () => {
    // try {
    //   const response = await DentistService.Invitations();
    //   if (response.status === 200) {
    //     NotificationManager.success(response.data.message, "Notification");
    //     setInvitations(response.data.data.list);
    //   }
    // } catch (err) {
    //  // console.log(err);
    //   NotificationManager.error(err?.response?.data?.message);
    // }
  };

  const getAgents = async () => {
    // try {
    //   const response = await DentistService.agentListe();
    //   if (response.status === 200) {
    //     NotificationManager.success(response.data.message, "Notification");
    //     setAgents(response.data.data);
    //   }
    // } catch (err) {
    //  // console.log(err);
    //   NotificationManager.error(err?.response?.data?.message);
    // }
  };

  const getWorks = async () => {
    // try {
    //   const response = await WorkService.Works();
    //   if (response.status === 200) {
    //     NotificationManager.success(response.data.message, "Notification");
    //     setWorks(response.data.data.list);
    //   }
    // } catch (err) {
    //  // console.log(err);
    //   NotificationManager.error(err?.response?.data?.message);
    // }
  };

  const agentWorkCount = (idAgent) => {
    return [...works].filter((work) => work.accessible_id === idAgent).length;
  };
  return (
    <>
      <Preloader />
      <Sidebar user={user} />

      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar user={user} pageName={"Dashboard Courier"} icon={faHome} />
        <hr />
        <div>
          <NotificationContainer />
          <Row className="justify-content-md-center flex-column">
            {invitations && invitations.length > 0 ? (
              <Col className="mb-4">
                <Card className="p-3">
                  <h5>
                    {" "}
                    <FontAwesomeIcon icon={faEnvelope} /> Nouvelles Invitations{" "}
                  </h5>
                  {invitations.map((invi, index) => (
                    <div key={index}>
                      {" "}
                      {invi.source_invitation === "laboratoire" ? (
                        <>
                          <span>
                            {" "}
                            {invi.laboratory?.last_name +
                              " " +
                              invi.laboratory?.first_name}
                          </span>

                          <Button variant="success" size="sm">
                            {" "}
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                            /> Accepter{" "}
                          </Button>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </Card>
              </Col>
            ) : (
              ""
            )}

            <Col className="mb-4">
              <Card className="p-3">
                <div className="d-flex align-items-center mb-3">
                  <h4>
                    <FontAwesomeIcon icon={faFileWord} /> Travaux sortants :{" "}
                    {
                      [...works].filter(
                        (work) => work.status === "outgoing_dentist"
                      ).length
                    }{" "}
                  </h4>
                  <Button
                    onClick={() => navigate(PagesRoutes.CourierWorks.path)}
                    variant="success"
                    size="sm"
                    className="ms-2 mb-2"
                  >
                    {" "}
                    Voir{" "}
                  </Button>
                </div>

                <div className="d-flex align-items-center">
                  <h4>
                    <FontAwesomeIcon icon={faFileWord} /> Travaux entrants :{" "}
                    {
                      [...works].filter(
                        (work) => work.status === "outgoing_laboratory"
                      ).length
                    }{" "}
                  </h4>
                  <Button
                    onClick={() => navigate(PagesRoutes.CourierWorks.path)}
                    variant="success"
                    size="sm"
                    className="ms-2 mb-2"
                  >
                    {" "}
                    Voir{" "}
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </main>
    </>
  );
};

export default DashCourier;
