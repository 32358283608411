import React, { useEffect, useState } from "react";
import Sidebar from "../../Shared/Sidebar";
import Preloader from "../../Shared/Preloader/Preloader";
import Navbar from "../../Shared/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  Button,
  Modal,
  Form,
  Col,
  Row,
  Table,
} from "@themesberg/react-bootstrap";
import { currentUser } from "../../../authentication/auth";
import {
  faPaperPlane,
  faPlus,
  faPlusCircle,
  faSave,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Category from "./components/Category";
import Supplier from "./components/Fournisseur";
import FirebaseNotif from "../../Shared/FirebaseNotif/FirebaseNotif";
import StockService from "../../services/StockService";
const LaboStock = () => {
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [showAddCategory, setShowAddCategory] = useState(false);

  const handleClose = () => {
    setShowAddProduct(false);
    setShowAddCategory(false);
  };
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    getCategories();
  }, []);
  const [categories, setCategories] = useState([]);

  const getCategories = async () => {
    try {
      const res = await StockService.getCategories();
      if (res.status === 200) {
        setCategories(res.data.data.list);
      }
    } catch (e) {
      // console.log(e);
    }
  };
  const [name, setName] = useState("");
  const [uri, setUri] = useState(null);
  const [category_id, setCategoryId] = useState(null);
  const addProduct = () => {};
  const addCategory = async (e) => {
    e.preventDefault();
    try {
      const res = await StockService.addCategory({
        name,
        uri,
        category_id,
      });
      if (res.status === 200) {
        NotificationManager.success(res.data.message, "Stock");
      }
    } catch (e) {
      NotificationManager.error("error", "Stock");
    }
  };

  return (
    <>
      {" "}
      <Preloader />
      <Sidebar user={user} />
      {/* <FirebaseNotif /> */}
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar user={user} pageName={"Gestion du stock"} icon={faWarehouse} />
        <hr />
        <div>
          <div className="text-end mb-3"></div>
        </div>

        <div>
          <div className="d-flex justify-content-end">
            <Button
              variant={"main"}
              size="sm"
              onClick={() => setShowAddCategory(true)}
            >
              <FontAwesomeIcon icon={faPlusCircle} /> Ajouter categorie{" "}
            </Button>
            {/* <Button variant={"main"} onClick={() => setShowAddProduct(true)}>
              <FontAwesomeIcon icon={faPlusCircle} /> Ajouter article{" "}
            </Button> */}
          </div>

          <div className="mt-2">
            <Row>
              {categories.map((c) => (
                <Col md={12} lg={3}>
                  <Category category={c} />
                </Col>
              ))}
            </Row>
            <hr />
            <div>
              <div className="d-flex justify-content-end">
                <Button
                  size="sm"
                  variant={"main"}
                  onClick={() => setShowAddProduct(true)}
                >
                  <FontAwesomeIcon icon={faPlusCircle} /> Nouveau achat{" "}
                </Button>
              </div>
              <h5>Derniers achats</h5>
              <Table
                responsive
                hover={true}
                className="align-items-center table-flush table-bordered"
              >
                <thead className="thead-light">
                  <tr>
                    <td>Numero</td>
                    <td>Nom</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>test</td>
                    <td>test</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <hr />
            <div>
              <div className="d-flex justify-content-end">
                <Button
                  size="sm"
                  variant={"main"}
                  onClick={() => setShowAddProduct(true)}
                >
                  <FontAwesomeIcon icon={faPlusCircle} /> Nouveau fournisseur{" "}
                </Button>
              </div>
              <h5>Liste des frournisseurs</h5>
              <Row>
                <Col md={6} lg={3}>
                  <Supplier />
                </Col>
              </Row>
            </div>
          </div>

          {/* <div className="mt-2">
            <hr />
            <h5> Liste des articles</h5>
            <Table
              responsive
              hover={true}
              className="align-items-center table-flush table-bordered"
            >
              <thead className="thead-light">
                <tr>
                  <td>Numero</td>
                  <td>Nom</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>test</td>
                  <td>test</td>
                </tr>
              </tbody>
            </Table>
          </div> */}
        </div>

        <NotificationContainer />
      </main>
      <Modal
        as={Modal.Dialog}
        centered
        show={showAddCategory}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faPlus} /> Ajouter nouvelle catégorie
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className={""}>
            <Form onSubmit={addCategory}>
              <Form.Group className="mb-2">
                <Form.Label>Nomination </Form.Label>
                <Form.Control
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>

              {/* <Form.Group className="mb-2">
                <Form.Label>Categorie article</Form.Label>
                <select className="form-control">
                  <option>Categorie 1</option>
                  <option>Categorie 1</option>
                </select>
              </Form.Group> */}
              <Form.Group className="mb-2">
                <Form.Label>Image </Form.Label>
                <Form.Control type="file"></Form.Control>
              </Form.Group>

              <Form.Group className="d-flex justify-content-end">
                <Button type="submit" variant={"main"}>
                  <FontAwesomeIcon icon={faSave} /> Sauvegarder{" "}
                </Button>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        as={Modal.Dialog}
        centered
        show={showAddProduct}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faPlus} /> Ajouter catégorie
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className={""}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label>Nom </Form.Label>
                <Form.Control></Form.Control>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Nom article</Form.Label>
                <Form.Control></Form.Control>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Categorie article</Form.Label>
                <select className="form-control">
                  <option>Categorie 1</option>
                  <option>Categorie 1</option>
                </select>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Description article</Form.Label>
                <Form.Control as="textarea"></Form.Control>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Qunatite article</Form.Label>
                <Form.Control type="number"></Form.Control>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Unite article</Form.Label>
                <select className="form-control">
                  <option>Unite 1</option>
                </select>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Prix article</Form.Label>
                <Form.Control type="number"></Form.Control>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Commentaire article</Form.Label>
                <Form.Control></Form.Control>
              </Form.Group>

              <Form.Group className="d-flex justify-content-end">
                <Button type="submit" variant={"main"}>
                  <FontAwesomeIcon icon={faSave} /> Sauvegarder{" "}
                </Button>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LaboStock;
