import React from "react";
import "./style.css";
import SaverImage from "../../../assets/components.png";
const SaverVous = () => {
  return (
    <div className={"saverVousContainer"}>
      <div className={"saverVousSmallContainer"}>
        <p>Savez Vous</p>
        <span>
          la Communication Dentiste - Prothésiste est un critère de Qualité
        </span>
        <ul className={"saverVousList"}>
          <li>Réduisez le temps au Téléphone :</li>
          <span>L’information est dans votre espace EasyLab</span>
          <li>Unifier et centraliser le canal de communication :</li>
          <span> Tout est dans la platoform</span>
          <li>
            Transfert de Fichiers (empreintes Digitales) et Photo pour
            <br /> de Meilleurs résultats.
          </li>
          <li>Système de Facturation personalisable.</li>
          <li>Gardez des relations Professionnelles.</li>
          <li>
            Réduisez les coûts cachés des tâches administratives et réception
            d’appel téléphoniques.
          </li>
        </ul>
      </div>
      <img src={SaverImage} />
    </div>
  );
};

export default SaverVous;
