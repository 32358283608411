
import React from 'react';
import { Image } from '@themesberg/react-bootstrap';

import ReactLogo from "../../../assets/easylab.svg";
import { useState , useEffect } from 'react';

export default (props) => {
 
  const [loaded , setLoaded] = useState(false)
  useEffect(()=>{
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  },[])

  return (
    <div className={`preloader bg-soft flex-column justify-content-center align-items-center ${!loaded ? "" : "show"}`}>
      <Image className="loader-element animate__animated animate__jackInTheBox" src={ReactLogo} height={100} />
    </div>
  );
};
