import React from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCalendarTimes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
const TimeLine = (props) => {
  const { items } = props;
  return (
    <div>
      <div className="TimeLine wrapper border border-1">
        <h1> Journal d'activitees </h1>
        <hr />
        <ul className="sessions">
          {items.map((item, index) => (
            <li key={index}>
              <div className="time">
                {" "}
                <FontAwesomeIcon icon={faCalendar} />{" "}
                {moment.unix(item.date).format("DD-MM-YYYY HH:mm")}{" "}
              </div>
              <h1>{item.content}</h1>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TimeLine;
