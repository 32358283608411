import {
  faCheckCircle,
  faEdit,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Button, Spinner } from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { axiosInstance } from "../../../config/axios";
import { APIURL } from "../../../configApi";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const GearPaymentBlock = () => {
  const [loading, setLoading] = useState(false);
  const syncPayment = () => {
    setLoading(true);
    axiosInstance
      .get(`${APIURL}/laboratory/invoices/synchronize_payments`)
      .then((data) => {
        // console.log("sync data", data);
        NotificationManager.success(
          "Actualisation effectué avec succès",
          "Paiement"
        );
        setLoading(false);
        window.location.reload(true);
      })
      .catch((err) => {
        // console.log("sync data", err);
        NotificationManager.error(
          "Erreur lors de la synchronisation",
          "Paiement"
        );
        setLoading(false);
      });
  };

  return (
    <>
      <Card className="my-2">
        <Card.Header>
          <h5 className="mb-2">{"Paramètres de facturation"}</h5>
        </Card.Header>
        <Card.Body>
          <div className="text-center">
            <p>Le calcul sera effectué automatiquement à minuit.</p>
            {loading ? (
              <Button variant="primary" className="me-2 btn-sm">
                <Spinner animation="border" /> Actualiser le calcul maintenant
              </Button>
            ) : (
              <Button
                onClick={() => syncPayment()}
                variant="primary"
                className="me-2 btn-sm"
              >
                <FontAwesomeIcon icon={faSync} /> Actualiser le calcul
                maintenant
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
export default GearPaymentBlock;
