import {
  faCheckCircle,
  faEdit,
  faSave,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  Nav,
  Row,
  Form,
  Button,
  ModalDialog,
} from "@themesberg/react-bootstrap";
import React, { useState, useEffect } from "react";

import { NotificationManager } from "react-notifications";

const UploadAttachmentModal = (props) => {
  const { show, handleClose, work, setShow, setWork } = props;
  const [file, setFile] = useState();

  const uploadFile = (e) => {
    e.preventDefault();
    // console.log("file in event ===> ",e.target.files[0])

    // if ( e.target.files[0] == file ){
    //     NotificationManager.error('Vous avez déjà téléchargé une photo avec le même nom. Verifiez SVP', 'Notification');
    // }else{
    //         setFile(e.target.files[0])
    //         const data = new FormData();
    //             data.append('attachments_file' , file)
    //         axiosInstance.post(`${APIURL}/global/upload_files/pictures` , data).then(res=> {
    //            // console.log(res.data.data)
    //             const files = attachments_files;
    //             files.push(res.data.data?.id)
    //             setAttachments_files(files)
    //             NotificationManager.success('Image téléchargée avec succès', 'Notification');
    //         }).catch(
    //             err=> {
    //                // console.log(err)
    //                 NotificationManager.error(err.response.data.message, 'Notification');
    //             }
    //         )
    // }
  };

  useEffect(() => {}, [work]);

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={show}
      onHide={handleClose}
      size={"lg"}
    >
      <Modal.Header>
        <Modal.Title className="h6">
          <FontAwesomeIcon icon={faEdit} />
          Editer les pièces jointes de la fiche {work?.reference_work}
        </Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className={"mb-3"}>
            <Col>
              <FormGroup>
                <FormLabel> Empreintes digitales </FormLabel>
                <FormControl className={"mb-2"} type={"file"}></FormControl>
                <FormControl className={"mb-2"} type={"file"}></FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FormLabel> Attachments </FormLabel>{" "}
                <span className={"text-muted"}></span>
                <FormControl
                  className={"mb-2"}
                  type={"file"}
                  onChange={(e) => uploadFile(e)}
                ></FormControl>
              </FormGroup>
            </Col>
          </Row>

          <hr />

          <div>Show old files</div>
          <hr />

          <Form.Group className={"d-flex justify-content-end mt-3"}>
            <Button
              type={"reset"}
              onClick={handleClose}
              variant={"danger"}
              className={"me-2"}
            >
              <FontAwesomeIcon icon={faWindowClose} />
              {" Annuler "}{" "}
            </Button>
            <Button type={"submit"} variant={"success"}>
              <FontAwesomeIcon icon={faSave} />
              {" Sauvegarder"}{" "}
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default UploadAttachmentModal;
