import { axiosInstance } from "../../config/axios";
import { APIURL } from "../../configApi";

const InvoicesService = {};
InvoicesService.List = () => {
  return axiosInstance.get(`${APIURL}/global/invoices`);
};

InvoicesService.ListByDentistAndLaboratory = (
  dentist_id,
  laboratory_id,
  page
) => {
  return axiosInstance.get(
    `${APIURL}/global/invoices?dentist_id=${dentist_id}&laboratory_id=${laboratory_id}&page=${page}`
  );
};

export default InvoicesService;
