import React from "react";
import { Card } from "@themesberg/react-bootstrap";
import { useNavigate } from "react-router-dom";
const Supplier = (props) => {
  const { id } = props;
  const navigate = useNavigate();
  return (
    <>
      <Card className="mb-2">
        <Card.Header>Supplier</Card.Header>
      </Card>
    </>
  );
};
export default Supplier;
