const {axiosInstance} = require("../config/axios");
const {APIURL} = require("../configApi");

const PaymentService = {}
PaymentService.liste = ()=> {
        return  axiosInstance.get(`${APIURL}/laboratory/payments` )
}



export default PaymentService;