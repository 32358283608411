import { Button, Row } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import Invitations from "../../Shared/components/Table/Invitations";
import Navbar from "../../Shared/Navbar/Navbar";
import Preloader from "../../Shared/Preloader/Preloader";
import Sidebar from "../../Shared/Sidebar";
import { useNavigate } from "react-router-dom";
import { config, currentUser } from "../../../authentication/auth";
import { APIURL } from "../../../configApi";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faPlusCircle,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { PagesRoutes } from "../../../pages_routes";
import UsersTable from "../../Shared/components/Table/UsersTable";
import { NotificationContainer } from "react-notifications";
import { axiosInstance } from "../../../config/axios";
import FirebaseNotif from "../../Shared/FirebaseNotif/FirebaseNotif";

const DentistAgents = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [agents, setAgents] = useState([]);
  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    getAgents();
  }, []);

  const getAgents = () => {
    axiosInstance
      .get(`${APIURL}/dentist/secretaries/all`)
      .then((res) => {
        setAgents(res.data.data);
        //console.log(agents)
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <>
      <Preloader />
      <Sidebar user={user} />
      {/* <FirebaseNotif /> */}
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar user={user} pageName={"Liste des assistants"} icon={faUsers} />
        <hr />
        <div>
          <div className="d-flex justify-content-end mb-2">
            <Button
              variant={"main"}
              onClick={() => navigate(PagesRoutes.DentistAgentsAdd.path)}
            >
              {" "}
              <FontAwesomeIcon icon={faPlusCircle} /> Ajouter{" "}
            </Button>
          </div>
          <NotificationContainer />

          {agents && agents.length > 0 ? (
            <UsersTable
              name={"Liste des assistants"}
              users={agents}
              type={"assistant"}
              headColumns={[
                "email",
                "first_name",
                "principal_phone",
                "is_active",
                "cin",
                "last_name",
              ]}
              refreshAgents={getAgents}
            />
          ) : (
            "Aucun assistant dans la liste. Cliquez sur le button ajouter  .."
          )}
        </div>
      </main>
    </>
  );
};

export default DentistAgents;
