import React, { useEffect, useState } from "react";
import Preloader from "../../Shared/Preloader/Preloader";
import Sidebar from "../../Shared/Sidebar";
import Navbar from "../../Shared/Navbar/Navbar";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  Modal,
  ModalFooter,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faEnvelope,
  faHouseUser,
  faInfoCircle,
  faPhoneAlt,
  faPlus,
  faPlusCircle,
  faSave,
  faShare,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useNavigate } from "react-router-dom";
import { currentUser } from "../../../authentication/auth";
import { axiosInstance } from "../../../config/axios";
import { APIURL, getPages } from "../../../configApi";
import DentistsTable from "../../Shared/components/Table/DentistsTable";
import RegisterService from "../../services/RegisterService";
import axios from "axios";
import PaginatorDentistList from "../../Shared/components/Paginator/PaginatorDentistList";
import FirebaseNotif from "../../Shared/FirebaseNotif/FirebaseNotif";

const LaboDentistList = () => {
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    state_id: "",
    city_id: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const [email, setEmail] = useState(null);
  const [addedType, setAddedType] = useState(0);
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => {
    setShowDefault(false);
    setAddedType(0);
  };

  const [dentists, setDentists] = useState([]);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [pages, setPages] = useState(0);

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [description, setDescription] = useState("");
  const [country_id, setCountryId] = useState(1);
  const [state_id, setStateId] = useState(0);
  const [city_id, setCityId] = useState(0);
  const [principal_phone, setPrincipalPhone] = useState("");
  const [ordinal_number_dentist, setOrdinalNumberDentist] = useState(1);
  const [full_address, setFullAdress] = useState();

  const getCountries = async () => {
    try {
      const response = await RegisterService.getCountries();
      setCountries(response.data.data);
      // console.log("countries", response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };
  const getStates = async (idCountry) => {
    try {
      const response = await RegisterService.getStates(idCountry);
      setStates(response.data.data);
      // console.log("states", response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };
  const getCities = async (idState) => {
    try {
      const response = await RegisterService.getCities(idState);
      setCities(response.data.data);
      // console.log("cities", response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    //getAgents()
    getDentists();
    getCountries();
    getStates(1);
  }, []);
  const getDentists = () => {
    axiosInstance
      .get(`${APIURL}/laboratory/dentists`)
      .then((res) => {
        setDentists(res.data.data.list);
        setPages(getPages(res.data.data.total));
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const sendNewInvitation = (e) => {
    e.preventDefault();
    axiosInstance
      .post(`${APIURL}/global/request_invitations`, { email: email })
      .then((res) => {
        NotificationManager.success(
          "Invitation envoye avec succès",
          "Notification"
        );
        setShowDefault(false);
        setEmail("");
      })
      .catch((err) => {
        NotificationManager.error(err.response.data.message, "Notification");
        setEmail("");
      });
  };

  const changeAddedType = (e) => {
    // console.log("type is changing");
    setAddedType(e);
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setCityId(0);
    setStateId(0);
    setFullAdress("");
    setPrincipalPhone("");
    setDescription("");
    setOrdinalNumberDentist("");
  };
  const verifForm = () => {
    if (first_name.length == 0) {
      setErrors({
        first_name: "Nom est obligatoire",
      });
      setLoading(false);
      return false;
    }
    if (last_name.length == 0) {
      setErrors({
        last_name: "Prenom est obligatoire",
      });
      setLoading(false);
      return false;
    }
    if (state_id == 0) {
      setErrors({
        state_id: "Gouvernorat est obligatoire",
      });
      setLoading(false);
      return false;
    }
    if (city_id == 0) {
      setErrors({
        city_id: "Ville est obligatoire",
      });
      setLoading(false);
      return false;
    }

    if (principal_phone.length == 0) {
      setErrors({
        principal_phone: "Téléphone est obligatoire",
      });
      setLoading(false);
      return false;
    }

    return true;
  };

  const registerUser = (e) => {
    e.preventDefault();
    // console.log(errors);
    if (verifForm()) {
      setLoading(true);
      let user = {};
      let endpoint = "";

      user = {
        first_name,
        last_name,
        full_address,
        ordinal_number_dentist,
        principal_phone,
        city_id,
        country_id,
        state_id,
        description,
        email,
      };
      endpoint = "/laboratory/dentists/temporary";

      // console.log("user", user);

      axiosInstance
        .post(`${APIURL + endpoint}`, user)
        .then((res) => {
          // console.log(res);
          setError("");
          setSuccess("Compte crée avec succès");
          setLoading(false);
          handleClose();
          getDentists();
          resetForm();
          NotificationManager.success(
            "Compte local ajoutee avec succès",
            "Notification"
          );
        })
        .catch((err) => {
          // console.log(err);
          setError(err.response.data.message);
          setLoading(false);
          NotificationManager.error(err.response.data.message, "Notification");
        });
    }
  };

  return (
    <>
      <Preloader />
      <Sidebar user={user} />
      {/* <FirebaseNotif /> */}
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar
          user={user}
          pageName={"Liste des dentistes"}
          icon={faHouseUser}
        />
        <hr />
        <div>
          <div className="d-flex justify-content-end mb-2">
            <Button variant={"main"} onClick={() => setShowDefault(true)}>
              <FontAwesomeIcon icon={faPlusCircle} /> Ajouter{" "}
            </Button>
          </div>
          <NotificationContainer />

          <div>
            {dentists && dentists.length > 0 ? (
              <DentistsTable
                setUsers={(value) => setDentists(value)}
                pages={pages}
                name={"Liste des dentistes"}
                users={dentists}
                headColumns={[
                  "email",
                  "first_name",
                  "principal_phone",
                  "last_name",
                  "is_active",
                ]}
                refreshUsers={() => getDentists()}
              />
            ) : (
              "No data found .."
            )}
          </div>
        </div>
      </main>

      <Modal
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faPlus} /> Ajouter un nouveau dentiste
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-evenly">
            <Button
              onClick={() => setAddedType(1)}
              title="Creer un un compte local"
              className="me-2"
            >
              Ajouter
            </Button>{" "}
            <Button
              onClick={() => setAddedType(2)}
              title="Inviter un dentiste par mail"
            >
              Inviter
            </Button>
          </div>

          {addedType && addedType == 2 ? (
            <Form onSubmit={sendNewInvitation} className="mt-4">
              <p> </p>
              <Row>
                <Col md={"10"}>
                  <FormGroup>
                    <FormControl
                      type={"email"}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder={"Email de dentiste ..."}
                    ></FormControl>
                  </FormGroup>
                </Col>
                <Col md={"2"}>
                  <Button type={"submit"} variant={"main"}>
                    <FontAwesomeIcon icon={faShare} /> Envoyer{" "}
                  </Button>
                </Col>
              </Row>
            </Form>
          ) : addedType && addedType == 1 ? (
            <Form onSubmit={registerUser} className="mt-4">
              <Row>
                <Col>
                  <Form.Group id="lastname" className="mb-4">
                    <Form.Label>Nom </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <Form.Control
                        value={first_name}
                        onChange={(e) => setFirstName(e.target.value)}
                        autoFocus
                        type="text"
                        placeholder="Nom du dentiste à ajouter..."
                      />
                    </InputGroup>
                    {errors.first_name && errors.first_name != "" ? (
                      <p className="text-danger"> {errors.first_name}</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group id="firstname" className="mb-4">
                    <Form.Label>Prénom </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <Form.Control
                        value={last_name}
                        onChange={(e) => setLastName(e.target.value)}
                        type="text"
                        placeholder="prénom du dentiste à ajouter ..."
                      />
                    </InputGroup>
                    {errors.last_name && errors.last_name != "" ? (
                      <p className="text-danger"> {errors.last_name}</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Pays :</Form.Label>
                    <Form.Select
                      disabled={true}
                      value={country_id}
                      onChange={(e) => {
                        setCountryId(e.target.value);
                        getStates(e.target.value);
                      }}
                    >
                      <option defaultValue>Selectionner une pays </option>

                      {countries.map((country) => (
                        <option value={country.id}> {country.name}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Gouvernorat :</Form.Label>
                    <Form.Select
                      value={state_id}
                      onChange={(e) => {
                        setStateId(e.target.value);
                        getCities(e.target.value);
                      }}
                    >
                      <option defaultValue>
                        Selectionner une Gouvernorat{" "}
                      </option>

                      {states.map((state) => (
                        <option value={state.id}> {state.name}</option>
                      ))}
                    </Form.Select>
                    {errors.state_id && errors.state_id != "" ? (
                      <p className="text-danger"> {errors.state_id}</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Ville :</Form.Label>
                    <Form.Select
                      value={city_id}
                      onChange={(e) => setCityId(e.target.value)}
                    >
                      <option defaultValue>Selectionner une ville </option>

                      {cities.map((city) => (
                        <option value={city.id}> {city?.name}</option>
                      ))}
                    </Form.Select>
                    {errors.city_id && errors.city_id != "" ? (
                      <p className="text-danger"> {errors.city_id}</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Adresse du dentiste : </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faAddressCard} />
                      </InputGroup.Text>
                      <Form.Control
                        value={full_address}
                        onChange={(e) => setFullAdress(e.target.value)}
                        type="text"
                        placeholder="Adresse ..."
                      />
                    </InputGroup>
                    {errors.full_address && errors.full_address != "" ? (
                      <p className="text-danger"> {errors.full_address}</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-4">
                    <Form.Label>Téléphone : </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faPhoneAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        value={principal_phone}
                        onChange={(e) => setPrincipalPhone(e.target.value)}
                        type="tel"
                        placeholder="Numero téléphone"
                      />
                    </InputGroup>
                    {errors.principal_phone && errors.principal_phone != "" ? (
                      <p className="text-danger"> {errors.principal_phone}</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Numéro Ordinal ( Optionnel ) </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faAddressCard} />
                      </InputGroup.Text>
                      <Form.Control
                        value={ordinal_number_dentist}
                        onChange={(e) =>
                          setOrdinalNumberDentist(e.target.value)
                        }
                        autoFocus
                        type="number"
                        placeholder="1234"
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <FormGroup className="mb-3">
                <FormControl
                  as="textarea"
                  rows={1}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder={"Commentaire ..."}
                ></FormControl>
              </FormGroup>
              <Col className="text-end">
                {loading ? (
                  <Button type={"submit"} variant={"main"}>
                    <Spinner animation="border" />
                  </Button>
                ) : (
                  <Button
                    type={"submit"}
                    onClick={() => setLoading(true)}
                    variant={"main"}
                  >
                    <FontAwesomeIcon icon={faSave} /> Sauvegarder{" "}
                  </Button>
                )}
              </Col>
            </Form>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col lg={12} md={12}>
              <div className="flex ">
                <h6>
                  {" "}
                  <FontAwesomeIcon icon={faInfoCircle} /> Ajouter un nouveau
                  dentiste à votre liste{" "}
                </h6>
              </div>
            </Col>
            <Col>
              <p>
                <strong>Ajouter</strong> un dentiste localement et contrôler
                vous même la gestion des Fiches de travaux, du flux et de la
                facturation (le Dentiste n’a pas de Compte).
              </p>
            </Col>
            <Col>
              <p>
                <strong> Invitez le </strong>
                s’il possède un compte EasyLab (Notez bien ici en cas de
                création de nouveau travail pour le Dentiste, le Dr doit le
                valider l travail pour pouvoir le facturer)
              </p>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LaboDentistList;
