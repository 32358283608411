import { axiosInstance } from "../../config/axios";
import { APIURL } from "../../configApi";

const WorkService = {};
WorkService.editPrice = (idWork, data) => {
  return axiosInstance.put(`${APIURL}/global/works/${idWork}/edit_price`, data);
};

WorkService.editDatePrevu = (idWork, data) => {
  return axiosInstance.put(
    `${APIURL}/laboratory/works/${idWork}/foreseen_delivery_date`,
    data
  );
};

WorkService.archiver = (idWork) => {
  return axiosInstance.put(`${APIURL}/laboratory/works/${idWork}/archived`);
};

WorkService.download_attachment_file = (idfile) => {
  return axiosInstance.get(
    `${APIURL}/global/download_files/${idfile}/attachments_file`
  );
};

WorkService.getOneById = (idWork) => {
  return axiosInstance.get(`${APIURL}/global/works/${idWork}`);
};

WorkService.recieveLaboratory = (workId) => {
  return axiosInstance.put(`${APIURL}/global/works/${workId}/change_status`, {
    step: "received_laboratory",
  });
};

WorkService.recieveLocalDentist = (workId) => {
  return axiosInstance.put(`${APIURL}/global/works/${workId}/change_status`, {
    step: "received_dentist",
  });
};

WorkService.changeStatus = (workId, data) => {
  return axiosInstance.put(
    `${APIURL}/global/works/${workId}/change_status`,
    data
  );
};

WorkService.assignToAgent = (workId, data) => {
  return axiosInstance.put(
    `${APIURL}/laboratory/works/${workId}/affected_to_agent`,
    data
  );
};

WorkService.assignToCourier = (workId, data) => {
  return axiosInstance.put(
    `${APIURL}/laboratory/works/${workId}/affected_to_courier`,
    data
  );
};

WorkService.update = (workId, data) => {
  return axiosInstance.post(`${APIURL}/global/works/${workId}`, data);
};

WorkService.worksByStatus = (status) => {
  return axiosInstance.get(`${APIURL}/global/works?page=1&status=${status}`);
};

WorkService.add = (data) => {
  return axiosInstance.post(`${APIURL}/global/works`, data);
};

WorkService.Works = () => {
  return axiosInstance.get(`${APIURL}/global/works`);
};

WorkService.worksWithPaginate = (page) => {
  return axiosInstance.get(`${APIURL}/global/works?page=${page}`);
};

WorkService.changeStatusMultiple = (data) => {
  return axiosInstance.put(
    `${APIURL}/global/works/change-status-multiple-selector`,
    data
  );
};

WorkService.requestPrevuDate = (workID) => {
  return axiosInstance.put(
    `${APIURL}/dentist/works/${workID}/requested_delivery_date`,
    {
      is_delivery_date_requested: true,
    }
  );
};

export default WorkService;
