import React, { useEffect, useState } from "react";
import Preloader from "../../Shared/Preloader/Preloader";
import Sidebar from "../../Shared/Sidebar";
import Navbar from "../../Shared/Navbar/Navbar";
import {
  Accordion,
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  Row,
  Spinner,
  useAccordionButton,
} from "@themesberg/react-bootstrap";
import { PagesRoutes } from "../../../pages_routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faExclamationTriangle,
  faFileMedical,
  faFolder,
  faList,
  faListOl,
  faMoneyBill,
  faPlusCircle,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { currentUser } from "../../../authentication/auth";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../config/axios";
import { APIURL } from "../../../configApi";

// import "react-modern-calendar-datepicker/lib/DatePicker.css";
// import { Calendar } from "react-modern-calendar-datepicker";
import { fr } from "date-fns/locale";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import FirebaseNotif from "../../Shared/FirebaseNotif/FirebaseNotif";
const SettingsDentist = () => {
  const navigate = useNavigate();

  const [launch_date, setLaunchDate] = useState();

  const [user, setUser] = useState();
  const [groupes, setGroupes] = useState();
  const [laboratoires, setLaboratoires] = useState([]);
  const [laboratoire_id, setLaboratoireID] = useState();
  const [update, setUpdate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingSettings, setLoadingSettings] = useState(false);
  const handleClose = () => {
    // setShowPlanifier(false);
    // setShowDeleteGroupe(false);
    // setShowChangement(false);
  };
  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    //getGroupes();
    getMyLabo();
  }, []);

  const getMyLabo = () => {
    setLoading(true);
    axiosInstance
      .get(`${APIURL}/dentist/laboratories/all`)
      .then((res) => {
        // console.log(res.data.data);
        setLaboratoires(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };

  const selectService = (service) => {
    // setName(service.name);
    // setDescription(service.description);
    // setPrice(service.price);
  };
  const getGroupes = (idLabo) => {
    setLoadingSettings(true);
    axiosInstance
      .get(`${APIURL}/global/settings/services/` + idLabo)
      .then((res) => {
        // console.log(res.data.data);
        setGroupes(res.data.data);
        setUpdate((prev) => prev + 1);
        setLoadingSettings(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoadingSettings(false);
      });
  };

  return (
    <>
      <Preloader />
      <Sidebar user={user} />
      {/* <FirebaseNotif /> */}
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar user={user} />
        <hr />
        <div>
          <NotificationContainer />

          <div>
            {loading ? (
              <div className="text-center">
                <Spinner animation="border" className="text-center" />
              </div>
            ) : (
              <>
                <h4>
                  {" "}
                  Choisir un laboratoire pour consulter la liste des services.{" "}
                </h4>

                <select
                  value={laboratoire_id}
                  className="form-control"
                  onChange={(e) => {
                    setLaboratoireID(e.target.value);
                    getGroupes(e.target.value);
                  }}
                >
                  <option> Choisir un laboratoire </option>
                  {laboratoires && laboratoires.length > 0
                    ? laboratoires.map((labo) => (
                        <option value={labo.id}>
                          {" "}
                          {labo.last_name + " " + labo.first_name}{" "}
                        </option>
                      ))
                    : ""}
                </select>
              </>
            )}
          </div>

          {loadingSettings ? (
            <div className="text-center mt-4">
              <Spinner animation="border" />
            </div>
          ) : (
            <div className="m-4">
              <Accordion defaultActiveKey={["0"]} alwaysOpen>
                {groupes && groupes.length > 0
                  ? groupes.map((g, index) => (
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>
                          <span className={"fw-bold"}>
                            <FontAwesomeIcon
                              icon={faFolder}
                              className={"me-2"}
                            />
                            {g.name.toUpperCase()}
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Accordion defaultActiveKey={["0"]} alwaysOpen>
                            {g.sub_groups.length > 0 &&
                              g.sub_groups.map((sg, indexSG) => (
                                <Accordion.Item eventKey={indexSG}>
                                  <Accordion.Header>
                                    <span className={"fw-bold"}>
                                      <FontAwesomeIcon
                                        icon={faListOl}
                                        className={"me-2"}
                                      />
                                      {sg.name.toUpperCase()}{" "}
                                    </span>{" "}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <Accordion
                                      defaultActiveKey={["0"]}
                                      alwaysOpen
                                    >
                                      {sg.services.length > 0 &&
                                        sg.services.map((service, indexS) => (
                                          <Accordion.Item eventKey={indexS}>
                                            <Accordion.Header
                                              title={service.description}
                                            >
                                              <span className={"fw-bold"}>
                                                <FontAwesomeIcon
                                                  icon={faFileMedical}
                                                  className={"me-2"}
                                                />
                                                {service.name.toUpperCase()}
                                                <i className="ms-4">
                                                  {` ( ` +
                                                    service?.price +
                                                    ` dt )`}
                                                </i>{" "}
                                              </span>{" "}
                                            </Accordion.Header>
                                          </Accordion.Item>
                                        ))}
                                    </Accordion>
                                  </Accordion.Body>
                                </Accordion.Item>
                              ))}
                          </Accordion>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))
                  : ""}
              </Accordion>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default SettingsDentist;
