import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Dropdown,
  Form,
  Modal,
  OverlayTrigger,
  Spinner,
  Table,
  Tooltip,
} from "@themesberg/react-bootstrap";

import {
  faFileAlt,
  faSortDown,
  faSortUp,
  faTrash,
  faTruckPickup,
} from "@fortawesome/free-solid-svg-icons";
import { config, currentUser } from "../../../../authentication/auth";
import { useNavigate } from "react-router-dom";

import moment from "moment/moment";
import "moment/locale/fr";
import PaginatorArchives from "../Paginator/PaginatorArchives";

//import moment from "moment/moment";

moment.locale("fr");

const Archives = (props) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const { pages, works, setWorks } = props;

  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
  }, []);

  return (
    <>
      <Card border="light" className="shadow-sm">
        {loading ? (
          <Spinner animation="border" className="m-4" />
        ) : (
          <>
            <Table
              responsive
              hover={true}
              className="align-items-center table-flush"
            >
              <thead className="thead-light">
                <tr>
                  <th> # </th>

                  <th> Référence </th>
                  {props.role != "dentist" ? (
                    <th>
                      <div className={"d-flex align-items-center"}>
                        Dentiste{" "}
                        <div
                          className={
                            "ms-2 d-flex flex-column align-items-center "
                          }
                        >
                          <FontAwesomeIcon
                            className={"fa-md"}
                            style={{ marginBottom: "-10px" }}
                            icon={faSortUp}
                          />
                          <FontAwesomeIcon
                            className={"fa-md"}
                            icon={faSortDown}
                          />
                        </div>
                      </div>
                    </th>
                  ) : (
                    <th>
                      <div className={"d-flex align-items-center"}>
                        Laboratoire{" "}
                        <div
                          className={
                            "ms-2 d-flex flex-column align-items-center "
                          }
                        >
                          <FontAwesomeIcon
                            className={"fa-md"}
                            style={{ marginBottom: "-10px" }}
                            icon={faSortUp}
                          />
                          <FontAwesomeIcon
                            className={"fa-md"}
                            icon={faSortDown}
                          />
                        </div>
                      </div>
                    </th>
                  )}

                  <th> Travail </th>

                  <th> Fichiers attachés </th>
                  <th> Agent </th>

                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {works &&
                  works.length > 0 &&
                  works.map((work, index) => (
                    <tr
                      onClick={() =>
                        navigate(`/${user?.role}/works/show/${work.id}`)
                      }
                    >
                      <td> {(page - 1) * 10 + index + 1} </td>
                      <td> {work?.reference_work} </td>

                      <td>
                        {props.role != "dentist"
                          ? work.dentist.last_name +
                            ` ` +
                            work.dentist.first_name +
                            ` ( ${work?.dentist?.city?.name} )`
                          : work?.laboratory?.last_name +
                            ` ` +
                            work?.laboratory?.first_name +
                            ` ( ${work?.laboratory?.city?.name} )`}
                      </td>
                      <td>{work?.service?.name}</td>

                      <td>
                        {" "}
                        {work.attachments_files.length > 0 ? (
                          <div>
                            <FontAwesomeIcon
                              className={"fa-md"}
                              icon={faFileAlt}
                            />{" "}
                            ( {work.attachments_files.length} )
                          </div>
                        ) : (
                          "Aucun fichier"
                        )}
                      </td>
                      <td>
                        {" "}
                        {work.accessible &&
                          work.accessible?.last_name +
                            " " +
                            work.accessible?.first_name}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>

            <div className="d-flex justify-content-center mt-3">
              <PaginatorArchives
                totalPages={pages}
                updateArchives={(value) => setWorks(value)}
                loader={(value) => setLoading(value)}
                activatedItem={page}
                updatePageSelected={(value) => setPage(value)}
              />
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default Archives;
