import React, { useEffect, useState } from "react";
import Preloader from "../../Shared/Preloader/Preloader";
import Sidebar from "../../Shared/Sidebar";
import Navbar from "../../Shared/Navbar/Navbar";
import {
  Alert,
  Button,
  Card,
  Spinner,
  Tab,
  Tabs,
} from "@themesberg/react-bootstrap";

import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { NotificationContainer } from "react-notifications";

import InvoicesTable from "../../Shared/components/Table/Invoices";

import { currentUser } from "../../../authentication/auth";
import { useNavigate } from "react-router-dom";

import InvoicesService from "../../services/InvoicesService";
import LaboratoryService from "../../services/LaboratoireService";

import WorksToBeInvoiced from "../../Shared/components/Table/WorkToBeInvoiced";
import WorksNotInvoiced from "../../Shared/components/Table/WorksNotInvoiced";

import { getPages } from "../../../configApi";
import GearBlock from "./GearBlock";
import FirebaseNotif from "../../Shared/FirebaseNotif/FirebaseNotif";

const InvoicesLabo = () => {
  const [update, setUpdate] = useState(0);
  const [invoices, setInvoices] = useState([]);
  const [dentists, setDentists] = useState([]);
  const [dentist_id, setDentistId] = useState();
  const [worksToBeInvoiced, setWorksToBeInvoiced] = useState([]);
  const [worksNotInvoiced, setWorksNotInvoiced] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [pagesNotInvoiced, setPagesNotInvoiced] = useState(0);
  const [pagesToBeInvoiced, setPagesToBeInvoiced] = useState(0);
  const [pagesInvoices, setPagesInvoices] = useState(0);
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();
  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    getInvoices();
  }, []);

  const getInvoices = () => {
    //getAllInvoices();
    getAllDentist();
    getWorksToBeInvoiced();
    getWorksNotInvoiced();
  };

  useEffect(() => {
    updateDentist(update);
  }, [update]);

  const updateDentist = (id) => {
    console.log("update dentist", id);
    setPagesInvoices(0);
    setLoading(true);
    setDentistId(id);
    getWorksToBeInvoiced(id);
    getWorksNotInvoiced(id);
    getAllInvoices(id);
  };

  const refreshData = () => {
    setLoading(true);
    getAllInvoices(dentist_id);
    getWorksToBeInvoiced(dentist_id);
    getWorksNotInvoiced(dentist_id);
  };

  const getAllInvoices = async (dentist_id) => {
    console.log("getAllInvoices declanched ===>", dentist_id);
    try {
      const invoices = await InvoicesService.ListByDentistAndLaboratory(
        dentist_id,
        user.id,
        1
      );
      if (invoices.status == 200) {
        setInvoices(invoices.data.data.list);
        setPagesInvoices(getPages(invoices.data.data.total));
        setTotal(invoices.data.data.total);
      }
      console.log("invoices===>", invoices.data.data.list);
    } catch (err) {}
  };

  useEffect(() => {
    getAllInvoices(dentist_id);
  }, [dentist_id]);
  const getAllDentist = async () => {
    try {
      const dentists = await LaboratoryService.Dentists();
      if (dentists.status == 200) {
        // console.log("liste des dentists==>", dentists.data.data);
        setDentists(dentists.data.data);
      }
    } catch (err) {}
  };

  const getFacturationParam = (dentist_id) => {
    //// console.log(
    //   "dentists list ",
    //   dentists.find((dentist) => dentist.id == dentist_id)
    // );
    return dentists.find((dentist) => dentist.id == dentist_id)
      .invoice_day_by_current_laboratory;
  };

  const getFullnameParam = (dentist_id) => {
    //// console.log(
    //   "dentists list ",
    //   dentists.find((dentist) => dentist.id == dentist_id)
    // );
    let d = dentists.find((dentist) => dentist.id == dentist_id);
    return d.first_name + " " + d.last_name;
  };

  const getWorksToBeInvoiced = async (id) => {
    // console.log("id dentist ====>", id);
    try {
      const works = await LaboratoryService.worksToBeInvoicedByDentist(id, 1);
      if (works.status == 200) {
        // console.log("liste des works==>", works.data.data.list);

        // console.log(
        // "liste des works after filter 111 ==> ",
        //  works.data.data.list
        //);
        setPagesToBeInvoiced(works.data.data.total);
        setWorksToBeInvoiced(works.data.data.list);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getWorksNotInvoiced = async (id) => {
    // console.log("id dentist ====>", id);
    try {
      const works = await LaboratoryService.worksNotInvoicedByDentist(id, 1);
      if (works.status == 200) {
        // console.log("liste des works==>", works.data.data.list);
        setPagesNotInvoiced(works.data.data.total);
        // console.log("liste des works after filter ==> ", works.data.data.list);
        setWorksNotInvoiced(works.data.data.list);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <Preloader />
      <Sidebar user={user} />
      {/* <FirebaseNotif /> */}
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar
          user={user}
          pageName={"Liste des factures"}
          icon={faFileInvoiceDollar}
        />
        <hr />
        <div>
          <NotificationContainer />
          <div className="mb-3 col-4 offset-4">
            <label> Choisir un dentiste </label>
            {/* <Select options={dentists}></Select> */}

            <select
              className="form-control"
              onChange={(e) => updateDentist(e.target.value)}
            >
              <option value={""}> Choisir un dentist </option>
              {dentists.map((d) => (
                <option value={d.id}>
                  {" "}
                  {d.last_name + " " + d.first_name}{" "}
                </option>
              ))}
            </select>
          </div>
          <hr />
          {dentist_id && dentist_id != " " ? (
            <Tabs
              defaultActiveKey="to_be_invoiced"
              className="mb-3"
              onSelect={() => refreshData()}
            >
              <Tab
                eventKey="to_be_invoiced"
                title={"Facture en cours ( " + pagesToBeInvoiced + " )"}
              >
                {loading ? (
                  <div className={"text-center"}>
                    <Spinner animation={"border"} className="mt-4" />
                  </div>
                ) : !loading && worksToBeInvoiced ? (
                  <>
                    <WorksToBeInvoiced
                      setDentist={(value) => setUpdate(value)}
                      facturation_day={getFacturationParam(dentist_id)}
                      doctor={getFullnameParam(dentist_id)}
                      dentists={dentists}
                      currentStatus={"to_be_invoiced"}
                      works={worksToBeInvoiced}
                      updateWorks={(value) => setWorksToBeInvoiced(value)}
                      dentist_id={dentist_id}
                      pages={getPages(pagesToBeInvoiced)}
                      refreshWorks={(id) => getWorksToBeInvoiced(id)}
                      refreshUsers={() => getAllDentist()}
                    />
                  </>
                ) : (
                  // <NotInvoicedWorks />
                  ""
                )}
              </Tab>
              <Tab
                eventKey="not_invoiced"
                title={
                  "Travaux non encore facturés ( " + pagesNotInvoiced + " )"
                }
              >
                {worksNotInvoiced && (
                  <WorksNotInvoiced
                    currentStatus={"not_invoiced"}
                    works={worksNotInvoiced}
                    refreshWorks={(id) => getWorksNotInvoiced(id)}
                    dentist_id={dentist_id}
                    pages={getPages(pagesNotInvoiced)}
                    updateWorks={(value) => setWorksNotInvoiced(value)}
                  />
                )}
              </Tab>
              <Tab
                eventKey="invoices"
                title={"Liste des factures ( " + total + " )"}
              >
                {invoices && invoices.length > 0 ? (
                  <InvoicesTable
                    setInvoices={(values) => setInvoices(values)}
                    pagesInvoices={pagesInvoices}
                    invoices={invoices}
                    dentist_id={dentist_id}
                    laboratory_id={user.id}
                    name={"Liste des factures"}
                    headColumns={[
                      "total",
                      "dentist",
                      "created_at",
                      "total_price",
                      "remaining_price",
                      "path",
                      "status_paid",
                    ]}
                  />
                ) : (
                  "Aucune facture dans la liste."
                )}
              </Tab>
            </Tabs>
          ) : (
            <Alert variant="warning">
              {" "}
              Choisir un dentiste tout d'abord pour voir la liste des factures
              ...{" "}
            </Alert>
          )}
        </div>
      </main>
    </>
  );
};

export default InvoicesLabo;
