import React from "react";
import "./style.css";

const PlatformService = () => {
  return (
    <div className={"PlatformServiceContainer"}>
      <p className={"titlePlatform"}>
        La plateforme de gestion qu’il vous faut
      </p>
      <ul className={"DescriptionPlatform"}>
        <li>Créer une fiche de liaison pour chaque Travail en 20sécondes.</li>
        <li>
          Garder tous les détails d’une fiche de liaison avec tous son contenu
          (photos, fichiers attachées, journal d’activités...).
        </li>
        <li>Visualiser instantnnément l’état de tout vos travaux.</li>
        <li>
          Avec un simple click assigner vos travaux à vos collaborateurs (agents
          - coursiers / livreurs).
        </li>
      </ul>
    </div>
  );
};

export default PlatformService;
