import React from 'react';
import './style.css'
import Step1Image from '../../../assets/EasyLabImageStep1.png'
import Step2Image from '../../../assets/EasyLabImageStep2.png'

const EasyLabSteps = () => {
    const data = [
        {
            id: 1,
            text: 'Créer Votre Compte',
            image:  Step1Image
        },{
            id: 2,
            text: 'Inviter vos partenaires \n' +
                'Gérer et manager vos projets prothétiques',
            image:  Step2Image
        },
        {
            id: 3,
            text: 'Savourer votre vie',
            image:  Step1Image
        },
    ]
    return (
        <div className={'easyLabStepsContainer'}>
            <p>EasyLab En 3 étapes</p>
            <div className={'cardContainerSteps'}>
                {data.map((item, index)=>{
                    return (
                        <div className={'stepsDescription'} key={index.toString()}>
                            <img  src={item.image}/>
                            <pre>{item.text}</pre>
                        </div>
                    )
                })}
            </div>

        </div>
    );
};

export default EasyLabSteps;
