import React, { useState } from "react";
import Preloader from "../Shared/Preloader/Preloader";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  FormCheck,
  Image,
  InputGroup,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
// import BgImage from "../../assets/img/illustrations/signin.svg";
import ThemesbergLogo from "../../assets/easylab.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleUp,
  faArrowCircleRight,
  faCode,
  faEnvelope,
  faKey,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import ResetPasswordService, { checkmail } from "../../Services/ResetPassword";
import { PagesRoutes } from "../../pages_routes";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [rpassword, setRPassword] = useState("");
  const [error, setError] = useState("");
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const checkMail = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await ResetPasswordService.checkmail(email);
      if (response.status === 200) {
        setError("");
        setStep(2);
        setLoading(false);
      }
    } catch (e) {
      //if (e.status === 400 ){
      setError(e?.response?.data?.message);
      setLoading(false);
      //}
    }
  };
  const checkCode = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await ResetPasswordService.checkCode({ email, code });
      if (response.status === 200) {
        setError("");
        setStep(3);
        setLoading(false);
      }
    } catch (e) {
      //if (e.status === 400 ){
      setError(e?.response?.data?.message);
      setLoading(false);
      //}
    }
    //setStep(3);
  };
  const changePassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (password != rpassword) {
      setError("Mot de passe non confirmé");
    }
    try {
      const response = await ResetPasswordService.updatePassword({
        email,
        code,
        password,
      });
      if (response.status === 200) {
        setError("");
        setStep(4);
        setLoading(false);
      }
    } catch (e) {
      //if (e.status === 400 ){
      setError(e?.response?.data?.message);
      setLoading(false);
      //}
    }
  };
  return (
    <main>
      <Preloader />
      <section className="d-flex align-items-center my-4 mt-lg-3 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image" style={{}}>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="p-2 mb-4 text-center">
                  <Image
                    src={ThemesbergLogo}
                    className="card-img-top w-50  border-white"
                  />
                </div>

                <div className="text-center text-md-center my-4 mt-md-0">
                  <h3 className="mb-0">Mot de passe oublié </h3>
                </div>
                {error && error != "" ? (
                  <div className="alert alert-danger"> {error}</div>
                ) : (
                  ""
                )}
                {step == 1 ? (
                  <Form className="mt-5" onSubmit={checkMail}>
                    <Form.Group id="email" className="mb-4">
                      <Alert variant={"success"}>Entrer votre email.</Alert>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          onChange={(e) => setEmail(e.target.value)}
                          autoFocus
                          required
                          value={email}
                          type="email"
                          placeholder="example@company.com"
                        />
                      </InputGroup>
                    </Form.Group>
                    {loading ? (
                      <Button
                        variant="main"
                        disabled={"true"}
                        className="w-100"
                      >
                        <Spinner animation="border" />
                      </Button>
                    ) : (
                      <Button variant="main" type="submit" className="w-100">
                        Envoyer
                      </Button>
                    )}
                  </Form>
                ) : step == 2 ? (
                  <Form className="mt-4" onSubmit={checkCode}>
                    <Form.Group id="email" className="mb-4">
                      <Alert variant={"success"}>
                        Nous avons envoyé un email à <b>{email}</b>.<br />
                        Veuillez entrer le code.
                      </Alert>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCode} />
                        </InputGroup.Text>
                        <Form.Control
                          onChange={(e) => setCode(e.target.value)}
                          autoFocus
                          required
                          value={code}
                          type="password"
                          placeholder="Code"
                        />
                      </InputGroup>
                    </Form.Group>
                    <div className={"d-flex justify-content-end my-3"}>
                      <a>
                        {" "}
                        <FontAwesomeIcon icon={faArrowAltCircleUp} /> renvoyé le
                        code{" "}
                      </a>
                    </div>
                    {loading ? (
                      <Button
                        variant="main"
                        disabled={"true"}
                        className="w-100"
                      >
                        <Spinner animation="border" />
                      </Button>
                    ) : (
                      <Button variant="main" type="submit" className="w-100">
                        Envoyer
                      </Button>
                    )}
                  </Form>
                ) : step == 3 ? (
                  <Form className="mt-4" onSubmit={changePassword}>
                    <Alert variant={"success"}>
                      Entrer votre nouveau mot de passe
                    </Alert>

                    <Form.Group id="email" className="mb-4">
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faKey} />
                        </InputGroup.Text>
                        <Form.Control
                          onChange={(e) => setPassword(e.target.value)}
                          autoFocus
                          required
                          value={password}
                          type="password"
                          placeholder="Entrer votre nouveau mot de passe ..."
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group id="email" className="mb-4">
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faKey} />
                        </InputGroup.Text>
                        <Form.Control
                          onChange={(e) => setRPassword(e.target.value)}
                          autoFocus
                          required
                          value={rpassword}
                          type="password"
                          placeholder="Confirmer le mot de passe ..."
                        />
                      </InputGroup>
                    </Form.Group>
                    {loading ? (
                      <Button
                        variant="main"
                        disabled={"true"}
                        className="w-100"
                      >
                        <Spinner animation="border" />
                      </Button>
                    ) : (
                      <Button variant="main" type="submit" className="w-100">
                        Envoyer
                      </Button>
                    )}
                  </Form>
                ) : step == 4 ? (
                  <div>
                    <Alert variant={"success"} className={"text-center"}>
                      Votre mot de passe a été modifié avec succès.
                      <br />
                      Connectez-vous :{" "}
                      <a onClick={() => navigate(PagesRoutes.Login.path)}>
                        {" "}
                        <b>login</b>{" "}
                      </a>
                    </Alert>
                  </div>
                ) : (
                  ""
                )}

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Retourner à la page :
                    <Card.Link as={Link} to="/login" className="ms-2 fw-bold">
                      {` Connectez-vous `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default ResetPassword;
