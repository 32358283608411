import {
  Card,
  Form,
  Table,
  Col,
  Button,
  Dropdown,
  Modal,
  Badge,
  Image,
  Row,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowAltCircleRight,
  faCheckCircle,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faEye,
  faSync,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import UserUnknownAvatar from "../../../../assets/img/user-unknown.jpg";
import { translate_fr, translate_column_value } from "../../../../lang/fr";
import Paginator from "../Paginator";
import EditAgent from "../Modals/EditAgent";
import CheckToggle from "../CheckToggle";
import CustomToast from "../CustomToast";
import moment from "moment";
import { axiosInstance } from "../../../../config/axios";
import { APIURL } from "../../../../configApi";
import { NotificationManager } from "react-notifications";
import PaginatorInvitations from "../Paginator/PaginatorInvitations";

export default function (props) {
  const [userData, setUserData] = useState();
  const [showInvit, setShowInvit] = useState(false);
  const [checked, setChecked] = useState(false);
  const [pages, setPages] = useState(false);
  const [keys, setKeys] = useState([]);
  const [invitation, setInvitation] = useState({});
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDefault, setShowDefault] = useState(false);
  const { refreshInvitations, invitations, headColumns, name } = props;
  const handleClose = () => {
    setShowInvit(false);
    setShowDefault(false);
  };

  useEffect(() => {
    if (invitations.length > 0) setKeys(Object.keys(invitations[0]));
    renderThs();
    setPages(Math.floor(invitations.length / 10));
  }, []);

  const renderThs = async () => {
    let selectedKeys = [];

    if (headColumns.length > 0)
      headColumns.map((key) => {
        // console.log("data in if ==[>", key, keys);
        if (Object.keys(invitations[0]).includes(key)) selectedKeys.push(key);
      });
    // console.log(selectedKeys);
    setKeys(selectedKeys);
  };
  const rappeler = (id) => {
    axiosInstance
      .get(`${APIURL}/global/request_invitations/${id}/reminder`)
      .then((res) => {
        NotificationManager.success(
          "Rappel d'invitation envoyé avec succès",
          "Notification"
        );
      })
      .catch((err) => {
        NotificationManager.error(err.response.data.message, "Notification");
      });
  };

  const accepter = (id) => {
    axiosInstance
      .put(`${APIURL}/global/request_invitations/${id}/accepted`)
      .then((res) => {
        NotificationManager.success(
          "Invitation acceptée avec succès",
          "Notification"
        );
        refreshInvitations();
      })
      .catch((err) => {
        NotificationManager.error(
          "Erreur lors de l'acceptation de l'invitation",
          "Notification"
        );
      });
  };
  const renderLigne = () => {
    return invitations.map((invitation) => (
      <tr>
        {renderColumn(invitation)}
        <td>
          {invitation.status == "pending" ? (
            invitation.source_direction == "outgoing" ? (
              <Dropdown drop={faEllipsisV} className="me-2 mb-2">
                <Dropdown.Toggle split variant="default">
                  <FontAwesomeIcon
                    icon={faEllipsisV}
                    className="dropdown-arrow"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className={"user-dropdown dropdown-menu-right mt-2"}
                >
                  <Dropdown.Item
                    className={"text-success"}
                    onClick={() => rappeler(invitation.id)}
                  >
                    <FontAwesomeIcon
                      icon={faSync}
                      className="dropdown-arrow me-2"
                    />{" "}
                    Rappeler
                  </Dropdown.Item>

                  {/*<Dropdown.Item className={'text-danger'} onClick={() => setShowDefault(true)}><FontAwesomeIcon icon={faTrash} className="dropdown-arrow me-2" /> Annuler</Dropdown.Item>*/}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Button onClick={() => accepter(invitation.id)} variant={"main"}>
                <FontAwesomeIcon icon={faCheckCircle} /> Accepter{" "}
              </Button>
            )
          ) : (
            //     <Dropdown drop={faEllipsisV}  className="me-2 mb-2" >
            //
            //
            // <Dropdown.Toggle split variant="default" >
            // <FontAwesomeIcon icon={faEllipsisV} className="dropdown-arrow" />
            // </Dropdown.Toggle>
            //
            // <Dropdown.Menu className={'user-dropdown dropdown-menu-right mt-2'}>
            //
            // <Dropdown.Item  className={'text-success'} onClick={ () =>accepter(invitation.id) }>
            // <FontAwesomeIcon icon={faCheckCircle} className="dropdown-arrow me-2"  /> Acceptee</Dropdown.Item>
            // <Dropdown.Item className={'text-danger'} onClick={() => setShowDefault(true)}><FontAwesomeIcon icon={faTrash} className="dropdown-arrow me-2" /> Refusee</Dropdown.Item>
            //
            // </Dropdown.Menu>
            // </Dropdown>
            ""
          )}
        </td>
      </tr>
    ));
  };

  const renderColumn = (data) => {
    return headColumns.map((k, i) =>
      Object.keys(data).includes(k.toString()) ? (
        k === "laboratory" ? (
          <td
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowInvit(true);
              setUserData(data.laboratory);
            }}
          >
            {" "}
            {getObjectKeyValue(data, k)}
          </td>
        ) : k === "dentist" ? (
          <td
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowInvit(true);
              setUserData(data.dentist);
            }}
          >
            {" "}
            {getObjectKeyValue(data, k)}
          </td>
        ) : (
          <td> {getObjectKeyValue(data, k)}</td>
        )
      ) : (
        ""
      )
    );
  };

  const getObjectKeyValue = (obj, key) => {
    // console.log("get object value", obj.id, key);

    return Object.entries(obj).map((e) =>
      e[0] == key ? (
        ["created_at", "updated_at"].includes(e[0]) ? (
          moment.unix(e[1]).format("DD-MM-YYYY")
        ) : typeof e[1] === "object" ? (
          <>
            {" "}
            {translate_column_value(
              e[1].last_name + " " + e[1].first_name
            )}{" "}
          </>
        ) : e[1] == "pending" ? (
          <span className="badge bg-danger p-2">
            {" "}
            {translate_column_value(e[1])}
          </span>
        ) : e[1] == "accepted" ? (
          <span className="badge bg-main p-2">
            {" "}
            {translate_column_value(e[1])}
          </span>
        ) : e[1] == "outgoing" ? (
          <span className="badge bg-main p-2">
            {" "}
            {translate_column_value(e[1])}{" "}
          </span>
        ) : e[1] == "incoming" ? (
          <span className="badge bg-danger p-2">
            {" "}
            {translate_column_value(e[1])}{" "}
          </span>
        ) : (
          translate_column_value(e[1])
        )
      ) : (
        ""
      )
    );
  };

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <div>
            <h5 className="mb-2">{name}</h5>

            {/*<div className="w-100 d-flex align-items-center justify-content-start  py-4">*/}

            {/*<div className="w-25 me-2">*/}
            {/*    <span> Statue : </span>*/}
            {/*<select className="form-control">*/}
            {/*                                    <option> Acceptee </option>*/}
            {/*                                    <option> En cours </option>*/}
            {/*                                </select>*/}
            {/*</div>*/}
            {/*<div className="w-25">*/}
            {/*    <span> Type : </span>*/}
            {/*<select className="form-control">*/}
            {/*                                    <option> Recue </option>*/}
            {/*                                    <option> Envoyee </option>*/}
            {/*                                </select>*/}
            {/*</div>*/}

            {/*</div>*/}

            {props.searched ? (
              <input
                type={"text"}
                placeholder={"chercher ..."}
                className={"form-control"}
              />
            ) : (
              ""
            )}
          </div>
        </Card.Header>
        <Table
          responsive
          hover={true}
          className="align-items-center table-flush"
        >
          <thead className="thead-light">
            <tr>
              {keys.map((key, i) => (
                <th key={i}> {translate_fr(key, "invitations")} </th>
              ))}
              <th> </th>
            </tr>
          </thead>
          <tbody>{renderLigne()}</tbody>
        </Table>

        <div className="d-flex justify-content-center mt-3">
          <PaginatorInvitations
            totalPages={pages}
            setInvitations={refreshInvitations}
            activatedItem={1}
          />
        </div>
      </Card>

      {/*<EditAgent showForm={showEditForm} invitation={invitation} closeForm={()=>setShowEditForm(false)} />*/}

      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h4 text-main">Alert Suppression</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <p className={"text-dark"}>
            {" "}
            Voulez-vous vraiment supprimee ce agent ?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="main" className="ms-auto" onClick={handleClose}>
            Non
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Oui
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        as={Modal.Dialog}
        centered
        show={showInvit}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faEnvelope} /> Détails de l'invitation
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            {userData?.photo_profile ? (
              <Image
                src={userData?.photo_profile}
                className="rounded-circle mx-auto my-2"
                style={{ width: "8rem", height: "8rem" }}
              />
            ) : (
              <Image
                src={UserUnknownAvatar}
                className="rounded-circle mx-auto my-2"
                style={{ width: "8rem", height: "8rem" }}
              />
            )}
          </div>
          <hr />
          <Row>
            <Col md={6} sm={12}>
              <h5>
                Nom :{" "}
                <span className={"text-success"}> {userData?.first_name} </span>
              </h5>
            </Col>
            <Col md={6} sm={12}>
              <h5>
                Prénom :{" "}
                <span className={"text-success"}> {userData?.last_name} </span>
              </h5>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={6} sm={12}>
              <h5>
                Email :{" "}
                <span className={"text-success"}> {userData?.email} </span>
              </h5>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={6} sm={12}>
              <h5>
                Ville :{" "}
                <span className={"text-success"}>
                  {" "}
                  {userData?.city?.name} ( {userData?.state?.name} ){" "}
                </span>
              </h5>
            </Col>
            <Col md={6} sm={12}>
              <h5>
                Téléphone :{" "}
                <span className={"text-success"}>
                  {" "}
                  {userData?.principal_phone}{" "}
                </span>
              </h5>
            </Col>
          </Row>
          <hr />

          <div>
            <h5>
              Adresse :{" "}
              <span className={"text-success"}>{userData?.full_address}</span>
            </h5>
          </div>
          <hr />
          <div>
            <h5>
              Déscription :{" "}
              <span className={"text-success"}>{userData?.description}</span>
            </h5>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
