import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faBell,
  faCog,
  faEnvelopeOpen,
  faSearch,
  faSignOutAlt,
  faSortDown,
  faTooth,
  faUserShield,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import {
  Row,
  Col,
  Nav,
  Form,
  Image,
  Navbar,
  Dropdown,
  Container,
  ListGroup,
  InputGroup,
} from "@themesberg/react-bootstrap";

import NOTIFICATIONS_DATA from "../../../data/notifications";
import Profile3 from "../../../assets/img/team/profile-picture-3.jpg";
import { useNavigate } from "react-router-dom";
import { PagesRoutes } from "../../../pages_routes";
import axios from "axios";
import { axiosInstance } from "../../../config/axios";
import { APIURL } from "../../../configApi";
import UserUnknownAvatar from "../../../assets/img/user-unknown.jpg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import NetworkStatus from "../NetworkStatus";
const getOnLineStatus = () =>
  typeof navigator !== "undefined" && typeof navigator.onLine === "boolean"
    ? navigator.onLine
    : true;
export default (props) => {
  // console.log("pagename", props);
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  const areNotificationsRead = notifications.reduce(
    (acc, notif) => acc && notif.read,
    true
  );
  const [status, setStatus] = useState(getOnLineStatus());
  const setOnline = () => setStatus(true);
  const setOffline = () => setStatus(false);

  const goToSettings = () => {
    if (props.user.role == "laboratory") {
      navigate(PagesRoutes.LaboSettings.path);
    } else if (props.user.role == "dentist") {
      navigate(PagesRoutes.DentistSettings.path);
    } else if (props.user.role == "courier") {
      navigate(PagesRoutes.CourierProfile.path);
    }
  };
  const goToSupport = () => {
    navigate("/support");
  };
  const markNotificationsAsRead = () => {
    setTimeout(() => {
      setNotifications(notifications.map((n) => ({ ...n, read: true })));
    }, 300);
  };
  const goToMyProfile = () => {
    if (props.user.role == "laboratory") {
      navigate(PagesRoutes.LaboProfile.path);
    } else if (props.user.role == "dentist") {
      navigate(PagesRoutes.DentistProfile.path);
    } else if (props.user.role == "courier") {
      navigate(PagesRoutes.CourierProfile.path);
    }
  };

  const Notification = (props) => {
    const { link, sender, image, time, message, read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="col-auto">
            <Image
              src={image}
              className="user-avatar lg-avatar rounded-circle"
            />
          </Col>
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  const logout = () => {
    axiosInstance
      .post(`${APIURL}/global/auth/logout`, {
        fcm_token: localStorage.getItem("token") || "",
      })
      .then((res) => {
        localStorage.clear();
        navigate("/login");
      })
      .catch((err) => {
        NotificationManager.error("Error lors de deconnexion", "Auth");
      });
  };
  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div>
          <h2>
            {" "}
            <FontAwesomeIcon icon={props.icon} /> {props.pageName}
          </h2>
        </div>
        <div className="d-flex justify-content-end w-50">
          <Nav className="align-items-center">
            <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead}>
              <Dropdown.Toggle
                as={Nav.Link}
                className="text-dark icon-notifications me-lg-3"
              >
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" />
                  {areNotificationsRead ? null : (
                    <span className="icon-badge rounded-circle unread-notifications" />
                  )}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link
                    href="#"
                    className="text-center text-primary fw-bold border-bottom border-light py-3"
                  >
                    Notifications
                  </Nav.Link>

                  {notifications.map((n) => (
                    <Notification key={`notification-${n.id}`} {...n} />
                  ))}

                  <Dropdown.Item className="text-center text-primary fw-bold py-3">
                    View all
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>

            <NetworkStatus />

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  {props.user?.photo_profile ? (
                    <Image
                      src={props.user?.photo_profile}
                      className="user-avatar md-avatar rounded-circle"
                    />
                  ) : (
                    <Image
                      src={UserUnknownAvatar}
                      className="user-avatar md-avatar rounded-circle"
                    />
                  )}

                  <div className="media-body ms-2 text-dark align-items-center">
                    {props.user?.role == "dentist" ? (
                      <span className="mb-0 font-small fw-bold">
                        <FontAwesomeIcon icon={faTooth} className={"me-2"} />
                        {props.user?.last_name + " " + props.user?.first_name}
                      </span>
                    ) : (
                      <span className="mb-0 font-small fw-bold">
                        {/*<FontAwesomeIcon icon={faTooth} className={'me-2'}/>*/}
                        {props.user?.last_name + " " + props.user?.first_name}
                      </span>
                    )}
                    <FontAwesomeIcon className="ms-2" icon={faSortDown} />
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item
                  className="fw-bold"
                  onClick={() => {
                    goToMyProfile();
                  }}
                >
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> Mon
                  profil
                </Dropdown.Item>

                {props.user?.role != "courier" ? (
                  <Dropdown.Item
                    className="fw-bold"
                    onClick={() => {
                      goToSettings();
                    }}
                  >
                    <FontAwesomeIcon icon={faCog} className="me-2" /> Paramètres
                  </Dropdown.Item>
                ) : (
                  ""
                )}

                {/* <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> Messages
                </Dropdown.Item> */}
                <Dropdown.Item
                  className="fw-bold"
                  onClick={() => {
                    goToSupport();
                  }}
                >
                  <FontAwesomeIcon icon={faUserShield} className="me-2" />{" "}
                  Contactez Nous
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item className="fw-bold" onClick={logout}>
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    className="text-danger me-2"
                  />{" "}
                  Déconnexion
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
