import React, { useState } from "react";
import "./style.css";
import { Button, Form } from "@themesberg/react-bootstrap";
import ContactService from "../../services/ContactService";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [notification, setNotification] = useState("");
  const [loading, setLoading] = useState(false);
  const [notifType, setNotifType] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setNotifType("");
    try {
      const response = await ContactService.sendEmail({
        client_name: "Newsletter",
        email: email,
        phone: "Newsletter",
        subject: "Newsletter",
        description: "Newsletter",
      });
      if (response.status === 200) {
        setNotifType("success");
        setNotification(
          "Vous êtes maintenant inscrit dans notre newsletter.",
          "Notification"
        );
        setLoading(false);
      }
    } catch (err) {
      setNotifType("error");
      setNotification(err?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <div className={"newsletterContainer"}>
      <h1>Abonnez-vous à notre newsletter</h1>
      <h3>Et restez informé aux dernières mise à jour de la Plateforme</h3>
      {notification && notification.length > 0 ? (
        <h3
          className={
            notifType === "success"
              ? `text-success text-start`
              : `text-danger text-start`
          }
        >
          {notification}
        </h3>
      ) : (
        ""
      )}

      <Form onSubmit={handleSubmit}>
        <div className={"newsletterInputContainer"}>
          <input
            placeholder={"Entrer votre adresse E-mail"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            className={"NewsletterSubmitButton"}
            variant="primary"
            type="submit"
          >
            Abonnez-vous
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Newsletter;
