import React from "react";
import { Card } from "@themesberg/react-bootstrap";
import { useNavigate } from "react-router-dom";
const Category = ({ category }) => {
  const { id, name } = category;
  const navigate = useNavigate();
  return (
    <>
      <Card className="mb-2">
        <Card.Header
          onClick={() => {
            navigate(`/laboratory/stock/category/${id}`);
          }}
        >
          {name}
        </Card.Header>
      </Card>
    </>
  );
};
export default Category;
