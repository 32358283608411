import { useNavigate } from "react-router-dom";
import Preloader from "../../Shared/Preloader/Preloader";
import Sidebar from "../../Shared/Sidebar";
import Navbar from "../../Shared/Navbar/Navbar";
import { Button, Spinner, Tab, Tabs } from "@themesberg/react-bootstrap";
import { PagesRoutes } from "../../../pages_routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faPlusCircle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { NotificationContainer } from "react-notifications";
import React, { useEffect, useState } from "react";
import { currentUser } from "../../../authentication/auth";
import Works from "../../Shared/components/Table/Works";

import { APIURL, getPages } from "../../../configApi";
import LaboratoryService from "../../services/LaboratoireService";
import Archives from "../../Shared/components/Table/Archives";
import DentistService from "../../services/DentistService";
import FirebaseNotif from "../../Shared/FirebaseNotif/FirebaseNotif";

const ArchivesDentist = () => {
  const navigate = useNavigate();
  const [pages, setPages] = useState(0);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [outgoing_dentist_rows, setOutgoing_dentist_rows] = useState(0);
  const [outgoing_laboratory_rows, setOutgoing_laboratory_rows] = useState(0);
  const [at_dentist_rows, setAt_dentist_rows] = useState(0);
  const [at_laboratory_rows, setAt_laboratory_rows] = useState(0);
  const [currentStatus, setCurrentStatus] = useState("outgoing_dentist");
  const [works, setWorks] = useState([]);
  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    //getAgents()
    getWorks();
  }, []);

  const getWorks = async (e) => {
    try {
      setLoading(true);
      const response = await DentistService.worksArchivee(pages);
      if (response) {
        setWorks(response.data.data.list);
        setLoading(false);
        setPages(getPages(response.data.data.total));
      }
    } catch (err) {
      // console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      <Preloader />
      <Sidebar user={user} />
      {/* <FirebaseNotif /> */}
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar
          user={user}
          pageName={"Liste des travaux archivés"}
          icon={faBriefcase}
        />
        <hr />
        <div>
          <NotificationContainer />

          <div>
            {loading ? (
              <Spinner animation={"border"} className="m-4" />
            ) : (
              <Archives
                pages={pages}
                works={works}
                role={"dentist"}
                setWorks={(value) => setWorks(value)}
              />
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default ArchivesDentist;
