import React from "react";
import "./style.css";
import LandingHeader from "./Landing-Header/LandingHeader";
import CoverPage from "./cover-page/coverPage";
import MakeSimple from "./make-simple/makesimple";
import Prothesiste from "./prothesiste/Prothesiste";
import SaverVous from "./saver-vous/saver-vous";
import EasyLabSteps from "./easy-lab-steps/easy-lab-steps";
import OurService from "./our-service/our-service";
import DispoServiceLanding from "./dispo-service/dispo-serviceLanding";
import PlatformService from "./platformService/platform-service";
import SecondaryAccount from "./secondary-account/secondary-account";
import Feature from "./Features/feature";
import Avis from "./avis/avis";
import Contactus from "./contact-us/contactus";
import Newsletter from "./newsletter/newsletter";
import LandingFooter from "./LandingFooter/LandingFooter";
import QuetionsLanding from "./questionLanding/quetionsLanding";
import { NotificationContainer } from "react-notifications";
const Landing = () => {
  return (
    <>
      <NotificationContainer />
      <LandingHeader />
      <CoverPage />
      <MakeSimple />
      <Prothesiste />
      <SaverVous />
      <EasyLabSteps />
      <OurService />
      <DispoServiceLanding />
      <PlatformService />
      <SecondaryAccount />
      <Feature />
      <Avis />
      <QuetionsLanding />
      <Contactus />
      <Newsletter />
      <LandingFooter />
    </>
  );
};

export default Landing;
