import {
  Card,
  Form,
  Table,
  Col,
  Button,
  Dropdown,
  Modal,
  Row,
  Spinner,
  FormGroup,
  FormLabel,
  FormControl,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faCalendarDay,
  faCalendarTimes,
  faEdit,
  faEllipsisV,
  faEye,
  faMoneyBill,
  faPlug,
  faPlusCircle,
  faPlusSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { translate_fr } from "../../../../lang/fr";
import Paginator from "../Paginator";
import EditAgent from "../Modals/EditAgent";
import CheckToggle from "../CheckToggle";

import { axiosInstance } from "../../../../config/axios";
import { APIURL } from "../../../../configApi";
import { NotificationManager } from "react-notifications";
import ShowAgent from "../../../Laboratoire/Agents/Show";
import ShowCourier from "../../../Laboratoire/Couriers/Show";
import EditCourier from "../Modals/EditCourier";
import ShowDentist from "../../../Laboratoire/Dentists/Show";
import PaginatorDentistList from "../Paginator/PaginatorDentistList";
import LaboratoryService from "../../../services/LaboratoireService";

export default function (props) {
  const [day, setDay] = useState();
  const [showAddPaiment, setShowAddPaiment] = useState(false);
  const [showSpecificPrice, setShowSpecificPrice] = useState(false);
  const [showDateFacturation, setShowDateFacturation] = useState(false);
  const [checked, setChecked] = useState(false);
  const [keys, setKeys] = useState([]);
  const [user, setUser] = useState({});
  const [showEditForm, setShowEditForm] = useState(false);
  const [showUserData, setShowUserData] = useState(false);
  const [showDefault, setShowDefault] = useState(false);
  const [searchKeywords, setSearchKeywords] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [fees, setFees] = useState();
  const [showAdditionalFees, setShowAdditionalFees] = useState(false);
  const { refreshUsers, users, setUsers, pages } = props;
  const [discount, setDiscount] = useState(0);
  const [GPrice, setGPrice] = useState(0);
  const [service_id, setServiceId] = useState("");
  const handleClose = () => {
    setShowDefault(false);
    setShowDateFacturation(false);
    setShowAdditionalFees(false);
    setShowAddPaiment(false);
    setShowSpecificPrice(false);
  };
  const [price, setPrice] = useState("");
  const [code_cheque, setCodeCheque] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const addNewPayment = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (formValidation()) {
      try {
        const response = await LaboratoryService.addPayment({
          payment_type: paymentMode,
          description: code_cheque,
          dentist_id: user.id,
          price: price,
        });
        if (response.status === 200) {
          setLoading(false);
          //setShowDefault(false);
          setPrice("");
          setShowAddPaiment(false);
          //setDentist_id("");
          setPaymentMode("");
          setCodeCheque("");
          //getMyPayments();
          NotificationManager.success(
            "Paiement ajouté avec succès",
            "Notification"
          );
        }
      } catch (err) {
        setLoading(false);
        NotificationManager.error(err.response.data.message, "Notification");
      }
    } else {
      setLoading(false);
    }
  };
  const formValidation = () => {
    var localerrors = {};

    if (paymentMode === "") {
      localerrors = {
        ...localerrors,
        ...{ paymentMode: "Choissisez le mode paiement ... " },
      };
    }

    if (price === "") {
      localerrors = {
        ...localerrors,
        ...{ price: "Le montant ne peut pas être vide ... " },
      };
    }
    setErrors(localerrors);
    if (Object.keys(localerrors).length > 0) return false;
    else return true;
  };
  const addRemise = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});
    if (user.length === 0) {
      setErrors({ user: "Choisir au moins un dentiste" });
      setLoading(false);
      return false;
    }
    if (service_id.length === 0) {
      setErrors({ service_id: "Choisir un service" });
      setLoading(false);
      return false;
    }
    if (discount.length === 0) {
      setErrors({ discount: "Choisir un nouveau prix" });
      setLoading(false);
      return false;
    }
    // console.log(parseInt(GPrice) - parseInt(discount));
    if (parseInt(GPrice) - parseInt(discount) <= 0) {
      setErrors({ discount: "Valeur erronée" });
      setLoading(false);
      return false;
    }
    setDiscount(parseInt(GPrice) - parseInt(discount));

    try {
      const res = await LaboratoryService.addRemise({
        ...{
          dentists_ids: [user.id],
          service_id,
        },
        ...{ discount: parseInt(GPrice) - parseInt(discount) },
      });
      if (res.status === 200) {
        NotificationManager.success(
          "Prix  ajouté avec succès",
          "Prix Spécifique"
        );
        //setUser(null);
        // setDentistsIds([]);
        setDiscount("");
        setServiceId("");
        getGroupes();

        handleClose();
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      NotificationManager.error(
        "Problème lors de l'ajout de prix",
        "Prix Spécifique"
      );
    }
  };
  const getGroupes = () => {
    axiosInstance
      .get(`${APIURL}/laboratory/groups`)
      .then((res) => {
        // console.log(res.data.data.list);
        setGroupes(res.data.data.list);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const [groupes, setGroupes] = useState();
  const getServiceGPrice = (serviceId) => {
    groupes.map((g) => {
      g.sub_groups.map((sb) => {
        sb.services.map((s) => {
          if (s.id == serviceId) {
            setGPrice(s.price);
          }
        });
      });
    });
  };
  const [errors, setErrors] = useState({
    user: "",
    discount: "",
    service_id: "",
  });
  useEffect(() => {
    if (users.length > 0) {
      setKeys(Object.keys(props.users[0]));
      renderThs();
    }
    getGroupes();
  }, []);

  const editAdditionalFees = async (e) => {
    e.preventDefault();
    try {
      const response = await LaboratoryService.Additional_fees(user.id, {
        additional_fees: fees,
      });
      if (response.status === 200) {
        NotificationManager.success(response?.data?.message, "Notification");
        refreshUsers();
        handleClose();
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const renderThs = async () => {
    let selectedKeys = [];
    //// console.log(selectedKeys);
    //// console.log(keys);
    if (keys)
      Object.keys(props.users[0]).map((key) => {
        if (props.headColumns.includes(key)) selectedKeys.push(key);
      });
    //console.log(selectedKeys);
    setKeys(selectedKeys);
  };
  const editAgent = (user) => {
    setUser(user);
    setShowEditForm(true);
    //console.log(showEditForm)
  };

  const renderLigne = () => {
    return props.users.map((selectedUser) => (
      <tr>
        {renderColumn(selectedUser)}
        <td>
          <Dropdown drop={faEllipsisV} className="me-2 mb-2">
            <Dropdown.Toggle split variant="default">
              <FontAwesomeIcon icon={faEllipsisV} className="dropdown-arrow" />
            </Dropdown.Toggle>

            <Dropdown.Menu className={"user-dropdown dropdown-menu-right mt-2"}>
              <Dropdown.Item
                className={"text-main"}
                onClick={() => {
                  setShowUserData(true);
                  setUser(selectedUser);
                }}
              >
                <FontAwesomeIcon icon={faEye} className="dropdown-arrow me-2" />{" "}
                Afficher
              </Dropdown.Item>
              {/* <Dropdown.Item
                onClick={() => {
                  setShowDateFacturation(true);
                  setUser(selectedUser);

                  setDay(selectedUser?.invoice_day_by_current_laboratory);
                }}
              >
                <FontAwesomeIcon
                  icon={faCalendarDay}
                  className="dropdown-arrow me-2"
                />{" "}
                Jour de facturation
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setShowAdditionalFees(true);
                  setUser(selectedUser);
                  setFees(selectedUser?.additional_fees);
                }}
              >
                <FontAwesomeIcon
                  icon={faPlusSquare}
                  className="dropdown-arrow me-2"
                />{" "}
                Frais supplémentaires
              </Dropdown.Item> */}

              <Dropdown.Item
                onClick={() => {
                  setShowAddPaiment(true);
                  setUser(selectedUser);
                }}
              >
                <FontAwesomeIcon
                  icon={faPlusSquare}
                  className="dropdown-arrow me-2"
                />{" "}
                Ajouter paiement
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setShowSpecificPrice(true);
                  setUser(selectedUser);
                }}
              >
                <FontAwesomeIcon
                  icon={faCalendarDay}
                  className="dropdown-arrow me-2"
                />{" "}
                Ajouter un prix specifique
              </Dropdown.Item>
              {/*<Dropdown.Item className={'text-danger'} onClick={() => {setShowDefault(true);setAgent(selectedUser)} }><FontAwesomeIcon icon={faTrash} className="dropdown-arrow me-2" /> Supprimer</Dropdown.Item>*/}
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    ));
  };

  const renderColumn = (data) => {
    return Object.keys(data).map((k, i) =>
      props.headColumns.includes(k.toString()) ? (
        <td> {getObjectKeyValue(data, k)}</td>
      ) : (
        ""
      )
    );
  };

  const getObjectKeyValue = (obj, key) => {
    // console.log("get object value", obj.id, key);

    return Object.entries(obj).map((e) =>
      e[0] == key ? (
        [true, false].includes(e[1]) ? (
          obj.email.indexOf("temporary") != -1 ? (
            <>
              {" "}
              <CheckToggle type={"dentist"} checked={e[1]} id={obj.id} />{" "}
            </>
          ) : (
            <></>
          )
        ) : e[1].indexOf("temporary") != -1 ? (
          <span className="badge bg-success"> Compte local </span>
        ) : (
          e[1]
        )
      ) : (
        ""
      )
    );
  };

  const searchUser = (value) => {
    if (searchKeywords) {
      axiosInstance
        .get(`${APIURL}/laboratory/dentists/all?search=${value}`)
        .then((res) => setUsers(res.data.data))
        .catch((err) => {}); // console.log(err));
    }
  };

  const affectDateToDentist = async (e) => {
    e.preventDefault();

    try {
      const response = await LaboratoryService.updateFacturationDateDentist(
        user.id,
        { day: day }
      );
      if (response.status === 200) {
        NotificationManager.success(
          "Actualisation de la date de facturation mensuelle effectuè avec succès",
          "Notification"
        );
        refreshUsers();
        handleClose();
        setDay(1);
      }
    } catch (err) {
      NotificationManager.error(
        "Erreur lors de l'actualisation de la date de facturation mensuelle",
        "Notification"
      );
      handleClose();
      setDay(1);
    }
  };
  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <div>
            <h5 className="mb-2">{props.name}</h5>

            <input
              type={"text"}
              value={searchKeywords}
              placeholder={"chercher ..."}
              onChange={(e) => {
                setSearchKeywords(e.target.value);
                searchUser(e.target.value);
              }}
              className={"form-control"}
            />
          </div>
        </Card.Header>

        {loading ? (
          <div className="text-center">
            <Spinner animation={"border"} className="my-4" />
          </div>
        ) : (
          <div>
            <Table
              responsive
              hover={true}
              className="align-items-center table-flush"
            >
              <thead className="thead-light">
                <tr>
                  {keys.map((key, i) => (
                    <th key={i}> {translate_fr(key, "agents")} </th>
                  ))}
                  <th> </th>
                </tr>
              </thead>
              <tbody>{renderLigne()}</tbody>
            </Table>

            <div className="d-flex justify-content-center mt-3">
              <PaginatorDentistList
                totalPages={pages}
                loader={(value) => setLoading(value)}
                setDentists={(value) => setUsers(value)}
                updatePageSelected={(value) => setPage(value)}
                activatedItem={page}
              />
            </div>
          </div>
        )}
      </Card>

      <ShowDentist
        page={page}
        refreshList={(value) => setUsers(value)}
        showForm={showUserData}
        dentist={user}
        closeForm={() => setShowUserData(false)}
        updateDentist={() => refreshUsers()}
      />

      <Modal
        as={Modal.Dialog}
        centered
        show={showDateFacturation}
        onHide={handleClose}
      >
        <Form onSubmit={affectDateToDentist}>
          <Modal.Header>
            <Modal.Title className="h4 text-main">
              Choisir la date de facturation automatique pour Dr.{" "}
              {user.first_name}{" "}
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Nouvelle date de facturation</Form.Label>
              <Form.Control
                value={day}
                type={"number"}
                max={"28"}
                min={"1"}
                onChange={(e) => setDay(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" className="ms-auto" onClick={handleClose}>
              Annuler
            </Button>
            <Button variant="main" type={"submit"}>
              Enregistrer
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        as={Modal.Dialog}
        centered
        show={showAdditionalFees}
        onHide={handleClose}
      >
        <Form onSubmit={editAdditionalFees}>
          <Modal.Header>
            <Modal.Title className="h4 text-main">
              Modifier les frais supplémentaires pour Dr.{user.first_name}{" "}
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Frais supplémentaires</Form.Label>
              <Form.Control
                value={fees}
                type={"number"}
                max={"50"}
                min={"0"}
                onChange={(e) => setFees(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" className="ms-auto" onClick={handleClose}>
              Annuler
            </Button>
            <Button variant="main" type={"submit"}>
              Enregistrer
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        as={Modal.Dialog}
        centered
        show={showSpecificPrice}
        onHide={handleClose}
      >
        <Form>
          <Modal.Header>
            <Modal.Title className="h6">Ajouter un prix spécifique</Modal.Title>

            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <Form>
              {/* <FormGroup>
                <FormLabel>Dentistes : </FormLabel>

                {options && options.length > 0 ? (
                  <Select
                    name="dentist"
                    onChange={userIdHandler}
                    options={options}
                    isMulti
                  />
                ) : (
                  ""
                )}
                {errors && errors.dentists_ids ? (
                  <p className="text-danger"> {errors.dentists_ids} </p>
                ) : (
                  ""
                )}
              </FormGroup> */}
              <FormGroup>
                <FormLabel>Liste des services : </FormLabel>
                <select
                  className="form-control"
                  value={service_id}
                  onChange={(e) => {
                    setServiceId(e.target.value);
                    getServiceGPrice(e.target.value);
                  }}
                >
                  <option> Choisir une service </option>
                  {groupes && groupes.length > 0
                    ? groupes.map((g) =>
                        g.sub_groups.map((sg) => (
                          <optgroup label={sg.name + ` ( ` + g.name + ` )`}>
                            {" "}
                            {sg.services.map((s) => (
                              <option value={s.id}>{s.name}</option>
                            ))}
                          </optgroup>
                        ))
                      )
                    : ""}
                </select>
              </FormGroup>
              {errors && errors.service_id ? (
                <p className="text-danger"> {errors.service_id} </p>
              ) : (
                ""
              )}
              <Row>
                <Col>
                  {" "}
                  <FormGroup>
                    <FormLabel>Prix spécifique : </FormLabel>
                    <FormControl
                      min={0}
                      max={GPrice - 1}
                      type="number"
                      value={discount}
                      onChange={(e) => setDiscount(e.target.value)}
                    />
                  </FormGroup>
                  {errors && errors.discount ? (
                    <p className="text-danger"> {errors.discount} </p>
                  ) : (
                    ""
                  )}
                </Col>
                <Col>
                  <h6 className="mt-2">Prix général :</h6>
                  <h6 className="mt-2">{GPrice} DT</h6>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={"danger"} onClick={handleClose}>
              Annuler
            </Button>
            {loading ? (
              <Button variant={"success"}>
                <Spinner animation="border" />
              </Button>
            ) : (
              <Button variant={"success"} onClick={addRemise}>
                Sauvegarder
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        as={Modal.Dialog}
        centered
        show={showAddPaiment}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faMoneyBill} /> Ajouter une nouvelle tranche
            de paiement
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={addNewPayment}>
            <Row>
              {/* <Col>
                <div className={"mb-2"}>
                  <label> Dentiste </label>
                  <select
                    className="form-control"
                    value={dentist_id}
                    onChange={(e) => setDentist_id(e.target.value)}
                  >
                    <option value=""> == Choisir le dentiste == </option>
                    {dentists.map((d) => (
                      <option value={d.id}>
                        {" "}
                        {d.last_name + " " + d.first_name}{" "}
                      </option>
                    ))}
                  </select>

                  {errors?.dentist_id && (
                    <span
                      className="text-danger mt-3 fw-bold"
                      style={{ fontSize: "14px" }}
                    >
                      {" "}
                      * {errors?.dentist_id}{" "}
                    </span>
                  )}
                </div>
              </Col> */}
              <Col>
                <div className={"mb-2"}>
                  <label> Mode de Paiement </label>
                  <select
                    className="form-control"
                    value={paymentMode}
                    onChange={(e) => setPaymentMode(e.target.value)}
                  >
                    <option> == Choisir le mode de paiement == </option>
                    <option value="cash"> Espèce </option>
                    <option value="cheque"> Chèque </option>
                    <option value="money_order"> Virement </option>
                  </select>

                  {errors?.paymentMode && (
                    <span
                      className="text-danger mt-3 fw-bold"
                      style={{ fontSize: "14px" }}
                    >
                      {" "}
                      * {errors?.paymentMode}{" "}
                    </span>
                  )}
                </div>
              </Col>
            </Row>

            <FormGroup className={"mb-2"}>
              <label>Montant</label>
              <FormControl
                type={"number"}
                min={0}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                placeholder={"Montant du paiement ..."}
              ></FormControl>
              {errors?.price && (
                <span
                  className="text-danger mt-3 fw-bold"
                  style={{ fontSize: "14px" }}
                >
                  {" "}
                  * {errors?.price}{" "}
                </span>
              )}
            </FormGroup>

            <FormGroup className={"mb-2"}>
              <label>Informations supplémentaires </label>
              <FormControl
                as={"textarea"}
                value={code_cheque}
                onChange={(e) => setCodeCheque(e.target.value)}
                placeholder={"Ajouter des notes ..."}
              ></FormControl>
            </FormGroup>

            <div className={"d-flex justify-content-end mb-2"}>
              {loading ? (
                <Button>
                  <Spinner animation="border" />
                </Button>
              ) : (
                <Button type={"submit"} variant={"main"}>
                  <FontAwesomeIcon icon={faPlusCircle} /> Ajouter{" "}
                </Button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
