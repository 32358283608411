import { axiosInstance } from "../../config/axios";
import { APIURL } from "../../configApi";

const DentistService = {};

DentistService.archiver = (workId) => {
  return axiosInstance.put(`${APIURL}/dentist/works/${workId}/archived`);
};

DentistService.addSecritaire = (data) => {
  return axiosInstance.post(`${APIURL}/dentist/secretaries`, data);
};

DentistService.sentToLaboratory = (workId, data) => {
  return axiosInstance.put(
    `${APIURL}/global/works/${workId}/change_status`,
    data
  );
};

DentistService.recievedDentist = (workId, data) => {
  return axiosInstance.put(
    `${APIURL}/global/works/${workId}/change_status`,
    data
  );
};

DentistService.updatePatient = (idWork, data) => {
  return axiosInstance.put(
    `${APIURL}/global/works/${idWork}/update_patient`,
    data
  );
};

DentistService.acceptWork = (idWork) => {
  return axiosInstance.put(`${APIURL}/dentist/works/${idWork}/accept`);
};

DentistService.Laboratories = () => {
  return axiosInstance.get(`${APIURL}/dentist/laboratories/all`);
};

DentistService.agentListe = () => {
  return axiosInstance.get(`${APIURL}/dentist/secretaries/all`);
};

DentistService.Invitations = () => {
  return axiosInstance.get(`${APIURL}/global/request_invitations`);
};

DentistService.getAllWorks = () => {
  return axiosInstance.get(`${APIURL}/global/works`);
};

DentistService.Payments = (page) => {
  return axiosInstance.get(`${APIURL}/dentist/payments?page=${page}`);
};

DentistService.DownloadInvoice = (id) => {
  return axiosInstance.get(`${APIURL}/global/download_files/${id}/invoices`);
};

DentistService.worksArchivee = (page) => {
  return axiosInstance.get(
    `${APIURL}/global/works?page=${page}&status=archived`
  );
};

DentistService.acceptInvitation = (id) => {
  return axiosInstance.put(
    `${APIURL}/global/request_invitations/${id}/accepted`,
    {}
  );
};

DentistService.worksToBeInvoicedByLaboratory = (id, page) => {
  return axiosInstance.get(
    `${APIURL}/dentist/works/pre_invoice?status_invoiced=to_be_invoiced&laboratory_id=${id}&page=${page}`
  );
};

export default DentistService;
