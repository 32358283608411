import React from 'react';
import './style.css'

const MakeSimple = () => {
    return (
        <div className={"MakeSimpleContainer"} id="apropos">
            <p className={'titleSimple'}>Pour faire Simple</p>
            <p className={'DescriptionSimple'}>
                Avoir un compte à <span>EasyLab.tn</span> vous aide à reprendre l’Artiste caché en vous et votre compte EasyLab
                vous facilite toute vos tâches orgnisationnelles Quotidienne en un click de façon tres simpifiée,
                Intuitive, dynamique, Multi-utilisateurs et EASY-To-USE.
            </p>
        </div>
    );
};

export default MakeSimple;
