import { axiosInstance } from "../../config/axios";
import { APIURL } from "../../configApi";

const CoursierService = {};
CoursierService.changeStatus = (id, status) => {
  return axiosInstance.put(`${APIURL}/courier/works/${id}/change_sub_status`, {
                        sub_status_courier: status,
  });
};

export default CoursierService;
