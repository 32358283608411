const {axiosInstance} = require("../config/axios");
const {APIURL} = require("../configApi");

const SupportService = {}
SupportService.sendMessage = (data)=> {
        return  axiosInstance.post(`${APIURL}/global/contact_us`,
                    data
            )
}



export default SupportService;