import { axiosInstance } from "../../config/axios";
import { APIURL } from "../../configApi";

const InvitatationService = {};
InvitatationService.List = (page) => {
  let data = [];
  axiosInstance
    .get(`${APIURL}/global/request_invitations?page=${page}`)
    .then((res) => {
      //// console.log(res)
      data = res.data.data.list;
    })
    .catch((err) => {
      data = "error";
    });

  return data;
};

export default InvitatationService;
