const data = {
  city: "Ville",
  first_name: "Nom",
  last_name: "Prenom",
  cin: "CIN",
  email: "Email",
  principal_phone: "Telephone",
  is_active: "Active",
  status: "Statue",
  created_at: "Date d'ajout",
  updated_at: "Date de modification",
};
const commun = {
  archived: "Archivé",
  next: "Suivant",
  previous: "Précédent",
  price: "Montant",
  remaining_price: "Montant restant",
  dentist_id: "Dentiste",
  dentist: "Dentiste",
  courier: "Coursier",
  laboratory_id: "Laboratoire",
  laboratory: "Laboratoire",
  outgoing_laboratory: "Sortie Laboratoire",
  outgoing_dentist: "Sortie Dentiste",
  at_laboratory: "Au Laboratoire",
  at_dentist: "Au Dentiste",
  man: "M",
  women: "F",
  created_at: "Date de création",
  updated_at: "Date de modification",
};

const InvoicesData = {
  total_price: "Montant",
  total: "Total",
  path: "Lien",
  status_paid: "Statut du paiement",
};

const InvitationData = {
  source_direction: "Type",
  laboratory: "laboratoire",
  dentist: "Dentiste",
  source_invitation: "Depuis",
  status: "Statut",
  created_at: "Date de l'invitation",
  updated_at: "Date de modification",
};

const PayementsData = {
  dentist: "Dentiste",
  is_total_used: "Totalement utilisé",
};

const values = {
  laboratory: "laboratoire",
  pending: "En cours",
  accepted: "Acceptée",
  outgoing: "Envoyée",
  incoming: "Reçue",
  not_paid: "Pas encore payé",
  paid: "Payé",
  partial_paid: "Partiellement payé",
};

export const translate_fr = (field: string, type: string) => {
  if (field === null || field === undefined || field.length === 0) return "";

  //console.log("field", field);
  let value = "";
  let keys;
  let localData = commun;
  if (type === "agents") {
    localData = { ...data, ...localData };
  } else if (type === "invitations") {
    localData = { ...InvitationData, ...localData };
  } else if (type === "payments") {
    localData = { ...PayementsData, ...localData };
  } else if (type === "invoices") {
    localData = { ...InvoicesData, ...localData };
  } else {
    localData = { ...values, ...localData };
  }
  keys = Object.keys(localData);

  if (typeof field === "string") {
    // @ts-ignore
    if (keys.includes(field.toLowerCase()))
      // @ts-ignore
      value = localData[field.toLowerCase()];
    return value;
  } else {
    return "";
  }
};

export const translate_column_value = (field: string) => {
  let value = field;
  let keys = Object.keys(values);
  // @ts-ignore
  if (keys.includes(field.toLowerCase())) value = values[field.toLowerCase()];
  return value;
};
