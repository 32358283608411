import {
  faCircle,
  faDesktop,
  faMobileAlt,
  faTabletAlt,
  faUser,
  faUserAlt,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";

const trafficShares = [
  {
    id: 1,
    label: "Agent 1 : Kamel",
    value: 50,
    color: "tertiary",
    icon: faUser,
  },
  {
    id: 2,
    label: "Agent 2 : Mariem",
    value: 20,
    color: "secondary",
    icon: faUserAlt,
  },
  {
    id: 3,
    label: "Agent 3 : Ines",
    value: 10,
    color: "primary",
    icon: faUserCircle,
  },
  {
    id: 4,
    label: "Non affecte",
    value: 20,
    color: "warning",
    icon: faCircle,
  },
];

const totalOrders = [
  { id: 1, label: "July", value: [1, 5, 2, 5, 4, 3], color: "primary" },
  { id: 2, label: "August", value: [2, 3, 4, 8, 1, 2], color: "secondary" },
];

export { trafficShares, totalOrders };
