import {
  faEdit,
  faEllipsisV,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dropdown,
  Button,
  Table,
  Card,
  Spinner,
} from "@themesberg/react-bootstrap";
import React, { useState, useEffect } from "react";
import { translate_column_value, translate_fr } from "../../../../lang/fr";

import moment from "moment";
import { useNavigate } from "react-router-dom";

import { axiosInstance } from "../../../../config/axios";
import { APIURL, getPages } from "../../../../configApi";
import PaginatorInvoices from "../Paginator/PaginatorInvoices";

const InvoicesTable = (props) => {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const {
    invoices,
    pagesInvoices = 0,
    headColumns,
    name,
    dentist_id,
    laboratory_id,
    setInvoices,
  } = props;
  useEffect(() => {
    setPage(1);
  }, [dentist_id]);
  const [is_used, setIs_Used] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showDefault, setShowDefault] = useState(false);
  const [keys, setKeys] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [pages, setPages] = useState(pagesInvoices);

  const downloadInvoice = (id) => {
    setLoading(true);
    setIs_Used(id);
    fetch(`https://api.easylab.tn/global/download_files/${id}/invoices`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = "file_" + id + ".pdf";
          alink.click();
          setLoading(false);
        });
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
        //NotificationManager.error(err?.message, "Notification");
      });
  };

  const getPaid = () => {
    axiosInstance
      .get(`${APIURL}/global/statistics/paid_values/${dentist_id}`)
      .then((data) => {
        // console.log("total restant", data?.data?.data?.total_paid);
        setTotal(data?.data?.data?.total_paid);
      })
      .catch((err) => {
        // console.log("total restant", err);
      });
  };
  useEffect(() => {
    //console.log(payments)
    if (invoices.length > 0) setKeys(Object.keys(invoices[0]));
    renderThs();
    console.log("total invoices", pagesInvoices);
    // setPages(Math.floor(pagesInvoices / 10));
    // console.log("head columns =>", headColumns);
    getPaid();
  }, []);

  useEffect(() => {
    getPaid();
  }, [dentist_id]);

  const renderThs = () => {
    let selectedKeys = [];
    //console.log("hello first invoice ==> ",invoices)
    if (headColumns.length > 0 && invoices.length > 0)
      headColumns.map((key) => {
        // console.log("data in if ==[>", key, keys);
        if (Object.keys(invoices[0]).includes(key)) selectedKeys.push(key);
      });
    // console.log(selectedKeys);
    setKeys(selectedKeys);

    // console.log("hello keys ...", keys);
  };

  const renderLigne = () => {
    return invoices.map((invoice) => (
      <tr key={invoice.id}>
        {renderColumn(invoice)}
        <td>
          {invoice.is_used === false ? (
            <Dropdown drop={faEllipsisV} className="me-2 mb-2">
              <Dropdown.Toggle split variant="default">
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  className="dropdown-arrow"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu
                className={"user-dropdown dropdown-menu-right mt-2"}
              >
                <Dropdown.Item className={"text-success"}>
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="dropdown-arrow me-2"
                  />{" "}
                  Modifier
                </Dropdown.Item>

                <Dropdown.Item
                  className={"text-danger"}
                  onClick={() => {
                    setShowDefault(true);
                    setInvoice(invoice);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="dropdown-arrow me-2"
                  />{" "}
                  Supprimer
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            ""
          )}
        </td>
        <td>
          {loading && is_used == invoice.id ? (
            <Button variant="success" disabled="true">
              <Spinner animation="border" />
            </Button>
          ) : (
            <Button
              variant="success"
              onClick={() => downloadInvoice(invoice.id)}
            >
              Télécharger
            </Button>
          )}
        </td>
      </tr>
    ));
  };

  const renderColumn = (data) => {
    return headColumns.map((k, i) =>
      Object.keys(data).includes(k.toString()) ? (
        <td> {getObjectKeyValue(data, k)}</td>
      ) : (
        ""
      )
    );
  };

  const getObjectKeyValue = (obj, key) => {
    //// console.log("get object value", obj, key);

    return Object.entries(obj).map((e) =>
      e[0] == key ? (
        key == "path" ? (
          <a
            onClick={() => window.open(`http://` + e[1], "_blank")}
            className={"badge bg-main p-2"}
          >
            {" "}
            voir le fichier{" "}
          </a>
        ) : ["created_at", "updated_at"].includes(e[0]) ? (
          moment.unix(e[1]).format("DD-MM-YYYY")
        ) : typeof e[1] == "string" ? (
          translate_column_value(e[1])
        ) : ["dentist", "laboratory"].includes(e[0]) ? (
          translate_column_value(e[1].last_name) +
          " " +
          translate_column_value(e[1].first_name)
        ) : (
          e[1] + " TND"
        )
      ) : (
        ""
      )
    );
  };

  // const getotal = () => {
  //     axiosInstance
  // }

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <div className="d-flex justify-content-between">
            <h5 className="mb-2">{name}</h5>

            {total >= 0 ? (
              <Button variant="outline-success" disabled>
                {" "}
                Total restant : {total} DT
              </Button>
            ) : total < 0 && total >= -2000 ? (
              <Button variant="outline-info" disabled>
                {" "}
                Total restant : {total} DT
              </Button>
            ) : (
              <Button variant="outline-danger" disabled>
                {" "}
                Total restant : {total} DT
              </Button>
            )}
          </div>
        </Card.Header>
        <Table
          responsive
          hover={true}
          className="align-items-center table-flush"
        >
          <thead className="thead-light">
            <tr>
              {keys.map((key, i) => (
                <th key={i}> {translate_fr(key, "invoices")} </th>
              ))}
              <th> </th>
              <th> Fichier PDF </th>
            </tr>
          </thead>
          <tbody>{renderLigne()}</tbody>
        </Table>
        <div className="d-flex justify-content-center mt-3">
          <PaginatorInvoices
            totalPages={pagesInvoices}
            dentist_id={dentist_id}
            laboratory_id={laboratory_id}
            loader={(value) => setLoading(value)}
            setInvoices={(value) => setInvoices(value)}
            updatePageSelected={(value) => setPage(value)}
            activatedItem={page}
          />
        </div>
      </Card>
    </>
  );
};

export default InvoicesTable;
