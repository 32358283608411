import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { axiosInstance } from "../../../../config/axios";
import { APIURL } from "../../../../configApi";
import { NotificationManager } from "react-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import LaboratoryService from "../../../services/LaboratoireService";

const EditService = (props) => {
  const [loading, setLoading] = useState(false);
  const { refreshGroupeList, service, discounts } = props;
  const [discountDetails, setDiscountDetails] = useState({});
  const [discount, setDiscount] = useState();
  const [errors, setErrors] = useState({
    discount: "",
  });
  const [name, setName] = useState(service.name);
  const [description, setDescription] = useState(service.description);
  const [price, setPrice] = useState(service.price);
  const [showDeleteService, setShowDeleteService] = useState(false);
  const handleClose = () => {
    setShowDeleteService(false);
    setShowEditRemise(false);
  };
  const [showEditRemise, setShowEditRemise] = useState(false);
  const deleteService = () => {
    axiosInstance
      .delete(`${APIURL}/laboratory/services/${service.id}`)
      .then((res) => {
        // console.log(res.data);
        NotificationManager.success(
          "Service supprimé avec succès",
          "Notification"
        );
        refreshGroupeList();
        //setName('')
      })
      .catch((err) => {
        // console.log(err);
        NotificationManager.error(
          "Erreur lors de suppression du Service",
          "Notification"
        );
      });
  };

  const updateService = (e) => {
    e.preventDefault();
    axiosInstance
      .put(`${APIURL}/laboratory/services/${service.id}`, {
        name: name,
        description: description,
        price: price,
        sub_group_id: service.sub_group_id,
      })
      .then((res) => {
        // console.log(res.data);
        NotificationManager.success(
          "Service modifié avec succès",
          "Notification"
        );
        refreshGroupeList();
        //setName('')
      })
      .catch((err) => {
        // console.log(err);
        NotificationManager.error(
          "Erreur lors de la modification d'un Service",
          "Notification"
        );
      });
  };

  const deleteRemise = async (discountId) => {
    try {
      const res = await LaboratoryService.deleteRemise(discountId);
      if (res.status === 200) {
        NotificationManager.success("Remise supprimee avec succes", "Remise");
        refreshGroupeList();
        handleClose();
      }
    } catch (err) {
      NotificationManager.error(
        "Probleme lors de la suppression de remise",
        "Remise"
      );
    }
  };
  const misajourRemise = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});

    if (discount.length === 0) {
      setErrors({ discount: "Choisir un nouveau prix" });
      setLoading(false);
      return false;
    }
    // console.log(parseInt(price) - parseInt(discount));
    if (parseInt(price) - parseInt(discount) <= 0) {
      setErrors({ discount: "Valeur erronée" });
      setLoading(false);
      return false;
    }
    setDiscount(parseInt(price) - parseInt(discount));

    // console.log(discount, [], service.id);
    try {
      const res = await LaboratoryService.addRemise({
        ...{
          dentists_ids: [discountDetails.dentist_id],
          service_id: service.id,
        },
        ...{ discount: parseInt(price) - parseInt(discount) },
      });
      if (res.status === 200) {
        NotificationManager.success(
          "Prix  ajouté avec succès",
          "Prix Spécifique"
        );

        setDiscount("");
        handleClose();
      }
      refreshGroupeList();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      NotificationManager.error(
        "Problème lors de l'ajout de prix",
        "Prix Spécifique"
      );
    }
  };
  return (
    <>
      <Form onSubmit={updateService}>
        <FormGroup>
          <FormLabel> Nom du service </FormLabel>
          <FormControl
            type={"text"}
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></FormControl>
        </FormGroup>
        <FormGroup>
          <FormLabel> Déscription du service </FormLabel>
          <FormControl
            as={"textarea"}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></FormControl>
        </FormGroup>
        <FormGroup>
          <FormLabel> Prix du service </FormLabel>
          <FormControl
            type={"number"}
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          ></FormControl>
        </FormGroup>
        <hr />
        <div>
          <h5>Liste des prix spécifiques :</h5>
          <Row>
            {service.discounts &&
              service.discounts.map((d) => (
                <Col md={6} lg={4} sm={12}>
                  <div
                    className="card p-2 d-flex flex-row align-items-center justify-content-between"
                    title="Cliquer pour supprimer"
                  >
                    <div
                      onClick={() => {
                        setShowEditRemise(true);
                        setDiscountDetails(d);
                        setDiscount(parseInt(price - d.discount));
                      }}
                    >
                      <h5>
                        Dr. {d.dentist?.last_name + " " + d.dentist?.first_name}
                      </h5>
                      <h5>
                        {" "}
                        {parseInt(service.price) - parseInt(d.discount)} DT{" "}
                      </h5>
                    </div>

                    <h5
                      className="btn btn-sm btn-dark"
                      onClick={() => deleteRemise(d.id)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </h5>
                  </div>
                </Col>
              ))}
          </Row>
        </div>
        <hr />
        <FormGroup className={"d-flex justify-content-between "}>
          <Button
            variant={"danger"}
            className={"mt-3"}
            onClick={() => setShowDeleteService(true)}
          >
            {" "}
            Supprimer le service{" "}
          </Button>

          <Button variant={"success"} className={"mt-3 me-2"} type={"submit"}>
            {" "}
            Enregistrer les modifications{" "}
          </Button>
        </FormGroup>
      </Form>

      <Modal
        as={Modal.Dialog}
        centered
        show={showDeleteService}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faTrash} /> Supprimer le service
            <span className={"text-danger"}> {service.name} </span>{" "}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className={"text-center"}>
            <h2>
              {" "}
              <FontAwesomeIcon icon={faExclamationTriangle} /> ATTENTION !{" "}
            </h2>
            Voulez-vous vraiment supprimer le service{" "}
            <span className={"text-danger"}>{service.name} </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={"danger"} onClick={handleClose}>
            Annuler
          </Button>

          <Button variant={"success"} onClick={deleteService}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        as={Modal.Dialog}
        centered
        show={showEditRemise}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">Modifier prix spécifique</Modal.Title>

          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Form>
            <p>
              <span className="text-main"> {service?.name} </span>
              pour Dr.{" "}
              <span className="text-main">
                {discountDetails.dentist?.last_name +
                  " " +
                  discountDetails.dentist?.first_name}
              </span>
            </p>
            <Row>
              <Col>
                {" "}
                <FormGroup>
                  <FormLabel>Prix spécifique : </FormLabel>
                  <FormControl
                    min={0}
                    max={price - 1}
                    type="number"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </FormGroup>
                {errors && errors.discount ? (
                  <p className="text-danger"> {errors.discount} </p>
                ) : (
                  ""
                )}
              </Col>
              <Col>
                <h6 className="mt-2">Prix général :</h6>
                <h6 className="mt-2">{price} DT</h6>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={"danger"} onClick={handleClose}>
            Annuler
          </Button>
          {loading ? (
            <Button variant={"success"}>
              <Spinner animation="border" />
            </Button>
          ) : (
            <Button variant={"success"} onClick={misajourRemise}>
              Modifier
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditService;
