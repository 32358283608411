import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faCheckCircle,
  faCity,
  faEnvelope,
  faPhoneAlt,
  faUnlockAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
  Nav,
  Image,
  Spinner,
} from "@themesberg/react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import ThemesbergLogo from "../../assets/easylab.svg";
import BgImage from "../../assets/img/illustrations/signin.svg";
import Preloader from "../Shared/Preloader/Preloader";
import axios from "axios";
import { APIURL } from "../../configApi";
import RegisterService from "../services/RegisterService";

const Register = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const typefromurl = queryParameters.get("role") || "dentiste";

  const navigate = useNavigate();
  const [type, setType] = useState(typefromurl);

  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const [step, setStep] = useState(1);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rpassword, setRPassword] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [full_address, setFull_address] = useState("");
  const [fiscal_number_laboratory, setFiscal_number_laboratory] = useState();
  const [principal_phone, setPrincipal_phone] = useState();
  const [ordinal_number_dentist, setOrdinal_number_dentist] = useState();
  const [city_id, setCity_id] = useState(1);
  const [state_id, setState_id] = useState(1);
  const [country_id, setCountry_id] = useState(1);
  const [loading, setLoading] = useState(false);

  const [description, setDescription] = useState();

  const getCountries = async () => {
    try {
      const response = await RegisterService.getCountries();
      setCountries(response.data.data);
      // console.log("countries", response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };
  const getStates = async (idCountry) => {
    try {
      const response = await RegisterService.getStates(idCountry);
      setStates(response.data.data);
      // console.log("states", response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };
  const getCities = async (idState) => {
    try {
      const response = await RegisterService.getCities(idState);
      setCities(response.data.data);
      // console.log("cities", response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    getCountries();
    getStates(1);
  }, []);

  const registerUser = (e) => {
    e.preventDefault();
    setLoading(true);
    let user = {};
    let endpoint = "";
    if (type == "laboratoire") {
      const regex = /[0-9]{7}\/[a-zA-Z]/;

      if (!fiscal_number_laboratory.match(regex)) {
        setError("La matricule fiscale doit respecter le format : 1234567/L ");
        setLoading(false);
        return false;
      }

      user = {
        email,
        password,
        first_name,
        last_name,
        full_address,
        fiscal_number_laboratory,
        principal_phone,
        city_id,
        country_id,
        state_id,
      };
      endpoint = "/laboratory/auth/sign_up";
    } else {
      user = {
        email,
        password,
        first_name,
        last_name,
        full_address,
        ordinal_number_dentist,
        principal_phone,
        city_id,
        country_id,
        state_id,
      };
      endpoint = "/dentist/auth/sign_up";
    }

    // console.log("user", user);

    axios
      .post(`${APIURL + endpoint}`, user)
      .then((res) => {
        // console.log(res);
        setError("");
        setSuccess("Compte crée avec succès");
        setLoading(false);
        setStep(4);
      })
      .catch((err) => {
        // console.log(err);
        setError(err.response.data.message);
        setLoading(false);
      });
  };

  const verifMail = () => {
    setLoading(true);
    if (password != rpassword) {
      setError("Le mot de passe n'est pas identique ...");
      setLoading(false);
      return false;
    }
    axios
      .post(`${APIURL}/global/auth/check_email`, { email: email })
      .then((res) => {
        setStep(3);
        setError("");
        setLoading(false);
      })
      .catch((err) => {
        setError("Email déjà utilisé ...");
        setLoading(false);
      });
  };
  const renderSpecificFields = () => {
    return (
      <>
        {type == "dentiste" ? (
          <Form.Group id="email" className="mb-4">
            <Form.Label>Numéro Ordinal</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faAddressCard} />
              </InputGroup.Text>
              <Form.Control
                value={ordinal_number_dentist}
                onChange={(e) => setOrdinal_number_dentist(e.target.value)}
                autoFocus
                required
                type="number"
                placeholder="1234"
              />
            </InputGroup>
          </Form.Group>
        ) : (
          <Form.Group id="email" className="mb-4">
            <Form.Label>Matricule fiscale</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faAddressCard} />
              </InputGroup.Text>
              <Form.Control
                value={fiscal_number_laboratory}
                onChange={(e) => setFiscal_number_laboratory(e.target.value)}
                autoFocus
                required
                type="text"
                placeholder="112233/A"
              />
            </InputGroup>
          </Form.Group>
        )}
      </>
    );
  };
  const renderType = () => {
    // console.log(type);
    return (
      <>
        {type == "dentiste" ? (
          <Row>
            <Col>
              <Nav
                fill
                defaultActiveKey="home"
                variant="pills"
                className="d-flex"
              >
                <Nav.Item>
                  <Nav.Link
                    eventKey="home"
                    onClick={() => setType("dentiste")}
                    className="mb-sm-3 mb-md-0 bg-main text-white"
                  >
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-white me-2"
                    />
                    <span>Dentiste</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => setType("laboratoire")}>
                  <Nav.Link eventKey="profile" className="mb-sm-3 mb-md-0">
                    Laboratoire
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <Nav
                fill
                defaultActiveKey="home"
                variant="pills"
                className="d-flex"
              >
                <Nav.Item>
                  <Nav.Link
                    eventKey="home"
                    onClick={() => setType("dentiste")}
                    className="mb-sm-3 mb-md-0 "
                  >
                    Dentiste
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="profile"
                    onClick={() => setType("laboratoire")}
                    className="mb-sm-3 mb-md-0 bg-main text-white"
                  >
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-white me-2"
                    />
                    <span>Laboratoire</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const next = (nextStep) => {
    setError("");
    setStep(nextStep);
  };
  return (
    <main>
      <Preloader />
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="p-2 mb-4 text-center">
                  <Image
                    src={ThemesbergLogo}
                    className="card-img-top w-50 border-white"
                  />
                </div>
                <div className="text-center text-md-center my-4 mt-md-0">
                  <h3 className="mb-0">Créer un compte chez EasyLab</h3>
                </div>
                {error && error != "" ? (
                  <div className="text-center text-md-center my-2 mt-md-0 alert alert-danger">
                    <p className="m-0 p-0">{error}</p>
                  </div>
                ) : (
                  ""
                )}

                <Form className="mt-4" onSubmit={registerUser}>
                  {step == 1 ? (
                    <>
                      {renderType()}
                      <Row>
                        <Form.Group id="email" className="mb-4">
                          <Form.Label>Nom </Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUser} />
                            </InputGroup.Text>
                            <Form.Control
                              value={first_name}
                              onChange={(e) => setFirst_name(e.target.value)}
                              autoFocus
                              required
                              type="text"
                              placeholder="Votre nom ..."
                            />
                          </InputGroup>
                        </Form.Group>

                        <Form.Group id="email" className="mb-4">
                          <Form.Label>Prénom </Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUser} />
                            </InputGroup.Text>
                            <Form.Control
                              value={last_name}
                              onChange={(e) => setLast_name(e.target.value)}
                              autoFocus
                              required
                              type="text"
                              placeholder="Votre prénom ..."
                            />
                          </InputGroup>
                        </Form.Group>
                      </Row>
                      <div className="d-flex justify-content-end">
                        {loading ? (
                          <Button variant="main" disabled="true">
                            <Spinner animation="border" />
                          </Button>
                        ) : (
                          <Button variant="main" onClick={() => setStep(2)}>
                            {" "}
                            Suivant{" "}
                          </Button>
                        )}
                      </div>
                    </>
                  ) : step == 2 ? (
                    <>
                      <Form.Group id="email" className="mb-4">
                        <Form.Label>Votre Email</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faEnvelope} />
                          </InputGroup.Text>
                          <Form.Control
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoFocus
                            required
                            type="email"
                            placeholder="example@company.com"
                          />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group id="password" className="mb-4">
                        <Form.Label>
                          Créer un nouveau mot de passe pour votre espace
                          EasyLab :{" "}
                        </Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                            required
                            type="password"
                            placeholder="Créer un nouveau mot de passe pour votre espace
                            EasyLab"
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group id="password" className="mb-4">
                        <Form.Label>Confirmer Mot de passe : </Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            value={rpassword}
                            onChange={(e) => {
                              setRPassword(e.target.value);
                            }}
                            required
                            type="password"
                            placeholder="Password"
                          />
                        </InputGroup>
                      </Form.Group>

                      <div className="d-flex justify-content-between">
                        <Button variant="main-outline" onClick={() => next(1)}>
                          {" "}
                          Précédent{" "}
                        </Button>

                        {loading ? (
                          <Button variant="main" disabled="true">
                            <Spinner animation="border" />
                          </Button>
                        ) : (
                          <Button variant="main" onClick={verifMail}>
                            {" "}
                            Suivant{" "}
                          </Button>
                        )}
                      </div>
                    </>
                  ) : step == 3 ? (
                    <>
                      <Row>
                        <Col>
                          <Form.Group id="password" className="mb-4">
                            <Form.Label>Pays :</Form.Label>
                            <Form.Select
                              disabled={true}
                              value={country_id}
                              onChange={(e) => {
                                setCountry_id(e.target.value);
                                getStates(e.target.value);
                              }}
                            >
                              <option defaultValue>
                                Selectionner une pays{" "}
                              </option>

                              {countries.map((country) => (
                                <option value={country.id}>
                                  {" "}
                                  {country.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Gouvernorat :</Form.Label>
                            <Form.Select
                              value={state_id}
                              onChange={(e) => {
                                setState_id(e.target.value);
                                getCities(e.target.value);
                              }}
                            >
                              <option defaultValue>
                                Selectionner une Gouvernorat{" "}
                              </option>

                              {states.map((state) => (
                                <option value={state.id}> {state.name}</option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Ville :</Form.Label>
                            <Form.Select
                              value={city_id}
                              onChange={(e) => setCity_id(e.target.value)}
                            >
                              <option defaultValue>
                                Selectionner une ville{" "}
                              </option>

                              {cities.map((city) => (
                                <option value={city.id}> {city?.name}</option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group id="password" className="mb-4">
                            <Form.Label>Adresse : </Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faAddressCard} />
                              </InputGroup.Text>
                              <Form.Control
                                value={full_address}
                                onChange={(e) =>
                                  setFull_address(e.target.value)
                                }
                                required
                                type="text"
                                placeholder="Adresse ..."
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Group className="mb-4">
                            <Form.Label>Téléphone : </Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faPhoneAlt} />
                              </InputGroup.Text>
                              <Form.Control
                                value={principal_phone}
                                onChange={(e) =>
                                  setPrincipal_phone(e.target.value)
                                }
                                required
                                type="tel"
                                placeholder="Numero téléphone"
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col>{renderSpecificFields()}</Col>
                      </Row>

                      <div className="d-flex justify-content-between">
                        <Button variant="main-outline" onClick={() => next(2)}>
                          {" "}
                          Précédent{" "}
                        </Button>
                        {loading ? (
                          <Button variant="main" disabled="true">
                            <Spinner animation="border" />
                          </Button>
                        ) : (
                          <Button variant="main" type="submit">
                            Créer votre compte
                          </Button>
                        )}
                      </div>
                    </>
                  ) : step == 4 ? (
                    <div className="text-center text-md-center my-2 mt-md-0 alert alert-main">
                      <h3 className="m-0 p-0 text-white">
                        <FontAwesomeIcon icon={faCheckCircle} /> {success}
                      </h3>
                    </div>
                  ) : (
                    ""
                  )}
                </Form>

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Vous avez déjà un compte?
                    <Card.Link
                      as={Link}
                      to="/login"
                      className="ms-2 fw-bold text-secondary"
                    >
                      Connectez-vous
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default Register;
