export const currentUser = () => {

    let  user : String | null  = null
    if (localStorage.getItem('user')) {
        if (localStorage.getItem('user') != null) {
            if (localStorage.getItem('user') != '') {
                if (localStorage.getItem('user') != undefined) {

                    // @ts-ignore
                     user = JSON.parse(localStorage.getItem('user'));


                }
            }
        }
    }
    return user

}


export const config = () => {

    if ( typeof(localStorage.getItem('token')) === "string" ){
         // @ts-ignore
        return localStorage.getItem('token')
    }else{
        return '';
    }

}