import {
  faCheckCircle,
  faEdit,
  faSave,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  Nav,
  Row,
  Form,
  Button,
  ModalDialog,
} from "@themesberg/react-bootstrap";
import React, { useState, useEffect } from "react";

import { NotificationManager } from "react-notifications";
import LaboratoryService from "../../../services/LaboratoireService";

const EditPatientModal = (props) => {
  const { show, handleClose, work, setShow, setWork } = props;

  // console.log("hello work from inside ", work);

  const [full_name_patient, setFull_name_patient] = useState(
    work?.full_name_patient
  );
  const [age_patient, setAge_patient] = useState(work?.age_patient);
  const [sex_patient, setSex_patient] = useState(work?.sex_patient);
  const [real_reference, setRealReference] = useState(work?.real_reference);
  const updatePatient = async (e) => {
    e.preventDefault();

    try {
      const response = await LaboratoryService.updatePatient(work?.id, {
        full_name_patient: full_name_patient,
        age_patient: parseInt(age_patient),
        sex_patient: sex_patient,
      });
      // console.log(response);
      if (response) {
        setShow(false);
        setWork();
        NotificationManager.success("Informations mise à jour avec succès");
      }
    } catch (err) {
      // console.log(err);
      NotificationManager.error(err?.response?.data?.message, "Erreur");
    }
  };

  const updateRealReference = async (e) => {
    e.preventDefault();

    try {
      const response = await LaboratoryService.updateRealReference(work?.id, {
        real_reference,
      });
      //console.log(response);
      if (response) {
        setShow(false);
        setWork();
        NotificationManager.success("Informations mise à jour avec succès");
      }
    } catch (err) {
      // console.log(err);
      NotificationManager.error(err?.response?.data?.message, "Erreur");
    }
  };
  useEffect(() => {
    setFull_name_patient(work?.full_name_patient);
    setAge_patient(work?.age_patient);
    setSex_patient(work?.sex_patient);
    setRealReference(work?.real_reference);
  }, [work]);

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={show}
      onHide={handleClose}
      size={"md"}
    >
      <Modal.Header>
        <Modal.Title className="h6">
          <FontAwesomeIcon icon={faEdit} />
          Editer les informations de la fiche {work?.reference_work}
        </Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        {work?.real_reference && <h5>{work?.real_reference}</h5>}
        <Form onSubmit={updateRealReference}>
          <FormGroup>
            <FormLabel>
              {" "}
              Numéro actuel de la fiche ( étape transitoire ){" "}
            </FormLabel>
            <Row>
              <Col md={8}>
                <FormControl
                  type={"text"}
                  placeholder={"Numéro actuel ..."}
                  value={real_reference}
                  onChange={(e) => setRealReference(e.target.value)}
                ></FormControl>{" "}
              </Col>
              <Col md={4}>
                <Button type={"submit"} variant={"success"}>
                  <FontAwesomeIcon icon={faSave} />
                  {" Sauvegarder"}{" "}
                </Button>
              </Col>
            </Row>
          </FormGroup>
        </Form>

        {work?.dentist?.role === "fake_dentist" && (
          <>
            <hr />
            <h6>
              <FontAwesomeIcon icon={faEdit} />
              Editer les informations du patient
            </h6>
            <Form onSubmit={updatePatient}>
              <FormGroup>
                <FormLabel> Nom prénom patient </FormLabel>
                <FormControl
                  type={"text"}
                  placeholder={"foulen ben foulen ..."}
                  value={full_name_patient}
                  onChange={(e) => setFull_name_patient(e.target.value)}
                ></FormControl>
              </FormGroup>

              <FormGroup>
                <FormLabel> </FormLabel>

                {sex_patient === "women" ? (
                  <Row>
                    <Col>
                      <Nav fill variant="pills" className="d-flex">
                        <Nav.Item>
                          <Nav.Link
                            onClick={() => setSex_patient("women")}
                            className="mb-sm-3 mb-md-0 bg-main text-white"
                          >
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="text-white me-2"
                            />
                            <span>Femme</span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item onClick={() => setSex_patient("man")}>
                          <Nav.Link
                            title={"Clique ici pour changer le sex"}
                            className="border border-dark mb-sm-3 mb-md-0"
                          >
                            Homme
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Row>
                ) : sex_patient === "man" ? (
                  <Row>
                    <Col>
                      <Nav fill variant="pills" className="d-flex">
                        <Nav.Item>
                          <Nav.Link
                            title={"Clique ici pour changer le sex"}
                            onClick={() => setSex_patient("women")}
                            className="border border-dark mb-sm-3 mb-md-0"
                          >
                            <span>Femme</span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item onClick={() => setSex_patient("man")}>
                          <Nav.Link className="mb-sm-3 mb-md-0  bg-main text-white">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="text-white me-2"
                            />
                            <span>Homme</span>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col>
                      <Nav fill variant="pills" className="d-flex">
                        <Nav.Item>
                          <Nav.Link
                            title={"Clique ici pour changer le sex"}
                            onClick={() => setSex_patient("women")}
                            className="border border-dark mb-sm-3 mb-md-0"
                          >
                            <span>Femme</span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item onClick={() => setSex_patient("man")}>
                          <Nav.Link className="border border-dark mb-sm-3 mb-md-0">
                            <span>Homme</span>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Row>
                )}
              </FormGroup>

              <FormGroup>
                <FormLabel> Age du patient </FormLabel>
                <FormControl
                  min={"0"}
                  max={"120"}
                  type={"number"}
                  value={age_patient}
                  onChange={(e) => setAge_patient(e.target.value)}
                ></FormControl>
              </FormGroup>
              <Form.Group className={"d-flex justify-content-end mt-3"}>
                <Button
                  type={"reset"}
                  onClick={handleClose}
                  variant={"danger"}
                  className={"me-2"}
                >
                  <FontAwesomeIcon icon={faWindowClose} />
                  {" Annuler "}{" "}
                </Button>
                <Button type={"submit"} variant={"success"}>
                  <FontAwesomeIcon icon={faSave} />
                  {" Sauvegarder"}{" "}
                </Button>
              </Form.Group>
            </Form>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default EditPatientModal;
