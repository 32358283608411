import { useEffect, useState } from "react";
import { axiosInstance } from "../../../../config/axios";
import { APIURL } from "../../../../configApi";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import Preloader from "../../../Shared/Preloader/Preloader";
import Sidebar from "../../../Shared/Sidebar";
import Navbar from "../../../Shared/Navbar/Navbar";
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  ModalFooter,
  Row,
  Spinner,
  Tab,
  Tabs,
} from "@themesberg/react-bootstrap";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import { currentUser } from "../../../../authentication/auth";
import { translate_fr } from "../../../../lang/fr";

import TimeLine from "../../../Shared/components/TimeLine";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTimesCircle,
  faFile,
  faPlus,
  faPlusCircle,
  faUpload,
  faArrowLeft,
  faCheckCircle,
  faEye,
  faDownload,
  faExclamationTriangle,
  faMoneyBill,
  faInfo,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import EditPatientModal from "./ModalEditPatient";
import UploadAttachmentModal from "./ModalUploadAttachment";
import FileService from "../../../services/FileService";
import WorkService from "../../../services/WorkService";
import { PagesRoutes } from "../../../../pages_routes";
import WorkActionList from "../WorkActionList";
import { fr } from "date-fns/locale";
import { DayPicker } from "react-day-picker";
const DentistWorkShow = () => {
  const [preview, setPreview] = useState("");
  const [FileType, setFileType] = useState("");
  const [items, setItems] = useState([]);
  const [image, setImage] = useState("");
  const [update, setUpdate] = useState(0);
  const [addImageModal, setAddImageModal] = useState(false);
  const [empreinte, setEmpreinte] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);

  const [showEditPatient, setShowEditPatient] = useState(false);
  const [attachmentUpload, setAttachmentUpload] = useState(false);

  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [localFiles, setLocalFiles] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingEmpreinte, setLoadingEmpreinte] = useState(false);
  const [disabledUploadImage, setDisabledUploadImage] = useState(true);
  const [disabledUploadEmpreint, setDisabledUploadEmpreint] = useState(true);
  const [idFileDeleted, setIdFileDeleted] = useState();
  const [popupModal, setPopupModal] = useState(false);
  const [showEditDatePrevuModal, setShowEditDatePrevuModal] = useState(false);
  const [datePrevu, setDatePrevu] = useState("");

  const requestPrevu = async () => {
    try {
      const response = await WorkService.requestPrevuDate(id);
      if (response.status === 200) {
        NotificationManager.success("Demande info envoyée");
        //console.log(response);
        getCurrentWorkData();
        setUpdate((prev) => prev + 1);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      // console.log(err);
    }
  };
  const downloadImage = async (id) => {
    // console.log("download file", id);

    fetch(
      `https://api.easylab.tn/global/download_files/${id}/attachments_file`,
      {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    ).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "file.png";
        alink.click();
      });
    });
  };
  const handleClose = () => {
    setShowEditDatePrevuModal(false);
    setShowEditPatient(false);
    setAttachmentUpload(false);
    setAddImageModal(false);
    setShowImageModal(false);
    setPopupModal(false);
  };

  const Popupdelete = (id) => {
    setIdFileDeleted(id);
    setPopupModal(true);
  };

  const downloadFile = async (id) => {
    // console.log("download file", id);

    fetch(`https://api.easylab.tn/global/download_files/${id}/extra_finger`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "file.ply";
        alink.click();
      });
    });
  };

  const deleteFile = async (workId, fileId) => {
    // console.log("Delete file ===>", workId, fileId);
    try {
      const response = await FileService.delete(workId, fileId);
      if (response.status === 200) {
        NotificationManager.success(response.data.message, "Notification");
        setPopupModal(false);
        setUpdate((prev) => prev + 1);
      }
    } catch (err) {
      // console.log(err);
      NotificationManager.error(err?.response?.data?.message, "Notification");
    }
  };

  const chooseImageToShow = (file) => {
    setImage(file);
    setShowImageModal(true);
  };
  const navigate = useNavigate();
  const [user, setUser] = useState();
  let { id } = useParams();
  const [work, setWork] = useState();
  const [activities, setActivities] = useState([]);
  useEffect(() => {
    moment.locale("fr");
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    getCurrentWorkData();
    getWorkActivities();
  }, [update]);
  const getWorkActivities = () => {
    axiosInstance
      .get(`${APIURL}/global/works/${id}/activities`)
      .then((res) => {
        let local_items = [];
        setActivities(res.data.data);
        res.data.data.forEach((a) => {
          local_items.push({
            date: a.created_at,
            content: a.content,
          });
        });
        setItems(local_items);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const getCurrentWorkData = () => {
    axiosInstance
      .get(`${APIURL}/global/works/${id}`)
      .then((res) => {
        setWork(res.data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const chooseFile = async (e) => {
    e.preventDefault();
    if (e.target.files[0] == file) {
      NotificationManager.error(
        "Vous avez déjà téléchargé une photo avec le même nom. Verifiez SVP",
        "Notification"
      );
    } else {
      try {
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setPreview(objectUrl);
        setFile(e.target.files[0]);
        setDisabledUploadImage(false);
      } catch (err) {}
    }
  };

  const uploadFile = async () => {
    setLoadingImage(true);
    try {
      const data = new FormData();
      data.append("attachments_file", file);
      const response = await FileService.uploadImage(data, work.id);
      if (response.status === 200) {
        const lfiles = localFiles;
        const files = work.attachments_files;
        files.push(response.data.data?.id);
        lfiles.push(response.data.data);
        setLocalFiles(lfiles);
        work.attachments_files = files;

        // console.log("work updated ==>", work);
        //updateWork(work);
        //getCurrentWorkData()
        handleClose();
        // console.log(lfiles);
        setUpdate((prev) => prev + 1);
        setLoadingImage(false);
        setDisabledUploadImage(true);
        setPreview("");
      }
    } catch (err) {
      // console.log(err);
      NotificationManager.error(err.response?.data?.message, "Notification");
      setLoadingImage(false);
      setDisabledUploadImage(true);
      handleClose();
    }
  };

  const updateWork = async (data) => {
    try {
      const response = await WorkService.update(work.id, data);
      if (response.status === 200) {
        NotificationManager.success(
          "Image téléchargée avec succès",
          "Notification"
        );
      }
    } catch (err) {
      NotificationManager.error(err?.response?.data?.message, "Notification");
    }
  };

  return (
    <>
      <Preloader />
      <Sidebar user={user} />

      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar user={user} pageName={"Détails du travail"} icon={faFile} />
        <hr />
        <div>
          <NotificationContainer />

          {work && (
            <>
              <div className={"mb-4 d-flex justify-content-between"}>
                <h2 className={"w-50"}>Travail : {work.reference_work} </h2>
                <div className="w-50 d-flex justify-content-between align-items-center">
                  <WorkActionList work={work} />

                  <Button
                    className="ms-2"
                    variant={"main"}
                    onClick={() => navigate(PagesRoutes.DentistWorks.path)}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} /> Voir liste{" "}
                  </Button>
                </div>
              </div>
              <Row>
                <Col sm={8} className={"mb-2"}>
                  <Card className={"p-3 "}>
                    <Card.Title
                      className={
                        "d-flex flex-lg-row flex-column  justify-content-between"
                      }
                    >
                      <h5>
                        {" "}
                        Laboratoire :{" "}
                        <span className={"text-main"}>
                          {work.laboratory?.last_name +
                            ` ` +
                            work.laboratory?.first_name}
                        </span>{" "}
                      </h5>
                      <h5>
                        {" "}
                        Ville :
                        <span className={"text-main"}>
                          {" " + work.laboratory?.city?.name}
                        </span>
                      </h5>

                      {work.full_name_patient ? (
                        <h5>
                          {" "}
                          Patient :{" "}
                          <span className={"text-main"}>
                            {work.full_name_patient?.toUpperCase() +
                              " " +
                              work.age_patient +
                              "ans (" +
                              translate_fr(work.sex_patient, "agents") +
                              ")"}
                          </span>
                        </h5>
                      ) : (
                        ""
                      )}
                      {work.status != "archived" && (
                        <Button
                          variant={"main m-2"}
                          title={"Editer les informations du patient"}
                          onClick={() => setShowEditPatient(true)}
                        >
                          <FontAwesomeIcon icon={faEdit} />{" "}
                        </Button>
                      )}
                    </Card.Title>
                  </Card>
                </Col>
                <Col sm={4} className={"mb-2"}>
                  <Card>
                    <Card.Title
                      className={
                        "p-3 d-flex flex-lg-row flex-column align-items-center justify-content-between"
                      }
                    >
                      Statut Actuel{" "}
                      <span className={"p-2 bg-danger"}>
                        {translate_fr(work.status, "agents")}
                      </span>
                    </Card.Title>
                  </Card>
                </Col>
                <Col sm={8}>
                  <Card className={"p-3 "}>
                    <Card.Title
                      className={
                        "d-flex flex-lg-row flex-column justify-content-between"
                      }
                    >
                      <div>
                        <h5> Nature </h5>
                        <h5 className={"text-main"}>
                          {" "}
                          {work.service?.name?.toUpperCase()}{" "}
                        </h5>
                        <div
                          className={
                            "d-flex flex-lg-row flex-column justify-content-between"
                          }
                        >
                          <h5> Elements </h5>
                          <h5 className={"text-main mx-4"}>
                            {" "}
                            {work.elements_numbers}{" "}
                          </h5>
                          <h5> Teinte </h5>
                          <h5 className={"text-main mx-4"}>
                            {" "}
                            {work.shade?.toUpperCase()}{" "}
                          </h5>
                        </div>
                      </div>

                      <div>
                        {work.is_urgent ? (
                          <h5
                            className={"p-2 text-center text-white"}
                            style={{ backgroundColor: "#e74c3c" }}
                          >
                            Travail urgent
                          </h5>
                        ) : (
                          ""
                        )}
                        {work.requested_delivery_date && (
                          <>
                            <h5>Date de livraison souhaitée :</h5>

                            <h5 className={"text-main"}>
                              {moment
                                .unix(work.requested_delivery_date)
                                .format("dddd") + " "}
                              {moment
                                .unix(work.requested_delivery_date)
                                .format("DD-MM-YYYY")}
                            </h5>
                          </>
                        )}
                        <div className="d-flex flex-column">
                          <h5>Date prévu pour </h5>

                          {!work.foreseen_delivery_date &&
                            !work.is_delivery_date_requested && (
                              <Button
                                className="btn-sm"
                                variant={"main"}
                                onClick={() => requestPrevu(work.id)}
                              >
                                {" "}
                                <FontAwesomeIcon icon={faInfo} /> Demander info
                              </Button>
                            )}
                        </div>
                        {work.foreseen_delivery_date ? (
                          <h5 className={"text-main"}>
                            {moment
                              .unix(work.foreseen_delivery_date)
                              .format("dddd") + " "}
                            {moment
                              .unix(work.foreseen_delivery_date)
                              .format("DD-MM-YYYY")}
                          </h5>
                        ) : work.is_delivery_date_requested ? (
                          <p className="text-main">
                            {" "}
                            <FontAwesomeIcon icon={faEnvelope} /> Demande
                            envoyée
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </Card.Title>
                  </Card>
                  <Card className={"mt-2 p-3"}>
                    <div className={""}>
                      <div>
                        <div className="d-flex justify-content-between">
                          <h5> Photos : </h5>
                          {work.status != "archived" && (
                            <Button
                              variant={"main"}
                              onClick={() => setAddImageModal(true)}
                            >
                              {" "}
                              <FontAwesomeIcon icon={faPlus} />{" "}
                            </Button>
                          )}
                        </div>

                        <div className="d-flex">
                          {work.attachments_files.map((file, index) =>
                            index < 3 ? (
                              <div className="border border-1 me-1 p-1">
                                <div className="d-flex justify-content-between">
                                  <span> Fichier {index + 1} </span>
                                  <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    onClick={() => Popupdelete(file.id)}
                                  />
                                </div>

                                <div>
                                  <img
                                    src={`${file.full_path}`}
                                    width={"100"}
                                    onClick={() => {
                                      chooseImageToShow(file);
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </Card>
                  {/* <Card className={"mt-2 p-3"}>
                    <div className={""}>
                      <div>
                        <div className="d-flex justify-content-between">
                          <h5> Empreintes digitales : </h5>
                        </div>

                        <div className="d-flex">
                          {work.files_extra_finger_print.map((file, index) =>
                            index < 3 ? (
                              <div className="w-50 border border-1 border-success me-1 p-1">
                                <div className="d-flex justify-content-between align-items-center">
                                  <span> Empreinte {index + 1} </span>
                                  <Button
                                    variant={"main"}
                                    onClick={() => downloadFile(file.id)}
                                  >
                                    {" "}
                                    <FontAwesomeIcon icon={faDownload} />
                                  </Button>
                                </div>
                              </div>
                            ) : (
                              ""
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </Card> */}

                  <Card className={"mt-2 p-3"}>
                    <div className={""}>
                      <div>
                        <div className="d-flex justify-content-between">
                          <h5> Empreintes digitales : </h5>
                          {work.status != "archived" && (
                            <Button
                              variant={"main"}
                              onClick={() => {
                                setFileType("empreinte");
                                setAddImageModal(true);
                              }}
                            >
                              {" "}
                              <FontAwesomeIcon icon={faPlus} />{" "}
                            </Button>
                          )}
                        </div>

                        <div className="d-flex">
                          {work.files_extra_finger_print.map((Afile, index) =>
                            index < 3 && Afile.path ? (
                              <div className="border border-1 me-1 p-1">
                                <div className="d-flex justify-content-between">
                                  <a href={Afile.path}> Fichier {index + 1} </a>
                                  <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    onClick={() =>
                                      deleteFile(
                                        work.id,
                                        "attachments_files",
                                        Afile.id
                                      )
                                    }
                                  />
                                </div>

                                <div>
                                  <img
                                    src={Afile.path}
                                    width={"100"}
                                    onClick={() => {
                                      setImage(Afile);
                                      setShowImageModal(true);
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </Card>
                  <div className={"mt-2"}>
                    <Row>
                      <Col>
                        <Card className={"p-3"}>
                          <h5> Commentaires : </h5>
                          <div>{work.comment}</div>
                        </Card>
                      </Col>
                      <Col>
                        <Card className={"p-3"}>
                          <div className={"d-flex justify-content-between"}>
                            <h5> Prix : </h5>
                          </div>

                          <div>{work?.price} TND</div>
                        </Card>
                      </Col>
                    </Row>
                    {/* <Card className={'mt-2 p-3 mb-2'}>
                                        <h5> Messages : </h5>
                                        <div>
                                            UnderConstruction
                                        </div>
                                    </Card> */}
                  </div>
                </Col>
                <Col sm={4} className={""}>
                  <div>
                    {activities.length > 0 && items.length > 0 ? (
                      <TimeLine items={items} />
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>

        <EditPatientModal
          show={showEditPatient}
          handleClose={() => handleClose()}
          work={work}
          setShow={(value) => setShowEditPatient(value)}
          setWork={() => getCurrentWorkData()}
        />

        <UploadAttachmentModal
          show={attachmentUpload}
          handleClose={() => handleClose()}
          work={work}
          setShow={(value) => setShowEditPatient(value)}
          setWork={() => getCurrentWorkData()}
        />
      </main>

      {FileType == "empreinte" ? (
        <Modal
          as={Modal.Dialog}
          centered
          show={addImageModal}
          onHide={handleClose}
          size={"lg"}
        >
          <Modal.Header>
            <Modal.Title className="h6">
              <FontAwesomeIcon icon={faPlusCircle} /> Ajouter une empreinte{" "}
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <img src={preview} width={"150"} />
            <input
              type="file"
              className="form-control mb-2"
              onChange={(e) => chooseFile(e)}
            />
            <Button onClick={() => uploadFile()}>
              <FontAwesomeIcon icon={faUpload} /> Telecharger{" "}
            </Button>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal
          as={Modal.Dialog}
          centered
          show={addImageModal}
          onHide={handleClose}
          size={"lg"}
        >
          <Modal.Header>
            <Modal.Title className="h6">
              <FontAwesomeIcon icon={faPlusCircle} /> Ajouter une image{" "}
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <img src={preview} width={"150"} />
            <input
              type="file"
              className="form-control mb-2"
              onChange={(e) => chooseFile(e)}
            />
            <Button disabled={disabledUploadImage} onClick={() => uploadFile()}>
              <FontAwesomeIcon icon={faUpload} /> Telecharger{" "}
            </Button>
          </Modal.Body>
        </Modal>
      )}

      <Modal
        as={Modal.Dialog}
        centered
        show={showImageModal}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faEye} /> Afficher Image{" "}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={image.full_path} />
          </div>
        </Modal.Body>
        <ModalFooter>
          <Button
            type="button"
            variant="success"
            onClick={() => downloadImage(image.id)}
          >
            <FontAwesomeIcon icon={faDownload} /> Telecharger{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        as={Modal.Dialog}
        centered
        show={popupModal}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faEye} /> Alert de suppression du fichier{" "}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className={"text-center"}>
            <h2>
              {" "}
              <FontAwesomeIcon icon={faExclamationTriangle} /> ATTENTION !{" "}
            </h2>
            Voulez-vous vraiment supprimer ce fichier !
          </div>
        </Modal.Body>
        <ModalFooter>
          <Button variant={"success"} onClick={handleClose}>
            Annuler
          </Button>

          <Button
            variant={"danger"}
            onClick={() => deleteFile(work.id, idFileDeleted)}
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DentistWorkShow;
