import React, { useState } from "react";
import { Form, Button, Spinner } from "@themesberg/react-bootstrap";
import "./style.css";
import { axiosInstance } from "../../../config/axios";
import { APIURL } from "../../../configApi";
import { NotificationManager } from "react-notifications";
import ContactService from "../../services/ContactService";

const Contactus = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [telephone, setTelephone] = useState("");
  const [notification, setNotification] = useState("");
  const [loading, setLoading] = useState(false);
  const [notifType, setNotifType] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setNotifType("");

    try {
      const response = await ContactService.sendEmail({
        client_name: name,
        email: email,
        phone: telephone,
        subject: subject,
        description: message,
      });
      if (response.status === 200) {
        setNotifType("success");
        setNotification(
          "L'equipe EasyLab vous remercie pour votre interraction.",
          "Notification"
        );
        setName("");
        setEmail("");
        setTelephone("");
        setSubject("");
        setMessage("");
        setLoading(false);
      }
    } catch (err) {
      setNotifType("error");
      setNotification(err?.response?.data?.message);
      setLoading(false);
    }
  };
  return (
    <div className={"ContactUsContainer"} id="contacts">
      <h1>Contactez Nous</h1>
      <p>
        Si vous avez des questions ou souhaitez obtenir plus d'informations sur
        notre plateforme, N'hésitez pas à nous contacter en remplissant ce
        formulaire.
      </p>
      <Form onSubmit={handleSubmit} className={"ContactUsInput"}>
        {notification && notification.length > 0 ? (
          <p className={notifType}>{notification}</p>
        ) : (
          ""
        )}
        <Form.Group controlId="formName">
          <Form.Control
            type="text"
            placeholder="Nom*"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Control
            type="email*"
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formEmail">
          <Form.Control
            type="tel*"
            placeholder="Telephone"
            value={telephone}
            onChange={(event) => setTelephone(event.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formList">
          <Form.Control
            type="list*"
            placeholder="list"
            value={subject}
            onChange={(event) => setSubject(event.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formMessage">
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Message"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
          />
        </Form.Group>

        {loading ? (
          <Button
            disabled="true"
            className={"ContactUsSubmitButton"}
            variant="primary"
            type="submit"
          >
            {" "}
            <Spinner animation="border" />
          </Button>
        ) : (
          <Button
            className={"ContactUsSubmitButton"}
            variant="primary"
            type="submit"
          >
            Envoyer
          </Button>
        )}
      </Form>
    </div>
  );
};

export default Contactus;
