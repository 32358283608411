import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Row,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import Sidebar from "../../Shared/Sidebar";
import Preloader from "../../Shared/Preloader/Preloader";
import Navbar from "../../Shared/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { currentUser } from "../../../authentication/auth";
import { axiosInstance } from "../../../config/axios";
import { APIURL } from "../../../configApi";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPlusCircle,
  faShare,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import Invitations from "../../Shared/components/Table/Invitations";
import FirebaseNotif from "../../Shared/FirebaseNotif/FirebaseNotif";

const DentistInvitations = () => {
  const [email, setEmail] = useState();

  const [showDefault, setShowDefault] = useState(false);

  const handleClose = () => {
    setShowDefault(false);
  };
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [invitations, setInvitations] = useState([]);
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    getMyInvitations();
  }, []);

  const getMyInvitations = () => {
    axiosInstance
      .get(`${APIURL}/global/request_invitations?page=${page}`)
      .then((res) => {
        setInvitations(res.data.data.list);
        // console.log(invitations);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const sendNewInvitation = (e) => {
    e.preventDefault();
    axiosInstance
      .post(`${APIURL}/global/request_invitations`, { email: email })
      .then((res) => {
        NotificationManager.success(
          "Invitation envoyée avec succès",
          "Notification"
        );
        setShowDefault(false);
        setEmail("");
        getMyInvitations();
        //console.log(invitations)
      })
      .catch((err) => {
        NotificationManager.error(err.response.data.message, "Notification");
        setEmail("");
      });
  };

  return (
    <>
      <Preloader />
      <Sidebar user={user} />
      {/* <FirebaseNotif /> */}
      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar user={user} pageName={"Liste des invitations"} icon={faUsers} />
        <hr />
        <div>
          <div className="text-end mb-3">
            <button
              className="btn btn-main"
              onClick={() => setShowDefault(true)}
            >
              <FontAwesomeIcon icon={faPlusCircle} /> Inviter{" "}
            </button>
          </div>
          <Row className="justify-content-md-center">
            {invitations.length > 0 ? (
              <Invitations
                refreshInvitations={getMyInvitations}
                name={"Liste des invitations"}
                invitations={invitations}
                headColumns={[
                  "laboratory",
                  "status",
                  "created_at",
                  "source_direction",
                ]}
                searched={false}
              />
            ) : (
              "Aucun invitation trouvee ..."
            )}
          </Row>
        </div>

        <Modal
          as={Modal.Dialog}
          centered
          show={showDefault}
          onHide={handleClose}
          size={"lg"}
        >
          <Modal.Header>
            <Modal.Title className="h6">
              <FontAwesomeIcon icon={faEnvelope} /> Envoyer une nouvelle
              Invitation
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={sendNewInvitation}>
              <Row>
                <Col md={"10"}>
                  <FormGroup>
                    <FormControl
                      type={"email"}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder={"Email du laboratoire ..."}
                    ></FormControl>
                  </FormGroup>
                </Col>
                <Col md={"2"}>
                  <FormGroup>
                    <Button type={"submit"} variant={"main"}>
                      <FontAwesomeIcon icon={faShare} /> Envoyer{" "}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>

        <NotificationContainer />
      </main>
    </>
  );
};

export default DentistInvitations;
