import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Dropdown,
  Form,
  Modal,
  OverlayTrigger,
  Spinner,
  Table,
  Tooltip,
} from "@themesberg/react-bootstrap";
import { translate_fr } from "../../../../lang/fr";
import Paginator from "../Paginator";
import {
  faArrowDown,
  faArrowUp,
  faBriefcaseMedical,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faEye,
  faFileAlt,
  faImage,
  faMoneyBill,
  faPaperclip,
  faPeopleCarry,
  faPlusCircle,
  faSortDown,
  faSortUp,
  faTrash,
  faTruckPickup,
} from "@fortawesome/free-solid-svg-icons";
import { config, currentUser } from "../../../../authentication/auth";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../../config/axios";
import { APIURL } from "../../../../configApi";
import CheckBox from "../CheckBox";
import moment from "moment/moment";
import "moment/locale/fr";

import { NotificationManager } from "react-notifications";
import LaboratoryService from "../../../services/LaboratoireService";
import PaginatorToBeInvoiced from "../Paginator/PaginatorToBeInvoiced";
import GearBlock from "../../../Laboratoire/invoices/GearBlock";

//import moment from "moment/moment";

moment.locale("fr");

const WorksToBeInvoiced = (props) => {
  const navigate = useNavigate();

  const [work, setWork] = useState({});
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(false);

  const [price, setPrice] = useState();
  const [page, setPage] = useState(1);
  const [user, setUser] = useState(null);
  const [current_total_invoice, setCurrentTotalInvoice] = useState(0);

  const {
    setDentist,
    dentist_id,
    pages,
    currentStatus,
    works,
    dentists,
    facturation_day,
    refreshWorks,
    updatedRows,
    updateWorks,
    doctor,
    refreshUsers,
  } = props;
  // const [total, setTotal] = useState(0);
  const editPrice = async (e) => {
    e.preventDefault();

    try {
      const response = await LaboratoryService.editWorkPrice(work.id, price);
      if (response.status == 200) {
        NotificationManager.success(
          "Prix de travail modifiee avec succes",
          "Notification"
        );
        setPrice();
        getTotal();
        setShowDefault(false);
        refreshWorks(dentist_id);
      }
    } catch (err) {
      NotificationManager.error(err.response.data.message, "Notification");
    }
  };

  useEffect(() => {
    //refreshWorks(currentStatus)
    // console.log("status ====>", currentStatus);
    // setTotal(current_total_invoice);
    // console.log("hello from to be invoiced....,Liste works", works);

    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    getTotal();
    //getWorks()
  }, []);

  const getTotal = () => {
    axiosInstance
      .get(`${APIURL}/global/invoices/${dentist_id}/current_total_invoice`)
      .then((data) => {
        // console.log("get total function ", data);
        setCurrentTotalInvoice(data.data.data.current_total_invoice);
      })
      .catch((err) => {
        // console.log("get total function ", err);
      });
  };

  return (
    <>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" className="m-4" />
        </div>
      ) : (
        <>
          <Card border="light" className="shadow-sm">
            <Card.Header>
              <div className="d-flex justify-content-between">
                <h5 className="mb-2">{"Facture en cours"}</h5>
                {/* A modifier */}
                <Button variant="outline-success" disabled>
                  {" "}
                  Total en cours : {current_total_invoice} DT
                </Button>
              </div>
            </Card.Header>
            <Table
              responsive
              hover={true}
              className="align-items-center table-flush"
            >
              <thead className="thead-light">
                <tr>
                  <th> Référence </th>

                  <th> Travail </th>
                  <th> Status </th>

                  <th> Prix </th>

                  <th>
                    <FontAwesomeIcon icon={faPaperclip} />{" "}
                  </th>

                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {works &&
                  works.map(
                    (work, index) =>
                      work.status_invoiced === "to_be_invoiced" && (
                        <tr key={index}>
                          <td>
                            <OverlayTrigger
                              placement="right"
                              trigger={["hover", "focus"]}
                              overlay={
                                <Tooltip>
                                  {`Date de creation : ` +
                                    moment
                                      .unix(work.created_at)
                                      .format("DD-MMM")}
                                  <br />
                                  {work.comment}
                                </Tooltip>
                              }
                            >
                              <a
                                className={"text-primary"}
                                onClick={() => {
                                  setPrice(100);
                                  setShowDefault(true);
                                }}
                              >
                                {work.reference_work}{" "}
                              </a>
                            </OverlayTrigger>
                          </td>
                          {/*<td> {work.requested_delivery_date} </td>*/}
                          {/*<td> {work.service.name} </td>*/}

                          <td>
                            {" "}
                            {work.service.name} ( {work.elements_numbers} ){" "}
                          </td>
                          <td> {translate_fr(work.status, "invoices")} </td>
                          <td> {work.price} DT </td>
                          <td>
                            {" "}
                            {work.attachments_files.length > 0 && (
                              <div>
                                <FontAwesomeIcon
                                  className={"fa-md"}
                                  icon={faImage}
                                />{" "}
                                ( {work.attachments_files.length} )
                              </div>
                            )}
                            {work.files_extra_finger_print.length > 0 && (
                              <div>
                                <FontAwesomeIcon
                                  className={"fa-md"}
                                  icon={faFileAlt}
                                />{" "}
                                ( {work.files_extra_finger_print.length} )
                              </div>
                            )}
                            {work.attachments_files.length == 0 &&
                              work.files_extra_finger_print.length == 0 &&
                              "vide"}
                          </td>

                          <td>
                            <Dropdown drop={faEllipsisV} className="me-2 mb-2">
                              <Dropdown.Toggle split variant="default">
                                <FontAwesomeIcon
                                  icon={faEllipsisV}
                                  className="dropdown-arrow"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                className={
                                  "user-dropdown dropdown-menu-right mt-2"
                                }
                              >
                                <Dropdown.Item
                                  className={"text-primary"}
                                  onClick={() =>
                                    navigate(
                                      "/laboratory/works/show/" + work.id
                                    )
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faEye}
                                    className="dropdown-arrow me-2"
                                  />{" "}
                                  Afficher{" "}
                                </Dropdown.Item>
                                {user?.role != "dentist" ? (
                                  <Dropdown.Item
                                    className={"text-success"}
                                    onClick={() => {
                                      setWork(work);
                                      setShowDefault(true);
                                      setPrice(work.price);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faEdit}
                                      className="dropdown-arrow me-2"
                                    />{" "}
                                    Modifier prix{" "}
                                  </Dropdown.Item>
                                ) : (
                                  ""
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      )
                  )}
              </tbody>
            </Table>
            <div className="d-flex justify-content-center mt-3">
              <PaginatorToBeInvoiced
                loader={(value) => setLoading(value)}
                totalPages={pages}
                type={"works"}
                status={currentStatus}
                activatedItem={page}
                updatePageSelected={(value) => setPage(value)}
                dentist_id={dentist_id}
                setWorks={updateWorks}
              />
            </div>

            <Modal
              as={Modal.Dialog}
              centered
              show={showDefault}
              onHide={handleClose}
              size={"lg"}
            >
              <Modal.Header>
                <Modal.Title className="h6">
                  <FontAwesomeIcon icon={faMoneyBill} /> Modifier le prix d'un
                  travail{" "}
                </Modal.Title>
                <Button
                  variant="close"
                  aria-label="Close"
                  onClick={handleClose}
                />
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={editPrice}>
                  <div className={"mb-2 d-flex justify-content-between p-2"}>
                    <input
                      className="form-control w-75"
                      placeholder="nouveau prix..."
                      onChange={(e) => setPrice(e.target.value)}
                      value={price}
                      min={"0"}
                      type={"number"}
                    />

                    <Button type={"submit"} variant={"main"}>
                      <FontAwesomeIcon icon={faPlusCircle} /> Modifier{" "}
                    </Button>
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
          </Card>

          {user && user.role === "laboratory" && (
            <GearBlock
              setDentistId={(value) => setDentist(value)}
              doctor={doctor}
              travaux={works.length}
              current_total_invoice={current_total_invoice}
              dentists={dentists}
              facturation_day={facturation_day}
              dentist_id={dentist_id}
              refreshUsers={() => refreshUsers()}
            />
          )}
        </>
      )}
    </>
  );
};

export default WorksToBeInvoiced;
