import React, {useRef, useState} from 'react';
import './theme.css';
const CustomAutoSuggest = (props) => {

    const [isFocus , setIsFocus] = useState(false);
    const [isHovred , setIsHovred] = useState(false);
    const inputRef = useRef()
    //const [value , setValue] = useState();
    const { value, onChange , suggestions  , changeValue } = props;

    return(
        <>

            <div onMouseEnter={()=>{ setIsHovred(true)}} onMouseLeave={()=>{ setIsHovred(false)}} className={'mb-2'}>
            <input
                className={'form-control'}
                value={value}
                onChange={onChange}
                onFocus={()=>setIsFocus(true)}
                onBlur={()=>{
                     if(!isHovred) setIsFocus(false)

                 }}
                ref={inputRef}
            />
            {isFocus &&
            <div className={'suggestions'}>

                {suggestions.map(
                    (sug,index) => {
                        const isMatch = sug.toUpperCase().indexOf(value.toUpperCase()) > -1;
                        return (
                            <div  key={index}>
                            {
                                isMatch && <div className={'sugg-option'}
                                                onClick={()=>{
                                    changeValue(sug)
                                inputRef.current.focus()

                                }
                                }> { sug }</div>
                            }
                            </div> )
                    })
                }


            </div>
            }
            </div>
        </>
    )
}

export default CustomAutoSuggest;