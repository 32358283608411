import React from "react";
import "../Landing/style.css";
import "./style.css";
import LandingHeader from "../Landing/Landing-Header/LandingHeader";
import CoverPage from "../Landing/cover-page/coverPage";

import LandingFooter from "../Landing/LandingFooter/LandingFooter";

import { NotificationContainer } from "react-notifications";
const Politics = () => {
  return (
    <>
      <NotificationContainer />
      <LandingHeader />
      {/* <CoverPage /> */}
      <div class={"coverContainer"}>
        <div class="container">
          <h1>Politics</h1>
          <hr />
          <p>
            Chez Easylab, nous comprenons l’importance de protéger les
            informations confidentielles de nos clients.
            <br /> Notre politique de confidentialité s’engage à assurer la
            sécurité et la confidentialité des données collectées/communiquées,
            utilisées et traitées par notre Plateforme Easylab.
            <br /> Les informations personnelles que vous nous fournissez,
            telles que les noms, les contacts, les fiches, les photos, les
            données des employés, des clients, des fournisseurs, les
            informations financières et autres données sensibles, sont traitées
            de manière confidentielle et sécurisée.
            <br /> Nous ne partageons ces informations qu’avec les parties
            autorisées impliquées dans le bon fonctionnement de votre plateforme
            Easylab et nous nous engageons à respecter les lois et les
            réglementations en vigueur relatives à la confidentialité des
            données.
          </p>
          <p>
            Pour toute question ou demande concernant notre politique de
            confidentialité ou nos mentions légales, veuillez nous contacter à
            l'adresse mail contact@easylab.tn (ou) numéro de téléphone/whatsapp
            suivant : + 216 99 838 200.
          </p>
        </div>
      </div>
      <LandingFooter />
    </>
  );
};
export default Politics;
