import { useEffect, useState } from "react";
import { axiosInstance } from "../../../../config/axios";
import { APIURL } from "../../../../configApi";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import Preloader from "../../../Shared/Preloader/Preloader";
import Sidebar from "../../../Shared/Sidebar";
import Navbar from "../../../Shared/Navbar/Navbar";
import { fr } from "date-fns/locale";
import {
  Button,
  Card,
  Col,
  Form,
  FormControl,
  FormGroup,
  Modal,
  ModalFooter,
  Row,
  Spinner,
  Tab,
  Tabs,
} from "@themesberg/react-bootstrap";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import { currentUser } from "../../../../authentication/auth";
import { translate_fr } from "../../../../lang/fr";

import TimeLine from "../../../Shared/components/TimeLine";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCalendarAlt,
  faCheckCircle,
  faCircle,
  faClosedCaptioning,
  faDownload,
  faEdit,
  faExclamationTriangle,
  faEye,
  faFile,
  faList,
  faMoneyBill,
  faPlus,
  faPlusCircle,
  faTimesCircle,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { PagesRoutes } from "../../../../pages_routes";
import ActionList from "../WorkActionList";
import WorkActionList from "../WorkActionList";
import WorkService from "../../../services/WorkService";
import FileService from "../../../services/FileService";
import { DayPicker } from "react-day-picker";
import EditPatientModal from "./ModalEditPatient";

const LaboWorkShow = () => {
  const [image, setImage] = useState({});
  const [showImageModal, setShowImageModal] = useState(false);
  const [showEditPriceModal, setShowEditPriceModal] = useState(false);
  const [showEditDatePrevuModal, setShowEditDatePrevuModal] = useState(false);
  const chooseImageToShow = (file) => {
    setImage(file);
    setShowImageModal(true);
  };
  const [datePrevu, setDatePrevu] = useState("");
  const handleCloseEditPriceModal = () => setShowEditPriceModal(false);
  const handleCloseEditDatePrevuModal = () => setShowEditDatePrevuModal(false);
  const [items, setItems] = useState([]);
  const [showCoursier, setShowCoursier] = useState(false);
  const handleClose = () => {
    setAddImageModal(false);
    setShowCoursier(false);
    setShowImageModal(false);
    setPreview("");
    setPopupModal(false);
    setShowEditPatient(false);
  };
  const handleShow = () => setShowCoursier(true);
  const [loading, setLoading] = useState(false);

  const [price, setPrice] = useState(0);
  const [showAgent, setShowAgent] = useState(false);
  const handleCloseAgent = () => setShowAgent(false);
  const handleShowAgent = () => setShowAgent(true);
  const [update, setUpdate] = useState(0);

  const navigate = useNavigate();
  const [user, setUser] = useState();
  let { id } = useParams();
  const [work, setWork] = useState();
  const [activities, setActivities] = useState([]);
  const [coursiers, setCoursiers] = useState([]);
  const [coursier_id, setCoursier_id] = useState();

  const [agents, setAgents] = useState([]);
  const [agent_id, setAgent_id] = useState();

  const [addImageModal, setAddImageModal] = useState(false);

  const [file, setFile] = useState();
  const [preview, setPreview] = useState("");
  const [localFiles, setLocalFiles] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingEmpreinte, setLoadingEmpreinte] = useState(false);
  const [disabledUploadImage, setDisabledUploadImage] = useState(true);
  const [disabledUploadEmpreint, setDisabledUploadEmpreint] = useState(true);
  const [showEditPatient, setShowEditPatient] = useState(false);
  const [idFileDeleted, setIdFileDeleted] = useState();
  const [popupModal, setPopupModal] = useState(false);

  const editDatePrevuWork = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Create a new Date object from the timestamp string
    var date = new Date(datePrevu);

    // Extract components
    var year = date.getFullYear();
    var month = ("0" + (date.getMonth() + 1)).slice(-2); // Month is zero-based, so we add 1 and pad with leading zero if needed
    var day = ("0" + date.getDate()).slice(-2); // Pad with leading zero if needed
    var hour = ("0" + date.getHours()).slice(-2); // Pad with leading zero if needed
    var minute = ("0" + date.getMinutes()).slice(-2); // Pad with leading zero if needed
    var second = ("0" + date.getSeconds()).slice(-2); // Pad with leading zero if needed

    // Format the date and time
    var formattedDate =
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;

    //console.log(formattedDate);

    try {
      const response = await WorkService.editDatePrevu(id, {
        foreseen_delivery_date: formattedDate,
      });
      if (response.status === 200) {
        NotificationManager.success("Date prevu modifié avec succès");
        // console.log(response);
        getCurrentWorkData();
        setUpdate((prev) => prev + 1);
        setLoading(false);
        setShowEditDatePrevuModal(false);
      }
    } catch (err) {
      setLoading(false);
      // console.log(err);
    }
  };
  const assignedToCoursier = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosInstance
      .put(`${APIURL}/laboratory/works/${id}/affected_to_courier`, {
        courier_id: coursier_id,
      })
      .then((res) => {
        NotificationManager.success(
          "Coursier assignee avec succes",
          "Notification"
        );
        handleClose();
        setCoursier_id(null);
        getCurrentWorkData();
        setLoading(false);
      })
      .catch((err) => {
        NotificationManager.error(err.response.data.message, "Notification");
        setLoading(false);
      });
  };

  const downloadFile = async (id) => {
    // console.log("download file", id);

    fetch(`https://api.easylab.tn/global/download_files/${id}/extra_finger`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "file.ply";
        alink.click();
      });
    });
  };

  const downloadImage = async (id) => {
    // console.log("download file", id);

    fetch(
      `https://api.easylab.tn/global/download_files/${id}/attachments_file`,
      {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    ).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "file.png";
        alink.click();
      });
    });
  };

  const assignedToAgent = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosInstance
      .put(`${APIURL}/laboratory/works/${id}/affected_to_agent`, {
        accessible_id: agent_id,
      })
      .then((res) => {
        NotificationManager.success(
          "Agent assignee avec succes",
          "Notification"
        );
        setLoading(false);
        handleCloseAgent();
        setAgent_id(null);
        getCurrentWorkData();
      })
      .catch((err) => {
        NotificationManager.error(err.response.data.message, "Notification");
        setLoading(false);
      });
  };

  useEffect(() => {
    moment.locale("fr");
    if (currentUser() == null) {
      navigate("/login");
    } else {
      setUser(currentUser());
    }
    getCurrentWorkData();
    getWorkActivities();
    getCoursiers();
    getAgents();
  }, [update]);

  const getCoursiers = () => {
    axiosInstance
      .get(`${APIURL}/laboratory/couriers/all`)
      .then((res) => setCoursiers(res.data.data))
      .catch((err) => {}); // console.log(err));
  };

  const getAgents = () => {
    axiosInstance
      .get(`${APIURL}/laboratory/agents/all`)
      .then((res) => setAgents(res.data.data))
      .catch((err) => {}); // console.log(err));
  };

  const getWorkActivities = () => {
    axiosInstance
      .get(`${APIURL}/global/works/${id}/activities`)
      .then((res) => {
        let local_items = [];
        setActivities(res.data.data);
        res.data.data.forEach((a) => {
          local_items.push({
            date: a.created_at,
            content: a.content,
          });
        });
        setItems(local_items);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const getCurrentWorkData = async () => {
    try {
      setLoading(true);
      const response = await WorkService.getOneById(id);
      if (response.status === 200) {
        // console.log(response);
        setWork(response.data.data);
        setLoading(false);
      }
    } catch (err) {
      // console.log(err);
      setLoading(false);
    }
  };

  const editPriceWork = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await WorkService.editPrice(id, { price: price });
      if (response.status === 200) {
        NotificationManager.success("Prix modifié avec succès");
        // console.log(response);
        getCurrentWorkData();
        setUpdate((prev) => prev + 1);
        setLoading(false);
        setShowEditPriceModal(false);
      }
    } catch (err) {
      setLoading(false);
      // console.log(err);
    }
  };

  const deleteFile = async (workId, fileId) => {
    // console.log("Delete file ===>", workId, fileId);
    try {
      const response = await FileService.delete(workId, fileId);
      if (response.status === 200) {
        NotificationManager.success(response.data.message, "Notification");
        setUpdate((prev) => prev + 1);
        handleClose();
      }
    } catch (err) {
      // console.log(err);
      NotificationManager.error(err?.response?.data?.message, "Notification");
    }
  };

  const Popupdelete = (id) => {
    setIdFileDeleted(id);
    setPopupModal(true);
  };
  const updateWork = async (data) => {
    try {
      const response = await WorkService.update(work.id, data);
      // console.log("updated work ===>", response.data);
      if (response.status === 200) {
        NotificationManager.success(
          "Image téléchargée avec succès",
          "Notification"
        );
      }
    } catch (err) {
      NotificationManager.error(err?.response?.data?.message, "Notification");
    }
  };
  const chooseFile = async (e) => {
    e.preventDefault();
    if (e.target.files[0] == file) {
      NotificationManager.error(
        "Vous avez déjà téléchargé une photo avec le même nom. Verifiez SVP",
        "Notification"
      );
    } else {
      try {
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setPreview(objectUrl);
        setFile(e.target.files[0]);
        setDisabledUploadImage(false);
      } catch (err) {}
    }
  };

  const uploadFile = async () => {
    setLoadingImage(true);
    try {
      const data = new FormData();
      data.append("attachments_file", file);
      const response = await FileService.uploadImage(data, work.id);
      if (response.status === 200) {
        const lfiles = localFiles;
        const files = work.attachments_files;
        files.push(response.data.data?.id);
        lfiles.push(response.data.data);
        setLocalFiles(lfiles);
        work.attachments_files = files;

        // console.log("work updated ==>", work);
        //updateWork(work);
        //getCurrentWorkData()
        handleClose();
        // console.log(lfiles);
        setUpdate((prev) => prev + 1);
        setLoadingImage(false);
        setDisabledUploadImage(true);
      }
    } catch (err) {
      // console.log(err);
      NotificationManager.error(err.response?.data?.message, "Notification");
      setLoadingImage(false);
      setDisabledUploadImage(true);
      handleClose();
    }
  };
  return (
    <>
      <Preloader />
      <Sidebar user={user} />

      <main className="content" style={{ margin: "0px !important" }}>
        <Navbar user={user} pageName={"Détails du travail"} icon={faFile} />
        <hr />
        <div>
          <NotificationContainer />
          {loading ? (
            <Spinner animation="border" className="m-4" />
          ) : (
            <>
              {" "}
              {work && (
                <>
                  <div className={"mb-4 d-flex justify-content-between"}>
                    <h2>Travail : {work.reference_work} </h2>
                    <WorkActionList
                      work={work}
                      couriers={coursiers}
                      agents={agents}
                    />
                    <div>
                      {/*<Button variant={'main'}> <FontAwesomeIcon icon={faCheckCircle} /> Valider </Button>*/}

                      <Button
                        variant={"main ms-2"}
                        onClick={() => navigate(PagesRoutes.LaboWorks.path)}
                      >
                        {" "}
                        <FontAwesomeIcon icon={faList} /> Voir liste travaux{" "}
                      </Button>
                    </div>
                  </div>
                  <Row>
                    <Col sm={8} className={"mb-2"}>
                      <Card className={"p-3"}>
                        <Card.Title
                          className={
                            "d-flex flex-lg-row flex-column justify-content-between"
                          }
                        >
                          <h5>
                            {" "}
                            Dentiste :{" "}
                            <span className={"text-main"}>
                              {work.dentist.first_name} ({" "}
                              {" " + work.dentist?.city?.name})
                            </span>{" "}
                          </h5>
                          {work.real_reference && (
                            <h5>
                              {" "}
                              N° Fiche :
                              <span className={"text-main"}>
                                {" " + work?.real_reference}
                              </span>
                            </h5>
                          )}

                          {work.full_name_patient ? (
                            <h5>
                              {" "}
                              Patient :{" "}
                              <span className={"text-main"}>
                                {work.full_name_patient?.toUpperCase()}
                                {work.age_patient &&
                                  " " +
                                    translate_fr(work.age_patient, "agents") +
                                    "ans (" +
                                    translate_fr(work.sex_patient, "agents") +
                                    ")"}
                              </span>
                            </h5>
                          ) : (
                            ""
                          )}
                          {work.status != "archived" && (
                            <Button
                              variant={"main m-2"}
                              title={"Editer les informations du patient"}
                              onClick={() => setShowEditPatient(true)}
                            >
                              <FontAwesomeIcon icon={faEdit} />{" "}
                            </Button>
                          )}
                        </Card.Title>
                      </Card>

                      <Card className={"mt-2 p-3 "}>
                        <Card.Title
                          className={
                            "d-flex flex-lg-row flex-column justify-content-between"
                          }
                        >
                          <div>
                            <h5> Nature </h5>
                            <h5 className={"text-main"}>
                              {" "}
                              {work.service?.name?.toUpperCase()}{" "}
                            </h5>
                            <div
                              className={
                                "d-flex flex-lg-row flex-column justify-content-between"
                              }
                            >
                              <h5> Elements </h5>
                              <h5 className={"text-main mx-4"}>
                                {" "}
                                {work.elements_numbers}{" "}
                              </h5>
                              <h5> Teinte </h5>
                              <h5 className={"text-main mx-4"}>
                                {" "}
                                {work.shade?.toUpperCase()}{" "}
                              </h5>
                            </div>
                          </div>
                          <div className="me-2">
                            {work.is_urgent ? (
                              <h5
                                className={"p-2 text-center text-white"}
                                style={{ backgroundColor: "#e74c3c" }}
                              >
                                Travail urgent
                              </h5>
                            ) : (
                              ""
                            )}
                            {work.requested_delivery_date && (
                              <>
                                <h5>Date de livraison souhaitée :</h5>

                                <h5 className={"text-main"}>
                                  {moment
                                    .unix(work.requested_delivery_date)
                                    .format("dddd") + " "}
                                  {moment
                                    .unix(work.requested_delivery_date)
                                    .format("DD-MM-YYYY")}
                                </h5>
                              </>
                            )}
                            <div className="d-flex justify-content-between">
                              <h5>Date prévu pour </h5>

                              <Button
                                className="ms-2 mb-2 btn-sm"
                                variant={"main"}
                                onClick={() => setShowEditDatePrevuModal(true)}
                              >
                                {" "}
                                <FontAwesomeIcon icon={faCalendar} />{" "}
                              </Button>
                            </div>

                            {work.foreseen_delivery_date ? (
                              <h5 className={"text-main"}>
                                {moment
                                  .unix(work.foreseen_delivery_date)
                                  .format("dddd") + " "}
                                {moment
                                  .unix(work.foreseen_delivery_date)
                                  .format("DD-MM-YYYY")}
                              </h5>
                            ) : (
                              ""
                            )}
                          </div>
                        </Card.Title>
                      </Card>

                      <Card className={"mt-2 p-3"}>
                        <div className={""}>
                          <div>
                            <div className="d-flex justify-content-between">
                              <h5> Photos : </h5>
                              {work.status != "archived" && (
                                <Button
                                  variant={"main"}
                                  onClick={() => setAddImageModal(true)}
                                >
                                  {" "}
                                  <FontAwesomeIcon icon={faPlus} />{" "}
                                </Button>
                              )}
                            </div>

                            <div className="d-flex">
                              {work.attachments_files.map((file, index) =>
                                index < 3 ? (
                                  <div
                                    key={index}
                                    className="border border-1 me-1 p-1"
                                  >
                                    <div className="d-flex justify-content-between">
                                      <span> Fichier {index + 1} </span>
                                      <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        onClick={() => Popupdelete(file.id)}
                                      />
                                    </div>

                                    <div>
                                      <img
                                        src={`${file.full_path}`}
                                        width={"100"}
                                        onClick={() => {
                                          chooseImageToShow(file);
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </Card>
                      <Card className={"mt-2 p-3"}>
                        <div className={""}>
                          <div>
                            <div className="d-flex justify-content-between">
                              <h5> Empreintes digitales : </h5>
                            </div>

                            <div className="d-flex">
                              {work.files_extra_finger_print.map(
                                (file, index) =>
                                  index < 3 ? (
                                    <div
                                      key={index}
                                      className="w-50 border border-1 border-success me-1 p-1"
                                    >
                                      <div className="d-flex justify-content-between align-items-center">
                                        <span> Empreinte {index + 1} </span>
                                        <Button
                                          variant={"main"}
                                          onClick={() => downloadFile(file.id)}
                                        >
                                          {" "}
                                          <FontAwesomeIcon icon={faDownload} />
                                        </Button>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )
                              )}
                            </div>
                          </div>
                        </div>
                      </Card>
                      <div className={"mt-2"}>
                        <Row>
                          <Col>
                            <Card className={"p-3"}>
                              <h5> Commentaires : </h5>
                              <div>{work.comment}</div>
                            </Card>
                          </Col>
                          <Col>
                            <Card className={"p-3"}>
                              <div className={"d-flex justify-content-between"}>
                                <h5> Prix : </h5>
                                <div>
                                  {work.status != "archived" && (
                                    <Button
                                      variant={"main"}
                                      onClick={() => {
                                        setPrice(work.price);
                                        setShowEditPriceModal(true);
                                      }}
                                    >
                                      {" "}
                                      <FontAwesomeIcon icon={faEdit} />{" "}
                                    </Button>
                                  )}
                                </div>
                              </div>

                              <div>{work.price} DT</div>
                            </Card>

                            <Card className={"mt-2 p-3"}>
                              <div className={"d-flex justify-content-between"}>
                                <h5> Agent : </h5>
                                <div>
                                  {work.status != "archived" && (
                                    <Button
                                      variant={"main"}
                                      onClick={() => {
                                        handleShowAgent();
                                        setAgent_id(work?.accessible_id);
                                      }}
                                    >
                                      {" "}
                                      <FontAwesomeIcon icon={faEdit} />{" "}
                                    </Button>
                                  )}
                                </div>
                              </div>

                              <div>
                                {work.accessible_id && (
                                  <h4 style={{ color: "#3498db" }}>
                                    {" "}
                                    {work.accessible.last_name}{" "}
                                    {work.accessible.first_name}{" "}
                                  </h4>
                                )}
                              </div>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col sm={4} className={"mb-2"}>
                      <Card>
                        <Card.Title
                          className={
                            "p-3 d-flex align-items-center justify-content-between"
                          }
                        >
                          Statut Actuel{" "}
                          <span className={"p-2 bg-danger"}>
                            {translate_fr(work.status, "agents")}
                          </span>
                        </Card.Title>

                        <div className={"p-3"}>
                          <div className={"d-flex justify-content-between"}>
                            <h5> Coursier : </h5>
                            <div>
                              {work.status != "archived" && (
                                <Button
                                  variant={"main"}
                                  onClick={() => {
                                    handleShow();
                                    setCoursier_id(work?.courier_id);
                                  }}
                                >
                                  {" "}
                                  <FontAwesomeIcon icon={faEdit} />{" "}
                                </Button>
                              )}
                            </div>
                          </div>

                          <div>
                            {work.courier_id && (
                              <h4 style={{ color: "#3498db" }}>
                                {" "}
                                {work.courier.last_name}{" "}
                                {work.courier.first_name}{" "}
                              </h4>
                            )}
                          </div>
                        </div>
                      </Card>
                      <div className={"mt-2"}>
                        {activities.length > 0 && items.length > 0 ? (
                          <TimeLine items={items} />
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </div>
      </main>

      <Modal
        as={Modal.Dialog}
        centered
        show={showCoursier}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faMoneyBill} /> Assigner un coursier{" "}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {coursiers && coursiers.length > 0 ? (
            <Form onSubmit={assignedToCoursier}>
              <div className={"mb-2"}>
                <label className={"mb-2"}> Coursier </label>
                <select
                  className="form-control"
                  value={coursier_id}
                  onChange={(e) => setCoursier_id(e.target.value)}
                >
                  <option value=""> == Choisir le coursier == </option>
                  {coursiers.map((d) => (
                    <option key={d.id} value={d.id}>
                      {" "}
                      {d.last_name + " " + d.first_name}{" "}
                    </option>
                  ))}
                </select>
              </div>

              <div className={"d-flex justify-content-end mb-2"}>
                <Button type={"submit"} variant={"main"}>
                  {loading ? (
                    <Spinner animation="border" />
                  ) : (
                    <>
                      {" "}
                      <FontAwesomeIcon icon={faPlusCircle} />
                      {" Assigner "}{" "}
                    </>
                  )}
                </Button>
              </div>
            </Form>
          ) : (
            <div>
              <p>
                {" "}
                Vous n'avez aucun coursier inscrit dans votre liste. Ajouter un
                nouveau{" "}
              </p>
              <div className={"d-flex justify-content-center"}>
                <Button
                  variant={"main"}
                  onClick={() => navigate("/laboratory/coursiers/add")}
                >
                  Ajouter un coursier
                </Button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        as={Modal.Dialog}
        centered
        show={showAgent}
        onHide={handleCloseAgent}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faMoneyBill} /> Assigner l'agent{" "}
          </Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleCloseAgent}
          />
        </Modal.Header>
        <Modal.Body>
          {agents && agents.length > 0 ? (
            <Form onSubmit={assignedToAgent}>
              <div className={"mb-2"}>
                <label className={"mb-2"}> Agent </label>
                <select
                  className="form-control"
                  value={agent_id}
                  onChange={(e) => setAgent_id(e.target.value)}
                >
                  <option value=""> == Choisir l'agent == </option>
                  {agents.map((d) => (
                    <option value={d.id}>
                      {" "}
                      {d.last_name + " " + d.first_name}{" "}
                    </option>
                  ))}
                </select>
              </div>

              <div className={"d-flex justify-content-end mb-2"}>
                <Button type={"submit"} variant={"main"}>
                  {loading ? (
                    <Spinner animation="border" />
                  ) : (
                    <>
                      {" "}
                      <FontAwesomeIcon icon={faPlusCircle} />
                      {" Assigner "}{" "}
                    </>
                  )}
                </Button>
              </div>
            </Form>
          ) : (
            <div>
              <p>
                {" "}
                Vous n'avez aucun agent inscrit dans votre liste. Ajouter un
                nouveau{" "}
              </p>
              <div className={"d-flex justify-content-center"}>
                <Button
                  variant={"main"}
                  onClick={() => navigate("/laboratory/agents/add")}
                >
                  Ajouter un agent
                </Button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        as={Modal.Dialog}
        centered
        show={showEditDatePrevuModal}
        onHide={handleCloseEditDatePrevuModal}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faMoneyBill} /> Changer la date prévue de
            sortie{" "}
          </Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleCloseEditDatePrevuModal}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={editDatePrevuWork}>
            <div className={"mb-2"}>
              {/* <Form.Control
                className="form-control"
                value={datePrevu}
                type={"datetime-local"}
                onChange={(e) => setDatePrevu(e.target.value)}
              ></Form.Control> */}
              <div className="d-flex  justify-content-center">
                <label className={"mb-2"}> Prévu pour le </label>
              </div>
              <div className="d-flex  justify-content-center">
                <DayPicker
                  mode="single"
                  selected={datePrevu}
                  onSelect={setDatePrevu}
                  // footer={footer}
                  locale={fr}
                  fromDate={new Date(moment().add(0, "days"))}
                />
                {/* <DateTimePicker /> */}
              </div>
            </div>

            <div className={"d-flex justify-content-end mb-2"}>
              <Button type={"submit"} variant={"main"}>
                {loading ? (
                  <Spinner animation="border" />
                ) : (
                  <>
                    {" "}
                    <FontAwesomeIcon icon={faPlusCircle} /> Changer{" "}
                  </>
                )}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        as={Modal.Dialog}
        centered
        show={showEditPriceModal}
        onHide={handleCloseEditPriceModal}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faMoneyBill} /> Changer le prix{" "}
          </Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleCloseEditPriceModal}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={editPriceWork}>
            <div className={"mb-2"}>
              <label className={"mb-2"}> Nouveau prix </label>
              <Form.Control
                className="form-control"
                value={price}
                type={"number"}
                min={"0"}
                onChange={(e) => setPrice(e.target.value)}
              ></Form.Control>
            </div>

            <div className={"d-flex justify-content-end mb-2"}>
              <Button type={"submit"} variant={"main"}>
                {loading ? (
                  <Spinner animation="border" />
                ) : (
                  <>
                    {" "}
                    <FontAwesomeIcon icon={faPlusCircle} /> Changer{" "}
                  </>
                )}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {image && image.id ? (
        <Modal
          as={Modal.Dialog}
          centered
          show={showImageModal}
          onHide={handleClose}
          size={"lg"}
        >
          <Modal.Header>
            <Modal.Title className="h6">
              <FontAwesomeIcon icon={faEye} /> Afficher Image{" "}
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 text-center">
              <img src={image.full_path} />
            </div>
          </Modal.Body>
          <ModalFooter>
            <a onClick={() => downloadImage(image.id)}>
              <Button type="button" variant="success">
                <FontAwesomeIcon icon={faDownload} /> Telecharger{" "}
              </Button>
            </a>
          </ModalFooter>
        </Modal>
      ) : (
        ""
      )}

      <Modal
        as={Modal.Dialog}
        centered
        show={addImageModal}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faPlusCircle} /> Ajouter une image{" "}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <img src={preview} width={"150"} />
          <input
            type="file"
            className="form-control mb-2"
            onChange={(e) => chooseFile(e)}
          />
          <Button onClick={() => uploadFile()}>
            <FontAwesomeIcon icon={faUpload} /> Telecharger{" "}
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        as={Modal.Dialog}
        centered
        show={popupModal}
        onHide={handleClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            <FontAwesomeIcon icon={faEye} /> Alert de suppression du fichier{" "}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className={"text-center"}>
            <h2>
              {" "}
              <FontAwesomeIcon icon={faExclamationTriangle} /> ATTENTION !{" "}
            </h2>
            Voulez-vous vraiment supprimer ce fichier !
          </div>
        </Modal.Body>
        <ModalFooter>
          <Button variant={"success"} onClick={handleClose}>
            Annuler
          </Button>

          <Button
            variant={"danger"}
            onClick={() => deleteFile(work.id, idFileDeleted)}
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>
      <EditPatientModal
        show={showEditPatient}
        handleClose={() => handleClose()}
        work={work}
        setShow={(value) => setShowEditPatient(value)}
        setWork={() => getCurrentWorkData()}
      />
    </>
  );
};

export default LaboWorkShow;
