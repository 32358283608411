import { Badge, Form } from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { APIURL } from "../../../../configApi";
import axios from "axios";
import { config } from "../../../../authentication/auth";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

export default function (props) {
  const { checked, id, type } = props;
  const [localCheck, setLocalCheck] = useState(checked);
  const [url, setUrl] = useState("");

  const ChangeUserStatus = () => {
    if (type && type === "dentist") {
      axios
        .patch(
          `${APIURL}/laboratory/dentists/temporary/${id}/block`,
          { is_active: !checked },
          {
            headers: {
              Authorization: "Bearer " + config(),
            },
          }
        )
        .then((res) => {
          setLocalCheck((prevState) => !prevState);
          if (!localCheck)
            NotificationManager.success("Dentiste activé", "Notification");
          else NotificationManager.error("Dentiste désactivé", "Notification");
        })
        .catch((err) => {}); // console.log(err)); //
    } else {
      axios
        .put(
          `${APIURL}/laboratory/agents/${id}/change_status`,
          { is_active: !checked },
          {
            headers: {
              Authorization: "Bearer " + config(),
            },
          }
        )
        .then((res) => {
          setLocalCheck((prevState) => !prevState);
          if (!localCheck)
            NotificationManager.success("Agent activé", "Notification");
          else NotificationManager.error("Agent désactivé", "Notification");
        })
        .catch((err) => {}); // console.log(err)); //
    }
  };

  return (
    <>
      <Form.Check
        type="switch"
        id="custom-switch"
        checked={localCheck}
        onChange={ChangeUserStatus}
      />
    </>
  );
}
