const { axiosInstance } = require("../config/axios");
const { APIURL } = require("../configApi");

const ResetPasswordService = {};
ResetPasswordService.checkmail = (email) => {
  return axiosInstance.post(`${APIURL}/global/forgot_password/check_email`, {
    type_api: "check_email",
    email: email,
  });
};

ResetPasswordService.checkCode = ({ email, code }) => {
  return axiosInstance.post(`${APIURL}/global/forgot_password/check_code`, {
    type_api: "check_code",
    email: email,
    code: code,
  });
};

ResetPasswordService.updatePassword = ({ email, code, password }) => {
  return axiosInstance.post(
    `${APIURL}/global/forgot_password/change_password`,
    {
      type_api: "change_password",
      email: email,
      code: code,
      password: password,
    }
  );
};

export default ResetPasswordService;
