import { Navigate } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import React from "react";
export function LaboRoute({ children }) {
  const authUser = JSON.parse(localStorage.getItem("user") || "{}");
  const history = useNavigate();

  if (authUser) {
    // not logged in so redirect to login page with the return url
    if (authUser?.role === "laboratory") {
      return children;
    }
  }

  // authorized so return child components

  return <Navigate to="/login" state={{ from: history.location }} />;
}
